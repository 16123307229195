import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["spinnerSize", "height", "width", "color"],
    _excluded2 = ["height", "width"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { Flex } from 'rebass/styled-components';
export function FullPageLoader(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#61a58b' : _ref$color;
  return __jsx(Flex, {
    justifyContent: "center",
    alignItems: "center",
    style: {
      height: '50vh',
      minHeight: '700px'
    }
  }, __jsx(ClipLoader, {
    color: color,
    size: 100
  }));
}
/**
 * @type {JSX.Element}
 *
 */

export function SmallLoader(_ref2) {
  var _ref2$spinnerSize = _ref2.spinnerSize,
      spinnerSize = _ref2$spinnerSize === void 0 ? null : _ref2$spinnerSize,
      _ref2$height = _ref2.height,
      height = _ref2$height === void 0 ? 60 : _ref2$height,
      _ref2$width = _ref2.width,
      width = _ref2$width === void 0 ? null : _ref2$width,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? '#61a58b' : _ref2$color,
      props = _objectWithoutProperties(_ref2, _excluded);

  return __jsx(Container, _extends({
    height: height,
    width: width
  }, props), __jsx(ClipLoader, {
    color: color,
    size: spinnerSize || height
  }));
}
export function SkeletonLoader(_ref3) {
  var _ref3$height = _ref3.height,
      height = _ref3$height === void 0 ? 60 : _ref3$height,
      _ref3$width = _ref3.width,
      width = _ref3$width === void 0 ? null : _ref3$width,
      props = _objectWithoutProperties(_ref3, _excluded2);

  return __jsx(Container, _extends({
    className: "skeleton-loading",
    height: height,
    width: width
  }, props), __jsx(Skeleton, null));
}
var Container = styled(Flex).withConfig({
  displayName: "loading__Container",
  componentId: "sc-1w869cj-0"
})(["width:", ";height:", ";display:flex;justify-content:center;align-items:center;.skeleton-loading{padding:0;justify-content:flex-end;align-items:flex-start;}"], function (_ref4) {
  var width = _ref4.width;
  return width ? "".concat(width, "px") : '100%';
}, function (_ref5) {
  var height = _ref5.height;
  return "".concat(height - 10, "px");
});
var Skeleton = styled.div.withConfig({
  displayName: "loading__Skeleton",
  componentId: "sc-1w869cj-1"
})(["height:75%;width:50%;animation:skeleton-loading 1s linear infinite alternate;background-color:", ";border-radius:4px;margin-left:auto;@keyframes skeleton-loading{0%{background-color:hsl(200,0%,90%);}100%{background-color:hsl(200,0%,70%);}}"], function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.grey[95];
});