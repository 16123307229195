import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useApolloClient } from '@apollo/react-hooks';
import useTranslation from 'hooks/use-translation';
import sendPasswordResetEmailV2 from 'shared/graphql/password/mutations/send-password-reset-email-v2.gql';
import useErnie from 'shared/hooks/use-ernie';
import { ERNIE_DEFAULT_MESSAGES, ERNIE_TIMEOUTS, ERNIE_TYPES } from 'shared/constants';

type UseResetPasswordResult = {
  resetPassword: ({ email }: { email: string }) => Promise<void>;
};

export const useResetPassword = (): UseResetPasswordResult => {
  const apolloClient = useApolloClient();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const showErnie = useErnie();
  const { t } = useTranslation();

  const ernieMessages = {
    success: t('resetPassword.success', 'An email has been sent with instructions'),
    failure: t('common.ernieError', '{{errorMessage}}', {
      errorMessage: ERNIE_DEFAULT_MESSAGES.ERROR,
    }),
  };
  const onFail = (): void => showErnie(ernieMessages.failure, ERNIE_TYPES.DANGER, ERNIE_TIMEOUTS.LONG);

  const resetPassword = async ({ email }): Promise<void> => {
    try {
      const response = await apolloClient.mutate({ mutation: sendPasswordResetEmailV2, variables: { email } });
      const { success } = response.data.sendPasswordResetEmailV2;

      if (success) {
        showErnie(ernieMessages.success, ERNIE_TYPES.SUCCESS, ERNIE_TIMEOUTS.SHORT);
        DutchiePayEnrollment.resetPasswordSucceeded();
      } else {
        onFail();
      }
    } catch (err) {
      console.error(err);
      onFail();
    }
  };

  return {
    resetPassword,
  };
};
