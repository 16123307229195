import moment from 'moment';
import _ from 'lodash';
import { integrationsByCategory } from 'shared/core/constants/integrations';
import { flagIsEnabledForDispensary } from 'shared/utils/evaluate-dispensary-feature-flag';
export var formatBirthdayForRewards = function formatBirthdayForRewards(dateString) {
  var date = moment(dateString, ['MM-DD-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY']);

  if (date.isValid()) {
    return date === null || date === void 0 ? void 0 : date.format('YYYY-MM-DD');
  }

  return '';
};
export var rewardsProgramEnabled = function rewardsProgramEnabled(cart) {
  var _cart$order;

  var featureFlags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var rewardAdapters = _.keys(integrationsByCategory.rewards);

  var _cart$order$dispensar = (_cart$order = cart.order) === null || _cart$order === void 0 ? void 0 : _cart$order.dispensary,
      consumerDispensaryIntegrations = _cart$order$dispensar.consumerDispensaryIntegrations,
      dispensaryId = _cart$order$dispensar.id; // Fyllo is currently behind a flag.


  var fylloFlagEnabled = flagIsEnabledForDispensary('rollout.enable-fyllo-integration', featureFlags, dispensaryId);
  return !!_.find(rewardAdapters, function (adapter) {
    var _consumerDispensaryIn;

    var integrationEnabled = consumerDispensaryIntegrations === null || consumerDispensaryIntegrations === void 0 ? void 0 : (_consumerDispensaryIn = consumerDispensaryIntegrations[adapter]) === null || _consumerDispensaryIn === void 0 ? void 0 : _consumerDispensaryIn.enabled;
    return adapter === 'fyllo' ? fylloFlagEnabled && integrationEnabled : integrationEnabled;
  });
};