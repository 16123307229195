/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export const DiscountTag = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.04327 10.1914L0.303358 6.45152C0.109059 6.25606 0 5.99165 0 5.71605C0 5.44045 0.109059 5.17605 0.303358 4.98059L4.78395 0.5H10V5.71605L5.51941 10.1914C5.42252 10.2884 5.30747 10.3654 5.18083 10.4179C5.05418 10.4704 4.91843 10.4974 4.78134 10.4974C4.64425 10.4974 4.5085 10.4704 4.38185 10.4179C4.25521 10.3654 4.14015 10.2884 4.04327 10.1914ZM7 4.5C7.55228 4.5 8 4.05228 8 3.5C8 2.94772 7.55228 2.5 7 2.5C6.44772 2.5 6 2.94772 6 3.5C6 4.05228 6.44772 4.5 7 4.5Z'
      fill='currentColor'
    />
  </svg>
);
