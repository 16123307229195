/* eslint-disable @typescript-eslint/no-empty-function */

import { USStatesMap } from 'shared/constants/cities-geography';
import { GqlDispensaries } from 'types/graphql';
import { Dispensary, CommerceComponentsDataInterface, DispensaryHoursSettings, OrderTypesConfigV2 } from './types';

const mapDispensaryToRemote = (dispensary: GqlDispensaries): Dispensary => {
  const enabledOrderTypes = dispensary.enabledOrderTypes ?? {};

  return {
    id: dispensary.id,
    status: dispensary.status ?? '',
    name: dispensary.name ?? '',
    cname: dispensary.cName ?? '',
    chain: dispensary.chain ?? '',
    phone: dispensary.phone ?? '',
    email: dispensary.email ?? '',
    hours: (dispensary.hoursSettings ?? {}) as DispensaryHoursSettings,
    orderTypes: {
      pickup: !!enabledOrderTypes.pickup,
      inStorePickup: !!enabledOrderTypes.inStorePickup,
      curbsidePickup: !!enabledOrderTypes.curbsidePickup,
      driveThruPickup: !!enabledOrderTypes.driveThruPickup,
      delivery: !!enabledOrderTypes.delivery,
      kiosk: !!enabledOrderTypes.kiosk,
    },
    images: {
      logo: dispensary.logoImage ?? '',
    },
    address: {
      street1: dispensary.location?.ln1 ?? '',
      street2: dispensary.location?.ln2 ?? '',
      city: dispensary.location?.city ?? '',
      state: dispensary.location?.state ? USStatesMap[dispensary.location.state] : undefined,
      stateAbbreviation: dispensary.location?.state ?? '',
      zip: dispensary.location?.zipcode ?? '',
    },
    links: {
      website: '',
      storeFrontRoot: '',
    },
    orderTypesConfig: dispensary.orderTypesConfigV2 as OrderTypesConfigV2,
  };
};

const prepareDataForRemote = (
  dispensary?: GqlDispensaries,
  dispensaryChainLocations?: GqlDispensaries[]
): CommerceComponentsDataInterface => ({
  currentLocation: dispensary ? mapDispensaryToRemote(dispensary) : undefined,
  dataLoaders: {
    getAllLocations: async () => (dispensaryChainLocations ? dispensaryChainLocations.map(mapDispensaryToRemote) : []),
  },
  actions: {
    goToInfoPage: () => {},
    goToStoreFront: () => {},
  },
});

export { prepareDataForRemote };
