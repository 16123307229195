import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ProductRecommendationSection } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { Category } from 'src/generics/data';
import { ProductCarousel } from 'src/components/product-carousel';
import { ProductCarousel as ProductCarouselV2 } from 'src/components/carousel-v2/types/product-carousel';

import { MIN_PRODUCTS } from './often-purchased-with-carousel.constants';
import { useOftenPurchasedWithCarousel } from './use-often-purchased-with-carousel';

export type OftenPurchasedWithCarouselProps = {
  productId: string;
  category: Category;
};

export function OftenPurchasedWithCarousel(props: OftenPurchasedWithCarouselProps): JSX.Element | null {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const carouselProps = useOftenPurchasedWithCarousel(props);
  const { products, loading } = carouselProps;
  const label = `Often bought with`;

  if (!loading && !products.length) {
    return null;
  }

  if (isProductCardsV2Enabled) {
    return <ProductCarouselV2 products={products} isLoading={loading} title={label} />;
  }

  return (
    <ProductCarousel
      heading={label}
      minimumProducts={MIN_PRODUCTS}
      trackerSection={ProductRecommendationSection.oftenPurchasedWith}
      {...carouselProps}
    />
  );
}
