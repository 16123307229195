import LogRocket from 'shared/logrocket';

const TRACK = 'track';
const IDENTIFY = 'identify';

type LogRocketUtils = {
  track: (tracker: string, options?: Options) => void;
  identify: (tracker: string, options?: Options) => void;
};

type PossibleOptions = {
  financialInstitution: string;
  name: string;
  email: string;
  checkoutToken: string;
  lastEnrollmentStep: string;
  reason: string;
};

type Options = Partial<PossibleOptions>;

export default function useLogRocket(): LogRocketUtils {
  const errorHandler = (utilType: string, identifier: string, options: Options = {}): void => {
    try {
      if (LogRocket[utilType]) {
        LogRocket[utilType](identifier, options);
      }
    } catch (e) {
      console.log(`Couldn't set logrocket info.`);
      console.error(e);
    }
  };

  const track = (tracker: string, options?: Options): void => {
    errorHandler(TRACK, tracker, options);
  };

  const identify = (identifer: string, options?: Options): void => {
    errorHandler(IDENTIFY, identifer, options);
  };

  return { track, identify };
}
