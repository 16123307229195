import React from 'react';
import { useRouter } from 'next/router';
import { configure } from 'mobx';

import CartStore from 'src/state/cart';
import DutchiePayEnrollmentStore from 'src/state/dutchiePay-enrollment';
import InstoreCart from 'src/payments/state/instore-cart';
import FeatureFlagsStore from 'src/state/feature-flags';
import SearchPreferencesStore from 'src/state/search-preferences';
import UIStore from 'src/state/ui';
import UserStore from 'src/state/user';
import PaymentsStore from 'src/payments/state/payments';
import PusherStore from 'src/state/pusher';
import { ModalsStore } from 'src/state/modals';
import { InstoreEnrollmentStore } from 'src/state/instore-enrollment';
import PublicEnv from 'shared/utils/public-env';

configure({
  enforceActions: `never`,
});

export function createStores(router) {
  const FeatureFlags = new FeatureFlagsStore();
  const UI = new UIStore(router);
  const stores = {
    Cart: new CartStore(UI.isDutchieMain, FeatureFlags),
    DutchiePayEnrollment: new DutchiePayEnrollmentStore(FeatureFlags),
    InstoreCart: new InstoreCart(FeatureFlags),
    FeatureFlags,
    pusher: new PusherStore(PublicEnv.pusherKey),
    searchPreferences: new SearchPreferencesStore(),
    Payments: new PaymentsStore(),
    UI,
    User: new UserStore(),
    Modals: new ModalsStore(),
    InstoreEnrollment: new InstoreEnrollmentStore(),
  };
  // remove these once we refactor out all references to lower case versions
  // uppercase is more consistent with consumer 1.0, and also it makes Cart vs Cart.order.cart more clear
  stores.user = stores.User;
  stores.cart = stores.Cart;

  return stores;
}

export default function useCreateStores() {
  const stores = React.useRef();
  const router = useRouter() || { route: '/', asPath: '/' };

  if (!stores.current) {
    stores.current = createStores(router);
  }

  if (process.browser) {
    window._____APP_STATE_____ = stores.current;
  }

  return stores.current;
}
