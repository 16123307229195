import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';

var getAllBogoSpecialsForCart = function getAllBogoSpecialsForCart(items, medicalOrder, globalSpecialsSettings) {
  var bogoSpecialsHash = _.reduce(_.flatMap(items, function (item) {
    var _item$specialData;

    return (item === null || item === void 0 ? void 0 : (_item$specialData = item.specialData) === null || _item$specialData === void 0 ? void 0 : _item$specialData.bogoSpecials) || [];
  }), function (hash, bogoSpecial) {
    var bogoRewards = bogoSpecial.bogoRewards,
        discountToCart = bogoSpecial.discountToCart,
        discountBundle = bogoSpecial.discountBundle,
        itemsForAPrice = bogoSpecial.itemsForAPrice,
        menuType = bogoSpecial.menuType,
        specialId = bogoSpecial.specialId;

    if (menuType === 'medical' && !medicalOrder || menuType === 'recreational' && medicalOrder || (bogoRewards || []).length < 1 && !(itemsForAPrice !== null && itemsForAPrice !== void 0 && itemsForAPrice.enabled) && !(discountToCart !== null && discountToCart !== void 0 && discountToCart.enabled) && !(discountBundle !== null && discountBundle !== void 0 && discountBundle.enabled)) {
      return hash;
    }

    var existingSpecial = hash[bogoSpecial.specialId];

    if (!existingSpecial) {
      hash[specialId] = _objectSpread(_objectSpread({}, bogoSpecial), {}, {
        discountPrecedence: bogoSpecial.discountPrecedence || globalSpecialsSettings.discountPrecedence
      });
    } else {
      hash[specialId] = _objectSpread(_objectSpread({}, existingSpecial), {}, {
        discountPrecedence: bogoSpecial.discountPrecedence || globalSpecialsSettings.discountPrecedence,
        bogoRewards: _.uniqBy([].concat(_toConsumableArray((existingSpecial === null || existingSpecial === void 0 ? void 0 : existingSpecial.bogoRewards) || []), _toConsumableArray(bogoRewards || [])), '_id')
      });
    }

    return hash;
  }, {}); // 3.5 data format compatibility for the rest of ecomm calcs


  _.forEach(bogoSpecialsHash, function (special) {
    _.forEach(special.bogoRewards, function (reward) {
      if (!_.isNil(reward.discountAmount) && !_.isNil(reward.discountType)) {
        reward[reward.discountType] = reward.discountAmount;
      }
    });
  });

  return _.sortBy(_.values(bogoSpecialsHash), function (special) {
    if (special.isRecurring) {
      return special.recurringEndDate ? Number(special.recurringEndDate) : Infinity;
    }

    return special.endStamp ? Number(special.endStamp) : Infinity;
  });
};

export default getAllBogoSpecialsForCart;