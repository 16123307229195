var SHARED_ROUTES = {
  AGE_VERIFICATION_GATE: '/age-verification-gate',
  ALL_PRODUCTS_SEARCH: '/products',
  BRANDS: '/brands',
  CHECKOUT: '/checkout',
  CITIES: '/cities',
  CNAME_INFO: '/[cName]/info',
  CNAME: '/[cName]',
  DEMO: '/demo',
  DISPENSARIES: '/dispensaries',
  DISPENSARY: '/dispensary',
  DISPENSARY_CNAME: '/dispensary/[cName]',
  EMBEDDED_MENU_CNAME: '/embedded-menu/[cName]',
  EMBEDDED_MENU: '/embedded-menu',
  INFO: '/info',
  KIOSKS_CNAME: '/kiosks/[cName]',
  KIOSKS: '/kiosks',
  MENU_SECTION_ID: '/collection/[menuSectionId]',
  MENU_SECTION: '/collection/',
  MOBILE_ECOMM_CNAME: '/mobile-ecomm/[cName]',
  MOBILE_ECOMM: '/mobile-ecomm',
  OFFER_SPECIAL_ID: '/offer/[specialId]',
  OFFER: '/offer',
  PRODUCT_ID: '/product/[productId]',
  PRODUCT: '/product/',
  PRODUCTS: '/products/',
  SALE_SPECIAL_ID: '/sale/[specialId]',
  SALE: '/sale',
  SHOP: '/shop',
  SPECIALS: '/specials',
  STORES_CNAME: '/stores/[cName]',
  STORES: '/stores',
  SUBCATEGORY: '/products/[category]/[subcategory]'
};
export default SHARED_ROUTES;