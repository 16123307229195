import React, { useRef, useEffect, useCallback } from 'react';
import Truncate from 'react-truncate';
import { useFlags } from 'launchdarkly-react-client-sdk';

import usePaths from 'hooks/use-paths';

import { getCategoryForProduct } from 'shared/helpers/products';
import { Link } from 'components/core';
import { Typography } from 'src/typography';
import { SponsoredTag } from 'utils/ads/ui';

import useProductPrices from 'src/hooks/use-product-prices';
import { CarouselTheme } from '../hybrid-carousel';
import { CAROUSEL_PRODUCT_CARD_TEST_ID } from '../hybrid-carousel.constants';
import { Item, StyledContainer } from './hybrid-carousel-card.styles';

import { Price } from '../../product-card/price';
import { QuickAddButton } from '../../product-card/quick-add-button';
import { StrainDetails } from '../../product-card/strain-details';
import { Brand, Content, Details, Name, ProductImage, StyledLink } from '../../product-card/product-card.components';
import { AddToCartButton } from '../../product-card/add-to-cart-button';
import { ProductCardProduct } from '../../product-card/product-card.types';
import { getBrandName } from '../../product-card/product-card.utils';
import { CARD_SIZES, Dimensions } from '../../product-card/product-card.constants';

export type HybridCarouselCardProps = {
  carouselTheme?: CarouselTheme;
  dimensions?: Dimensions;
  filledQuickAdd?: boolean;
  product: ProductCardProduct;
  productIndex: number;
  onClick: () => void;
  onQuickAddClick: () => void;
  onView?: () => void;
  hideSponsoredTags?: boolean;
};

export const HybridCarouselCard = ({
  carouselTheme,
  dimensions = CARD_SIZES.default,
  filledQuickAdd = false,
  hideSponsoredTags = false,
  product,
  onClick,
  onQuickAddClick,
  onView,
}: HybridCarouselCardProps): JSX.Element | null => {
  const { href, route } = usePaths({ category: getCategoryForProduct(product), product });
  const { gutter } = dimensions;
  const brandName = getBrandName(product);
  const cardRef = useRef<HTMLElement | null>(null);
  const onViewRef = useRef(onView);

  const flags = useFlags();
  const isAddToCartCTAEnabled = flags['growth.ecomm.add-to-cart-cta.experiment'] ?? false;

  // NOTE: This is a temporary fix to prevent the card from rendering if the price is 0
  const { standardPrice } = useProductPrices(product);
  const isZeroPrice = !standardPrice || standardPrice === 0;

  useEffect(() => {
    const callback = onViewRef.current;

    if (!callback) {
      return () => ({});
    }

    const cardObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback();
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const cardEl = cardRef.current;

    if (cardEl) {
      cardObserver.observe(cardEl);
    }

    return () => {
      if (cardEl) {
        cardObserver.unobserve(cardEl);
      }
    };
  }, []);

  const handleClick = useCallback((): void => {
    onClick();
  }, [onClick]);

  const handleQuickAddClick = useCallback((): void => {
    onQuickAddClick();
    onClick();
  }, [onQuickAddClick, onClick]);

  const displayQuickAddButton = !isAddToCartCTAEnabled && onQuickAddClick;
  const displayAddToCartButton = isAddToCartCTAEnabled && onQuickAddClick;

  useEffect(() => {
    if (isZeroPrice) {
      console.error(`Product ${String(product.id)} has a zero display price in HybridCarouselCard.`);
    }
  }, [isZeroPrice, product.id]);

  if (isZeroPrice) {
    return null;
  }

  return (
    <Item gutter={gutter} aria-label={product.name} aria-roledescription='slide' ref={cardRef}>
      <StyledContainer dimensions={dimensions} data-testid='carousel-product-card' carouselTheme={carouselTheme}>
        <Content>
          <Link href={href} route={route}>
            <StyledLink href={href} onClick={handleClick} data-testid={CAROUSEL_PRODUCT_CARD_TEST_ID}>
              <ProductImage
                dimensions={dimensions}
                width={dimensions.desktop}
                height={dimensions.desktop}
                product={product}
              />
              <Details dimensions={dimensions}>
                {!hideSponsoredTags && <SponsoredTag isSponsored={!!product.adTrackers} />}
                <Price product={product} carouselTheme={carouselTheme} />
                <Name>
                  <Truncate lines={2}>
                    <Typography.Title tag='span' size='small'>
                      {product.name}
                    </Typography.Title>
                  </Truncate>
                </Name>
                {brandName.length > 0 && (
                  <Brand tag='span' size='xsmall'>
                    {brandName}
                  </Brand>
                )}
                {product.strainType && <StrainDetails product={product} />}
              </Details>

              {displayAddToCartButton && <AddToCartButton onClick={handleQuickAddClick} product={product} />}
            </StyledLink>
          </Link>
          {displayQuickAddButton && (
            <QuickAddButton
              onClick={handleQuickAddClick}
              productName={product.name}
              carouselTheme={carouselTheme}
              filled={filledQuickAdd}
            />
          )}
        </Content>
      </StyledContainer>
    </Item>
  );
};
