import React from 'react';

import styled from 'styled-components';
import { MagnifyingGlass } from 'src/assets/magnifying-glass';
import { VisuallyHidden } from 'src/components/core/visually-hidden';

type SearchBarFilterProps = {
  value: string;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export const SearchBarFilter = ({ value, placeholder, onChange }: SearchBarFilterProps): JSX.Element => (
  <Container>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
    <SearchBarLabel htmlFor='search-bar-filter'>
      <VisuallyHidden>Search</VisuallyHidden>
      <SearchInputMagnifyingGlass />
    </SearchBarLabel>
    <StyledInput id='search-bar-filter' onChange={onChange} placeholder={placeholder} value={value} />
  </Container>
);

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  border: 1px solid #c1c7ca;
  border-radius: 20px;
  display: flex;
  gap: 0.5rem;
  margin: 0.75rem 0 0 0;
  padding: 0.3125rem 0.5rem;

  &:focus-within {
    border-color: #4c94d7;
    box-shadow: 0 1px 5px #0b99e666;
  }

  @media (min-width: 880px) {
    margin: 0.75rem 1rem 0 0;
  }
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  color: #121516;
  font-size: 0.8125rem;
  height: 1rem;
  line-height: 1.125rem;
  padding: 0;
  width: 100%;

  &.focus-visible {
    box-shadow: none;
  }

  &::placeholder {
    color: #828a8f;
    font-weight: 400;
  }
`;

const SearchInputMagnifyingGlass = styled(MagnifyingGlass)`
  color: #485055;
  height: 1rem;
  pointer-events: none;
  width: 1rem;
`;

const SearchBarLabel = styled.label`
  display: flex;
`;
