/* eslint-disable no-empty */
import _ from 'lodash';
var inMemoryStore = {
  setItem: function setItem(key, value) {
    if (key === 'setItem' || key === 'getItem') {
      throw new Error('Trying to overwrite', key, 'which is a reserved key in storage'); // eslint-disable-line
    }

    inMemoryStore[key] = value;
  },
  getItem: function getItem(key) {
    return inMemoryStore[key];
  },
  removeItem: function removeItem(key) {
    delete inMemoryStore[key];
  }
};
export function setPersistedValue(key, value) {
  var sessionOnly = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var setValue = function setValue(storage) {
    if (_.isNil(value)) {
      storage.removeItem(key);
    } else {
      value = JSON.stringify(value);
      storage.setItem(key, value);
    }
  }; // try local storage first


  if (!sessionOnly) {
    try {
      setValue(localStorage);
      return;
    } catch (e) {}
  } // then session storage


  try {
    setValue(sessionStorage);
    return;
  } catch (e) {} // then just use in-memory


  try {
    setValue(inMemoryStore);
  } catch (e) {}
}
export function getPersistedValue(key, defaultValue) {
  try {
    if (typeof sessionStorage !== 'undefined' && sessionStorage[key]) {
      return JSON.parse(sessionStorage[key]);
    }
  } catch (e) {}

  try {
    if (typeof localStorage !== 'undefined' && localStorage[key]) {
      return JSON.parse(localStorage[key]);
    }
  } catch (e) {}

  try {
    if (inMemoryStore[key]) {
      return JSON.parse(inMemoryStore[key]);
    }
  } catch (e) {}

  return defaultValue !== null && defaultValue !== void 0 ? defaultValue : null;
}
export function removePersistedValue(key) {
  var sessionOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  // try local storage first
  if (!sessionOnly) {
    try {
      localStorage.removeItem(key);
      return;
    } catch (e) {}
  } // then session storage


  try {
    sessionStorage.removeItem(key);
    return;
  } catch (e) {} // then just use in-memory


  try {
    inMemoryStore.removeItem(key);
  } catch (e) {}
}