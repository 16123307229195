import React, { memo, useEffect, useMemo } from 'react';
import { useObserver } from 'mobx-react';
import styled from 'styled-components';
import _ from 'lodash';
import { useRouter } from 'next/router';
import routeTo from 'utils/route-to';
import dynamic from 'next/dynamic';
import { focusStyles } from 'src/theme';

import useUI from 'hooks/use-ui';
import useColors from 'hooks/use-colors';
import usePaths from 'hooks/use-paths';
import useTranslation from 'hooks/use-translation';

import { tracker } from 'src/utils/analytics';
import CategoriesList from 'components/categories-list';
import Drawer from 'components/core/drawer';
import IframeHeightOverride from 'components/core/iframe-height-override';
import { Tabs, Tab } from 'components/core/tabs';
import { ARIA_ROLES } from 'shared/constants';
import useMenuFilterOptions from 'src/hooks/use-menu-filter-options';

const BrandsList = dynamic(() => import('components/brands-list'), {
  loading: () => null,
});

import StoreStatus from '../store-status';
import CloseButton from './components/close-button';
import InfoButton from './components/info-button';
import StoreInfoTab from './tabs/store-info';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

function MenuDrawer(props) {
  const { dispensary, onClose, open = false } = props;

  const UI = useUI();
  const colors = useColors();
  const { t } = useTranslation();
  const router = useRouter();
  const { href, route } = usePaths();
  const currentTab = useObserver(() => UI.currentMenuDrawerTab);
  const { brands, hasProductsOnSpecial, hideDiscounts } = useMenuFilterOptions({
    skipCurrentFilters: true,
  });

  const { tabs, styledTabs, TABS } = useMemo(() => {
    const TABS_CONSTANT = _.compact([
      {
        key: 'home',
        label: t('common.home', 'Home'),
        href,
        route,
        // eslint-disable-next-line no-use-before-define
        component: Link, // work around for getting material ui tabs act as links.
        hidden: false,
      },
      {
        key: 'categories',
        label: t('common.categories', 'Categories'),
        href: null,
        route: null,
        component: CategoriesList,
        hidden: false,
      },
      !_.isEmpty(brands) && {
        key: 'brands',
        label: t('common.brand_plural', 'Brands'),
        href: null,
        route: null,
        component: BrandsList,
        hidden: false,
      },
      hasProductsOnSpecial &&
        !hideDiscounts && {
          key: 'specials',
          label: t('common.specials', 'Specials'),
          href: `${href}/specials`,
          route: `${route}/specials`,
          // eslint-disable-next-line no-use-before-define
          component: Link,
          hidden: false,
        },
      !UI.viewOnlyMode && {
        key: 'info',
        label: t('common.info', 'Info'),
        href: null,
        route: null,
        component: () => <StoreInfoTab dispensary={dispensary} />,
        hidden: true,
      },
    ]);

    const styledTabsList = [];
    const tabsList = [];

    _.forEach(TABS_CONSTANT, ({ key, component, label, hidden }, index) => {
      const TabComponent = component || CategoriesList;
      tabsList.push(
        <TabPanel key={key} value={currentTab} index={index}>
          <TabComponent onClose={onClose} />
        </TabPanel>
      );

      styledTabsList.push(
        <StyledTab
          key={key}
          label={label}
          id={`tab-${index}`}
          className={hidden ? 'hidden' : ''}
          aria-controls={`tabpanel-${index}`}
        />
      );
    });
    return {
      TABS: TABS_CONSTANT,
      tabs: tabsList,
      styledTabs: styledTabsList,
    };
  }, [t, href, route, brands, hasProductsOnSpecial, hideDiscounts, UI.viewOnlyMode, dispensary, currentTab, onClose]);

  // always start at categories, unless brands was selected previously
  useEffect(() => {
    const onBrandsPage = _.includes(router?.route, 'brands');
    if (!open) {
      if (onBrandsPage) {
        UI.setCurrentMenuDrawerTab(2);
      } else {
        UI.setCurrentMenuDrawerTab(1);
      }
    }
  }, [TABS, UI, open, router?.route]);

  if (_.isEmpty(dispensary)) {
    return null;
  }

  const trackEvent = (label) => {
    tracker.gaGTMClickEvent({
      eventCategory: 'navigation click',
      eventAction: 'main menu',
      eventLabel: label,
    });
  };

  function changeTab(event, tabIndex) {
    if (tabIndex <= TABS.length) {
      UI.setCurrentMenuDrawerTab(tabIndex);
    }

    const selectedTab = TABS[tabIndex];

    trackEvent(selectedTab.key);

    if (selectedTab?.href) {
      routeTo({ as: selectedTab.href, url: selectedTab.route });
      onClose();
    }
  }

  function onInfoClick() {
    UI.setCurrentMenuDrawerTab(TABS.length - 1);
  }

  return (
    <StyledDrawer aria-label='Menu Drawer' ModalProps={{ role: ARIA_ROLES.REGION }} open={open} onClose={onClose}>
      <Container>
        <StoreStatusContainer>
          <StoreStatus dispensary={dispensary} />
          {!UI.viewOnlyMode && <InfoButton aria-label='Dispensary Info' onClick={onInfoClick} />}
          <CloseButton
            aria-label='Close Menu Drawer'
            backgroundColor={currentTab === '3' && colors.primaryGrey}
            onClick={onClose}
            right='18px'
            role='button'
            top='9px'
          />
        </StoreStatusContainer>

        <StyledTabs
          value={currentTab}
          onChange={changeTab}
          position={TABS.length < 5 ? 'center' : 'space-between'}
          aria-label={t('common.tab-selection', 'tab selection')}
          hideIndicator={TABS[UI.currentMenuDrawerTab]?.key === 'info'}
        >
          {styledTabs}
        </StyledTabs>

        {tabs}
      </Container>
    </StyledDrawer>
  );
}

export default memo(MenuDrawer);

const StoreStatusContainer = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
  align-items: space-between;
  align-content: center;
  background: ${({ theme }) => theme.colors.primaryGrey};
  font-size: 14px;
  padding: 25px;

  span {
    display: inline-block;

    &:first-of-type {
      margin-right: 5px;
    }
  }
`;

const StyledDrawer = styled((props) => <Drawer {...props} />)`
  height: 100%;

  .hidden {
    display: none;
  }

  .MuiPaper-root {
    width: 100%;
  }

  .Mui-selected {
    color: ${({ theme }) => theme.customized.colors.buttonsLinks} !important;
  }
`;

const Container = styled(IframeHeightOverride)`
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    display: ${({ hideIndicator }) => (hideIndicator ? `none` : `flex`)};
    background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
    justify-content: center;

    & > span {
      max-width: 90px;
      width: 100%;
    }
  }

  .MuiTabs-flexContainer {
    justify-content: ${({ position }) => position};
    margin: 0 21px;
  }

  .MuiTab-root {
    padding: 0 4px;
    margin: ${({ position }) => (position === 'center' ? '14px 18px 8px' : '14px 0px 8px')};
    color: ${({ theme }) => theme.colors.grey[45]};

    &:focus {
      ${focusStyles}
    }

    > span {
      font-size: 15px;
      line-height: 120%;
    }
  }
`;

const StyledTab = styled(Tab)`
  line-height: 18px;
  font-size: 16px !important;
  font-weight: bold;
  margin: 0;
  padding-top: 22px;
  padding-bottom: 14px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;
