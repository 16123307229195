import React from 'react';
import styled from 'styled-components';
import { BottomDrawer, BottomDrawerProps } from 'components/core/bottom-drawer';
import RangeSlider from 'components/core/range-slider';
import useTranslation from 'hooks/use-translation';
import { Button } from 'src/components/core';
import { pluralize } from 'shared/helpers/utils';

export type DistanceFilterBottomDrawerProps = Omit<BottomDrawerProps, 'children'> & {
  value: number;
  onChange: (distance: number) => void;
  onSave: () => void;
};

export function DistanceFilterBottomDrawer({
  open,
  onClose,
  onChange,
  value,
  onSave,
}: DistanceFilterBottomDrawerProps): JSX.Element {
  const { t } = useTranslation();

  const pluralizedMileString = pluralize(`mile`, value);

  return (
    <BottomDrawer open={open} onClose={onClose} heading={t('distance', 'Distance')}>
      <StyledRangeSlider
        max={50}
        min={1}
        onChange={(_e, newValue: number) => onChange(newValue)}
        value={value}
        aria-labelledby='distance-slider-label'
        aria-valuetext={t('distance-slider-value-text', '{{ value }} {{ pluralizedMileString }}', {
          value,
          pluralizedMileString,
        })}
      />
      <SliderLabel id='distance-slider-label'>
        Shop from dispensaries within{' '}
        <MilesString>
          {t('distance-slider-value-text', '{{ value }} {{ pluralizedMileString }}', {
            value,
            pluralizedMileString,
          })}
        </MilesString>
      </SliderLabel>
      <Button fontSize={13} size='large' onClick={onSave}>
        {t('save', 'Save')}
      </Button>
    </BottomDrawer>
  );
}

const StyledRangeSlider = styled(RangeSlider)`
  margin: 14px 0 0;
  &&& {
    width: calc(100vw - 62px);
    .MuiSlider-rail {
      height: 12px;
      width: calc(100vw - 50px);
    }
    .MuiSlider-track {
      height: 12px;
    }
    .MuiSlider-thumb {
      height: 25px;
      width: 25px;
      margin-top: -7px;
    }
  }
`;

const SliderLabel = styled.div`
  font-size: 12px;
  line-height: 19px;
  margin-top: 12px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const MilesString = styled.b`
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;
