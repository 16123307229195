import OrderComputationsAsync from 'shared/order/computations-async';
import { CALCULATION_PRICE_SERVICE } from 'shared/constants';
import { datadogLogs } from '@datadog/browser-logs';

async function performCalculations({ cart, apolloClient, userPosId, priceCartFlagState }) {
  const initialCalculationIndex = cart.calculationIndex;

  const discountSyncEnabled = cart.dispensary?.storeSettings?.enableLLxSaleDiscountSync;

  let calculationOutput;

  const calculationsInput = {
    ...cart.order,
    priceCartFlagState,
    discountSyncEnabled,
    userPosId,
    couponActions: {
      removeCoupon: cart.removeCoupon,
      applyCouponCode: cart.applyCouponCode,
    },
    priceService: CALCULATION_PRICE_SERVICE.cart,
  };

  const input = {
    data: calculationsInput,
    logger: datadogLogs.logger,
    apolloClient,
  };

  try {
    calculationOutput = await OrderComputationsAsync.compute(input);
  } catch (error) {
    datadogLogs.logger.error('Error in performCalculationsAction', error);
    cart.setCalculationOutput({});
  } finally {
    if (initialCalculationIndex === cart.calculationIndex) {
      cart.setCalculationOutput(calculationOutput);
      cart.setIsCalculating(false);
    }
  }
}

export default {
  performCalculations,
};
