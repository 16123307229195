import React from 'react';
import { Clickable } from 'shared/components';
import styled from 'styled-components';
import useTranslation from 'hooks/use-translation';

type ViewMoreLinkToggleProps = {
  expanded: boolean;
  onToggle: () => void;
};

export const ViewMoreLinkToggle = ({ expanded, onToggle }: ViewMoreLinkToggleProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ViewMore onClick={onToggle} data-testid={`view-${expanded ? 'less' : 'more'}-link-toggle`}>
      {expanded ? t('common.view-less', 'View Less') : t('common.view-more', 'View More')}
    </ViewMore>
  );
};

const ViewMore = styled(Clickable)`
  display: block;
  text-align: start;
  width: 100%;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  margin-top: 10px;
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 13px;
  }
`;
