import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash'; // eslint-disable-next-line import/no-extraneous-dependencies

import { getAvailableQuantity } from 'shared/order/bogo/common';

var issueDiscountBundleRewards = function issueDiscountBundleRewards(bogoCalcsData, bogoSpecial, conditionsManager) {
  var discountBundle = bogoSpecial.discountBundle,
      specialId = bogoSpecial.specialId,
      source = bogoSpecial.source;
  var discountType = discountBundle.discountType,
      value = discountBundle.value;
  var rewardsSatisfiers = {};

  _.forEach(conditionsManager.satisfiers, function (_ref) {
    var item = _ref.item,
        quantity = _ref.quantity;
    var quantityToDiscount = quantity; // if we have a limit we want to use that as the quantityToDiscount for this pass
    // unless the limit is greater than the availableQuantity
    // if there is a leftover that doesn't divide evenly across the limit
    // we don't want to include that remainder quantity
    // limits are only available on POS specials per the field "per cart limit"

    if (discountBundle !== null && discountBundle !== void 0 && discountBundle.limit && source === 'POS') {
      var availableQuantity = getAvailableQuantity({
        item: item,
        bogoCalcsData: bogoCalcsData,
        pendingConditionsSatisfiers: {},
        pendingRewardsSatisfiers: rewardsSatisfiers,
        specialId: bogoSpecial.specialId
      });
      var lesserOfAvailableAndLimit = Math.min(availableQuantity, discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.limit);

      if (availableQuantity >= (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.limit)) {
        quantityToDiscount = lesserOfAvailableAndLimit;
      } else {
        quantityToDiscount = lesserOfAvailableAndLimit % (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.limit) === 0 ? lesserOfAvailableAndLimit : 0;
      }
    }

    if (quantityToDiscount > 0) {
      bogoCalcsData.ignoreConditionsForDiscountBundle = true;
      rewardsSatisfiers[item.key] = {
        item: item,
        quantity: quantityToDiscount,
        rewards: _defineProperty({}, discountBundle._id, {
          quantity: quantityToDiscount,
          reward: _defineProperty({
            _id: discountBundle._id,
            quantity: quantityToDiscount
          }, discountType, value),
          discountBundle: _objectSpread({}, discountBundle),
          specialId: specialId,
          discountStacking: bogoSpecial.discountStacking,
          discountPrecedence: bogoSpecial.discountPrecedence,
          stackingBehavior: bogoSpecial.stackingBehavior,
          nonStackingBehavior: bogoSpecial.nonStackingBehavior,
          settingsDerivedFrom: bogoSpecial.settingsDerivedFrom
        })
      };
    }
  });

  return rewardsSatisfiers;
};

export default issueDiscountBundleRewards;