import _ from 'lodash';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import usePaths from 'src/hooks/use-paths';
import { CategoriesForMenu as categories, SubcategoryOptions as subcategories } from 'shared/constants';
import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';
import { GenericCarousel } from './generic-carousel';
import { defaultFilters, checkNeedToMakeEdibleIngestible, replaceEdibleWithIngestible } from '../carousels.utils';

export function SubcategoryCarousel(props) {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const { section, dispensaryState } = props;
  const { category, label, subcategory } = section;
  const currentSubcategory = _.find(subcategories[category], [`value`, subcategory]) || subcategory;

  const { loading, products } = useDispensaryProducts({
    filters: { ...defaultFilters, subcategories: [currentSubcategory.key], useCache: true },
    perPage: 25,
  });

  const makeEdibleIngestible = checkNeedToMakeEdibleIngestible(dispensaryState, category);

  const currentCategory = _.find(categories, [`value`, category]) || category;
  const { href, route, query } = usePaths({ category: currentCategory, subcategory });

  if (!products?.length && !loading) {
    return null;
  }

  const transformedHref = makeEdibleIngestible ? replaceEdibleWithIngestible(href) : href;

  if (isProductCardsV2Enabled) {
    return (
      <ProductCarousel
        products={products}
        isLoading={loading}
        href={transformedHref}
        hideTitle={props?.showTitle === false}
        hideLink={props?.showLink === false}
        title={label}
      />
    );
  }

  const sectionProps = {
    ...props,
    isLoading: loading,
    linkLabel: section.linkLabel,
    href: transformedHref,
    query,
    label,
    products,
    route,
  };

  return <GenericCarousel {...sectionProps} />;
}
