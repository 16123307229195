import React, { useRef } from 'react';
import { Link } from 'components/core';

import { useIntersectionObserver } from 'src/hooks/use-intersection-observer';

import usePaths from 'hooks/use-paths';
import useUI from 'shared/hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import ShoppingBags from 'src/assets/shopping-bags-icon';

import {
  Container,
  ListHeader,
  ListHeaderText,
  ListHeaderLink,
  ListContainer,
  ProductList,
  GradientEnd,
  GradientStart,
  ListPlaceholder,
} from './buy-it-again-list.styles';

type BuyItAgainListProps = {
  backgroundColor?: string;
  children: React.ReactNode;
  productCount: number;
  handleViewAllClick: (destinationUrl: string) => void;
};

const listPadding = 20;
export const BuyItAgainList = React.forwardRef(
  (
    { backgroundColor, children, productCount = 0, handleViewAllClick }: BuyItAgainListProps,
    ref
  ): JSX.Element | null => {
    const UI = useUI();
    const { dispensary } = useDispensary();
    const { href } = usePaths({ dispensary });
    const startWatcherRef = useRef<HTMLDivElement>(null);
    const startWatcher = useIntersectionObserver(startWatcherRef);
    const endWatcherRef = useRef<HTMLDivElement>(null);
    const endWatcher = useIntersectionObserver(endWatcherRef);

    const showPlaceholder = productCount < 2;

    const hasUserPaths = UI.isEmbedded || UI.isStoreFront;
    const accountOrderHistoryPath = hasUserPaths ? `${href}/user/orders` : '/account/orders';

    return (
      <Container
        data-testid='buy-it-again-list'
        ref={ref}
        role='group'
        aria-roledescription='slide'
        aria-label='Buy it again'
      >
        <ListHeader padding={listPadding}>
          <ListHeaderText tag='h3' size='xsmall' weight='semi'>
            Buy it again
          </ListHeaderText>
          <Link href={accountOrderHistoryPath}>
            <ListHeaderLink href={accountOrderHistoryPath} onClick={() => handleViewAllClick(accountOrderHistoryPath)}>
              View all orders
            </ListHeaderLink>
          </Link>
        </ListHeader>
        <ListContainer>
          <GradientStart
            isVisible={!showPlaceholder && !startWatcher?.isIntersecting}
            data-testid='list-gradient-start'
          />
          <ProductList padding={listPadding} showPlaceholder={showPlaceholder}>
            <div ref={startWatcherRef} />
            {children}
            {showPlaceholder && (
              <ListPlaceholder backgroundColor={backgroundColor} data-testid='buy-it-again-list-placeholder'>
                <ShoppingBags />
                <p>
                  Your recent purchases will show up here.{'\n'}
                  Easily buy your favorites again and again!
                </p>
              </ListPlaceholder>
            )}
            <div ref={endWatcherRef} />
          </ProductList>
          <GradientEnd isVisible={!showPlaceholder && !endWatcher?.isIntersecting} data-testid='list-gradient-end' />
        </ListContainer>
      </Container>
    );
  }
);
