const LEGACY_STRING = 'https://s3-us-west-2.amazonaws.com/dutchie-images/';
const IMGIX_SOURCE = 'https://images.dutchie.com/';

export const ensureSecureUrlProtocol = (src: string | null | undefined): string | null | undefined => {
  if (!src) { return src; }
  return src.replace('http://', 'https://');
};

export const replaceAWSSourceWithImgix = (src: string | null | undefined): string => {
  src = (ensureSecureUrlProtocol(src) ?? '').trim();
  // Replace S3 with imgix.
  if (src.includes(LEGACY_STRING)) {
    src = src.replace(LEGACY_STRING, IMGIX_SOURCE);
  }
  return src;
};
