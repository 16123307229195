import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useQuery } from '@apollo/react-hooks';
import useStores from 'shared/hooks/use-stores';
export default function useApolloQuery(query, variables) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useStores = useStores(),
      apolloClient = _useStores.apolloClient;

  return useQuery(query, _objectSpread(_objectSpread({
    fetchPolicy: 'cache-and-network',
    client: apolloClient
  }, options), {}, {
    variables: variables
  }));
}