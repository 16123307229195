/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export const AppleLogo = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='14' height='16' viewBox='0 0 14 16' fill='none' {...props}>
    <path
      d='M13.1871 12.2827C12.9552 12.8184 12.6808 13.3115 12.3627 13.7649C11.9293 14.3829 11.5743 14.8107 11.3008 15.0483C10.8768 15.4382 10.4225 15.6379 9.93598 15.6493C9.58673 15.6493 9.16555 15.5499 8.67528 15.3483C8.1834 15.1477 7.73136 15.0483 7.31804 15.0483C6.88455 15.0483 6.41965 15.1477 5.92237 15.3483C5.42433 15.5499 5.02312 15.655 4.71637 15.6654C4.24986 15.6853 3.78485 15.4799 3.3207 15.0483C3.02446 14.7899 2.65391 14.3469 2.21002 13.7194C1.73375 13.0493 1.3422 12.2723 1.03544 11.3864C0.706923 10.4295 0.542236 9.5029 0.542236 8.60583C0.542236 7.57825 0.764279 6.69197 1.20903 5.94927C1.55856 5.35271 2.02356 4.88212 2.60555 4.53666C3.18753 4.1912 3.81637 4.01516 4.49357 4.00389C4.86412 4.00389 5.35004 4.11851 5.95389 4.34377C6.55603 4.56979 6.94267 4.68441 7.11218 4.68441C7.23891 4.68441 7.66842 4.55038 8.39654 4.2832C9.0851 4.03541 9.66624 3.93281 10.1423 3.97323C11.4324 4.07734 12.4015 4.58588 13.0461 5.50206C11.8923 6.20113 11.3216 7.18025 11.333 8.43632C11.3434 9.41469 11.6983 10.2288 12.3959 10.8753C12.712 11.1753 13.065 11.4072 13.4578 11.5719C13.3726 11.8189 13.2827 12.0555 13.1871 12.2827ZM10.2284 0.640128C10.2284 1.40697 9.94829 2.12297 9.38987 2.78569C8.71598 3.57353 7.90088 4.02878 7.01697 3.95695C7.0057 3.86495 6.99917 3.76813 6.99917 3.66638C6.99917 2.93021 7.31965 2.14237 7.88876 1.4982C8.17289 1.17205 8.53426 0.900853 8.97247 0.684517C9.40974 0.471409 9.82335 0.353556 10.2124 0.333374C10.2237 0.435889 10.2284 0.53841 10.2284 0.640118V0.640128Z'
      fill='#2C3236'
    />
  </svg>
);
