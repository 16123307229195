/* eslint-disable lodash/prefer-constant */

import { createContext, useContext } from 'react';

type StoreFrontComponents = {
  header: () => null;
  footer: () => null;
};

type CommerceComponents = {
  storeFront: StoreFrontComponents;
};

export type CommerceComponentsContextType = {
  components: CommerceComponents;
};

export const CommerceComponentsContext = createContext<CommerceComponentsContextType>({
  components: {
    storeFront: {
      header: () => null,
      footer: () => null,
    },
  },
});

export const useCommerceComponents = (): CommerceComponentsContextType => useContext(CommerceComponentsContext);
