import React, { forwardRef, useContext } from 'react';
import _ from 'lodash';
import { useRouter } from 'next/router';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';

import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { tracker } from 'src/utils/analytics';
import { ProductRecommendationSource } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import EmptyLoadingState from 'components/empty-loading-state';
import CarouselSlider from 'components/carousel-slider';
import { CarouselProductCard } from 'src/components/product-card/carousel-product-card';
import { CARD_SIZES } from 'src/components/product-card/product-card.constants';
import { applySettings, DEFAULT_SKELETON_DELAY } from '../carousels.utils';
import { CardCarouselItem, Section } from '../carousels.styles';
import { CarouselHeader } from '../carousel-header';

const generateSlides = ({ dispensary, products, sectionIndex, handleAddToCart, trackerSource, UI }) => {
  const isEmbeddedCarousel = UI?.isEmbeddedCarousel ?? false;

  return _.map(applySettings(products), (product, index) => (
    <CardCarouselItem key={product?.id} role='group' aria-roledescription='slide' aria-label={product?.name}>
      <CarouselProductCard
        dimensions={CARD_SIZES.default}
        dispensary={dispensary}
        product={product}
        productIndex={index}
        sectionIndex={sectionIndex}
        width='100%'
        onQuickAddClick={() => {
          tracker.setContext({
            activeProductPosition: index,
          });
          handleAddToCart(product, trackerSource);
        }}
        onClick={() => {
          tracker.setContext({
            activeProductPosition: index,
          });
          UI.activeProductSource = trackerSource;
        }}
        trackerSource={trackerSource}
        isEmbeddedCarousel={isEmbeddedCarousel}
      />
    </CardCarouselItem>
  ));
};

export const GenericCarousel = (props) => {
  const UI = useUI();

  const { handleAddToCart } = useQuickAddToCart();
  const router = useRouter();

  const { showLink = true, isLoading, label, linkLabel, products, query, route, href, section, sectionIndex } = props;
  const showTitle = section?.hideTitle !== `true`;

  const { t } = useTranslation();
  const { dispensary } = useDispensary();
  const labelId = `${section.id}-label`;
  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: label,
  });

  // provide accessible label info
  let carouselProps = {};

  if (showTitle) {
    carouselProps = {
      'aria-labelledby': labelId,
    };
  } else {
    carouselProps = {
      'aria-label': label,
    };
  }

  const slides = generateSlides({
    dispensary,
    products,
    sectionIndex,
    handleAddToCart,
    trackerSource,
    UI,
  });

  return (
    <EmptyLoadingState isLoading={isLoading} page='productCarousel' delay={DEFAULT_SKELETON_DELAY}>
      <Section data-testid='product-carousel'>
        {(showLink || showTitle) &&
          CarouselHeader({
            href,
            isEmbeddedCarousel: UI.isEmbeddedCarousel,
            label,
            labelId,
            linkLabel,
            query,
            route,
            showLink,
            showTitle,
            t,
          })}
        <CarouselSlider carouselProps={carouselProps}>{slides}</CarouselSlider>
      </Section>
    </EmptyLoadingState>
  );
};
