import React from 'react';

import { VisuallyHidden } from 'shared/components/visually-hidden';
import { PlusIcon } from 'src/assets/plus';
import { Add, Discount, DiscountedPrice, Option, OriginalPrice } from './option.styles';

type OptionTileProps = {
  label: string;
  originalPrice: string | null;
  currentPrice: string;
  discountLabel: string | null;
  onClick: () => void;
  hasMultipleOptions: boolean;
  isOutlinedVariant?: boolean;
  isSelected?: boolean;
  isFixedSize?: boolean;
  shouldDisplayLabel?: boolean;
};

export const OptionTile = ({
  label,
  originalPrice,
  currentPrice,
  discountLabel,
  onClick,
  hasMultipleOptions,
  isOutlinedVariant = true,
  isSelected = false,
  isFixedSize = false,
  shouldDisplayLabel = true,
}: OptionTileProps): JSX.Element => (
  <Option
    $hasMultipleOptions={hasMultipleOptions}
    $isOutlinedVariant={isOutlinedVariant}
    $isSelected={isSelected}
    $isFixedSize={isFixedSize}
    onClick={onClick}
    data-testid='option-tile'
  >
    {shouldDisplayLabel && label}

    <b>{currentPrice}</b>

    {discountLabel && originalPrice && (
      <DiscountedPrice $hasMultipleOptions={hasMultipleOptions}>
        <OriginalPrice>{originalPrice}</OriginalPrice>
        <Discount>{discountLabel}</Discount>
      </DiscountedPrice>
    )}

    {!isOutlinedVariant && (
      <Add>
        <VisuallyHidden>Add {label} to cart</VisuallyHidden>
        <PlusIcon height={10} width={10} />
      </Add>
    )}
  </Option>
);
