import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useUser from 'src/hooks/use-user';

import { PersonalizedProductsQuery } from 'src/personalization/data-layer/types';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import { LoggedOut } from './logged-out';
import { LoggedOutV2 } from './logged-out-v2';
import { LoggedIn } from './logged-in';
import { LoggedInV2 } from './logged-in-v2';

type PersonalizedProductsProps = {
  personalizedProductsQuery: PersonalizedProductsQuery;
  sponsoredBrandQuery: SponsoredBrandQuery;
};

export const PersonalizedProducts = (props: PersonalizedProductsProps): JSX.Element | null => {
  const user = useUser();
  const flags = useFlags();
  const isLoggedIn = useObserver(() => user.isLoggedIn);

  const { personalizedProductsQuery, sponsoredBrandQuery } = props;
  const hasNoProducts = !personalizedProductsQuery.data?.getPersonalizedProducts?.products.length;

  if (hasNoProducts) {
    return null;
  }

  const loggedOutV2Enabled = flags['growth.ads.personalization-carousel-logged-out-cta-v2.rollout'] ?? false;
  const isSponsoredContentEnabledForPersonalization =
    flags['growth.ads.enable-sponsored-content-personalization.rollout'];
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const productQueryProps = {
    personalizedProductsQuery,
    sponsoredBrandQuery: isSponsoredContentEnabledForPersonalization ? sponsoredBrandQuery : undefined,
  };

  const LoggedInComponent = isProductCardsV2Enabled ? LoggedInV2 : LoggedIn;
  const LoggedOutComponent = loggedOutV2Enabled ? LoggedOutV2 : LoggedOut;

  const renderComponent = isLoggedIn ? (
    <LoggedInComponent {...productQueryProps} />
  ) : (
    <LoggedOutComponent {...productQueryProps} />
  );

  return <div data-testid='personalized-products'>{renderComponent}</div>;
};
