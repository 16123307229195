import { ProductCarouselProps } from 'src/components/product-carousel';
import { useRecentlyViewedProductsQuery } from 'types/graphql';
import { ApolloError } from 'apollo-client';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';

type UseRecentlyViewedCarouselParams = {
  dispensaryId: string;
  excludeProductId?: string;
};

type UseRecentlyViewedCarouselReturn = Omit<ProductCarouselProps, 'heading'>;

export function useRecentlyViewedCarousel({
  dispensaryId,
  excludeProductId,
}: UseRecentlyViewedCarouselParams): UseRecentlyViewedCarouselReturn {
  const { data: productIdsData, loading: productIdsLoading, error: productIdsError } = useRecentlyViewedProductsQuery({
    variables: {
      dispensaryId,
    },
    fetchPolicy: `network-only`,
    /*
     * The fetchPolicy of "network-only" is used in order to force the query to always be called due to the current user
     * being checked on the backend. Otherwise the previously cached products will appear.
     */
  });

  let productIds = productIdsData?.getRecentlyViewedProducts?.map((product) => product.id) ?? [];

  if (excludeProductId) {
    productIds = productIds.filter((productId) => productId !== excludeProductId);
  }

  const { products, loading: productsLoading } = useDispensaryProducts({
    filters: {
      dispensaryId,
      productIds,
      status: 'Active',
      removeProductsBelowOptionThresholds: true,
      sortBy: 'inputOrder',
    },
    page: 1,
    perPage: 25,
    skip: productIdsLoading || productIdsError instanceof ApolloError || !productIds.length,
  });

  return {
    products,
    loading: productIdsLoading || productsLoading,
  };
}
