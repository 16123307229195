import React, { useState } from 'react';
import styled from 'styled-components';
import { object, SchemaOf, string } from 'yup';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FormTextInput } from 'components/form-inputs';
import Form from 'components/forms/form';
import useTranslation, { ExtendedUseTranslationResponse } from 'hooks/use-translation';
import { emailRegEx } from 'components/forms/form-utils';
import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { EnrollmentSteps } from 'src/payments/constants';
import { DutchiePayButton, DutchiePayHeader, DutchiePayTextBody } from 'src/payments/components';
import { GoogleLogin, AppleLogin } from 'src/components/social-login';
import { Divider } from 'src/components/divider';

type EmailFormProps = {
  onSubmit: ({ email }: EmailInput) => Promise<void>;
  titleText?: string;
  subtitleText?: string;
};

export type EmailInput = {
  email: string;
};

const EmailFormValidation = (t: ExtendedUseTranslationResponse['t']): SchemaOf<EmailInput> =>
  object().shape({
    email: string()
      .required(t('form.emailRequired', 'email is required'))
      .matches(emailRegEx, t('form.validEmail', 'please enter a valid email address')),
  });

export function EmailForm({ onSubmit, titleText, subtitleText }: EmailFormProps): JSX.Element {
  const { t } = useTranslation();
  const { trackEnrollmentStepSubmitted } = useDutchiePayAnalytics();
  const [email, setEmail] = useState('');
  const emailIsValid = emailRegEx.test(email);
  const flags = useFlags();
  const isAppleLoginEnabled = flags[`growth.ecomm.sign-in-apple.rollout`] ?? false;

  const handleSubmit = (args): void => {
    trackEnrollmentStepSubmitted(EnrollmentSteps.emailForm);
    void onSubmit(args);
  };

  return (
    <>
      <DutchiePayHeader>{titleText ?? t('dutchiePay.enterEmail', 'Express checkout')}</DutchiePayHeader>
      <DutchiePayTextBody>
        {subtitleText ?? t('dutchiePay.checkAccount', `Enter your email to log in or sign up.`)}
      </DutchiePayTextBody>

      <Form onSubmit={handleSubmit} validationSchema={EmailFormValidation(t)} noValidate>
        <FormInputsContainer>
          <FormTextInput
            label='Email address'
            name='email'
            variant='filled'
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            hideFieldset
          />
        </FormInputsContainer>

        <DutchiePayButton disabled={!emailIsValid} requiredFieldKeys={['email']}>
          {t('common.continue', 'Continue')}
        </DutchiePayButton>
      </Form>

      <Divider />
      <SocialLogins>
        <GoogleLogin redirectUrl={window.location.href} />
        {isAppleLoginEnabled && <AppleLogin redirectUrl={window.location.href} />}
      </SocialLogins>
    </>
  );
}

const FormInputsContainer = styled.div`
  margin-bottom: 20px;
`;

const SocialLogins = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
