import _ from 'lodash';
import Big from 'big.js';
import { canadianFeeTaxes } from '../constants';
export var addCanadianDeliveryFeeTaxIfApplicable = function addCanadianDeliveryFeeTaxIfApplicable(dispensary, deliveryFee, salesTaxTotal, receipt) {
  var _dispensary$location;

  // In Canada there is a tax applied to Delivery Fees.
  // At this time the only dispensaries that use this
  // are in Canada, are discounts first, and are cumulative.
  // We are going to punt on designing UI and input for this
  // and go with those assumptions.
  if (((_dispensary$location = dispensary.location) === null || _dispensary$location === void 0 ? void 0 : _dispensary$location.country) === 'Canada') {
    var deliveryFeeTax = deliveryFee.times(canadianFeeTaxes[_.toUpper(dispensary.location.state)] || canadianFeeTaxes["default"]);
    salesTaxTotal = salesTaxTotal.plus(deliveryFeeTax);
    receipt.addTax({
      type: "sales",
      value: deliveryFeeTax.times(100)
    });
  }

  return salesTaxTotal;
};
export var calculateDeliveryFee = function calculateDeliveryFee(deliveryOption, dispensary, subtotalMinusCredit) {
  if (deliveryOption === 'delivery') {
    var _dispensary$deliveryI, _dispensary$deliveryI2, _dispensary$deliveryI3, _dispensary$deliveryI4, _dispensary$deliveryI5, _dispensary$deliveryI6;

    var feeType = (_dispensary$deliveryI = (_dispensary$deliveryI2 = dispensary.deliveryInfo) === null || _dispensary$deliveryI2 === void 0 ? void 0 : _dispensary$deliveryI2.feeType) !== null && _dispensary$deliveryI !== void 0 ? _dispensary$deliveryI : 'flat';

    if (feeType === 'flat' && _.isFinite((_dispensary$deliveryI3 = dispensary.deliveryInfo) === null || _dispensary$deliveryI3 === void 0 ? void 0 : _dispensary$deliveryI3.fee) && ((_dispensary$deliveryI4 = dispensary.deliveryInfo) === null || _dispensary$deliveryI4 === void 0 ? void 0 : _dispensary$deliveryI4.fee) > 0) {
      return Big(dispensary.deliveryInfo.fee / 100, 'Delivery Info Fee');
    }

    if (feeType === 'percent' && _.isFinite((_dispensary$deliveryI5 = dispensary.deliveryInfo) === null || _dispensary$deliveryI5 === void 0 ? void 0 : _dispensary$deliveryI5.percentFee) && ((_dispensary$deliveryI6 = dispensary.deliveryInfo) === null || _dispensary$deliveryI6 === void 0 ? void 0 : _dispensary$deliveryI6.percentFee) > 0) {
      return subtotalMinusCredit.times(Big(dispensary.deliveryInfo.percentFee / 100));
    }

    if (!_.isEmpty(dispensary.feeTiers)) {
      var deliveryFee = Big(0);

      _.forEach(dispensary.feeTiers, function (tier) {
        var tierFeeType = tier.feeType,
            percentFee = tier.percentFee;

        var min = _.toNumber(tier.min);

        var max = _.toNumber(tier.max);

        var fee = _.toNumber(tier.fee);

        if (subtotalMinusCredit !== null && subtotalMinusCredit !== void 0 && subtotalMinusCredit.gte(min) && subtotalMinusCredit !== null && subtotalMinusCredit !== void 0 && subtotalMinusCredit.lte(max)) {
          if (tierFeeType === 'percent' && _.isFinite(percentFee)) {
            deliveryFee = subtotalMinusCredit.times(Big(percentFee / 100));
          } else {
            deliveryFee = Big(fee, 'Fee');
          }
        }
      });

      return deliveryFee;
    }
  }

  return Big(0);
};
export var calculatePaymentFeeIfApplicable = function calculatePaymentFeeIfApplicable(dispensary, paymentMethod, negativePosttaxSubtotal, altBasisForZeroedSubtotal, posttaxSubtotal, receipt) {
  var paymentFee = Big(0);

  var feeInfo = _.find(dispensary.paymentFees, function (feeEntry) {
    return feeEntry.paymentType === paymentMethod || feeEntry.paymentType === 'creditCardAtDoor' && paymentMethod === 'creditCard' || feeEntry.paymentType === 'debitOnly' && paymentMethod === 'debitCard' || feeEntry.paymentType === 'debit' && paymentMethod === 'debitCard' || _.includes(["creditCard", "creditCardAtDoor", "creditCardByPhone"], feeEntry.paymentType) && paymentMethod === 'inStoreCreditCard';
  });

  if (feeInfo) {
    var fee = feeInfo.fee,
        feeType = feeInfo.feeType;

    if (feeType === 'cents') {
      paymentFee = Big(fee, 'Fee');
    } else {
      // Use product total instead of subtotal, if a discount has brought the subtotal to a negative
      // value and we zeroed it above.
      var subtotalForFeeCalc = negativePosttaxSubtotal ? altBasisForZeroedSubtotal : posttaxSubtotal; // subtotal * (fee / 100) * 100 -- last 100 because we are returning answer in cents

      paymentFee = subtotalForFeeCalc.times(fee).round();
    }
  }

  receipt.addFee({
    type: "payment",
    value: paymentFee
  });
  return paymentFee;
};