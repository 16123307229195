import _ from 'lodash';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useGetRecommendedProductsQuery } from 'types/graphql';
import { PersonalizedProductsQuery } from 'src/personalization/data-layer/types';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import BlueSparkle from 'src/assets/blue-sparkle-icon';

import { DesktopOnly, MobileOnly } from 'shared/components';
import { BlendedPersonalizedProductCarousel } from './blended-carousel';
import { TabbedPersonalizedProductCarousel } from './tabbed-carousel';

type LoggedInProps = {
  personalizedProductsQuery: PersonalizedProductsQuery;
  sponsoredBrandQuery?: SponsoredBrandQuery;
};

export const LoggedInV2 = (props: LoggedInProps): JSX.Element | null => {
  const flags = useFlags();
  const { dispensary } = useDispensary();
  const dispensaryId = dispensary?.id;

  const heading = 'Recommended for you';
  const subheading = 'Based on your shopping history';
  const icon = <BlueSparkle />;

  // We don't care about fetching Buy It Again products until this level
  const hasBuyItAgainEnabled = flags['growth.ads.buy-it-again-carousel.rollout'];
  const recommendedProductsQuery = useGetRecommendedProductsQuery({
    fetchPolicy: `network-only`,
    skip: !hasBuyItAgainEnabled,
    variables: {
      dispensaryId,
    },
  });

  const productQueries = {
    ...props,
    recommendedProductsQuery: _.pick(recommendedProductsQuery, ['data', 'loading', 'error']),
  };

  return (
    <>
      <MobileOnly mediaQuery='xs'>
        <TabbedPersonalizedProductCarousel heading={heading} subheading={subheading} icon={icon} {...productQueries} />
      </MobileOnly>

      <DesktopOnly mediaQuery='sm'>
        <BlendedPersonalizedProductCarousel heading={heading} subheading={subheading} icon={icon} {...productQueries} />
      </DesktopOnly>
    </>
  );
};
