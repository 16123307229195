import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import useCart from 'hooks/use-cart';
import useTranslation from 'hooks/use-translation';
import {
  dispensaryHoursForDay,
  getAcceptedPaymentOptions,
  getDeliveryFee,
  acceptingAfterHoursForDelivery,
  acceptingAfterHoursForPickup,
} from 'shared/helpers/dispensaries';
import { checkDeliveryOpenInfo, getEnabledV2OrderTypes, openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { getTranslationForOrderType } from 'shared/helpers/utils';
import StoreInfoDeliveryIcon from 'src/assets/store-info-delivery-icon';
import StoreInfoPaymentIcon from 'src/assets/store-info-payment-icon';
import StoreInfoMenusIcon from 'src/assets/store-info-menus-icon';
import { paymentMethodsToDisplayNames } from 'shared/constants';
import { Typography } from 'src/typography';

export default function StoreSidebar({ dispensary }) {
  const { t } = useTranslation();
  const Cart = useCart();

  const recDispensary = useObserver(() => dispensary.recDispensary);
  const medicalDispensary = useObserver(() => dispensary.medicalDispensary);
  const offerDelivery = useObserver(() => dispensary.orderTypesConfigV2.offerDeliveryService);
  const feeTiers = useObserver(() => dispensary.feeTiers);
  const status = useObserver(() => dispensary.status);

  const deliveryInfo = useObserver(() => dispensary.deliveryInfo);

  /**
   * Fee tiers may exist in dispensary data and not be in use.
   * Fees from fee tiers do not apply if a delivery zone or zip code
   * has a fee. They do apply if a zone or zip code is in
   * use, does not have a fee defined, and fee tiers exist.
   * This is handled in getDeliveryInfoForAddress
   */
  const shouldDisplayFeeTierInfo = !_.isFinite(deliveryInfo?.fee) && !_.isFinite(deliveryInfo?.percentFee);

  const deliveryFee = getDeliveryFee(dispensary, Cart.hasDeliveryAddress());

  const closedButAvailableForPreorder =
    acceptingAfterHoursForDelivery(dispensary) || acceptingAfterHoursForPickup(dispensary);

  const nowDayOfWeek = moment().format(`dddd`);
  const acceptedOptions = getAcceptedPaymentOptions(dispensary);
  const paymentOptions = _.keys(acceptedOptions);
  const paymentLabels = _.uniq(
    // eslint-disable-next-line lodash/prefer-lodash-method
    paymentOptions.map((opt) => {
      if (opt === 'dutchiePay' && dispensary.brandedDutchiePayEnabled) {
        return dispensary.brandedDutchiePayName;
      }
      if (opt === 'payOnlineMerrco') {
        return `Credit Card`;
      }
      return paymentMethodsToDisplayNames[opt];
    })
  );

  const enabledOrderTypes = getEnabledV2OrderTypes(dispensary);
  const openInfoForOrderTypes = openInfoForDispensary(dispensary);
  // If all order types are disabled, hide today's hour section
  const hideHours = enabledOrderTypes.length === 0;

  const availableOrderTypesInfo = checkDeliveryOpenInfo(openInfoForOrderTypes, dispensary);

  return (
    <SidebarContent wide>
      {!hideHours && (
        <>
          <Title size='xsmall' tag='h2'>
            {(props) => props.t('common.today', 'Today')}
          </Title>
          <HoursToday>
            <>
              {
                // eslint-disable-next-line lodash/prefer-lodash-method
                enabledOrderTypes.map((orderType) => {
                  const orderTypeStatus =
                    availableOrderTypesInfo[orderType].isOpen && status === 'open'
                      ? t('common.open', 'open')
                      : t('common.closed', 'closed');
                  const orderTypeEffectiveHours = dispensary.hoursSettings[orderType].effectiveHours;
                  return (
                    <Hours className={orderTypeStatus} key={`${orderType}-open-info`}>
                      <TypeLabel wide>{getTranslationForOrderType(t, orderType, true)}:</TypeLabel>
                      <Time>{dispensaryHoursForDay(orderTypeEffectiveHours[nowDayOfWeek])}</Time>
                      <StatusPill className={orderTypeStatus} narrow>
                        {orderTypeStatus}
                      </StatusPill>
                    </Hours>
                  );
                })
              }
            </>
            {closedButAvailableForPreorder && (
              <Hours>
                <Preorder>{t('common.closedAfterHoursAvailable', 'Closed, but preordering is available!')}</Preorder>
              </Hours>
            )}
          </HoursToday>
          <Divider />
        </>
      )}

      <Title size='xsmall' tag='h2'>
        {(props) => props.t('common.storeInfo', 'Store Info')}
      </Title>
      <Container>
        {offerDelivery && (
          <ContainerRow>
            <StoreInfoDeliveryIcon />
            <Info>
              <Caps>{t('common.delivery', 'Delivery')}</Caps>
              {!deliveryFee && t('common.noDeliveryFee', 'Free delivery')}
              {deliveryFee &&
                deliveryFee !== `varies` &&
                shouldDisplayFeeTierInfo &&
                feeTiers.length > 1 &&
                t('common.feeNotice', 'Delivery fee varies by order size.')}
              {deliveryFee &&
                deliveryFee !== `varies` &&
                shouldDisplayFeeTierInfo &&
                feeTiers.length === 1 &&
                `${deliveryFee} ${t('common.deliveryFee', 'Delivery fee.')}`}
              {deliveryFee === `varies` &&
                !shouldDisplayFeeTierInfo &&
                `${t('common.deliveryFee', 'Delivery fee varies by location.')}`}
              {deliveryFee &&
                deliveryFee !== `varies` &&
                !shouldDisplayFeeTierInfo &&
                `${deliveryFee} ${t('common.deliveryFee', 'Delivery fee.')}`}
            </Info>
          </ContainerRow>
        )}

        <ContainerRow>
          <StoreInfoPaymentIcon />
          <Info>
            <Caps>{t('common.payment', 'Payment')}</Caps>
            {paymentLabels.join(', ')}
          </Info>
        </ContainerRow>

        <ContainerRow>
          <StoreInfoMenusIcon />
          <Info>
            <Caps>{t('common.menus', 'Menus')}</Caps>
            {medicalDispensary && t('common.medical', 'Medical')}
            {medicalDispensary && recDispensary && ', '}
            {recDispensary && t('common.recreational', 'Recreational')}
          </Info>
        </ContainerRow>
      </Container>
    </SidebarContent>
  );
}

const Container = styled.div`
  margin-top: 30px;
`;

const Caps = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const Info = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[30]};
  line-height: 130%;
`;

const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  > svg {
    margin-right: 20px;
    min-width: 39px;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  margin: 30px 0;
`;

const StatusPill = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.grey[70]};
  color: ${({ theme }) => theme.colors.white} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  padding: 5px 11px 4px;
  display: inline-block;
  margin-left: 10px;

  ${({ theme }) => theme.breakpoints.between(`md`, `md`)} {
    padding: ${({ narrow }) => narrow && `5px 8px 4px`};
    margin-left: ${({ narrow }) => narrow && `5px`};
  }

  &.open {
    background: ${({ theme }) => theme.colors.lime[45]};
  }
`;

const Preorder = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 165%;
  color: ${({ theme }) => theme.colors.orange[65]};
  margin-top: 14px;
`;

const HoursToday = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 13px;
  line-height: 31px;

  .closed {
    color: ${({ theme }) => theme.colors.grey[45]};
  }
`;

const TypeLabel = styled.span`
  font-weight: bold;
  min-width: 75px;
  display: inline-block;

  ${({ theme }) => theme.breakpoints.up(`md`)} {
    min-width: 70px;
  }

  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    min-width: 80px;
  }
`;

const Time = styled.span``;

const Hours = styled.div`
  :first-of-type {
    margin-top: 13px;
  }
`;

const Title = styled(Typography.Heading)`
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const SidebarContent = styled.div`
  background: ${({ theme }) => theme.colors.grey[95]};
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  height: fit-content;
  margin-left: 25px;
  margin-top: 30px;
  max-width: calc(100% - 50px);
  padding: 30px 20px;
  width: 100%;

  @media (min-width: 360px) {
    padding: 30px 25px;
    margin: 30px auto 0;
  }

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    padding: 30px 40px;
  }

  ${({ theme }) => theme.breakpoints.up(`md`)} {
    flex-basis: 30%;
    padding: 30px 25px 41px 25px;
    margin: 0 auto;
    max-width: 315px;
  }

  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    padding: 30px 24px 41px 33px;
  }
`;
