import styled from 'styled-components';

import { Typography } from 'src/typography';
import { Button } from 'src/components/core';

const CUSTOM_BREAKPOINTS = {
  desktop: 1186,
  tablet: 1009,
  mobile: 750,
};

export const Wrapper = styled.div`
  background: #f5f6f7;
  border-style: 1px solid #e3e7e9;
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  margin: ${({ placement }) => (placement === 'secondary' ? '10px auto' : '40px auto')};
  max-width: 1200px;
`;

export const Content = styled.div`
  color: #2c3236;
  display: flex;
  align-items: center;
  padding: 24px;

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const Heading = styled(Typography.Heading)`
  font-size: 20px;
  margin-bottom: 10px;

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

export const Subheading = styled(Typography.Body)`
  font-size: 16px;
  line-height: 24px;
  color: #646d72;

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    font-size: 14px;
    line-height: 20px;
    /* Pull this text over the gap + icon width */
    margin-right: -34px;
  }
`;

export const AlreadyHaveAccount = styled(Typography.Body)`
  color: #646d72;
  margin-top: 12px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 16px;
  padding-right: 16px;

  @media (min-width: ${CUSTOM_BREAKPOINTS.desktop}px) {
    width: 100%;
  }

  @media (max-width: ${CUSTOM_BREAKPOINTS.tablet}px) {
    padding-right: unset;
    flex-direction: column;
  }

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    width: 100%;
  }
`;

export const SignUpButton = styled(Button)`
  box-shadow: 0px 2px 20px 0px ${({ theme }) => `${String(theme.customized.colors.buttonLinks)}40`};
  height: 50px;
  min-width: 50%;
  flex: 1;

  @media (max-width: ${CUSTOM_BREAKPOINTS.desktop}px) {
    min-width: 212px;
  }
  @media (max-width: ${CUSTOM_BREAKPOINTS.tablet}px) {
    min-width: 278px;
  }

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    min-width: 100%;
  }
`;

export const LoginButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
  font-weight: 700;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const TextContent = styled.div`
  max-width: 456px;
  padding-right: 32px;

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    padding-right: 0;
    max-width: 100%;
  }
`;

export const HeadingsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  gap: 16px;
`;

export const IconContainer = styled.div`
  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    order: 3;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: ${CUSTOM_BREAKPOINTS.tablet}px) {
    align-items: center;
  }

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    min-width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;

  @media (max-width: ${CUSTOM_BREAKPOINTS.mobile}px) {
    min-width: 100%;
  }
`;

export const SocialLoginWrapper = styled.div`
  min-width: 50%;
  flex: 1;

  @media (max-width: ${CUSTOM_BREAKPOINTS.tablet}px) {
    max-width: 100%;
  }
`;
