import { useInView } from 'react-hook-inview';

import { isPrerenderIoBot, isSearchEngineBot } from 'src/utils/bots';

export default function useViewportVisibility() {
  const [ref, isVisibleConst] = useInView({ unobserveOnEnter: true });
  let isVisible = isVisibleConst;

  // Disable lazy loading for prerender.io bot and search engine bots
  if (isPrerenderIoBot() || isSearchEngineBot()) {
    isVisible = true;
  }

  // this is a legacy hook, so we need to keep it for now.
  const hasBeenVisible = isVisible;

  return { hasBeenVisible, isVisible, ref };
}
