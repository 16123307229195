import React from 'react';
import { useRouter } from 'next/router';

import useStores from 'shared/hooks/use-stores';
import useErnie from 'shared/hooks/use-ernie';
import { getAgeRestriction } from 'shared/helpers/age-restrictions';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import useTranslation from 'hooks/use-translation';
import useGeoinfo from 'hooks/use-geoinfo';
import ROUTES from 'src/routes';

import CanadaTerritoryPickerModal from './canada-territory-picker-modal';
import AgeConfirmationModal from './age-confirmation-modal';
import { useAge } from './hooks';

export default function AgeRestrictionModal() {
  const { user, UI } = useStores();
  const [age, setAge] = useAge();
  const [geoinfo, setRegion] = useGeoinfo();
  const showErnie = useErnie();
  const { t } = useTranslation();
  const { route } = useRouter();

  if (!geoinfo) {
    return null;
  }

  const ageRestriction = getAgeRestriction(geoinfo);
  const isOpen = ageRestriction && age < ageRestriction;
  const shouldIgnoreRoute = [ROUTES.TERMS, ROUTES.PRIVACY, ROUTES.OAUTH_AUTO_LOGIN].includes(route);

  if (!isOpen || user.loading || shouldIgnoreRoute) {
    return null;
  }

  function handleContinue(newAge, rememberMe) {
    if (newAge < ageRestriction) {
      showErnie(
        t('age-restrictions.error-region', {
          defaultValue: "We're sorry, you must be at least {{ageRestriction}} yrs old to use this site in {{region}}!",
          ageRestriction,
          region: geoinfo.region,
        })
      );
      return;
    }
    setAge(newAge);
    user.setAgeOverride(newAge, rememberMe);
  }

  const modalProps = {
    onContinue: handleContinue,
    onRegionChange: setRegion,
    geoinfo,
    ageRestriction,
    hideDutchieBranding: UI.isStoreFront,
  };

  if (geoinfo.country === 'CA') {
    return (
      <ModalWrapper open>
        <CanadaTerritoryPickerModal {...modalProps} />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper open>
      <AgeConfirmationModal {...modalProps} />
    </ModalWrapper>
  );
}
