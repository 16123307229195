import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Typography } from 'src/typography';

type HeaderProps = {
  heading?: string;
  icon?: ReactNode;
  subheading?: string;
};

export const Header = ({ heading, icon, subheading }: HeaderProps): JSX.Element => (
  <Wrapper>
    {icon && <div>{icon}</div>}

    <div>
      {heading && (
        <Heading size='medium' tag='h2'>
          {heading}
        </Heading>
      )}
      {subheading && <Subheading size='xsmall'>{subheading}</Subheading>}
    </div>
  </Wrapper>
);

const Wrapper = styled.header`
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
  }
`;

const Heading = styled(Typography.Heading)`
  color: #2c3236;
`;

const Subheading = styled(Typography.Body)`
  color: #828a8f;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
`;
