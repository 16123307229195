import _ from 'lodash';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import usePaths from 'src/hooks/use-paths';
import { CategoriesForMenu as categories } from 'shared/constants';
import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';
import { defaultFilters, replaceEdibleWithIngestible, checkNeedToMakeEdibleIngestible } from '../carousels.utils';
import { GenericCarousel } from './generic-carousel';

export function CategoryCarousel(props) {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const { section, dispensaryState } = props;
  const { label, category } = section;
  const currentCategory = _.find(categories, [`value`, category]) || category;

  const makeEdibleIngestible = checkNeedToMakeEdibleIngestible(dispensaryState, category);

  const { products, loading } = useDispensaryProducts({
    filters: { ...defaultFilters, category },
    perPage: 25,
  });
  const { href, route } = usePaths({ category: currentCategory });
  const hasCategory = products?.length > 0;
  if (!hasCategory && !loading) {
    return null;
  }

  const transformedHref = makeEdibleIngestible ? replaceEdibleWithIngestible(href) : href;
  const transformedLabel = makeEdibleIngestible ? replaceEdibleWithIngestible(label) : label;

  if (isProductCardsV2Enabled) {
    return (
      <ProductCarousel
        products={products}
        isLoading={loading}
        href={transformedHref}
        hideTitle={props?.showTitle === false}
        hideLink={props?.showLink === false}
        title={transformedLabel}
      />
    );
  }

  const sectionProps = {
    ...props,
    isLoading: loading,
    linkLabel: makeEdibleIngestible ? replaceEdibleWithIngestible(section.linkLabel) : section.linkLabel,
    href: transformedHref,
    label: transformedLabel,
    products,
    route,
  };

  return <GenericCarousel {...sectionProps} />;
}
