import { ParsedUrlQuery } from 'querystring';
import SHARED_ROUTES from 'shared/routes';

import { GqlState, GetCitiesWithDispensariesQueryHookResult, GqlProducts } from 'types/graphql';
import { removeAllSpecialCharactersWithoutDash, removeDiacritics } from 'shared/helpers/utils';
import { unsupportedCities } from 'shared/constants/cities-geography';
import { Category } from 'src/components/categories-nav/categories-nav.types';
import { BREAD_CRUMB_ROUTES, Crumb, DispensaryInfo } from './bread-crumbs.types';

export function removeUnsupportedCities(
  data: GetCitiesWithDispensariesQueryHookResult['data'] | undefined
): GqlState[] | null {
  const statesWithDispensaries = data?.statesWithDispensaries;
  if (!statesWithDispensaries) {
    return null;
  }

  return statesWithDispensaries.map((state) => {
    const unsupportedCitiesForState = unsupportedCities[state.name];
    const cleanedCitiesForState = state.cities.filter((city) => !unsupportedCitiesForState?.includes(city));
    return {
      ...state,
      cities: cleanedCitiesForState,
    };
  });
}

export function getLocationUrl(countryCode: string, stateCode: string, city: string): string {
  return `/${countryCode.toLowerCase()}/dispensaries/${stateCode.toLocaleLowerCase()}-${city}`;
}

export function getFormattedCityName(cityName: string): string {
  return removeAllSpecialCharactersWithoutDash(removeDiacritics(cityName.toLowerCase()).split(' ').join('-'));
}

export function getCityWithDispensary(
  states: GqlState[] | null,
  stateCode: string,
  cityWithDashes: string
): string | null {
  const state = states?.find((s: GqlState) => s.name === stateCode);
  const city = state?.cities.filter(Boolean).find((c: string) => getFormattedCityName(c) === cityWithDashes);

  return city ?? null;
}

export function getState(states: GqlState[] | null, stateCode: string): GqlState | null {
  const foundState = states?.find((state: GqlState) => state.name === stateCode);
  if (!foundState) {
    return null;
  }
  return foundState;
}

function buildBaseCrumbs(dispensaryInfo: DispensaryInfo): Crumb[] {
  const { city, locationHref } = dispensaryInfo;

  return [
    {
      route: BREAD_CRUMB_ROUTES.HOME,
      href: BREAD_CRUMB_ROUTES.HOME,
      label: `Dispensaries`,
    },
    {
      route: locationHref ? BREAD_CRUMB_ROUTES.DISPENSARIES_COUNTRY_STATE_CITY : BREAD_CRUMB_ROUTES.DISPENSARIES,
      href: locationHref ?? BREAD_CRUMB_ROUTES.DISPENSARIES,
      label: `${city ?? ''}`,
    },
    {
      route: BREAD_CRUMB_ROUTES.DISPENSARY,
      href: `/dispensary/${dispensaryInfo.cName}`,
      label: `${dispensaryInfo.name}`,
    },
  ];
}

export function buildProductDetailPageCrumbs(
  menuVariantRoute: string,
  cName: string,
  product?: GqlProducts,
  categories?: Category[]
): Crumb[] {
  // Mapping of product categories to their respective routes.
  function formatCategory(category: string): string | undefined {
    const newMap = categories?.reduce((acc, cat) => {
      acc[cat.value.toLowerCase()] = cat.key;
      return acc;
    }, {});

    return newMap?.[category];
  }

  const baseCrumb: Crumb = {
    route: `${menuVariantRoute}/[cName]/products`,
    href: `${menuVariantRoute}/${cName}/products`,
    label: `All Products`,
  };

  const { type, subcategory } = product ?? {};
  const currentCategory = typeof type !== 'string' ? '' : type.toLowerCase();
  const currentSubcategory = typeof subcategory !== 'string' ? '' : subcategory.toLowerCase();
  const currentCategoryFormatted = formatCategory(currentCategory) ?? currentCategory;

  if (currentSubcategory) {
    return [
      baseCrumb,
      {
        route: `${menuVariantRoute}/[cName]/products/[category]`,
        href: `${menuVariantRoute}/${cName}/products/${currentCategoryFormatted}`,
        label: currentCategoryFormatted,
      },
      {
        route: `${menuVariantRoute}/[cName]/products/[category]/[subcategory]`,
        href: `${menuVariantRoute}/${cName}/products/${currentCategoryFormatted}/${currentSubcategory}`,
        label: currentSubcategory,
      },
    ];
  }

  if (currentCategoryFormatted) {
    return [
      baseCrumb,
      {
        route: `${menuVariantRoute}/[cName]/products/[category]`,
        href: `${menuVariantRoute}/${cName}/products/${currentCategoryFormatted}`,
        label: currentCategoryFormatted,
      },
    ];
  }

  return [baseCrumb];
}

function buildCategoryPageCrumb(cName: string, query: ParsedUrlQuery): Crumb {
  const { category } = query;
  const currentCategory = typeof category !== 'string' ? '' : category;

  return {
    route: BREAD_CRUMB_ROUTES.CATEGORY,
    href: `/dispensary/${cName}/products/${currentCategory}`,
    label: currentCategory,
  };
}

function buildSubcategoryPageCrumb(cName: string, query: ParsedUrlQuery): Crumb {
  const { category, subcategory, subcategories } = query;
  const currentCategory = typeof category !== 'string' ? '' : category;
  const subcategoryParam = subcategory ?? subcategories;
  const currentSubcategory = typeof subcategoryParam !== 'string' ? '' : subcategoryParam;

  return {
    route: BREAD_CRUMB_ROUTES.SUBCATEGORY,
    href: `/dispensary/${cName}/products/${currentCategory}/${currentSubcategory}`,
    label: currentSubcategory,
  };
}

function buildCategoriesPageCrumb(cName: string): Crumb {
  return {
    route: BREAD_CRUMB_ROUTES.CATEGORIES,
    href: `/dispensary/${cName}/categories`,
    label: `Categories`,
  };
}

function buildAllProductsPageCrumbs(cName: string, query: ParsedUrlQuery): Crumb[] {
  const baseCrumb: Crumb = {
    route: BREAD_CRUMB_ROUTES.CATEGORIES,
    href: `/dispensary/${cName}/categories`,
    label: `Categories`,
  };

  const urlProps = {
    query: { ...(query.search ? { search: query.search } : {}) },
  };

  if (query.subcategories) {
    return [
      baseCrumb,
      {
        ...buildCategoryPageCrumb(cName, query),
        urlProps,
      },
      {
        ...buildSubcategoryPageCrumb(cName, query),
        urlProps,
      },
    ];
  }

  if (query.category) {
    return [
      baseCrumb,
      {
        ...buildCategoryPageCrumb(cName, query),
        urlProps,
      },
    ];
  }

  return [baseCrumb];
}

function buildInfoPageCrumb(cName: string): Crumb {
  return {
    route: BREAD_CRUMB_ROUTES.INFO,
    href: `/dispensary/${cName}/info`,
    label: `Info`,
  };
}

function buildBrandsPageCrumb(cName: string): Crumb {
  return {
    route: BREAD_CRUMB_ROUTES.BRANDS,
    href: `/dispensary/${cName}/brands`,
    label: `Brands`,
  };
}

function buildSpecialsPageCrumb(cName: string): Crumb {
  return {
    route: BREAD_CRUMB_ROUTES.SPECIALS,
    href: `dispensary/${cName}/specials`,
    label: `Specials`,
  };
}

function buildBrandPageCrumb(cName: string, query: ParsedUrlQuery): Crumb {
  const brandName = typeof query.brandId === 'string' ? query.brandId.replaceAll('-', ' ') : '';

  return {
    route: BREAD_CRUMB_ROUTES.BRAND,
    href: `/dispensary/${cName}/brands/${typeof query.brandId === 'string' ? query.brandId : ''}`,
    label: brandName,
  };
}

function getCrumbBuilder(
  route: string,
  query: ParsedUrlQuery,
  dispensaryInfo: DispensaryInfo,
  product?: GqlProducts,
  categories?: Category[]
): () => Crumb[] {
  const { cName } = dispensaryInfo;

  const baseCrumbBuilder = (): Crumb[] => buildBaseCrumbs(dispensaryInfo);
  const builderMapping = {
    [BREAD_CRUMB_ROUTES.CATEGORY]: () => [
      ...baseCrumbBuilder(),
      buildCategoriesPageCrumb(cName),
      buildCategoryPageCrumb(cName, query),
    ],
    [BREAD_CRUMB_ROUTES.SUBCATEGORY]: () => [
      ...baseCrumbBuilder(),
      buildCategoriesPageCrumb(cName),
      buildCategoryPageCrumb(cName, query),
      buildSubcategoryPageCrumb(cName, query),
    ],
    [BREAD_CRUMB_ROUTES.PRODUCTS]: () => [...baseCrumbBuilder(), ...buildAllProductsPageCrumbs(cName, query)],
    [BREAD_CRUMB_ROUTES.INFO]: () => [...baseCrumbBuilder(), buildInfoPageCrumb(cName)],
    [BREAD_CRUMB_ROUTES.SPECIALS]: () => [...baseCrumbBuilder(), buildSpecialsPageCrumb(cName)],
    [BREAD_CRUMB_ROUTES.BRANDS]: () => [...baseCrumbBuilder(), buildBrandsPageCrumb(cName)],
    [BREAD_CRUMB_ROUTES.BRAND]: () => [
      ...baseCrumbBuilder(),
      buildBrandsPageCrumb(cName),
      buildBrandPageCrumb(cName, query),
    ],
    [BREAD_CRUMB_ROUTES.DEFAULT_PRODUCT_DETAIL_PAGE]: () => [
      ...buildProductDetailPageCrumbs(SHARED_ROUTES.DISPENSARY, cName, product, categories),
    ],
    [BREAD_CRUMB_ROUTES.EMBEDDED_PRODUCT_DETAIL_PAGE]: () => [
      ...buildProductDetailPageCrumbs(SHARED_ROUTES.EMBEDDED_MENU, cName, product, categories),
    ],
    [BREAD_CRUMB_ROUTES.KIOSKS_PRODUCT_DETAIL_PAGE]: () => [
      ...buildProductDetailPageCrumbs(SHARED_ROUTES.KIOSKS, cName, product, categories),
    ],
    [BREAD_CRUMB_ROUTES.STORES_FRONT_PRODUCT_DETAIL_PAGE]: () => [
      ...buildProductDetailPageCrumbs(SHARED_ROUTES.STORES, cName, product, categories),
    ],
  };

  return builderMapping[route] || baseCrumbBuilder;
}

type GetCrumbsParams = {
  route: string;
  query: ParsedUrlQuery;
  dispensaryInfo: DispensaryInfo;
  isMobile: boolean;
  product?: GqlProducts;
  categories?: Category[];
};

export function getCrumbs({ route, query, dispensaryInfo, isMobile, product, categories }: GetCrumbsParams): Crumb[] {
  const isDesktop = !isMobile;
  const builder = getCrumbBuilder(route, query, dispensaryInfo, product, categories);

  if (isDesktop) {
    return builder().filter((crumb) => crumb.label !== 'Categories');
  }

  return builder();
}
