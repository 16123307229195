import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
export default function RadioDisabledIcon(props) {
  return __jsx("svg", _extends({
    height: "18",
    viewBox: "0 0 18 18",
    width: "18",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("g", {
    fill: "none"
  }, __jsx("circle", {
    cx: "9",
    cy: "9",
    fill: "#fcfdfe",
    r: "8.5",
    stroke: "#b7bec3"
  })));
}