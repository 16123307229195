import { useEffect, useState } from 'react';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';

import { tracker } from '..';

const watchedFlags = [
  `growth.ads.personalization-carousel.placement`,
  `growth.ads.sponsored-brand-carousel.placement`,
  `growth.ads.user-events.enabled`,
  `growth.ads.tracking-through-events.rollout`,
  `growth.ads.consent-management.rollout`,
  `growth.ads.personalization.sort-by-relevance.rollout`,
  `growth.ecomm.telemetry.event-kill-switch.operational`,
  `growth.brands.telemetry.home-page-view-payload`,
] as const;

export type WatchedFeatureFlags = typeof watchedFlags[number];

export function useFeatureFlagsMonitor(waitForTrigger: boolean): boolean {
  const flags = useFlags();
  const client = useLDClient();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!client) {
      return;
    }
    // waiting for client.identify
    if (client.getContext()['ecomm-dispensary']) {
      setLoading(false);
    }
  }, [client, flags]); // run this block every time flags change

  useEffect(() => {
    if (!waitForTrigger && !loading) {
      const featureFlags = Object.fromEntries(watchedFlags.map((name) => [name, flags[name]]));

      tracker.setContext({ featureFlags });
    }
  }, [waitForTrigger, loading, flags]);

  return loading;
}
