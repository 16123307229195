// polyfills
import 'focus-options-polyfill';

import { ApolloProvider } from '@apollo/react-hooks';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useObserver } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import useCreateStores from 'state';
import { StyleSheetManager } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { GlobalStyles, EmbeddedStyles } from 'theme';
import { ThemeProvider } from 'theme/provider';
import { ZoomableImagePortalStyles } from 'components/zoomable-image';

import { StoresProvider } from 'contexts/stores';
import { ViewportProvider } from 'contexts/viewport';
import GlobalControllers from 'components/global-controllers';
import EmbeddedEventsController from 'components/embedded-events-controller';
// NOTE: GlobalModals is deprecated. we are in a process of refactoring to Modals
import GlobalModals from 'components/modals/global-modals';
import { Modals } from 'components/modals/modals';
import { SeoMenu } from 'components/seo-menu';
import { IdleDetection } from 'src/components/idle-detection';
import Ernie from 'components/ernie';
import Fallback from 'components/fallbacks';
import BackToTop from 'components/back-to-top';
import { ErrorFallback } from 'src/errors';
import { useInitGlobalGA, useTrackRouteChanges } from 'hooks/use-google-analytics';
import { useInitLogRocket } from 'hooks/use-init-log-rocket';
import { useInitDataDogLogs } from 'src/hooks/use-init-datadog-logs';
import { Analytics } from 'utils/analytics';
import { FidesController } from 'src/utils/fides';
import { useApollo } from 'utils/apollo/client';

import QueryParamProvider from 'utils/query-param-provider';
import useFixLogRocket from 'shared/hooks/use-fix-log-rocket';
import PublicEnv from 'shared/utils/public-env';
import { initializeModuleFederation } from 'src/utils/module-federation/runtime-init';
import { defaultUser } from 'shared/constants/users';
import { LoadPerformanceMode } from 'src/components/load-performance-mode';
import { IncompleteAccountDetection } from 'src/components/incomplete-account-detection';
import { LoyaltyAccountDetection } from 'src/components/loyalty-account-detection';

import 'react-multi-carousel/lib/styles.css';

// Want this called immediately so that remotes are available for the first page load
initializeModuleFederation();

// This will wrap every single page component
// this will *also* wrap every single storybook component!
function App({ Component, pageProps = {}, deviceType = `desktop` }) {
  // first thing's first....
  useFixLogRocket();
  const stores = useCreateStores();
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();
  const isEmbedded = useObserver(() => stores.UI.isEmbedded);
  const Layout = Component.layout || Fragment;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector(`#jss-server-side`);
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  // Enable Tracking & Logging Services
  useInitLogRocket(stores.UI);
  useInitGlobalGA(stores.FeatureFlags);
  useInitDataDogLogs();
  useTrackRouteChanges();

  return (
    <QueryParamProvider>
      <StoresProvider value={stores}>
        <StyleSheetManager>
          <ViewportProvider>
            <Normalize />
            <GlobalStyles />
            <ZoomableImagePortalStyles />
            {isEmbedded && <EmbeddedStyles />}
            {router.isFallback && <Fallback />}
            {!router.isFallback && (
              <ApolloProvider client={apolloClient}>
                <GlobalControllers />
                <FidesController />
                <ThemeProvider deviceType={deviceType}>
                  <IdleDetection>
                    <EmbeddedEventsController />
                    <Analytics />
                    <GlobalModals />
                    <Modals />
                    <Ernie />
                    {isEmbedded && <BackToTop />}
                    <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[router.asPath]}>
                      <SeoMenu>
                        <IncompleteAccountDetection>
                          <LoyaltyAccountDetection>
                            <Layout {...pageProps}>
                              <LoadPerformanceMode />
                              <Component {...pageProps} />
                            </Layout>
                          </LoyaltyAccountDetection>
                        </IncompleteAccountDetection>
                      </SeoMenu>
                    </ErrorBoundary>
                  </IdleDetection>
                </ThemeProvider>
              </ApolloProvider>
            )}
          </ViewportProvider>
        </StyleSheetManager>
      </StoresProvider>
    </QueryParamProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// App.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await NextApp.getInitialProps(appContext);

//   return {
//     ...appProps,
//   };
// };

// export default appWithTranslation(App);

const context = {
  kind: 'multi',
  user: defaultUser,
};

export default withLDProvider({
  clientSideID: PublicEnv.launchDarklyClientId,
  options: {
    evaluationReasons: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  context,
})(App);
