import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { mapApplyRewardsToConditions, mapConsumerTypes, mapDirectCopyFields, mapEnumFields, mapGlobalApplicationRules, mapSchedule, mapSpecialId, mapStackingBehavior } from './other-mappers';
import { FIELD_DEFAULTS } from './constants';
import { mapGlobalRewardThresholds, mapRewards } from './reward-mappers';
import { mapConditions, mapGlobalConditionThresholds } from './condition-mappers';
export var toSchemaV4 = function toSchemaV4(special) {
  var _ref, _directCopyFields$nam, _special$paymentRestr;

  var dispensary = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var conditions = null;
  var rewards = null;
  var globalApplicationRules = null;
  var globalConditionThresholds = null;
  var globalRewardThresholds = null;
  var _dispensary$dispensar = dispensary.dispensaryTimezone,
      dispensaryTimezone = _dispensary$dispensar === void 0 ? 'America/Los_Angeles' : _dispensary$dispensar;
  var directCopyFields = mapDirectCopyFields(special);
  var consumerTypes = mapConsumerTypes(special.menuType);
  var enumFields = mapEnumFields(special);
  var id = mapSpecialId(special);
  var schedule = mapSchedule(special, dispensaryTimezone);
  var stackingBehavior = mapStackingBehavior(special);

  if (special.specialType === 'sale') {
    rewards = mapRewards(special, 'saleDiscounts');
  }

  if (special.specialType === 'bogo') {
    // TODO: Dear super cool engineer in the future, do we want to differentiate POS and ecomm paths?
    // rewards = special.source === 'POS' ? mapRewards(special, 'bogoRewards') : mapAdvancedRewards(special);
    rewards = mapRewards(special, 'bogoRewards');
    conditions = mapConditions(special);
    globalApplicationRules = mapGlobalApplicationRules(special);
    globalConditionThresholds = mapGlobalConditionThresholds(special);
    globalRewardThresholds = mapGlobalRewardThresholds(special);
  }

  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, FIELD_DEFAULTS), directCopyFields), enumFields), {}, {
    applyRewardToConditions: mapApplyRewardsToConditions(special, rewards),
    conditions: conditions,
    consumerTypes: consumerTypes,
    directPOSProductIdMapping: (special === null || special === void 0 ? void 0 : special.directPOSProductIdMapping) || false,
    rawPOSWeightMapping: (special === null || special === void 0 ? void 0 : special.rawPOSWeightMapping) || false,
    useActiveBatchTagOfWeightOption: (special === null || special === void 0 ? void 0 : special.useActiveBatchTagOfWeightOption) || false,
    id: id,
    globalApplicationRules: globalApplicationRules,
    globalConditionThresholds: globalConditionThresholds,
    globalRewardThresholds: globalRewardThresholds,
    name: (_ref = (_directCopyFields$nam = directCopyFields.name) !== null && _directCopyFields$nam !== void 0 ? _directCopyFields$nam : special === null || special === void 0 ? void 0 : special.specialName) !== null && _ref !== void 0 ? _ref : special.name,
    // To account for both naming conventions
    paymentRestrictions: (_special$paymentRestr = special === null || special === void 0 ? void 0 : special.paymentRestrictions) !== null && _special$paymentRestr !== void 0 ? _special$paymentRestr : {
      payByBankSignupIncentive: false
    },
    rewards: rewards,
    schedule: schedule,
    stackingBehavior: stackingBehavior
  });
};