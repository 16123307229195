import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash'; // eslint-disable-next-line import/no-extraneous-dependencies

import Big from 'big.js';
import { amortizeDollarDiscount } from './utilities';
import { gatherProductTaxes } from './taxes';
export var calculateRewardDiscounts = function calculateRewardDiscounts(_ref) {
  var compositePrice = _ref.compositePrice,
      credit = _ref.credit,
      detail = _ref.detail,
      discountTaxOrder = _ref.discountTaxOrder,
      originalCompositePrice = _ref.originalCompositePrice,
      productTotal = _ref.productTotal,
      receiptDetail = _ref.receiptDetail,
      rewardApplicableSubtotal = _ref.rewardApplicableSubtotal,
      rewardApplicableSubtotalPlusTaxes = _ref.rewardApplicableSubtotalPlusTaxes,
      rewardCredit = _ref.rewardCredit,
      rewardLeftOver = _ref.rewardLeftOver,
      rewards = _ref.rewards,
      rewardAppliedToCredit = _ref.rewardAppliedToCredit;

  _.forEach(rewards, function (reward) {
    var additionalCredit = Big(0);

    if (reward !== null && reward !== void 0 && reward.percentage) {
      additionalCredit = additionalCredit.add(originalCompositePrice.times(reward.percentage));
      rewardCredit = rewardCredit.plus(additionalCredit);

      if (discountTaxOrder === 'discountsFirst') {
        compositePrice = compositePrice.minus(additionalCredit);

        if (Big(detail.basePriceMult, 'Base Price Mult').eq(1)) {
          rewardAppliedToCredit = true;
          credit = credit.plus(additionalCredit);
        } else {
          productTotal = productTotal.minus(additionalCredit);
        }
      } else {
        rewardAppliedToCredit = true;
        credit = credit.plus(additionalCredit);
      }

      receiptDetail.addReward({
        type: reward.type,
        id: reward.id,
        name: reward.name,
        value: additionalCredit.times(100)
      });
    } else if (reward !== null && reward !== void 0 && reward.dollar) {
      var rewardData = reward;

      if (discountTaxOrder === 'taxesFirst') {
        var maxDollarDiscount = Big(Math.min(Number(rewardApplicableSubtotalPlusTaxes), (reward === null || reward === void 0 ? void 0 : reward.dollar) || 0));
        rewardData = {
          dollar: Number(maxDollarDiscount)
        };
      }

      var _amortizeDollarDiscou = amortizeDollarDiscount(rewardData, rewardLeftOver, discountTaxOrder === 'taxesFirst' ? rewardApplicableSubtotalPlusTaxes : rewardApplicableSubtotal, originalCompositePrice),
          amortizedDiscount = _amortizeDollarDiscou.amortizedDiscount,
          leftOver = _amortizeDollarDiscou.leftOver;

      additionalCredit = Big(amortizedDiscount);
      rewardCredit = rewardCredit.plus(additionalCredit);
      rewardLeftOver = Big(leftOver);

      if (discountTaxOrder === 'taxesFirst') {
        rewardAppliedToCredit = true;
        credit = credit.plus(additionalCredit);
      } else {
        compositePrice = compositePrice.minus(additionalCredit);
        productTotal = productTotal.minus(additionalCredit);
      }

      receiptDetail.addReward({
        type: reward.type,
        id: reward.id,
        name: reward.name,
        value: additionalCredit.times(100)
      });
    }
  });

  return {
    compositePrice: compositePrice,
    credit: credit,
    productTotal: productTotal,
    rewardCredit: rewardCredit,
    rewardLeftOver: rewardLeftOver,
    rewardAppliedToCredit: rewardAppliedToCredit
  };
};
export var prepareRewards = function prepareRewards(_ref2) {
  var appliedRewards = _ref2.appliedRewards,
      details = _ref2.details,
      products = _ref2.products;
  var rewards = [];

  if (!_.isEmpty(appliedRewards)) {
    _.forEach(appliedRewards, function (_ref3) {
      var brand = _ref3.brand,
          copy = _ref3.copy,
          id = _ref3.id,
          operator = _ref3.operator,
          value = _ref3.value;
      var rewardValue = Big(parseFloat(value || 0));
      var rewardBase = {
        type: brand,
        name: copy,
        id: id
      };

      if (operator === '$') {
        rewards.push(_objectSpread(_objectSpread({}, rewardBase), {}, {
          dollar: rewardValue
        }));
      } else {
        rewards.push(_objectSpread(_objectSpread({}, rewardBase), {}, {
          percentage: rewardValue.div(100)
        }));
      }
    });
  }

  var rewardApplicableSubtotal = _.reduce(details, function (accum, detail) {
    var _detail$mixAndMatch;

    return accum.add((((_detail$mixAndMatch = detail.mixAndMatch) === null || _detail$mixAndMatch === void 0 ? void 0 : _detail$mixAndMatch.adjustedBasePrice) || detail.basePrice).times(detail.quantity).round(2, 1));
  }, Big(0));

  return {
    rewardApplicableSubtotal: rewardApplicableSubtotal,
    rewardApplicableSubtotalPlusTaxes: rewardApplicableSubtotal.plus(gatherProductTaxes(details, products)),
    rewardCredit: Big(0),
    rewardLeftOver: Big(0),
    rewards: rewards
  };
};