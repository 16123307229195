import React, { SVGAttributes } from 'react';

export const PlusIcon = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' {...props}>
    <path
      d='M0 5C0 4.43355 0.459195 3.97436 1.02564 3.97436H8.97436C9.54081 3.97436 10 4.43355 10 5C10 5.56645 9.54081 6.02564 8.97436 6.02564H1.02564C0.459195 6.02564 0 5.56645 0 5Z'
      fill='currentColor'
    />
    <path
      d='M5 10C4.43355 10 3.97436 9.54081 3.97436 8.97436V1.02564C3.97436 0.459195 4.43355 -6.7548e-09 5 0C5.56645 6.7548e-09 6.02564 0.459195 6.02564 1.02564V8.97436C6.02564 9.54081 5.56645 10 5 10Z'
      fill='currentColor'
    />
  </svg>
);
