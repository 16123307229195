import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
/**
 * @param {String} name
 * @param {String} value "yes", "no"
 * @param {Integer} height [n=20]
 * @param {Integer} width [n=20]
 * @param {Integer} checkHeight [n=9]
 * @param {Integer} checkWidth [n=5]
 * @param {String} checkColor [n='#ffffff']
 * @param {String} borderColor [n='#3f6b9e']
 * @param {String} backgroundColor [n='#eff5fc']
 * @param {String} checkedBackgroundColor [n='#eff5fc']
 * @param {String} id [Supports labels using the "for" property. i.e. - you can click a label]
 * @example
 *   import { Checkbox } from 'shared/components/';
 *
 *   <Checkbox
 *     name="dispensaryTypeMedical"
 *     value="medical"
 *     height={18}
 *     width={18}
 *     checkColor="#ffffff"
 *     borderColor="#3f6b9e"
 *     backgroundColor="#eff5fc"
 *     checkedBackgroundColor="#eff5fc"
 *   />
 *
 * @example - Using 'react-final-form'
 *
 *   <Field
 *     name="dispensaryTypeMedical"
 *     value="medical"
 *     type="checkbox"
 *     height={18}
 *     width={18}
 *     render={({ input, meta, ...rest }) => <Checkbox {...input} {...rest} />}
 *   /> Medical
 */

var Container = styled.div.withConfig({
  displayName: "checkbox__Container",
  componentId: "sc-1348luu-0"
})(["display:block;position:relative;cursor:pointer;height:", ";width:", ";user-select:none;"], function (props) {
  return "".concat(props.height || 20, "px");
}, function (props) {
  return "".concat(props.width || 20, "px");
});
var StyledCheckbox = styled.input.withConfig({
  displayName: "checkbox__StyledCheckbox",
  componentId: "sc-1348luu-1"
})(["position:absolute;opacity:0;height:", ";width:", ";z-index:1;cursor:pointer;&:checked ~ span{background-color:", ";border-color:", ";}&:checked ~ span:after{display:block;}"], function (props) {
  return "".concat(props.height || 20, "px");
}, function (props) {
  return "".concat(props.width || 20, "px");
}, function (props) {
  return props.checkedBackgroundColor || '#3bba5b';
}, function (props) {
  return props.checkedBackgroundColor || '#3bba5b';
});
var CustomCheckbox = styled.span.withConfig({
  displayName: "checkbox__CustomCheckbox",
  componentId: "sc-1348luu-2"
})(["position:absolute;top:0;left:0;height:", ";width:", ";background-color:", ";border:1px solid ", ";border-radius:2px;&:after{content:'';position:absolute;display:none;top:45%;left:55%;transform:translate(-50%,-50%) rotate(45deg);width:", ";height:", ";border:solid ", ";box-shadow:0 1px 0 0 #2b8943;border-width:0 2px 2px 0;}"], function (props) {
  return "".concat(props.height || 20, "px");
}, function (props) {
  return "".concat(props.width || 20, "px");
}, function (props) {
  return props.backgroundColor || '#eff5fc';
}, function (props) {
  return props.borderColor || '#3f6b9e';
}, function (props) {
  return "".concat(props.checkWidth || 5, "px");
}, function (props) {
  return "".concat(props.checkHeight || 9, "px");
}, function (props) {
  return props.checkColor || '#ffffff';
});

var Checkbox = function Checkbox(_ref) {
  var id = _ref.id,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(Container, props, __jsx(StyledCheckbox, _extends({}, props, {
    id: id,
    type: "checkbox"
  })), __jsx(CustomCheckbox, props));
};

export default Checkbox;