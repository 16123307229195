import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ProductRecommendationSection } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { ProductCarousel } from 'src/components/product-carousel';
import { ProductCarousel as ProductCarouselV2 } from 'src/components/carousel-v2/types/product-carousel';
import { useRelatedItemsCarousel } from './use-related-items-carousel';

export type RelatedItemsCarouselProps = {
  productId: string;
  category: string;
};

export function RelatedItemsCarousel(props: RelatedItemsCarouselProps): JSX.Element | null {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const carouselProps = useRelatedItemsCarousel(props);
  const { products, loading } = carouselProps;
  const label = `Related Items`;

  if (!loading && !products.length) {
    return null;
  }

  if (isProductCardsV2Enabled) {
    return <ProductCarouselV2 products={products} isLoading={loading} title={label} />;
  }

  return (
    <ProductCarousel heading={label} trackerSection={ProductRecommendationSection.relatedItems} {...carouselProps} />
  );
}
