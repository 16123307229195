import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["register", "inputId"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { space, color, flexbox, typography, layout } from 'styled-system';
import { Text } from 'rebass/styled-components';
export var Checkbox = styled(function (_ref) {
  var register = _ref.register,
      _ref$inputId = _ref.inputId,
      inputId = _ref$inputId === void 0 ? 'checkbox' : _ref$inputId,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx("input", _extends({
    id: inputId,
    type: "checkbox",
    ref: register
  }, props));
}).withConfig({
  displayName: "legacy-rebass__Checkbox",
  componentId: "sc-iyzmic-0"
})(["margin:0 8px 0 0;", " ", ""], space, color);
export var Label = styled.label.withConfig({
  displayName: "legacy-rebass__Label",
  componentId: "sc-iyzmic-1"
})(["align-items:center;display:flex;font-size:14px;margin-bottom:4px;", " ", " ", " ", ""], flexbox, typography, space, color);
export var Truncate = styled(Text).withConfig({
  displayName: "legacy-rebass__Truncate",
  componentId: "sc-iyzmic-2"
})(["overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"]);
export var Hide = styled.div.withConfig({
  displayName: "legacy-rebass__Hide",
  componentId: "sc-iyzmic-3"
})(["", ""], layout);