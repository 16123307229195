import React from 'react';
import { action, computed, makeObservable, observable, toJS, transaction } from 'mobx';
import reduce from 'lodash/fp/reduce';
import size from 'lodash/fp/size';
import cloneDeep from 'lodash/fp/cloneDeep';
import _ from 'lodash';
import moment from 'moment';
import { isBlank } from 'underscore.string';
import { tracker } from 'utils/analytics';

import { getStoredCartKey } from 'shared/actions/cart';
import { formatAddress, formatAddressShort } from 'shared/helpers/address';
import { integrationsByCategory } from 'shared/core/constants/integrations';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import isMobilePhone from 'validator/lib/isMobilePhone';
import OrderComputations from 'shared/order/computations';
import { getScheduledOrderTime } from 'shared/helpers/orders';
import { getTaxInclusionNote, getQuantityRemaining } from 'shared/helpers/products';
import { getComplianceConfig } from 'shared/core/helpers/compliance';
import {
  LOCAL_STORAGE_STORED_CART_KEY,
  MENU_TYPE_REC,
  MENU_TYPE_MED,
  PAYMENT_CASH,
  ORDER_TYPE_DELIVERY,
  ORDER_TYPE_PICKUP,
  ORDER_TYPE_CURBSIDE_PICKUP,
  ORDER_TYPE_DRIVE_THRU_PICKUP,
  defaultDurationEstimates,
  MARKETING_CAMPAIGN_PARAMS,
  CHECKOUT_LINE_ITEM_MAPPING,
} from 'shared/constants';
import {
  openInfoForDispensary,
  getDefaultRewardsProgramDisplayDescription,
  getDefaultRewardsProgramDisplayEnrollment,
  getDefaultRewardsProgramDisplayName,
  requiresCustomerState,
} from 'shared/core/helpers/dispensaries';
import { checkAllLimits } from 'shared/helpers/purchase-limits';
import { maxQuantity } from 'shared/helpers/max-quantity';

import { getCartAdditionAccess, getIsAfterHoursOrderV2, getOrderingOptionsV2 } from 'utils/helpers/dispensary';
import { cartItemId } from 'utils/helpers/product';
import {
  productAddedToCart as analyticsProductAddedToCart,
  productRemovedFromCart as analyticsProductRemovedFromCart,
  cartDidPurchase as analyticsCartDidPurchase,
} from 'utils/ecommerce-analytics';

import CartItem from './cart-item';
import calculationActions from './cart-calc-actions/calculation-actions';

/**
 * Type imports
 *
 * @typedef {import('shared/utils/type-utils').WhenNotVoid} WhenNotVoid
 */

const utmDataSymbol = Symbol('dispensaryId');

// the shopping cart!
export default class CartStore {
  @observable isCalculating = false;
  @observable calculationTimestamp = Date.now();
  @observable cartCalculationOutput = {};
  @observable isReady = false;
  @observable isDutchieMain = false;
  @observable isMedical = false;
  @observable paymentMethod = PAYMENT_CASH;
  @observable isFirstTimePBBUser = false;
  @observable items = {};
  @observable orderType = null;
  @observable dispensary = null;
  @observable mostRecentProduct = null;
  @observable featureFlags = {};

  @observable previewMode = null;
  @observable openInfoForDispensary = null;
  @observable durationEstimates = { ...defaultDurationEstimates };

  @observable checkoutToken = null;

  // checkout
  @observable tempAddress = '';
  @observable tempLocation = {};
  @observable customerState = null;
  @observable address = {};
  @observable freezeDeliveryInfo = false;
  @observable coupon = null;
  @observable deliveryAreaId = null;
  @observable tipValue = null;
  @observable guestInfoToSave = {};
  @observable guestCustomer = {};
  @observable guestCouponError = false;
  @observable saveGuestInfo = false;
  @observable stashedOrder = null;
  @observable hasRewardsWallet = false;
  @observable rewardAuth = {};
  @observable rewardPin = '';
  @observable appliedRewards = [];
  @observable availableRewards = [];
  @observable rewardBrandName = '';
  @observable hasCheckedForRewards = false;
  @observable reservationsAvailableByOrderType;
  @observable tempEstimate = null;
  @observable driversLicense;
  @observable reservation;
  @observable reservationType = '';
  @observable medicalCard;
  @observable inlineAuth = {};
  @observable inlineAuthType = `login`;
  @observable paysafeInput = {
    token: null,
    zip: null,
  };
  @observable utmData = null;
  @observable hypurAccessToken = null;
  @observable monerisInput = {};
  @observable autoCheckoutAfterBankLinkReconnect = false;
  @observable calculationIndex = 0;

  // kiosk checkout
  @observable specialInstructions = null;
  @observable destinationTerminal = null;
  @observable isKioskOrder = false;
  @observable isAnonymousKioskCheckout = false;
  @observable anonymousOrderId = '';
  @observable serverOrderId = '';
  // dutchiePay
  @observable paymentMethodId = '';

  // Temporary reporting obj for special hours scheduling data
  @observable schedulingData = null;

  constructor(isDutchieMain, FeatureFlags) {
    makeObservable(this);

    this.isDutchieMain = isDutchieMain;
    this.featureFlags = FeatureFlags;

    // these refs are necessary for the auto-save feature
    // for checkout sections. Referenced in on-click-submit.js
    this.paysafeFormRef = React.createRef();
    this.medicalComponentRef = React.createRef();
    this.orderTypeComponentRef = React.createRef();
    this.scheduledOrderingRef = React.createRef();

    const persistedMedicalCard = getPersistedValue(`medicalCard`, false);
    this.setMedicalCard(persistedMedicalCard);
  }

  @action.bound
  setGuestFromPersisted() {
    this.guestCustomer = getPersistedValue(`guestCustomer`, {});
    this.customerState = getPersistedValue(`customerState`, null);
  }

  @action
  clearPersistedGuestCustomer() {
    setPersistedValue(`guestCustomer`, {});
    setPersistedValue(`customerState`, null);
  }

  @action
  setAutoCheckoutAfterBankLinkReconnect(status) {
    this.autoCheckoutAfterBankLinkReconnect = status;
  }

  @action
  setFreezeDeliveryInfo(value) {
    this.freezeDeliveryInfo = value;
  }

  @action.bound
  addItem(dispensary, item, id = cartItemId(item)) {
    if (this.dispensary !== dispensary) {
      this.setDispensary(dispensary);
    }

    const newCartItem = new CartItem(this, item, id);
    const existingItem = this.items[id];

    if (existingItem) {
      existingItem.add(newCartItem);
    } else {
      this.items[id] = newCartItem;
    }

    this.mostRecentProduct = {
      id,
      quantity: item.quantity,
      option: item.option,
      additionalOption: item.additionalOption,
      trackerSource: item.trackerSource,
    };

    const addedCartItem = this.items[id];

    return addedCartItem;
  }

  @action.bound
  analyticsProductAddedToCart(item) {
    tracker.setContext({
      activeCart: {
        subtotal: this.costBreakdown.subtotal,
        ...cloneDeep(this),
      },
      activeDispensary: this.dispensary,
      activeOrder: this.order,
    });
    analyticsProductAddedToCart(item, cloneDeep(this));
  }

  @action.bound
  getItemQuantityByProductId(productId) {
    const itemInCart = this.getItemByProductId(productId);

    return itemInCart ? itemInCart.quantity : 0;
  }

  @action.bound
  getItemByProductId(productId) {
    const itemInCart = _.find(Object.entries(this.items), (item) => item[1]?.id === productId);
    return itemInCart ? itemInCart[1] : null;
  }

  @action.bound
  setAnonymousOrderId(anonymousOrderId) {
    this.anonymousOrderId = anonymousOrderId;
  }

  @action.bound
  setDispensary(dispensary) {
    this.dispensary = dispensary;
  }

  @action.bound
  setDestinationTerminal = (destinationTerminal) => {
    this.destinationTerminal = destinationTerminal;
  };

  @action.bound
  setIsAnonymousKioskCheckout(isAnonymousKisokCheckout) {
    this.isAnonymousKioskCheckout = isAnonymousKisokCheckout;
  }

  @action.bound
  setIsDutchieMain = (isDutchieMain) => {
    this.isDutchieMain = isDutchieMain;
  };

  @action.bound
  clearMostRecentProduct() {
    this.mostRecentProduct = null;
  }

  @action.bound
  applyReward(award) {
    this.appliedRewards = [];
    this.appliedRewards.push(award);
  }

  @action.bound
  setInlineAuthType(type) {
    this.inlineAuthType = type;
  }

  // dutchiePay
  @action.bound
  setPaymentMethodId(paymentMethodId) {
    this.paymentMethodId = paymentMethodId;
  }

  @action.bound
  setIsFirstTimePBBUser(isFirstTimePBBUser = false) {
    this.isFirstTimePBBUser = isFirstTimePBBUser;
  }

  @action.bound
  undoApplyReward(rewardId) {
    this.appliedRewards = _.dropWhile(this.appliedRewards, [`id`, rewardId]);
  }

  @action.bound
  changeItemWeight(item, option, showErnie, customerState) {
    const quantityRemaining = getQuantityRemaining(item.product, option, { isKiosk: this.isKioskOrder });

    const newItem = {
      ..._.clone(item),
      option,
      quantity: Math.min(quantityRemaining, item.quantity),
    };
    newItem.key = cartItemId(newItem);

    if (newItem.quantity === 0) {
      showErnie(`Sorry, this weight is currently out of stock.`);
      return;
    }

    const itemsSnapshot = toJS(this.items);
    const existingCartItem = itemsSnapshot[newItem.key];

    if (existingCartItem && existingCartItem.quantity + newItem.quantity > quantityRemaining) {
      showErnie(`Sorry, there is no more stock available for ${item.product.name} - ${option}.`);
      return;
    }

    const limitsCheck = checkAllLimits({
      newItemId: newItem.key,
      itemBeingAdded: newItem,
      itemBeingRemoved: item.key,
      existingItems: this.items,
      medical: this.isMedical,
      dispensary: this.dispensary,
      customerState,
    });

    if (!limitsCheck.withinLimits) {
      showErnie(limitsCheck.message);
    } else {
      this.addItem(this.dispensary, newItem);
      this.removeItem(item.key);

      this.restoreItemPositions({ itemsSnapshot, oldItemKey: item.key, newItemKey: newItem.key });
    }
  }

  @action.bound
  restoreItemPositions({ itemsSnapshot, oldItemKey, newItemKey }) {
    const snapshotKeyCount = _.keys(itemsSnapshot).length;
    const postSnapshotKeyCount = _.keys(this.items).length;
    if (snapshotKeyCount !== postSnapshotKeyCount) {
      return;
    }

    const oldItemPosition = _.findIndex(_.keys(itemsSnapshot), (key) => key === oldItemKey);
    const restoredItemOrder = _.without(_.keys(toJS(this.items)), newItemKey);
    restoredItemOrder.splice(oldItemPosition, 0, newItemKey);

    _.forEach(restoredItemOrder, (key) => {
      const itemToReinsert = this.items[key];

      this.removeItem(key);
      this.addItem(this.dispensary, itemToReinsert);
    });
  }

  @action.bound
  removeItem(entryId) {
    const item = this.items[entryId];
    analyticsProductRemovedFromCart(item, cloneDeep(this));
    if (item) {
      tracker.removedProductFromCart(item);
    }

    delete this.items[entryId];

    if (this.mostRecentProduct?.id === entryId) {
      this.clearMostRecentProduct();
    }

    if (_.isEmpty(this.items)) {
      this.orderType = null;
    }
  }

  @action.bound
  setOrderType(option) {
    this.orderType = option;
  }

  @action.bound
  setMenuType(menuType = MENU_TYPE_REC) {
    this.isMedical = menuType !== MENU_TYPE_REC;
  }

  // This function is used when changing menu types
  // specifically from med -> rec, as rec limits are lower then med limits.
  // Product wants the excess quantity to be taken off the bottom
  // the item quantities are added one by one and will stop if a limit is detected
  @action.bound
  applyQuantityLimits(pricingType, customerState) {
    if (_.isEmpty(this.items)) {
      return;
    }
    const isMedical = pricingType === MENU_TYPE_MED;

    const reversedCart = _.keys(this.items).reverse();

    _.forEach(reversedCart, (key) => {
      const maxLimit = maxQuantity(key, this.items, isMedical, { ...this.order, customerState });

      if (maxLimit > 0) {
        this.items[key].quantity = maxLimit;
      } else {
        this.removeItem(key);
      }
    });
  }

  @action.bound
  changePricingType = (pricingType = MENU_TYPE_REC) => {
    const medicalOrder = pricingType === MENU_TYPE_MED;

    if (!_.isEmpty(this.items)) {
      _.forEach(this.items, (item, id) => {
        if (!medicalOrder && item.product.medicalOnly) {
          this.removeItem(id);
        }
        if (medicalOrder && item.product.recOnly) {
          this.removeItem(id);
        }
      });
    }

    this.setMenuType(pricingType);
    this.isMedical = medicalOrder;
  };

  @action.bound
  decrementCartItemQuantityByOne(itemKey) {
    if (this.items[itemKey].quantity === 1) {
      this.removeItem(itemKey);
      return;
    }
    this.items[itemKey].quantity -= 1;
  }

  @action.bound
  changeItemQuantity(entryId, quantity, showErnie, customerState, handleAfterQuantityChange = _.noop) {
    const limitsCheck = checkAllLimits({
      newItemId: entryId,
      itemBeingAdded: { ..._.clone(this.items[entryId]), quantity },
      existingItems: this.items,
      medical: this.isMedical,
      dispensary: this.dispensary,
      changingQuantity: true,
      customerState,
    });

    if (!limitsCheck.withinLimits) {
      showErnie(limitsCheck.message);
      return;
    }

    const itemToChange = this.items[entryId];

    if (itemToChange) {
      itemToChange.quantity = quantity;

      this.mostRecentProduct = {
        id: itemToChange.key,
        quantity: itemToChange.quantity,
        option: itemToChange.option,
        additionalOption: itemToChange.additionalOption,
        trackerSource: itemToChange.trackerSource,
      };

      handleAfterQuantityChange();
    }
  }

  @action.bound
  setPaymentMethod(method) {
    this.paymentMethod = method;
  }

  @action.bound
  updateProduct(entryId, itemData) {
    if (this.items[entryId]) {
      this.items[entryId].product = itemData.product;
      this.items[entryId].quantity = itemData.quantity;
      this.items[entryId].option = itemData.option;
    }
  }

  @action.bound
  hasDeliveryAddress({ location = this.currentAddress } = {}) {
    const addressLine1 = location?.ln1 || '';
    // eslint-disable-next-line lodash/prefer-lodash-method
    const hasAddressLine = !_.isEmpty(_.trim(addressLine1)) && /^\d+$/.test(addressLine1.split(' ')[0]);
    const hasLocationLng = location?.lng;
    const noDeliveryAddress = !hasLocationLng || !hasAddressLine;

    if (noDeliveryAddress) {
      return false;
    }
    return true;
  }

  @action.bound
  updateAddress({ location, residentialStatus = 'UNVERIFIED' }) {
    if (location?.geometry?.coordinates) {
      const [lng, lat] = location.geometry.coordinates;

      location = { ...location, lng, lat }; // eslint-disable-line
    }

    this.address = { ...location, residentialStatus };
    setPersistedValue(`address`, location);
    setPersistedValue(`deliveryAreaId`, this.deliveryAreaId);
  }

  @action.bound
  setAddress(address) {
    this.address = address;
  }

  @action.bound
  resetAddress() {
    this.address = {};
  }

  @action.bound
  clearOrder() {
    const storedCart = getPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, {});
    delete storedCart[this.currentStoredCartKey];

    if (!this.dispensary?.featureFlags?.hideStoreHours) {
      this.tempEstimate = {
        type: this.isKioskOrder ? `wait` : this.orderType,
        estimate: this.isKioskOrder ? `5 - 7 min` : this.durationEstimates[this.orderType],
      };
    } else {
      this.tempEstimate = null;
    }

    this.checkoutToken = null;
    this.items = {};
    this.dispensary = null;
    this.orderType = null;
    this.specialInstructions = '';

    this.isKioskOrder = false;
    this.utmData = null;

    this.guestInfoToSave = {};
    if (this.guestCustomer) {
      this.guestCustomer = {};
    }
    this.guestCouponError = false;
    this.saveGuestInfo = false;

    this.clearPersistedGuestCustomer();
    this.removeCoupon();
    this.removeTip();
    this.clearMostRecentProduct();
    this.clearRewards();
    this.clearReservation();

    setPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, storedCart);
  }

  @action.bound
  analyticsCartPurchase(
    orders,
    /** @type WhenNotVoid<import('types/graphql').GqlCreateOrderMutation['createOrderV2']>['order']} */ serverOrder,
    _dispensary
  ) {
    analyticsCartDidPurchase(cloneDeep(this), serverOrder);
  }

  @action.bound
  clearRewards() {
    this.rewardAuth = {};
    this.hasCheckedForRewards = false;
    this.availableRewards = [];
    // Reset only if there are existing items.
    if (this.appliedRewards.length) {
      this.appliedRewards = [];
    }
    this.hasRewardsWallet = false;
    this.rewardBrandName = '';
    this.rewardsBalance = '';
    this.rewardPin = '';
  }

  @action.bound
  updateCustomerState(customerState) {
    this.customerState = customerState;
    setPersistedValue(`customerState`, customerState);
  }

  @action.bound
  updateGuestCustomer(property, value) {
    if (!this.guestCustomer) {
      this.guestCustomer = {};
    }

    // If user changes their phone number, we need to reset their rewards info.
    if (property === 'phone') {
      this.clearRewards();
    }

    this.guestCustomer[property] = value;
    setPersistedValue(`guestCustomer`, this.guestCustomer);
  }

  @action.bound
  removeTip() {
    this.tipValue = null;
  }

  @action.bound
  applyTip(type, value) {
    if (type === 'percent') {
      this.tipValue = { percent: parseFloat(value) };
    } else if (type === 'dollar') {
      this.tipValue = { dollar: parseFloat(value) };
    }
  }

  @action.bound
  applyCouponCode(coupon) {
    const { fixedDiscountInCents, percentDiscount } = coupon;
    if (percentDiscount) {
      this.couponValue = { ...coupon, percent: parseFloat(percentDiscount / 100) };
    } else if (fixedDiscountInCents) {
      this.couponValue = { ...coupon, dollar: parseFloat(fixedDiscountInCents / 100) };
    }

    this.coupon = coupon;

    if (this.isGuestOrder) {
      this.saveGuestInfo = true;
      this.guestCouponError = true;
    }
  }

  @action.bound
  setSpecialInstructions(value) {
    this.specialInstructions = value;
  }

  @action.bound
  removeCoupon() {
    this.coupon = null;
    this.couponValue = null;
  }

  @action.bound
  setDriversLicenseNumber(number) {
    this.driversLicense = number;
  }

  @action.bound
  setReservation(reservation) {
    this.reservation = reservation;
  }

  @action.bound
  setReservationType(reservationType) {
    this.reservationType = reservationType;
  }

  @action.bound
  setSchedulingData(schedulingData) {
    this.schedulingData = schedulingData;
  }

  @action.bound
  clearMedicalCard() {
    this.medicalCard = {};
    setPersistedValue(`medicalCard`, {});
  }

  @action.bound
  setIsMedical(isMedical) {
    this.isMedical = isMedical;
  }

  @action.bound
  setMedicalCard(medicalCard = false) {
    if (!_.isEmpty(medicalCard)) {
      this.isMedical = true;
      const { number = '', expirationDate = '', state = '' } = medicalCard;
      transaction(() => {
        this.setMedicalCardNumber(number);
        this.setMedicalCardExpiration(expirationDate);
        this.setMedicalCardState(state);
        setPersistedValue(`medicalCard`, toJS(this.medicalCard));
      });
    }
  }

  @action.bound
  setMedicalCardNumber(number) {
    const card = this.medicalCard || {};
    card.number = number;
    this.medicalCard = card;
  }

  @action.bound
  setMedicalCardExpiration(expirationDate) {
    const card = this.medicalCard || {};
    card.expirationDate = expirationDate;
    this.medicalCard = card;
  }

  @action.bound
  setMedicalCardState(state) {
    const card = this.medicalCard || {};
    card.state = state;
    this.medicalCard = card;
  }

  @action.bound
  setMedicalCardPhoto(photo) {
    const card = this.medicalCard || {};
    card.photo = photo;
    this.medicalCard = card;
  }

  @action
  setCampaignData = (params, dispensaryId) => {
    if (!dispensaryId) {
      return;
    }

    const marketingCampaignParams = _.pick(params, MARKETING_CAMPAIGN_PARAMS);

    // When navigating to a different page within the same dispensary, the utm params will be dropped.
    // This ensures we persist the utm params in state when we get an empty params obj while viewing the same dispo.
    if (_.isEmpty(marketingCampaignParams) && dispensaryId === this.utmData?.[utmDataSymbol]) {
      return;
    }

    this.utmData = marketingCampaignParams;

    // Using a Symbol here to keep this from being normally iterated.
    // The primary impact here is that it doesn't get sent over GraphQL.
    this.utmData[utmDataSymbol] = dispensaryId;
  };

  @action.bound
  updateInlineAuth(field, value) {
    _.set(this.inlineAuth, field, value);
  }

  @action.bound
  validateAuth() {
    const errors = [];

    if (this.inlineAuthType === 'signup') {
      if (_.isEmpty(_.get(this.order, `inlineAuth.firstName`))) {
        errors.push({
          message: window.innerWidth < 768 ? `First name` : `Please enter your first name`,
          card: `inlineAuth`,
          field: `firstName`,
        });
      }

      if (_.isEmpty(_.get(this.order, `inlineAuth.lastName`))) {
        errors.push({
          message: window.innerWidth < 768 ? `Last name` : `Please enter your last name`,
          card: `inlineAuth`,
          field: `lastName`,
        });
      }

      const phoneNumber = _.get(this.order, `inlineAuth.phone`);
      if (_.isEmpty(phoneNumber) || !isMobilePhone(phoneNumber, `en-US`)) {
        errors.push({
          message: `Please enter a valid phone number`,
          card: `inlineAuth`,
          field: `phone`,
        });
      }

      const [birthMonth, birthDay, birthYear] = _.split(_.get(this.order, `inlineAuth.birthdate`, ''), '/');

      if (birthMonth?.length < 2 || birthDay?.length < 2 || birthYear?.length < 4) {
        errors.push({
          message: `Please enter a valid birthday`,
          card: `inlineAuth`,
          field: `birthdate`,
        });
      }

      const minAge = getComplianceConfig(
        this.order.dispensary.location.state,
        this.order.medicalOrder ? `medMinAge` : `recMinAge`
      );

      const legalBirthday = moment().subtract(minAge, `years`).startOf(`day`);
      const customerBirthday = moment({
        month: parseInt(birthMonth, 10) - 1,
        day: birthDay,
        year: birthYear,
      });

      if (legalBirthday.isBefore(customerBirthday.startOf(`day`))) {
        errors.push({
          message: `You must be at least ${minAge} to order.`,
          card: `inlineAuth`,
          field: `birthdate`,
        });
      }
    }

    if (_.get(this.order, `inlineAuth.password`, '').length < 8) {
      errors.push({
        message: `Enter a Password (must be at least 8 characters)`,
        card: `inlineAuth`,
        field: `password`,
      });
    }

    if (errors.length > 0) {
      return {
        proceed: false,
        errors,
      };
    }

    return { proceed: true };
  }

  @action.bound
  clearReservation() {
    this.reservation = null;
  }

  @action.bound
  performCalculations = _.debounce(async (apolloClient, userPosId, priceCartFlagState) => {
    this.calculationIndex += 1;
    await calculationActions.performCalculations({ cart: this, apolloClient, userPosId, priceCartFlagState });
  }, 150);

  @action.bound
  setIsCalculating(isCalculating) {
    this.isCalculating = isCalculating;
  }

  @action.bound
  setCalculationOutput(output) {
    this.cartCalculationOutput = output;
  }

  @action.bound
  setCheckoutToken(token) {
    this.checkoutToken = token;
  }

  @action.bound
  setServerOrderId(serverOrderId) {
    this.serverOrderId = serverOrderId;
  }

  /* ********************************************** */
  /* *      end of actions start of computed      * */

  /* ********************************************** */

  @computed
  get currentStoredCartKey() {
    return getStoredCartKey(this.dispensary?.id, this.isDutchieMain);
  }

  @computed get openInfo() {
    return openInfoForDispensary(this.dispensary, { previewMode: this.previewMode });
  }

  @computed get cartAdditionAccess() {
    if (this.dispensary) {
      return getCartAdditionAccess(this.dispensary, { previewMode: this.previewMode });
    }
    return `normal`;
  }

  @computed get isOrderingPaused() {
    if (this.dispensary) {
      return this.dispensary.ordersArePaused;
    }

    return false;
  }

  @computed get isAfterHoursOrder() {
    return getIsAfterHoursOrderV2({
      dispensary: this.dispensary,
      isDelivery: this.isDelivery,
      isInStorePickup: this.isInStorePickup,
      isCurbsidePickup: this.isCurbsidePickup,
      isDriveThruPickup: this.isDriveThruPickup,
      openInfo: this.openInfo,
    });
  }

  @computed get orderOptions() {
    return getOrderingOptionsV2(this.dispensary, this.openInfo, this.costBreakdown);
  }

  @computed get isDelivery() {
    return this.orderType === ORDER_TYPE_DELIVERY;
  }

  @computed get isPickup() {
    return _.includes([ORDER_TYPE_PICKUP, ORDER_TYPE_CURBSIDE_PICKUP, ORDER_TYPE_DRIVE_THRU_PICKUP], this.orderType);
  }

  @computed get isInStorePickup() {
    return this.orderType === ORDER_TYPE_PICKUP;
  }

  @computed get isCurbsidePickup() {
    return this.orderType === ORDER_TYPE_CURBSIDE_PICKUP;
  }

  @computed get isDriveThruPickup() {
    return this.orderType === ORDER_TYPE_DRIVE_THRU_PICKUP;
  }

  @computed
  get simplifiedOrderType() {
    if (this.isPickup) {
      return ORDER_TYPE_PICKUP;
    }
    if (this.isDelivery) {
      return ORDER_TYPE_DELIVERY;
    }
    return null;
  }

  @computed
  get price() {
    return reduce((total, product) => total + product.price, 0, this.items);
  }

  @computed
  get requiresCustomerState() {
    if (this.isKioskOrder) {
      return false;
    }
    return requiresCustomerState(this.dispensary);
  }

  @computed
  get requiresPrePaid() {
    // Look into moving this into backofice -> https://dutchie.atlassian.net/browse/ENG-55095
    const stateMatches = [`ny`, `new york`];
    return this.isDelivery && stateMatches.some((state) => this.dispensary.address.toLowerCase().includes(state));
  }

  @computed
  get itemCount() {
    return reduce((total, item) => _.parseInt(total) + _.parseInt(item.quantity), 0, this.items);
  }

  @computed
  get isEmpty() {
    return this.itemCount === 0 && this.subtotal === 0;
  }

  @computed
  get costBreakdown() {
    if (!this.featureFlags?.flags?.['core.cats.enable-async-ecomm-calculations.rollout']) {
      try {
        return OrderComputations.compute(this.order);
      } catch (error) {
        console.log(error);
        return {};
      }
    }
    return this.cartCalculationOutput || {};
  }

  @computed
  get checkoutLineItems() {
    const hideDiscounts = this.dispensary?.storeSettings?.hideDiscountsFromConsumer;

    const taxLineItems = this.costBreakdown?.receipt?.details?.taxBreakdown ?? {};
    const feeLineItems = this.costBreakdown?.receipt?.details?.order?.fees ?? {};
    const lineItems = {
      ...this.costBreakdown?.receipt?.summary,
      ...taxLineItems,
      ...feeLineItems,
    };

    const discountValue = lineItems?.discounts ?? 0;
    const rewardValue = lineItems?.rewards ?? 0;

    return _.reduce(
      lineItems,
      (acc, value, key) => {
        if (typeof key === 'string') {
          const type = _.get(CHECKOUT_LINE_ITEM_MAPPING, key, key);
          // When the hideDiscountsFromConsumer store setting is enabled
          // we want to subtract the discounts present in the cost from the productSubtotal
          if (hideDiscounts && type === CHECKOUT_LINE_ITEM_MAPPING.subtotal) {
            value -= discountValue;
            value -= rewardValue;
          }
          if (
            hideDiscounts &&
            (type === CHECKOUT_LINE_ITEM_MAPPING.discounts || type === CHECKOUT_LINE_ITEM_MAPPING.rewards)
          ) {
            return acc;
          }
          acc.push({ type, value: value / 100 });
        }
        return acc;
      },
      []
    );
  }

  @computed
  get destinationTaxRateSum() {
    if (this.orderType !== 'delivery') {
      return 0;
    }

    const dispensaryTaxes = this.dispensary?.taxConfig?.taxes || [];
    const destinationTaxes = _.filter(dispensaryTaxes, ({ destinationRate }) => !_.isNull(destinationRate));
    return _.sumBy(destinationTaxes, 'destinationRate');
  }

  @computed
  get subtotal() {
    return this.costBreakdown.menuTotal || 0;
  }

  @computed
  get orderSubtotal() {
    return this.costBreakdown.subtotal || 0;
  }

  @computed
  get cartSubtotal() {
    return this.costBreakdown.menuTotal || 0;
  }

  @computed get subtotalMinusDiscounts() {
    const subtotal = this.costBreakdown.subtotal ?? 0;
    const credit = this.costBreakdown.credit ?? 0;
    return subtotal - credit;
  }

  @computed
  get cart() {
    if (size(this.items) === 0) {
      return null;
    }

    return this.items;
  }

  @computed
  get discountToCartApplied() {
    return _.some(this.items, ({ discountToCartApplied = false }) => discountToCartApplied);
  }

  @computed
  get appliedOffers() {
    return _.uniq(
      _.reduce(
        this.costBreakdown.details || [],
        (discountsToCartOffers, detail) => [
          ...discountsToCartOffers,
          ..._.map(detail?.bogoSavings?.individual || [], ({ specialId }) => specialId),
        ],
        []
      )
    );
  }

  @computed
  get mostRecentCartItem() {
    return this.items[this.mostRecentProduct?.id];
  }

  @computed
  get order() {
    const cart = this.cart ? {} : null;
    if (this.cart) {
      _.forEach(_.keys(this.cart), (key) => {
        cart[key] = toJS(this.cart[key].toJSON());
      });
    }

    return {
      dispensary: this.dispensary
        ? toJS({
            ...this.dispensary,
            useDartDiscountsPath: this.dispensary?.storeSettings?.enableLLxSaleDiscountSync,
          })
        : this.dispensary,
      firstTimePBBUser: this.isFirstTimePBBUser,
      medicalOrder: this.isMedical,
      paymentMethod: this.paymentMethod,
      isGuestOrder: this.isGuestOrder,
      guestCustomer: this.guestCustomer,
      deliveryOption: this.orderType, // FIXME not saving curbside, drive thru
      tipValue: this.tipValue,
      coupon: this.coupon,
      couponValue: this.couponValue,
      driversLicense: this.driversLicense,
      reservation: this.reservation,
      inlineAuth: this.inlineAuth,
      paysafeInput: this.paysafeInput,
      appliedRewards: this.appliedRewards,
      saveGuestInfo: this.saveGuestInfo,
      medicalCard: this.medicalCard,
      specialInstructions: this.specialInstructions,
      checkoutToken: this.checkoutToken,
      customerState: this.customerState,
      utmData: this.utmData,
      cart,
    };
  }

  @computed
  get taxInclusionNote() {
    const keys = _.keys(this.items);
    if (!keys.length) {
      return '';
    }
    const { product } = this.items[keys[0]];
    return getTaxInclusionNote({ product, dispensary: this.dispensary, medical: this.isMedical });
  }

  @computed
  get isGuestOrder() {
    return !this.dispensary?.storeSettings?.disableGuestCheckout && !!this.guestCustomer;
  }

  @computed
  get currentAddress() {
    return this.address;
  }

  @computed
  get checkoutReservationOptions() {
    const reservationsAvailableByOrderType = this.reservationsAvailableByOrderType || {};
    const { timezone, orderTypesConfigV2 } = this.order?.dispensary || {};
    if (!_.some(orderTypesConfigV2, ({ enableScheduledOrdering }, _orderType) => enableScheduledOrdering)) {
      return [];
    }
    const orderType = this.orderType === 'pickup' ? `inStorePickup` : this.orderType;
    const now = moment();
    const cartDeliveryAreaId = this.deliveryAreaId || getPersistedValue(`deliveryAreaId`);
    let reservations = null;

    if (orderType === 'delivery') {
      reservations = _.filter(
        reservationsAvailableByOrderType?.[orderType] || [],
        ({ startTimeISO, deliveryAreaId, active }) => {
          // if deliveryAreaId doesnt exist, show the reservation slot by default
          const deliveryAreaMatches = !cartDeliveryAreaId || !deliveryAreaId || cartDeliveryAreaId === deliveryAreaId;
          return now.isBefore(startTimeISO) && deliveryAreaMatches && active;
        }
      );
    } else {
      reservations = _.filter(reservationsAvailableByOrderType?.[orderType] || [], ({ startTimeISO }) =>
        now.isBefore(startTimeISO)
      );
    }

    const availableReservations = _.map(reservations, (reservation, i) => {
      const { dayOfWeek, prefix, startTime, endTime } = getScheduledOrderTime({
        reservation,
        timezone,
      });
      return {
        dayOfWeek,
        value: i,
        labelPrefix: prefix,
        label: `${startTime} - ${endTime}`,
        isDisabled: reservation.quantityRemaining === 0,
        startTimeISO: reservation.startTimeISO,
        reservation,
      };
    });

    return availableReservations;
  }

  @computed get apt() {
    if (!_.isEmpty(this.address.ln2)) {
      return this.address.ln2;
    }
    if (this.address.apt) {
      return this.address.apt;
    }
    return null;
  }

  @computed get formattedAddress() {
    // TODO: any more checking necessary here?
    // see consumer-webpack/src/state/ui.js for reference
    if (_.isEmpty(this.address)) {
      return '';
    }

    return formatAddress(this.address);
  }

  @computed get formattedAddressShort() {
    if (_.isEmpty(this.address)) {
      return '';
    }

    return formatAddressShort(this.address);
  }

  @computed get formattedGuestCustomerBirthdate() {
    return _.compact(
      _.reject([this.guestCustomer.birthMonth, this.guestCustomer.birthDay, this.guestCustomer.birthYear], isBlank)
    ).join('/');
  }

  @computed get menuType() {
    if (this.isMedical) {
      return MENU_TYPE_MED;
    }
    return MENU_TYPE_REC;
  }

  @computed get hasAddress() {
    const addressLine1 = this.address?.ln1 || '';
    // eslint-disable-next-line lodash/prefer-lodash-method
    const hasAddressLine = !_.isEmpty(addressLine1) || /^\d+$/.test(addressLine1.split(' ')[0]);
    const hasLocationLng = this.address?.lng;
    const hasNoAddress = !hasLocationLng && !hasAddressLine;
    if (hasNoAddress) {
      return false;
    }
    return true;
  }

  @computed get combineDiscounts() {
    const type = _.toLower(this.rewardsType);
    const integration = this.order.dispensary?.consumerDispensaryIntegrations;
    const { enabled, flags } = integration[type];
    if (enabled) {
      return !!flags.combineDiscounts;
    }
    return false;
  }

  @computed get rewardsType() {
    const { consumerDispensaryIntegrations = null } = this.order.dispensary ?? {};
    const { rewards } = integrationsByCategory;
    const rewardProgram = _.find(_.keys(rewards), (program) => !!consumerDispensaryIntegrations?.[program]?.enabled);
    return rewards[rewardProgram];
  }

  @computed get rewardsProgramDisplayName() {
    const dispensary = this.order?.dispensary ?? {};
    if (this.rewardsProgramEnabled) {
      return (
        dispensary?.storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayName ||
        getDefaultRewardsProgramDisplayName(dispensary)
      );
    }
    return getDefaultRewardsProgramDisplayName(dispensary);
  }

  @computed get rewardsProgramDisplayDescription() {
    const dispensary = this.order?.dispensary ?? {};
    if (this.rewardsProgramEnabled) {
      return (
        dispensary?.storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayDescription ||
        getDefaultRewardsProgramDisplayDescription(dispensary)
      );
    }
    return getDefaultRewardsProgramDisplayDescription(dispensary);
  }

  @computed get rewardsProgramDisplayEnrollment() {
    const dispensary = this.order?.dispensary ?? {};
    if (this.rewardsProgramEnabled) {
      return (
        dispensary?.storeSettings?.rewardsIntegrationConfiguration?.rewardsProgramDisplayEnrollment ||
        getDefaultRewardsProgramDisplayEnrollment(dispensary)
      );
    }
    return getDefaultRewardsProgramDisplayEnrollment(dispensary);
  }

  @computed get rewardsProgramEnabled() {
    const dispensary = this.order?.dispensary ?? {};
    const { consumerDispensaryIntegrations = null } = dispensary;
    const adapters = _.keys(integrationsByCategory.rewards);
    return _.some(adapters, (program) => !!consumerDispensaryIntegrations?.[program]?.enabled);
  }

  @computed get hasMedOnlyProducts() {
    return _.find(this.items, `product.medicalOnly`);
  }

  @computed get hasRecOnlyProducts() {
    return _.find(this.items, `product.recOnly`);
  }

  //  non-isolated menu items can have recOnly or medicalOnly attributes
  @computed get hasMenuTypeInclusiveProducts() {
    return !_.isEmpty(_.omit(this.items, (items) => items.medicalOnly || items.recOnly));
  }
}
