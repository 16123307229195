import React from 'react';

import { AppleLogo } from 'src/assets/apple-logo';
import { SocialLogin } from './social-login';
import { ProviderProps } from './social-login.types';

export const AppleLogin = ({ redirectUrl, autoLogin = false, onSubmit, className }: ProviderProps): JSX.Element => (
  <SocialLogin
    redirectUrl={redirectUrl}
    autoLogin={autoLogin}
    onSubmit={onSubmit}
    className={className}
    icon={<AppleLogo height={20} width={18} />}
    provider='apple'
    buttonText='Continue with Apple'
  />
);
