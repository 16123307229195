var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react';
import ErrorIcon from 'shared/components/ernie/error-icon';
import SuccessIcon from 'shared/components/ernie/success-icon';
import InfoIcon from 'shared/components/ernie/info-icon';
import { Container, IconContainer, Message } from 'shared/components/ernie/styled-components';
import { ARIA_ROLES, ERNIE_TIMEOUTS, ERNIE_FADE_TIME } from 'shared/constants';
import useStores from 'shared/hooks/use-stores';
var iconMapping = {
  danger: ErrorIcon,
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon
};
var Ernie = observer(function (_ref) {
  var _ref$isIframe = _ref.isIframe,
      isIframe = _ref$isIframe === void 0 ? false : _ref$isIframe,
      _ref$iframeOffset = _ref.iframeOffset,
      iframeOffset = _ref$iframeOffset === void 0 ? 0 : _ref$iframeOffset,
      _ref$parentOffset = _ref.parentOffset,
      parentOffset = _ref$parentOffset === void 0 ? 0 : _ref$parentOffset;

  var _useStores = useStores(),
      UI = _useStores.UI; // "end time" for the current operation, tracks both hiding and showing


  var _useState = useState(0),
      endTime = _useState[0],
      setEndTime = _useState[1]; // whether we're currently hidden or shown


  var _useState2 = useState(false),
      visible = _useState2[0],
      setVisible = _useState2[1];

  var _useState3 = useState(UI.ernieQueue.length > 0 && UI.ernieQueue[0]),
      currentAlert = _useState3[0],
      setCurrentAlert = _useState3[1];

  var _ref2 = currentAlert !== null && currentAlert !== void 0 ? currentAlert : {},
      type = _ref2.type,
      msg = _ref2.msg;

  var IconComponent = _.get(iconMapping, type, iconMapping.danger); // handle the various timings...
  // we want to show the messages for their `timeout` duration in ms,
  // and then between messages we want to chill with visible=false for ERNIE_FADE_TIME ms
  // plus we need to handle getting re-rendered while maintaining correct timings


  useEffect(function () {
    var _currentAlert$timeout;

    var now = Date.now(); // if endTime > 0 then we're either showing a message or in the fade time between

    if (endTime > 0) {
      if (!visible) {
        // in the delay in between messages so we wait, and then clear out state when we get there
        var _timeoutId = setTimeout(function () {
          setEndTime(0);
        }, endTime - now);

        return function () {
          return clearTimeout(_timeoutId);
        };
      } // currently showing a message! (re-)schedule the timeout and keep on keeping on


      var timeoutId = setTimeout(function () {
        setCurrentAlert(UI.ernieQueue.shift());
        setVisible(false);
        setEndTime(Date.now() + ERNIE_FADE_TIME);
      }, endTime - now);
      return function () {
        return clearTimeout(timeoutId);
      };
    } // end time is 0, so we're in an idle state here


    if (_.isEmpty(UI.ernieQueue)) {
      if (visible) {
        // this should never happen but might as well be safe :shrug:
        setVisible(false);
      }

      return null;
    } // idle and there's messages in the queue... showing time!
    // all we really need to do is setup the timeout and visible states because that will cause a re-render
    // and the above code will handle registering the timeout for when it's time to hide again


    setCurrentAlert(UI.ernieQueue[0]);
    var messageTimeout = (_currentAlert$timeout = currentAlert === null || currentAlert === void 0 ? void 0 : currentAlert.timeout) !== null && _currentAlert$timeout !== void 0 ? _currentAlert$timeout : ERNIE_TIMEOUTS.SHORT;
    setEndTime(now + messageTimeout);
    setVisible(true);
  }, [UI.ernieQueue, UI.ernieQueue.length, currentAlert, endTime, visible]);
  return __jsx(Container, {
    "aria-live": "assertive",
    "aria-label": visible ? 'Alert' : '',
    "aria-labelledby": visible ? 'ernie-message' : '',
    "data-cy": "ernie-container",
    "data-test": "ernie-container",
    "data-testid": "ernie-container",
    iframeOffset: iframeOffset,
    isIframe: isIframe,
    parentOffset: parentOffset,
    role: visible ? ARIA_ROLES.ALERT : ARIA_ROLES.PRESENTATION,
    type: type,
    visible: visible
  }, __jsx(IconContainer, null, __jsx(IconComponent, null)), __jsx(Message, {
    id: "ernie-message",
    "data-cy": "ernie-message",
    "data-test": "ernie-message",
    "data-testid": "ernie-message"
  }, __jsx(ReactMarkdown, {
    children: msg
  })));
});
export default Ernie;