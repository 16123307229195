import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import { matchesBrand, matchesCategory, matchesStrain, matchesVendor, matchesProductId, matchesInventoryTag, matchesProductTag } from 'shared/order/bogo/common';
import { isArmageddonDiscountSyncSpecial } from 'shared/helpers/specials-3-5';
export var prepareExcludedProducts = function prepareExcludedProducts() {
  var _ruleKeys$products;

  var excluded = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var ruleKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var productsHash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var result = {
    _id: excluded._id,
    excludedProducts: {},
    selectedExcludedProducts: {}
  };

  if (excluded !== null && excluded !== void 0 && excluded.products && (ruleKeys === null || ruleKeys === void 0 ? void 0 : (_ruleKeys$products = ruleKeys.products) === null || _ruleKeys$products === void 0 ? void 0 : _ruleKeys$products.length) > 0) {
    // handle sale specials
    var saleExclusions = setupExcludedProducts(excluded.products, ruleKeys.products, productsHash);
    result.excludedProducts = _objectSpread({}, (saleExclusions === null || saleExclusions === void 0 ? void 0 : saleExclusions.excludedProducts) || {});
    result.selectedExcludedProducts = _objectSpread({}, (saleExclusions === null || saleExclusions === void 0 ? void 0 : saleExclusions.selectedExcludedProducts) || {});
  } else {
    var _ruleKeys$conditions, _ruleKeys$rewards;

    // handle offers
    if ((ruleKeys === null || ruleKeys === void 0 ? void 0 : (_ruleKeys$conditions = ruleKeys.conditions) === null || _ruleKeys$conditions === void 0 ? void 0 : _ruleKeys$conditions.length) > 0) {
      var conditionExclusions = setupExcludedProducts(excluded.conditions, ruleKeys.conditions, productsHash);
      result.excludedProducts.conditions = _objectSpread({}, (conditionExclusions === null || conditionExclusions === void 0 ? void 0 : conditionExclusions.excludedProducts) || {});
      result.selectedExcludedProducts.conditions = _objectSpread({}, (conditionExclusions === null || conditionExclusions === void 0 ? void 0 : conditionExclusions.selectedExcludedProducts) || {});
    }

    if ((ruleKeys === null || ruleKeys === void 0 ? void 0 : (_ruleKeys$rewards = ruleKeys.rewards) === null || _ruleKeys$rewards === void 0 ? void 0 : _ruleKeys$rewards.length) > 0) {
      var rewardExclusions = setupExcludedProducts(excluded.rewards, ruleKeys.rewards, productsHash);
      result.excludedProducts.rewards = _objectSpread({}, (rewardExclusions === null || rewardExclusions === void 0 ? void 0 : rewardExclusions.excludedProducts) || {});
      result.selectedExcludedProducts.rewards = _objectSpread({}, (rewardExclusions === null || rewardExclusions === void 0 ? void 0 : rewardExclusions.selectedExcludedProducts) || {});
    }
  }

  return result;
};
export var getConditionName = function getConditionName(_ref) {
  var brandId = _ref.brandId,
      brandName = _ref.brandName,
      categoryName = _ref.categoryName,
      productGroup = _ref.productGroup;

  if (productGroup === 'categories') {
    return categoryName;
  }

  if (productGroup === 'brands') {
    return "BRAND__".concat(brandId !== null && brandId !== void 0 ? brandId : '*', "__").concat(brandName !== null && brandName !== void 0 ? brandName : '*');
  }

  return null;
};

var getExcludedBrandData = function getExcludedBrandData(ruleKeys) {
  var brands = {};

  _.forEach(ruleKeys, function (rule) {
    var brandId;
    var brandName;

    var split = _.split(rule, '__');

    split.shift(); // remove our BRAND_

    brandId = split.shift(); // get our id

    brandName = split.join(); // get our brandName
    // get rid of any junk

    if (brandId === '*') {
      brandId = null;
    }

    if (brandName === '*') {
      brandName = null;
    }

    if (brandId || brandName) {
      var _brandId;

      brands[(_brandId = brandId) !== null && _brandId !== void 0 ? _brandId : brandName] = brandName;
    }
  });

  return brands;
};

var isBrandExclusionMatch = function isBrandExclusionMatch(product) {
  var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return matchesBrand(rule, product, rule.productGroup, true);
};

var isCategoryExclusionMatch = function isCategoryExclusionMatch(product) {
  var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return matchesCategory(rule, product, rule.productGroup, true);
};

var isStrainExclusionMatch = function isStrainExclusionMatch(product) {
  var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return matchesStrain(rule, product, true);
};

var isVendorExclusionMatch = function isVendorExclusionMatch(product) {
  var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return matchesVendor(rule, product, true);
};

var isActiveBatchTagExclusionMatch = function isActiveBatchTagExclusionMatch(product) {
  var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var useActiveBatchTags = arguments.length > 2 ? arguments[2] : undefined;
  var useActiveBatchTagOfWeightOption = arguments.length > 3 ? arguments[3] : undefined;
  return matchesInventoryTag(rule, product, true, useActiveBatchTags, useActiveBatchTagOfWeightOption);
};

var isProductTagExclusionMatch = function isProductTagExclusionMatch(product) {
  var rule = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var useActiveBatchTags = arguments.length > 2 ? arguments[2] : undefined;
  return matchesProductTag(rule, product, true, useActiveBatchTags);
};

var isProductExclusionMatch = function isProductExclusionMatch(product) {
  var exclusion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _id = product._id,
      id = product.id,
      enterpriseProductId = product.enterpriseProductId;
  var exclusionId = exclusion._id,
      productIds = exclusion.productIds;
  var productId = _id !== null && _id !== void 0 ? _id : id;
  var epid = "EPID_".concat(enterpriseProductId);

  var productIdMatch = _.includes(_.compact([epid, enterpriseProductId, productId]), exclusionId);

  var productIdsMatch = _.includes(productIds, productId); // v3.5


  return productIdMatch || productIdsMatch || matchesProductId(exclusion, product, true);
};

export var isExcludedProduct = function isExcludedProduct(product) {
  var exclusions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var special = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var useActiveBatchTags = arguments.length > 3 ? arguments[3] : undefined;
  var useActiveBatchTagOfWeightOption = arguments.length > 4 ? arguments[4] : undefined;
  var checkAllExclusions = isArmageddonDiscountSyncSpecial(special);
  var isExcluded = false;

  _.forEach(exclusions, function (exclusion) {
    if (isProductExclusionMatch(product, exclusion)) {
      isExcluded = true;
      return false;
    }

    if (checkAllExclusions) {
      if (isBrandExclusionMatch(product, exclusion)) {
        isExcluded = true;
        return false;
      }

      if (isCategoryExclusionMatch(product, exclusion)) {
        isExcluded = true;
        return false;
      }

      if (isStrainExclusionMatch(product, exclusion)) {
        isExcluded = true;
        return false;
      }

      if (isVendorExclusionMatch(product, exclusion)) {
        isExcluded = true;
        return false;
      }

      if (isActiveBatchTagExclusionMatch(product, exclusion, useActiveBatchTags, useActiveBatchTagOfWeightOption)) {
        isExcluded = true;
        return false;
      }

      if (isProductTagExclusionMatch(product, exclusion, useActiveBatchTags)) {
        isExcluded = true;
        return false;
      }
    }

    return true; // Consistent return
  });

  return isExcluded;
};

var setupExcludedProducts = function setupExcludedProducts() {
  var excluded = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var ruleKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var productsHash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var result = {
    excludedProducts: {},
    selectedExcludedProducts: {}
  };
  var isBrand = !!_.find(ruleKeys, function (rule) {
    return _.includes(rule, 'BRAND_');
  });
  var brands = isBrand && getExcludedBrandData(ruleKeys);

  _.forEach(excluded, function (item) {
    var itemKey = item === null || item === void 0 ? void 0 : item.key;
    var uncategorizedKey = _.includes(itemKey, 'uncategorized_') ? "Uncategorized ".concat(_.split(itemKey, 'uncategorized_')[1]) : itemKey;

    if (!itemKey) {
      return;
    } // confirm that this is still an active rule


    var isActiveKey = _.includes(ruleKeys, itemKey) || _.includes(ruleKeys, uncategorizedKey); // brands are inconsistent and might be indexed by either id or name


    if (isBrand) {
      var _$find, _$find2;

      var matchesOnBrandName = (_$find = _.find(_.toPairs(brands), function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            _id = _ref3[0],
            name = _ref3[1];

        return name === itemKey;
      })) === null || _$find === void 0 ? void 0 : _$find[1];
      var matchesOnBrandId = (_$find2 = _.find(_.toPairs(brands), function (_ref4) {
        var _ref5 = _slicedToArray(_ref4, 1),
            id = _ref5[0];

        return id === itemKey;
      })) === null || _$find2 === void 0 ? void 0 : _$find2[0]; // don't accept the brandName in lieu of the id

      matchesOnBrandId = matchesOnBrandId === matchesOnBrandName ? null : matchesOnBrandId;

      if (matchesOnBrandId) {
        itemKey = matchesOnBrandId;
        isActiveKey = true;
      }

      if (matchesOnBrandName) {
        itemKey = matchesOnBrandName;
        isActiveKey = true;
      }
    }

    if (isActiveKey) {
      var _result$excludedProdu, _result$selectedExclu;

      if (!((_result$excludedProdu = result.excludedProducts) !== null && _result$excludedProdu !== void 0 && _result$excludedProdu[itemKey])) {
        result.excludedProducts[itemKey] = [];
      }

      if (!((_result$selectedExclu = result.selectedExcludedProducts) !== null && _result$selectedExclu !== void 0 && _result$selectedExclu[itemKey])) {
        result.selectedExcludedProducts[itemKey] = {};
      } // confirm that this product is still available


      var isActiveProduct = !!(productsHash !== null && productsHash !== void 0 && productsHash[item._id]);

      if (isActiveProduct) {
        // update main exclusions list
        result.excludedProducts[itemKey].push({
          __key: item._id,
          Name: item.Name
        }); // ensure that this is a selected exclusion

        result.selectedExcludedProducts[itemKey][item._id] = true;
      }
    }
  });

  return result;
};

export default {
  getConditionName: getConditionName,
  isExcludedProduct: isExcludedProduct,
  prepareExcludedProducts: prepareExcludedProducts
};