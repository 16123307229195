import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';

import { Product } from 'types/graphql-overrides';

import { isWeightedProduct } from 'shared/helpers/products';

import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import { useProductPricing } from 'src/hooks/use-product-pricing';

import { StarCheck } from 'src/assets/star-check';
import { DiscountTag } from 'src/assets/discount-tag';
import ProductImage from 'src/components/product-image';
import { OptionTile } from 'src/components/option-tile';
import { Option as BlankOptionTile } from 'src/components/option-tile/option.styles';

import { useCard } from './use-card';

type ProductListItemProps = {
  product: Product;
  productIndex: number;
  onClick: (product: Product, index: number) => void;
  onAddToCart: (product: Product, index: number, option: string) => void;
  hideBorder?: boolean;
};

export const FullCard = ({
  product,
  productIndex,
  onClick,
  onAddToCart,
  hideBorder = false,
}: ProductListItemProps): JSX.Element => {
  const { ref, hasBeenVisible } = useViewportVisibility();
  const {
    href,
    isStaffPick,
    isSponsored,
    brandName,
    strainType,
    potency,
    isSpecialOffer,
    hideDiscount,
    handleSponsoredProductClick,
  } = useCard({
    product,
    productIndex,
    hasBeenVisible,
  });

  const { getPriceOptions } = useProductPricing();
  const options = getPriceOptions(product);
  const totalOptions = options.length;
  const maxOptionsToDisplay = 8;
  const numberOptionsToDisplay = totalOptions > maxOptionsToDisplay ? 7 : totalOptions;

  const handleLinkClick = (): void => {
    onClick(product, productIndex);
    handleSponsoredProductClick();
  };

  return (
    <Wrapper ref={ref} $hideBorder={hideBorder}>
      <Link href={href}>
        <Anchor href={href} onClick={handleLinkClick}>
          <ImageWrapper>
            <ProductImage product={product} height={104} width={104} />
          </ImageWrapper>

          <div>
            {isSponsored && <Sponsored>Sponsored</Sponsored>}

            <Name>{product.name}</Name>

            {brandName && <Brand>{brandName}</Brand>}

            {(strainType || potency.length > 0) && (
              <Details>
                {strainType && <Strain>{strainType}</Strain>}

                {potency.length > 0 && (
                  <Potency>
                    {potency.map((p) => (
                      <div key={p}>{p}</div>
                    ))}
                  </Potency>
                )}
              </Details>
            )}

            {(isStaffPick || isSpecialOffer) && (
              <Tags>
                {isStaffPick && (
                  <StaffPick>
                    <StarCheck />
                    Staff Pick
                  </StaffPick>
                )}

                {isSpecialOffer && (
                  <SpecialOffer>
                    <DiscountTag />
                    Special Offer
                  </SpecialOffer>
                )}
              </Tags>
            )}
          </div>
        </Anchor>
      </Link>

      <OptionsWrapper>
        <Options $isNarrowLayout={numberOptionsToDisplay === 5 || numberOptionsToDisplay === 6}>
          {options
            .slice(0, numberOptionsToDisplay)
            .map(({ value, label, special, formattedPrice, formattedDiscount }) => (
              <OptionTile
                key={value}
                label={label}
                currentPrice={special ? special.formattedPrice : formattedPrice}
                originalPrice={special ? formattedPrice : null}
                discountLabel={special && !hideDiscount ? formattedDiscount : null}
                onClick={() => onAddToCart(product, productIndex, value)}
                hasMultipleOptions={options.length > 1}
                isOutlinedVariant={false}
                shouldDisplayLabel={isWeightedProduct(product) || options.length > 1}
              />
            ))}

          {/* Blank tile with more button when more than 8 options */}
          {totalOptions > maxOptionsToDisplay && (
            <BlankOptionTile
              onClick={() => onAddToCart(product, productIndex, '')}
              data-testid='blank-option-tile'
              $blank
              $hasMultipleOptions
            >
              +{totalOptions - numberOptionsToDisplay} more <br />
              options
            </BlankOptionTile>
          )}
        </Options>
      </OptionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-top: 1px solid #e3e7e9;
  padding: 20px 0;

  ${({ $hideBorder }) =>
    $hideBorder &&
    css`
      border-top: none;
    `}

  @media screen and (min-width: 740px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const Anchor = styled.a`
  align-items: center;
  display: flex;
  gap: 20px;

  @media screen and (min-width: 740px) {
    padding-right: 10px;
  }
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  height: 92px;
  width: 92px;

  @media screen and (min-width: 740px) {
    height: 104px;
    width: 104px;
  }

  img {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }
`;

const Sponsored = styled.div`
  color: #828a8f;
  font-size: 10px;
  line-height: 1;
  margin-bottom: 6px;

  @media screen and (min-width: 740px) {
    margin-bottom: 4px;
  }
`;

const Name = styled.div`
  color: #121516;
  font-size: 14px;
  font-weight: 600;
  line-height: ${16 / 14};

  @media screen and (min-width: 740px) {
    font-size: 16px;
    line-height: ${18 / 16};
  }
`;

const Brand = styled.div`
  color: #646d72;
  font: 12px/1 ${({ theme }) => theme.customized.fonts.secondary};
  margin-top: 6px;

  @media screen and (min-width: 740px) {
    margin-top: 4px;
  }
`;

const Details = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;

const Strain = styled.div`
  background: #e3e7e9;
  border-radius: 12px;
  color: #2c3236;
  display: inline-block;
  font: 500 12px/1 ${({ theme }) => theme.customized.fonts.secondary};
  padding: 6px 8px;
`;

const Potency = styled.div`
  align-items: center;
  color: #646d72;
  display: flex;
  gap: 8px;
  font: 12px/1 ${({ theme }) => theme.customized.fonts.secondary};
`;

const Tags = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 8px;
  line-height: ${13 / 12};
  margin-top: 12px;
  text-transform: ${({ theme }) => theme.customized.textTransforms.tags};
`;

const Tag = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  margin-top: 4px;
`;

const StaffPick = styled(Tag)`
  color: ${({ theme }) => theme.customized.colors.staffPickTag};
`;

const SpecialOffer = styled(Tag)`
  color: ${({ theme }) => theme.colors.green[40]};
`;

const OptionsWrapper = styled.div`
  @media screen and (max-width: 739px) {
    /* Shift to be full bleed of browser window */
    margin: 0 -25px;
    overflow-x: auto;
    /* Calculation is the negative margin + image size + gap */
    padding: 0 25px 0 ${25 + 92 + 20}px;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Options = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  @media screen and (max-width: 739px) {
    margin-top: 20px;
  }

  @media screen and (min-width: 740px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    min-width: 312px;
    max-width: 312px;

    ${({ $isNarrowLayout }) =>
      $isNarrowLayout &&
      css`
        min-width: 240px;
        max-width: 240px;
      `}
  }
`;
