import React from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useTranslation from 'src/hooks/use-translation';

import { Button } from 'components/core';
import { Clickable } from 'shared/components';

import { CartItemDetails } from '../cart-item-details';

import { ProductOptions } from './product-options';
import { ProductOptionsV2 } from './product-options-v2';
import { SelectWeightProps } from './select-weight.types';

export function SelectWeight({
  badge,
  disableDecrementing,
  disableIncrementing,
  handleAddToCart,
  handleQuantityDecrement,
  handleQuantityIncrement,
  handleSetSelectOption,
  onClose,
  options,
  product,
  quantity,
  selectedOption,
}: SelectWeightProps): JSX.Element {
  const { t } = useTranslation();

  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  return (
    <Container>
      <CartItemDetails
        badge={badge}
        product={product}
        quantity={quantity}
        disableIncrementing={disableIncrementing}
        disableDecrementing={disableDecrementing}
        handleIncrement={handleQuantityIncrement}
        handleDecrement={handleQuantityDecrement}
        formattedOption={selectedOption.label}
        formattedSpecialPrice={selectedOption.special?.formattedPrice}
        formattedStandardPrice={selectedOption.formattedPrice}
      />

      {isProductCardsV2Enabled ? (
        <ProductOptionsV2 options={options} selectedOption={selectedOption} handleOptionClick={handleSetSelectOption} />
      ) : (
        <ProductOptions options={options} selectedOption={selectedOption} handleOptionClick={handleSetSelectOption} />
      )}

      <ButtonsContainer>
        <AddToCartButton onClick={() => handleAddToCart({ quantity, option: selectedOption.value })}>
          {t('select-weight.add-to-cart', 'Add to cart')}
        </AddToCartButton>

        <CancelButton onClick={onClose}>{t('manage-weight.cancel', 'Cancel')}</CancelButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-top: 28px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddToCartButton = styled(Button)`
  font-size: 15px;
`;

const CancelButton = styled(Clickable)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
  font-size: 13px;
  margin-top: 17px;
  text-align: center;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};
`;
