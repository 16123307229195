import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Product } from 'types/graphql-overrides';
import { ProductPlacement } from 'src/types';
import useUI from 'src/hooks/use-ui';
import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { tracker } from 'src/utils/analytics';
import {
  ItemListName,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import EmptyLoadingState from 'src/components/empty-loading-state';
import { Card } from 'src/components/product-card-v2';
import { CarouselV2 } from '../carousel-v2';

type ProductCarouselProps = {
  isLoading: boolean;
  products: Product[];
  href?: string;
  hideTitle?: boolean;
  hideLink?: boolean;
  title?: string;
  subTitle?: string;
  icon?: ReactNode;
  header?: ReactNode;
  beforeProducts?: ReactNode;
  afterProducts?: ReactNode;
  mt?: string;
  gradientColor?: string;
  hideSponsoredTag?: boolean;
  placement?: ProductPlacement;
  source?: ProductRecommendationSource | null;
  isSmallTitle?: boolean;

  // In the long run, it would be nice to refactor this into the card so we don't have to completely override the card events but just pass an additional event to fire
  customProductClick?: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  customQuickAddToCart?: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  handleProductImpression?: (product: Product) => void;
};

export const ProductCarousel = ({
  isLoading,
  products,
  href,
  hideTitle,
  title,
  subTitle,
  icon,
  hideLink,
  header,
  beforeProducts,
  afterProducts,
  customProductClick,
  customQuickAddToCart,
  handleProductImpression,
  mt,
  gradientColor,
  hideSponsoredTag,
  placement,
  source = null,
  isSmallTitle = false,
}: ProductCarouselProps): JSX.Element => {
  const router = useRouter();
  const UI = useUI();
  const { handleAddToCart } = useQuickAddToCart();
  const trackerSource = getItemListName({
    router,
    source: source ?? ProductRecommendationSource.native,
    section: title ?? '',
  });

  const handleClick = (product: Product, index: number): void => {
    tracker.setContext({
      activeProductPosition: index,
    });

    tracker.productClicked({ product, position: index, trackerSource, placement });

    UI.activeProductSource = trackerSource;
  };

  const handleQuickAddClick = (product: Product, index: number): void => {
    tracker.setContext({
      activeProductPosition: index,
    });

    tracker.productClicked({ product, position: index, trackerSource, placement });

    void handleAddToCart(product, trackerSource);
  };

  if (isLoading) {
    return <EmptyLoadingState isLoading={isLoading} showBorder={false} page='productCarousel' />;
  }

  return (
    <>
      <CarouselV2
        href={href}
        hideTitle={hideTitle}
        title={title}
        subTitle={subTitle}
        icon={icon}
        hideLink={hideLink}
        header={header}
        mt={UI.isEmbeddedCarousel ? '0' : mt}
        gradientColor={gradientColor}
        isSmallTitle={isSmallTitle}
      >
        {beforeProducts}

        {products.map((product, index) => (
          <Wrapper key={product.id} role='group' aria-roledescription='slide' aria-label={product.name}>
            <Card
              data-testid='carousel-product-card'
              product={product}
              productIndex={index}
              hideSponsoredTag={hideSponsoredTag}
              onClick={customProductClick ?? handleClick}
              onQuickAddClick={customQuickAddToCart ?? handleQuickAddClick}
              onView={() => handleProductImpression?.(product)}
            />
          </Wrapper>
        ))}

        {afterProducts}
      </CarouselV2>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 184px;

  @media screen and (min-width: 1110px) {
    width: 220px;
  }
`;
