/* eslint-disable @typescript-eslint/naming-convention,camelcase */
import { isEqual } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useCart from 'src/hooks/use-cart';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import { useParticipatingInPersonalization } from '../helpers';

function useKillSwitch(): boolean {
  const flags = useFlags();
  return flags['growth.personalization.queries-kill-switch.operational'] ?? false;
}

function useLegacyKillSwitch(): boolean {
  const flags = useFlags();
  return flags['growth.personalization.legacy.queries-kill-switch.operational'] ?? false;
}

function useValidMenuType(): boolean {
  const { menuType } = useCart();
  const flags = useFlags();

  const serveMedical = flags['growth.ads.serve-medical.rollout'] ?? false;
  return menuType === 'rec' || menuType === 'both' || (menuType === 'med' && serveMedical);
}

export function useEnabledForHomePage(): boolean {
  // Home - For You Carousel
  const dispensaryParticipatingInPersonalization = useParticipatingInPersonalization();
  const isKillSwitchOn = useKillSwitch();

  return dispensaryParticipatingInPersonalization && !isKillSwitchOn;
}

const validSortOptions = new Set(['default', 'popular', 'relevance']);

export function useEnabledForCategoryPage(): boolean {
  // Category - For You Carousel
  const dispensaryParticipatingInPersonalization = useParticipatingInPersonalization();
  const isKillSwitchOn = useKillSwitch();

  const validMenuType = useValidMenuType();
  const { queryParams: qp, defaults } = useProductQueryParams();

  return (
    dispensaryParticipatingInPersonalization &&
    !isKillSwitchOn &&
    validMenuType &&
    qp.page === 1 &&
    validSortOptions.has(qp.sortby) &&
    isEqual(qp.brands, defaults.brands) &&
    isEqual(qp.subcategories, defaults.subcategories) &&
    isEqual(qp.straintypes, defaults.straintypes) &&
    isEqual(qp.potencythc, defaults.potencythc) &&
    isEqual(qp.potencycbd, defaults.potencycbd) &&
    isEqual(qp.effects, defaults.effects)
  );
}

export function useLegacyCarouselsEnabled(): boolean {
  // Cart Topppers and PDP Carousels
  // These are enabled for all dispensaries and not part of the 2.0 personalization features
  const isLegacyKillSwitchOn = useLegacyKillSwitch();

  return !isLegacyKillSwitchOn;
}
