import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _ from 'lodash';
import { canadianProvincesMap } from 'shared/core/constants/geography';
import { getComplianceConfig } from 'shared/core/helpers/compliance';
import { MILLIGRAMS_PER_GRAM, GRAMS_PER_OUNCE, FLOWER_TYPES, CONCENTRATE_TYPES, EDIBLE_TYPES, TINCTURE_TYPES, TOPICAL_TYPES, WEIGHTED_TYPES, MILLILITERS_PER_OZ } from './constants';
export var getStateLimits = function getStateLimits(customerState, dispensaryState) {
  var outOfStateLimits = getComplianceConfig(customerState, 'outOfStateLimits');
  var limits = getComplianceConfig(customerState, 'limits');

  if (outOfStateLimits && dispensaryState && dispensaryState !== customerState) {
    limits = outOfStateLimits;
  }

  return limits;
};
export var getTypeLimits = function getTypeLimits(cart) {
  var _limits$Flower, _limits$Concentrate, _limits$EdibleTHC, _limits$TinctureTHC, _limits$TopicalTHC, _limits$SolidEdibleNe, _limits$LiquidEdibleV, _limits$nonFlower;

  var limits = cart.limits,
      medical = cart.medical;
  var key = medical ? 'medical' : 'rec';
  var flowerLimit = ((_limits$Flower = limits.Flower) === null || _limits$Flower === void 0 ? void 0 : _limits$Flower[key]) || Infinity;
  var concentrateLimit = ((_limits$Concentrate = limits.Concentrate) === null || _limits$Concentrate === void 0 ? void 0 : _limits$Concentrate[key]) || Infinity;
  var edibleTHCLimit = ((_limits$EdibleTHC = limits.EdibleTHC) === null || _limits$EdibleTHC === void 0 ? void 0 : _limits$EdibleTHC[key]) || Infinity;
  var tinctureLimit = ((_limits$TinctureTHC = limits.TinctureTHC) === null || _limits$TinctureTHC === void 0 ? void 0 : _limits$TinctureTHC[key]) || Infinity;
  var topicalLimit = ((_limits$TopicalTHC = limits.TopicalTHC) === null || _limits$TopicalTHC === void 0 ? void 0 : _limits$TopicalTHC[key]) || Infinity;
  var solidEdibleNetWeightLimit = ((_limits$SolidEdibleNe = limits.SolidEdibleNetWeight) === null || _limits$SolidEdibleNe === void 0 ? void 0 : _limits$SolidEdibleNe[key]) || Infinity;
  var liquidEdibleVolumeLimitInOunces = ((_limits$LiquidEdibleV = limits.LiquidEdibleVolumeInOunces) === null || _limits$LiquidEdibleV === void 0 ? void 0 : _limits$LiquidEdibleV[key]) || Infinity;
  var nonFlowerLimit = ((_limits$nonFlower = limits.nonFlower) === null || _limits$nonFlower === void 0 ? void 0 : _limits$nonFlower[key]) || Infinity;
  return {
    flowerLimit: flowerLimit,
    concentrateLimit: concentrateLimit,
    edibleTHCLimit: edibleTHCLimit,
    tinctureLimit: tinctureLimit,
    topicalLimit: topicalLimit,
    solidEdibleNetWeightLimit: solidEdibleNetWeightLimit,
    liquidEdibleVolumeLimitInOunces: liquidEdibleVolumeLimitInOunces,
    nonFlowerLimit: nonFlowerLimit
  };
};
export var isFlower = function isFlower(type) {
  return _.includes(FLOWER_TYPES, type);
};
export var isConcentrate = function isConcentrate(type) {
  return _.includes(CONCENTRATE_TYPES, type);
};
export var isEdible = function isEdible(type) {
  return _.includes(EDIBLE_TYPES, type);
};
export var isTincture = function isTincture(type) {
  return _.includes(TINCTURE_TYPES, type);
};
export var isTopical = function isTopical(type) {
  return _.includes(TOPICAL_TYPES, type);
};
export var isWeightedProduct = function isWeightedProduct(_ref) {
  var type = _ref.type;
  return _.includes(WEIGHTED_TYPES, type);
};
export var isLiquidTypeProduct = function isLiquidTypeProduct(limits, type, subcategory) {
  var _limits$liquidEdibleT, _limits$liquidEdibleT2, _limits$liquidEdibleT3, _limits$liquidEdibleT4;

  var liquidCategories = (_limits$liquidEdibleT = (_limits$liquidEdibleT2 = limits.liquidEdibleTypes) === null || _limits$liquidEdibleT2 === void 0 ? void 0 : _limits$liquidEdibleT2.categories) !== null && _limits$liquidEdibleT !== void 0 ? _limits$liquidEdibleT : [];
  var liquidSubcategories = (_limits$liquidEdibleT3 = (_limits$liquidEdibleT4 = limits.liquidEdibleTypes) === null || _limits$liquidEdibleT4 === void 0 ? void 0 : _limits$liquidEdibleT4.subcategories) !== null && _limits$liquidEdibleT3 !== void 0 ? _limits$liquidEdibleT3 : [];
  var liquidTypes = [].concat(_toConsumableArray(liquidCategories), _toConsumableArray(liquidSubcategories));
  return _.includes(liquidTypes, type) || _.includes(liquidTypes, subcategory);
};
export var isSolidEdibleTypeProduct = function isSolidEdibleTypeProduct(type, subcategory) {
  return isEdible(type) && subcategory !== 'drinks';
};
export var getLimitForType = function getLimitForType(_ref2, cart) {
  var productType = _ref2.type,
      productSubcategory = _ref2.subcategory;
  var limits = cart.limits;

  var _getTypeLimits = getTypeLimits(cart),
      flowerLimit = _getTypeLimits.flowerLimit,
      concentrateLimit = _getTypeLimits.concentrateLimit,
      edibleTHCLimit = _getTypeLimits.edibleTHCLimit,
      tinctureLimit = _getTypeLimits.tinctureLimit,
      topicalLimit = _getTypeLimits.topicalLimit,
      solidEdibleNetWeightLimit = _getTypeLimits.solidEdibleNetWeightLimit,
      liquidEdibleVolumeLimitInOunces = _getTypeLimits.liquidEdibleVolumeLimitInOunces;

  var currentType = narrowProductType(productType, productSubcategory, limits);
  var typeMap = {
    Flower: flowerLimit,
    Concentrate: concentrateLimit,
    Edible: limits.usesSolidEdibleTypes ? solidEdibleNetWeightLimit : edibleTHCLimit,
    Tincture: tinctureLimit,
    Topical: topicalLimit,
    Liquid: liquidEdibleVolumeLimitInOunces
  };
  return typeMap[currentType];
};
export var formatDisplayedType = function formatDisplayedType(type) {
  var typeMap = {
    Liquid: 'liquids',
    liquids: 'liquids',
    liquid: 'liquids',
    Tincture: 'tinctures',
    tintures: 'tinctures',
    tincture: 'tinctures',
    seeds: 'seeds',
    Edible: 'edibles',
    edibles: 'edibles',
    edible: 'edibles',
    Topical: 'topicals',
    topicals: 'topicals',
    topical: 'topicals',
    Flower: 'flower',
    flower: 'flower',
    'Pre-Rolls': 'flower',
    'pre-rolls': 'flower',
    cannabis: 'cannabis',
    Cannabis: 'cannabis',
    Concentrate: 'concentrates',
    concentrate: 'concentrates',
    Concentrates: 'concentrates',
    concentrates: 'concentrates',
    drinks: 'drinks'
  };
  return typeMap[type];
};
export var getLimitMessage = function getLimitMessage(type, limit, state) {
  var unit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'g';

  var inCanada = _.has(canadianProvincesMap, state);

  var message = function message(msgLimit, msgUnit) {
    return (// eslint-disable-next-line max-len
      "Sorry! You've reached the ".concat(msgLimit).concat(msgUnit, " purchase limit for ").concat(type, " due to ").concat(inCanada ? 'federal' : 'state', " regulations.")
    );
  }; // if unit is milligrams, convert gram limit to milligrams


  if (unit === 'mg') {
    return message(limit *= MILLIGRAMS_PER_GRAM, unit);
  } // if Canada, convert oz limit to liters


  if (inCanada && type === 'liquids') {
    return message((limit / GRAMS_PER_OUNCE * MILLILITERS_PER_OZ / 1000).toFixed(1), 'L');
  }

  if (inCanada && type === 'liquid edibles') {
    return message((limit * MILLILITERS_PER_OZ / 1000).toFixed(1), 'L');
  } // if converting to oz results in a clean number gte to 1, show ozLimit


  var ozLimit = limit / GRAMS_PER_OUNCE;

  if (Math.round(ozLimit * 100) === ozLimit * 100 && ozLimit >= 1) {
    return message(ozLimit, 'oz');
  }

  return message(limit, unit);
};
export var narrowProductType = function narrowProductType(type) {
  var subcategory = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var limits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (isFlower(type)) {
    return 'Flower';
  }

  if (isConcentrate(type)) {
    return 'Concentrate';
  }

  if (isLiquidTypeProduct(limits, type, subcategory)) {
    return 'Liquid';
  }

  if (isEdible(type) || isSolidEdibleTypeProduct(type, subcategory)) {
    return 'Edible';
  }

  if (isTincture(type)) {
    return 'Tincture';
  }

  if (isTopical(type)) {
    return 'Topical';
  }

  return type;
};
export var productIncludedInType = function productIncludedInType(product, type, order) {
  var _dispensary$location, _ignoreTypeMap$type$c, _ignoreTypeMap$type, _ignoreTypeMap$type$s, _ignoreTypeMap$type2, _typeMap$type$categor, _typeMap$type, _typeMap$type$subcate, _typeMap$type2;

  if (!type || !order) {
    return false;
  }

  var category = product.type,
      subcategory = product.subcategory;
  var customerState = order.customerState,
      dispensary = order.dispensary;
  var limits = getStateLimits(customerState, dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$location = dispensary.location) === null || _dispensary$location === void 0 ? void 0 : _dispensary$location.state);
  var typeMap = limits.typeMap,
      ignoreTypeMap = limits.ignoreTypeMap;
  var defaultsForType;

  switch (type) {
    case 'Flower':
      defaultsForType = FLOWER_TYPES;
      break;

    case 'Concentrate':
      defaultsForType = CONCENTRATE_TYPES;
      break;

    case 'Edible':
      defaultsForType = EDIBLE_TYPES;
      break;

    case 'Tincture':
      defaultsForType = TINCTURE_TYPES;
      break;

    case 'Topicals':
      defaultsForType = TOPICAL_TYPES;
      break;

    default:
      defaultsForType = [type];
  }

  var ignoredCategoriesForType = (_ignoreTypeMap$type$c = ignoreTypeMap === null || ignoreTypeMap === void 0 ? void 0 : (_ignoreTypeMap$type = ignoreTypeMap[type]) === null || _ignoreTypeMap$type === void 0 ? void 0 : _ignoreTypeMap$type.categories) !== null && _ignoreTypeMap$type$c !== void 0 ? _ignoreTypeMap$type$c : [];
  var ignoredSubCategoriesForType = (_ignoreTypeMap$type$s = ignoreTypeMap === null || ignoreTypeMap === void 0 ? void 0 : (_ignoreTypeMap$type2 = ignoreTypeMap[type]) === null || _ignoreTypeMap$type2 === void 0 ? void 0 : _ignoreTypeMap$type2.subcategories) !== null && _ignoreTypeMap$type$s !== void 0 ? _ignoreTypeMap$type$s : [];
  var includedCategoriesForType = (_typeMap$type$categor = typeMap === null || typeMap === void 0 ? void 0 : (_typeMap$type = typeMap[type]) === null || _typeMap$type === void 0 ? void 0 : _typeMap$type.categories) !== null && _typeMap$type$categor !== void 0 ? _typeMap$type$categor : [];
  var includedSubCategoriesForType = (_typeMap$type$subcate = typeMap === null || typeMap === void 0 ? void 0 : (_typeMap$type2 = typeMap[type]) === null || _typeMap$type2 === void 0 ? void 0 : _typeMap$type2.subcategories) !== null && _typeMap$type$subcate !== void 0 ? _typeMap$type$subcate : []; // Only returns true if:
  // the product's category OR subcategory is NOT in the ignoreTypeMap overrides for the specified type, and...
  // the product's category OR subcategory is in the typeMap overrides or defaults for the specified type, or...
  // the product's category is the specified type

  var isIgnoredCategory = _.includes(ignoredCategoriesForType, category);

  var isIgnoredSubCategory = _.includes(ignoredSubCategoriesForType, subcategory);

  var isAddedCategory = _.includes(includedCategoriesForType, category);

  var isAddedSubCategory = _.includes(includedSubCategoriesForType, subcategory);

  if (isIgnoredCategory || isIgnoredSubCategory) {
    return false;
  }

  if (category !== type) {
    if (!isAddedCategory && !isAddedSubCategory && !_.includes(defaultsForType, category)) {
      return false;
    }
  }

  return true;
};