import _ from 'lodash';
import { useEffect } from 'react';
export var setCustomerFromQueryParams = function setCustomerFromQueryParams(_ref) {
  var Cart = _ref.Cart,
      queryParams = _ref.queryParams,
      isUserLoggedIn = _ref.isUserLoggedIn;
  var params = {};

  _.forEach(_.keys(queryParams), function (key) {
    if (_.startsWith(key, 'dtche')) {
      var _key$match;

      var dtcheKey = (_key$match = key.match(/\[(.*?)\]/)) === null || _key$match === void 0 ? void 0 : _key$match[1];

      if (dtcheKey) {
        params[dtcheKey] = queryParams[key];
      }
    } else {
      params[key] = queryParams[key];
    }
  });

  var birthdate = params.birthdate,
      email = params.email,
      firstName = params.firstName,
      lastName = params.lastName,
      medCardExpiration = params.medCardExpiration,
      medCardNumber = params.medCardNumber,
      medCardState = params.medCardState,
      phone = params.phone,
      stateResidency = params.stateResidency;

  var guestCustomerObj = _.omitBy({
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    email: email,
    birthMonth: _.slice(birthdate, 0, 2).join(''),
    birthDay: _.slice(birthdate, 2, 4).join(''),
    birthYear: _.slice(birthdate, 4, 8).join(''),
    stateResidency: stateResidency
  }, _.isEmpty);

  if (!isUserLoggedIn) {
    _.mapKeys(guestCustomerObj, function (value, key) {
      return Cart.updateGuestCustomer(key, value);
    });
  }

  if (medCardNumber) {
    Cart.setMedicalCardNumber(medCardNumber);
  }

  if (medCardExpiration) {
    Cart.setMedicalCardExpiration(medCardExpiration);
  }

  if (medCardState) {
    Cart.setMedicalCardState(medCardState);
  }
};
export var useCustomerFromQueryParams = function useCustomerFromQueryParams(_ref2) {
  var Cart = _ref2.Cart,
      queryParams = _ref2.queryParams,
      isUserLoggedIn = _ref2.isUserLoggedIn;
  useEffect(function () {
    setCustomerFromQueryParams({
      Cart: Cart,
      queryParams: queryParams,
      isUserLoggedIn: isUserLoggedIn
    });
  }, [Cart, queryParams]);
};