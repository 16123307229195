import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import usePaths from 'src/hooks/use-paths';

import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { tracker } from 'src/utils/analytics';
import { ProductRecommendationSource } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';
import { CarouselProductCard } from 'src/components/product-card';
import { CARD_SIZES } from 'src/components/product-card/product-card.constants';
import CarouselSlider from 'components/carousel-slider';
import EmptyLoadingState from 'components/empty-loading-state';
import { CarouselHeader } from '../carousel-header';
import { applySettings, defaultFilters, DEFAULT_SKELETON_DELAY } from '../carousels.utils';
import { CardCarouselItem, Section } from '../carousels.styles';

const sortProducts = (fetched, products) =>
  _.sortBy(
    fetched,
    (a, b) =>
      _.findIndex(products, (product) => product === a?.id) - _.findIndex(products, (product) => product === b?.id)
  );

const generateSlides = ({ dispensary, sortedProducts, sectionIndex, UI, handleAddToCart, trackerSource }) => {
  const isEmbeddedCarousel = UI?.isEmbeddedCarousel ?? false;

  return _.map(applySettings(sortedProducts), (product, index) => (
    <CardCarouselItem key={product.id}>
      <CarouselProductCard
        dimensions={CARD_SIZES.default}
        dispensary={dispensary}
        product={product}
        width='100%'
        productIndex={index}
        sectionIndex={sectionIndex}
        onQuickAddClick={() => {
          UI.setProductPosition({
            productId: product.id,
            productIndex: index,
            sectionIndex,
          });

          tracker.setContext({
            activeProductPosition: index,
          });
          handleAddToCart(product, trackerSource);
        }}
        onClick={() => {
          UI.setProductPosition({
            productId: product.id,
            productIndex: index,
            sectionIndex,
          });

          UI.activeProductSource = trackerSource;

          tracker.setContext({
            activeProductPosition: index,
          });
        }}
        trackerSource={trackerSource}
        isEmbeddedCarousel={isEmbeddedCarousel}
      />
    </CardCarouselItem>
  ));
};

export const CustomCarousel = observer(({ section, sectionIndex, showTitle = true, showLink = true }) => {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const UI = useUI();

  const { label, products, hideTitle, id } = section;
  const { t } = useTranslation();
  const { dispensary } = useDispensary();
  const { href, route } = usePaths({
    menuSection: {
      id,
    },
  });

  const { handleAddToCart } = useQuickAddToCart();
  const router = useRouter();

  const { products: fetchedProducts, loading } = useDispensaryProducts({
    filters: { ...defaultFilters, productIds: products },
  });

  const hasProducts = fetchedProducts?.length > 0;
  if (!hasProducts && !loading) {
    return null;
  }

  const sortedProducts = sortProducts(fetchedProducts, products);

  if (isProductCardsV2Enabled) {
    return (
      <ProductCarousel
        products={sortedProducts}
        isLoading={loading}
        href={href}
        hideTitle={showTitle === false}
        hideLink={showLink === false}
        title={label}
      />
    );
  }

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: label,
  });

  const slides = generateSlides({
    dispensary,
    sortedProducts,
    sectionIndex,
    UI,
    handleAddToCart,
    trackerSource,
  });

  return (
    <EmptyLoadingState isLoading={loading} page='productCarousel' delay={DEFAULT_SKELETON_DELAY} isEmpty={!hasProducts}>
      <Section data-testid='product-carousel'>
        <CarouselHeader
          t={t}
          label={label}
          showTitle={hideTitle !== `true`}
          linkLabel={label}
          href={href}
          route={route}
          showLink={false}
        />
        <CarouselSlider>{slides}</CarouselSlider>
      </Section>
    </EmptyLoadingState>
  );
});
