import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Imgix from 'shared/components/imgix';
import useEmblaCarousel from 'embla-carousel-react';

import { mediaSizes } from 'src/theme';

import { ImgixDefaultSettings } from 'shared/constants';
import { getFirstActiveImage } from 'shared/helpers/products';
import { Clickable } from 'shared/components';

import ProductImage from 'components/product-image';

export type ProductImageFields = {
  url: string;
  active: boolean;
  label?: string;
  description?: string;
};

export type ProductImagesProps = {
  images: ProductImageFields[];
  width?: number;
};

const carouselSettings = {
  draggable: false,
  loop: false,
  startIndex: 0,
  slidesToScroll: 1,
};

const cleanImageSources = (images: ProductImageFields[]): ProductImageFields[] =>
  images.filter((img) => img.active).slice(0, 9);

function getProductThumbnailName(index: number, description?: string): string {
  const newIndex = index + 1;
  return description ? `${description}-${newIndex}` : `Product Thumbnail ${newIndex}`;
}

export default function ProductImages(props: ProductImagesProps): JSX.Element {
  const [emblaRef, embla] = useEmblaCarousel(carouselSettings);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { images: imagesFromProps, width } = props;
  const images = useMemo(() => cleanImageSources(imagesFromProps), [imagesFromProps]);
  const isSingleImage = images.filter((img) => img.active).length === 1;
  const firstActiveImage = getFirstActiveImage(images);

  useEffect(() => {
    if (!embla) {
      return;
    }

    if (images.length > 1) {
      embla.on(`select`, () => setSelectedIndex(embla.selectedScrollSnap()));
    }
  }, [embla, images.length]);

  const onClickThumbnail = (index): void => {
    setSelectedIndex(index);
    embla?.scrollTo(index);
  };

  return (
    <Container data-testid='product-images' $width={width}>
      {isSingleImage && (
        <MainImageScrollContainer data-testid='main-product-image-scroll-container'>
          <MainImage
            key={firstActiveImage.url}
            src={firstActiveImage.url}
            width={width}
            htmlAttributes={{
              alt: firstActiveImage.description,
            }}
            isZoomable
          />
        </MainImageScrollContainer>
      )}
      {!isSingleImage && (
        <>
          <Viewport ref={(el) => emblaRef(el)}>
            <MainImageScrollContainer data-testid='main-product-image-scroll-container'>
              {images.map(
                (image, index) =>
                  image.active && (
                    <MainImageScrollItem key={image.url} $width={width}>
                      <MainImage
                        htmlAttributes={{
                          alt: image.description,
                        }}
                        width={width}
                        src={image.url}
                        className={`product-image-${index} ${selectedIndex === index ? 'is-selected' : ''}`}
                        isZoomable
                      />
                    </MainImageScrollItem>
                  )
              )}
            </MainImageScrollContainer>
          </Viewport>
          <ThumbnailsContainer data-testid='thumbnails-container' gridLength={images.length < 4 ? images.length : 4}>
            {images.map(
              (image, index) =>
                image.active && (
                  <ThumbnailImageContainer
                    key={image.url}
                    selected={selectedIndex === index}
                    onClick={() => onClickThumbnail(index)}
                    data-testid='product-thumbnail'
                  >
                    <ThumbnailImage
                      type='img'
                      imgixParams={ImgixDefaultSettings}
                      htmlAttributes={{
                        alt: getProductThumbnailName(index, image.description),
                        loading: 'lazy',
                        decoding: 'async',
                      }}
                      sizes='auto'
                      src={image.url}
                      className={`product-thumbnail-${index}`}
                    />
                  </ThumbnailImageContainer>
                )
            )}
          </ThumbnailsContainer>
        </>
      )}
    </Container>
  );
}

const Container = styled.div<{ $width: number }>`
  overflow: hidden;
  max-width: ${({ $width }) => $width}px;
  @media (max-width: ${mediaSizes.phone}px) {
    max-width: 325px;
  }
`;

const Viewport = styled.div`
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
`;

const MainImageScrollContainer = styled.div`
  width: 100%;
  min-height: 245px;
  display: flex;
  will-change: transform;
  margin-bottom: 20px;
`;

const MainImage = styled(ProductImage)`
  object-fit: contain;
  min-width: ${({ $width }) => $width}px;
`;

const MainImageScrollItem = styled.div<{ $width: number }>`
  object-fit: contain;
  min-width: ${({ $width }) => $width}px;
  width: 100%;
  height: auto;
  transition: opacity 150ms;
  @media (max-width: ${mediaSizes.phone}px) {
    width: 325px;
  }
`;

const ThumbnailsContainer = styled.div<{ gridLength: number }>`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(${({ gridLength }) => gridLength}, 75px);
  grid-column-gap: 12px;
  grid-row-gap: 15px;
  @media (max-width: ${mediaSizes.phone}px) {
    grid-template-columns: repeat(${({ gridLength }) => gridLength}, 75px);
    grid-column-gap: 8px;
    grid-row-gap: 20px;
  }
`;

const ThumbnailImageContainer = styled(Clickable)<{ selected: boolean }>`
  width: 74px;
  height: 74px;
  @media (max-width: ${mediaSizes.phone}px) {
    width: 69px;
    height: 69px;
  }
  border-radius: 5px;
  border: ${({ selected }) => (selected ? `1.5px solid #a3afba` : `1.5px solid transparent`)};
  cursor: pointer;
  text-align: center;
  overflow: hidden;
`;

const ThumbnailImage = styled(Imgix)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
