import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'shared/styles';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { UseProductOptionsStateReturn } from 'src/hooks/use-product-options-state';

import { OptionTile } from 'src/components/option-tile';

type ProductOptionsV2Props = Pick<UseProductOptionsStateReturn, 'options' | 'selectedOption'> & {
  handleOptionClick: (opt: UseProductOptionsStateReturn['selectedOption']) => void;
};

export const ProductOptionsV2 = ({
  options,
  selectedOption,
  handleOptionClick,
}: ProductOptionsV2Props): JSX.Element => {
  const { dispensary } = useDispensary();
  const hideDiscount = useHideDiscountFromConsumer(dispensary);
  const hasMultipleOptions = options.length > 1;

  return (
    <Wrapper>
      <Options>
        {options.map((option) => (
          <OptionTile
            key={option.value}
            label={option.label}
            currentPrice={option.special ? option.special.formattedPrice : option.formattedPrice}
            originalPrice={option.special ? option.formattedPrice : null}
            discountLabel={option.special && !hideDiscount ? option.formattedDiscount : null}
            hasMultipleOptions={hasMultipleOptions}
            onClick={() => handleOptionClick(option)}
            isSelected={selectedOption.value === option.value}
            isFixedSize
          />
        ))}
      </Options>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 24px 0;

  @media ${mediaQueries.belowMedium} {
    overflow-x: auto;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Options = styled.div`
  display: flex;
  gap: 10px;

  @media ${mediaQueries.medium} {
    flex-wrap: wrap;
  }
`;
