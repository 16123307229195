import React from 'react';
import styled from 'styled-components';

import useTranslation, { ExtendedUseTranslationResponse } from 'hooks/use-translation';

type DeliveryProps = { deliveryEstimate: string };
type PickupProps = { pickupEstimate: string };
type PickupAndDeliveryProps = DeliveryProps & PickupProps;

function addProperGrammarToEstimate(t: ExtendedUseTranslationResponse['t'], estimate: string): string {
  if (estimate.includes('today') || estimate.includes('tomorrow')) {
    return t('common.estimate-day', '{{estimate}}', { estimate });
  }
  return t('closedBut.estimate-date', 'on {{estimate}}', { estimate });
}

function PickupAndDelivery({ pickupEstimate, deliveryEstimate }: PickupAndDeliveryProps): JSX.Element {
  const { Trans } = useTranslation();
  return (
    <Trans i18nKey='closedBut.pickup-and-delivery'>
      Orders can be <b>picked up</b> {pickupEstimate}, or <b>delivered</b> {deliveryEstimate}.
    </Trans>
  );
}
function OnlyDelivery({ deliveryEstimate }: DeliveryProps): JSX.Element {
  const { Trans } = useTranslation();
  return (
    <Trans i18nKey='closedBut.delivery-only'>
      Orders can be <b>delivered</b> {deliveryEstimate}.
    </Trans>
  );
}
function OnlyPickup({ pickupEstimate }: PickupProps): JSX.Element {
  const { Trans } = useTranslation();
  return (
    <Trans i18nKey='closedBut.pickup-only'>
      Orders can be <b>picked up</b> {pickupEstimate}.
    </Trans>
  );
}

type ClosedButInfoProps = {
  formattedPickupEstimate: string;
  formattedDeliveryEstimate: string;
  storeHoursDeliveryOnly: boolean;
};

export default function ClosedButInfo(props: ClosedButInfoProps): JSX.Element {
  const { formattedPickupEstimate, formattedDeliveryEstimate, storeHoursDeliveryOnly } = props;
  const { t } = useTranslation();
  const pickupEstimate = formattedPickupEstimate ? addProperGrammarToEstimate(t, formattedPickupEstimate) : null;
  const deliveryEstimate = formattedDeliveryEstimate ? addProperGrammarToEstimate(t, formattedDeliveryEstimate) : null;

  return (
    <>
      {(pickupEstimate || deliveryEstimate) && (
        <PrimaryText>
          {deliveryEstimate && pickupEstimate && (
            <PickupAndDelivery deliveryEstimate={deliveryEstimate} pickupEstimate={pickupEstimate} />
          )}
          {!deliveryEstimate && pickupEstimate && <OnlyPickup pickupEstimate={pickupEstimate} />}
          {deliveryEstimate && !pickupEstimate && <OnlyDelivery deliveryEstimate={deliveryEstimate} />}
        </PrimaryText>
      )}

      {storeHoursDeliveryOnly && (
        <SecondaryText>
          {t('closedBut.deliveryStoreHours', '*Delivery orders must be placed during store hours')}
        </SecondaryText>
      )}
    </>
  );
}

const Text = styled.div`
  font-size: 13px;
  line-height: 22px;
`;

const PrimaryText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const SecondaryText = styled(Text)`
  color: ${({ theme }) => theme.colors.grey[70]};
  margin-top: 10px;
`;
