import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
export default (function (props) {
  return __jsx("svg", _extends({
    width: "14",
    height: "13",
    viewBox: "0 0 14 13",
    fill: "#D34A4A",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    id: "Fill 1",
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.72995 11.0892C7.53957 11.2841 7.27268 11.3972 6.99987 11.3972C6.72673 11.3972 6.46001 11.2841 6.26761 11.0892C6.07538 10.8941 5.96575 10.6239 5.96575 10.3474C5.96575 10.0729 6.07538 9.80256 6.26761 9.60783C6.46001 9.41293 6.72673 9.30187 6.99987 9.30187C7.27268 9.30187 7.53957 9.41276 7.72995 9.60783C7.92403 9.80256 8.034 10.0729 8.034 10.3474C8.034 10.6239 7.9242 10.8941 7.72995 11.0892ZM6.99951 3.25635C7.84411 3.25635 8.04986 3.89958 7.99817 4.26799L7.49141 8.26031C7.47435 8.62137 7.19749 8.7586 6.99951 8.7586C6.77772 8.7586 6.52704 8.64002 6.50762 8.26031L6.00086 4.26799C5.94883 3.88897 6.15998 3.25635 6.99951 3.25635ZM13.7966 10.7302L8.02226 0.598908C7.81094 0.22827 7.42074 0 6.99827 0C6.5758 0 6.18543 0.22827 5.97394 0.598908L0.158362 10.8033C-0.0527875 11.1739 -0.0527875 11.6305 0.158362 12.0011C0.369681 12.3717 0.760055 12.6 1.18252 12.6H12.8142H12.8176C13.4704 12.6 14 12.0636 14 11.4022C13.9998 11.1532 13.925 10.9217 13.7966 10.7302Z"
  }));
});