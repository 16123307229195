import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "width", "mt", "mb", "loading", "alignSelf", "alignItems", "buttonContainerWidth", "inverted", "link", "tooltip", "flexShrink"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Flex } from 'rebass';
import { Button, Tooltip } from 'shared/components';
var ButtonContainer = styled(Flex).withConfig({
  displayName: "button__ButtonContainer",
  componentId: "sc-p83jxd-0"
})(["position:relative;flex-shrink:", ";"], function (props) {
  return props.flexShrink;
});
export default observer(function (
/** @type any */
_ref) {
  var children = _ref.children,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? [1, 1, 1, 'auto'] : _ref$width,
      _ref$mt = _ref.mt,
      mt = _ref$mt === void 0 ? [20] : _ref$mt,
      _ref$mb = _ref.mb,
      mb = _ref$mb === void 0 ? [0] : _ref$mb,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading,
      _ref$alignSelf = _ref.alignSelf,
      alignSelf = _ref$alignSelf === void 0 ? 'auto' : _ref$alignSelf,
      _ref$alignItems = _ref.alignItems,
      alignItems = _ref$alignItems === void 0 ? 'center' : _ref$alignItems,
      _ref$buttonContainerW = _ref.buttonContainerWidth,
      buttonContainerWidth = _ref$buttonContainerW === void 0 ? '100%' : _ref$buttonContainerW,
      _ref$inverted = _ref.inverted,
      inverted = _ref$inverted === void 0 ? false : _ref$inverted,
      _ref$link = _ref.link,
      link = _ref$link === void 0 ? false : _ref$link,
      tooltip = _ref.tooltip,
      _ref$flexShrink = _ref.flexShrink,
      flexShrink = _ref$flexShrink === void 0 ? '0' : _ref$flexShrink,
      props = _objectWithoutProperties(_ref, _excluded);

  return __jsx(ButtonContainer, {
    width: buttonContainerWidth,
    mt: mt,
    mb: mb,
    flexDirection: "column",
    alignItems: alignItems,
    justifyContent: "center",
    alignSelf: alignSelf,
    flexShrink: flexShrink,
    className: "modal-button-container"
  }, !tooltip && __jsx(Button, _extends({
    width: width,
    loading: loading,
    inverted: inverted,
    link: link
  }, props), children), tooltip && __jsx(Tooltip, {
    mainCopy: __jsx(Button, _extends({
      width: width,
      loading: loading,
      inverted: inverted,
      link: link
    }, props), children),
    tooltipCopy: tooltip,
    hoverMain: true,
    right: "-29px",
    bottom: "44px"
  }));
});