/* eslint-disable @typescript-eslint/naming-convention */
import { TFunction } from 'hooks/use-translation';
import React from 'react';

import { WhoopsReason } from './whoops.types';
import { DefaultIcon } from './default-icon';
import { NoProductsIcon } from './no-products-icon';

type TReasonMapping = {
  [key in WhoopsReason]: TReasonMappingItem;
};

type TReasonMappingItem = {
  IconComponent: React.ComponentType;
  topText: (t: TFunction) => string;
  bottomText?: (t: TFunction) => string;
  buttonText?: (t: TFunction) => string;
  onButtonClick?: () => void;
};

export const reasonMapping: TReasonMapping = {
  [WhoopsReason.pageNotFound]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) =>
      t('whoops.could-not-find-page', "We're sorry, we couldn't find the page you were looking for!"),
    bottomText: (t: TFunction) =>
      t('whoops.page-does-not-exist', "It looks like the page you requested doesn't exist."),
    buttonText: (t: TFunction) => t('go-back', 'Go Back'),
    onButtonClick: () => window.history.back(),
  },
  [WhoopsReason.noProducts]: {
    IconComponent: NoProductsIcon,
    topText: (t: TFunction) => t('whoops.no-products-found', 'Sorry, but there are no products currently in stock.'),
    buttonText: (t: TFunction) => t('go-back', 'Go Back'),
    onButtonClick: () => window.history.back(),
  },
  [WhoopsReason.serverError]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) => t('whoops.server-error', 'Server error, please try again later.'),
    buttonText: (t: TFunction) => t('whoops.server-error', 'It looks like we are experiencing some issues.'),
    onButtonClick: () => window.location.reload(),
  },
  [WhoopsReason.default]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) => t('whoops.something-went-wrong', "We're sorry, something went wrong."),
    buttonText: (t: TFunction) => t('try-again', 'Try Again'),
    onButtonClick: () => window.location.reload(),
  },
  [WhoopsReason.loadError]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) =>
      t('whoops.technical-difficulties', 'Dutchie is experiencing some technical difficulties.'),
  },
  [WhoopsReason.ssoError]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) => t('whoops.sso-error', 'There was an error logging you in. Please try again.'),
  },
  [WhoopsReason.existingAppleAccount]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) =>
      t(
        'whoops.existing-apple-account-error',
        'An account with this email is already registered using Apple. Please log in with Apple to continue or use a different email to sign in with Google.'
      ),
  },
  [WhoopsReason.existingGoogleAccount]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) =>
      t(
        'whoops.existing-google-account-error',
        'An account with this email is already registered using Google. Please log in with Google to continue or use a different email to sign in with Apple.'
      ),
  },
  [WhoopsReason.adminSsoError]: {
    IconComponent: DefaultIcon,
    topText: (t: TFunction) =>
      t(
        'whoops.admin-sso-error',
        'There is an existing Admin account associated with this email address, SSO is not available.'
      ),
  },
};
