import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import _ from 'lodash';
import { isExcludedProduct as isExcludedProductHelper } from 'shared/helpers/specials/excluded-products';

var isExcludedProduct = function isExcludedProduct(item, bogoSpecial, type) {
  var _bogoSpecial$;

  // v3.0
  var _ref = bogoSpecial || {},
      _ref$excludedProducts = _ref.excludedProducts,
      excludedProducts = _ref$excludedProducts === void 0 ? {} : _ref$excludedProducts;

  var excludedTypeProducts = excludedProducts["".concat(type, "s")] || [];
  var isLegacyExcluded = isExcludedProductHelper(item, excludedTypeProducts); // v3.5

  var pluralizedAndCapitalizedType = "".concat(_.capitalize(type), "s");
  var excludedTypeProductsNew = ((_bogoSpecial$ = bogoSpecial["bogo".concat(pluralizedAndCapitalizedType)]) === null || _bogoSpecial$ === void 0 ? void 0 : _bogoSpecial$.exclusions) || [];

  var exclusionResults = _.map(excludedTypeProductsNew, function (exclusion) {
    return isExcludedProductHelper(item, [exclusion], bogoSpecial === null || bogoSpecial === void 0 ? void 0 : bogoSpecial.useActiveBatchTags, bogoSpecial === null || bogoSpecial === void 0 ? void 0 : bogoSpecial.useActiveBatchTagOfWeightOption);
  });

  var isExcluded = _.some(exclusionResults);

  return isLegacyExcluded || isExcluded;
};

export var getAvailableQuantity = function getAvailableQuantity(_ref2) {
  var _Number, _bogoCalcsData$condit, _bogoCalcsData$condit2, _bogoCalcsData$condit3, _bogoCalcsData$discou5, _bogoCalcsData$discou6, _bogoCalcsData$discou7, _bogoCalcsData$discou8, _bogoCalcsData$reward4, _bogoCalcsData$reward5, _bogoCalcsData$reward6, _pendingConditionsSat, _pendingConditionsSat2, _pendingRewardsSatisf3, _pendingRewardsSatisf4;

  var bogoCalcsData = _ref2.bogoCalcsData,
      _ref2$discountToCartA = _ref2.discountToCartApplicable,
      discountToCartApplicable = _ref2$discountToCartA === void 0 ? false : _ref2$discountToCartA,
      item = _ref2.item,
      excludeDefeatedRewardsSatisfiers = _ref2.excludeDefeatedRewardsSatisfiers,
      pendingConditionsSatisfiers = _ref2.pendingConditionsSatisfiers,
      pendingRewardsSatisfiers = _ref2.pendingRewardsSatisfiers,
      _ref2$specialId = _ref2.specialId,
      specialId = _ref2$specialId === void 0 ? '' : _ref2$specialId,
      _ref2$totalSpendAppli = _ref2.totalSpendApplicable,
      totalSpendApplicable = _ref2$totalSpendAppli === void 0 ? false : _ref2$totalSpendAppli;
  var itemKey = item === null || item === void 0 ? void 0 : item.key;
  var availableQuantity = (_Number = Number(item === null || item === void 0 ? void 0 : item.quantity)) !== null && _Number !== void 0 ? _Number : 0;

  if (discountToCartApplicable) {
    var _bogoCalcsData$discou, _bogoCalcsData$discou2, _bogoCalcsData$discou3, _bogoCalcsData$discou4;

    // we want to subtract if the item has satisfied for that dtc special before, for multiple redemptions
    availableQuantity -= (_bogoCalcsData$discou = (_bogoCalcsData$discou2 = bogoCalcsData.discountToCartSatisfiers) === null || _bogoCalcsData$discou2 === void 0 ? void 0 : (_bogoCalcsData$discou3 = _bogoCalcsData$discou2[specialId]) === null || _bogoCalcsData$discou3 === void 0 ? void 0 : (_bogoCalcsData$discou4 = _bogoCalcsData$discou3[itemKey]) === null || _bogoCalcsData$discou4 === void 0 ? void 0 : _bogoCalcsData$discou4.quantity) !== null && _bogoCalcsData$discou !== void 0 ? _bogoCalcsData$discou : 0;
    return availableQuantity;
  }

  if (totalSpendApplicable) {
    var _bogoCalcsData$reward, _bogoCalcsData$reward2, _bogoCalcsData$reward3, _pendingRewardsSatisf, _pendingRewardsSatisf2, _bogoCalcsData$defeat, _bogoCalcsData$defeat2, _bogoCalcsData$defeat3;

    availableQuantity -= (_bogoCalcsData$reward = (_bogoCalcsData$reward2 = bogoCalcsData.rewardsSatisfiers) === null || _bogoCalcsData$reward2 === void 0 ? void 0 : (_bogoCalcsData$reward3 = _bogoCalcsData$reward2[itemKey]) === null || _bogoCalcsData$reward3 === void 0 ? void 0 : _bogoCalcsData$reward3.quantity) !== null && _bogoCalcsData$reward !== void 0 ? _bogoCalcsData$reward : 0;
    availableQuantity -= (_pendingRewardsSatisf = pendingRewardsSatisfiers === null || pendingRewardsSatisfiers === void 0 ? void 0 : (_pendingRewardsSatisf2 = pendingRewardsSatisfiers[itemKey]) === null || _pendingRewardsSatisf2 === void 0 ? void 0 : _pendingRewardsSatisf2.quantity) !== null && _pendingRewardsSatisf !== void 0 ? _pendingRewardsSatisf : 0;
    availableQuantity -= (_bogoCalcsData$defeat = bogoCalcsData === null || bogoCalcsData === void 0 ? void 0 : (_bogoCalcsData$defeat2 = bogoCalcsData.defeatedRewardsSatisfiers) === null || _bogoCalcsData$defeat2 === void 0 ? void 0 : (_bogoCalcsData$defeat3 = _bogoCalcsData$defeat2[itemKey]) === null || _bogoCalcsData$defeat3 === void 0 ? void 0 : _bogoCalcsData$defeat3.quantity) !== null && _bogoCalcsData$defeat !== void 0 ? _bogoCalcsData$defeat : 0;
    return Math.max(0, availableQuantity);
  }

  var conditionsToCheck = bogoCalcsData !== null && bogoCalcsData !== void 0 && bogoCalcsData.ignoreConditionsForDiscountBundle ? 0 : (_bogoCalcsData$condit = (_bogoCalcsData$condit2 = bogoCalcsData.conditionsSatisfiers) === null || _bogoCalcsData$condit2 === void 0 ? void 0 : (_bogoCalcsData$condit3 = _bogoCalcsData$condit2[itemKey]) === null || _bogoCalcsData$condit3 === void 0 ? void 0 : _bogoCalcsData$condit3.quantity) !== null && _bogoCalcsData$condit !== void 0 ? _bogoCalcsData$condit : 0;
  availableQuantity -= conditionsToCheck;
  availableQuantity -= (_bogoCalcsData$discou5 = (_bogoCalcsData$discou6 = bogoCalcsData.discountToCartSatisfiers) === null || _bogoCalcsData$discou6 === void 0 ? void 0 : (_bogoCalcsData$discou7 = _bogoCalcsData$discou6[specialId]) === null || _bogoCalcsData$discou7 === void 0 ? void 0 : (_bogoCalcsData$discou8 = _bogoCalcsData$discou7[itemKey]) === null || _bogoCalcsData$discou8 === void 0 ? void 0 : _bogoCalcsData$discou8.quantity) !== null && _bogoCalcsData$discou5 !== void 0 ? _bogoCalcsData$discou5 : 0;
  availableQuantity -= (_bogoCalcsData$reward4 = (_bogoCalcsData$reward5 = bogoCalcsData.rewardsSatisfiers) === null || _bogoCalcsData$reward5 === void 0 ? void 0 : (_bogoCalcsData$reward6 = _bogoCalcsData$reward5[itemKey]) === null || _bogoCalcsData$reward6 === void 0 ? void 0 : _bogoCalcsData$reward6.quantity) !== null && _bogoCalcsData$reward4 !== void 0 ? _bogoCalcsData$reward4 : 0;
  availableQuantity -= (_pendingConditionsSat = pendingConditionsSatisfiers === null || pendingConditionsSatisfiers === void 0 ? void 0 : (_pendingConditionsSat2 = pendingConditionsSatisfiers[itemKey]) === null || _pendingConditionsSat2 === void 0 ? void 0 : _pendingConditionsSat2.quantity) !== null && _pendingConditionsSat !== void 0 ? _pendingConditionsSat : 0;
  availableQuantity -= (_pendingRewardsSatisf3 = pendingRewardsSatisfiers === null || pendingRewardsSatisfiers === void 0 ? void 0 : (_pendingRewardsSatisf4 = pendingRewardsSatisfiers[itemKey]) === null || _pendingRewardsSatisf4 === void 0 ? void 0 : _pendingRewardsSatisf4.quantity) !== null && _pendingRewardsSatisf3 !== void 0 ? _pendingRewardsSatisf3 : 0;

  if (excludeDefeatedRewardsSatisfiers) {
    var _bogoCalcsData$defeat4, _bogoCalcsData$defeat5, _bogoCalcsData$defeat6;

    availableQuantity -= (_bogoCalcsData$defeat4 = bogoCalcsData === null || bogoCalcsData === void 0 ? void 0 : (_bogoCalcsData$defeat5 = bogoCalcsData.defeatedRewardsSatisfiers) === null || _bogoCalcsData$defeat5 === void 0 ? void 0 : (_bogoCalcsData$defeat6 = _bogoCalcsData$defeat5[itemKey]) === null || _bogoCalcsData$defeat6 === void 0 ? void 0 : _bogoCalcsData$defeat6.quantity) !== null && _bogoCalcsData$defeat4 !== void 0 ? _bogoCalcsData$defeat4 : 0;
  }

  return Math.max(0, availableQuantity);
}; // TODO: after the 3.5 migration is complete, we should remove duplicate functions such as this
//  (checkProductForEligibleOptions makes this redundant)

export var isApplicableItem = function isApplicableItem(conditionOrReward, item, bogoSpecial, type) {
  // ensure that this isn't an excluded product
  if (isExcludedProduct(item, bogoSpecial, type, bogoSpecial)) {
    return false;
  } // if our reward is target price, make sure our item costs enough for the discount to actually apply


  if (!_.isNil(conditionOrReward === null || conditionOrReward === void 0 ? void 0 : conditionOrReward.targetPrice)) {
    var targetPriceValue = Big(parseFloat(conditionOrReward.targetPrice));

    if (targetPriceValue.gt(item.basePrice)) {
      return false;
    }
  }

  var categoryMatch = matchesCategory(conditionOrReward, item, conditionOrReward.productGroup);
  var brandMatch = matchesBrand(conditionOrReward, item, conditionOrReward.productGroup);

  if (conditionOrReward.productGroup === 'brands' || conditionOrReward.productGroup === 'brand') {
    var matchesSelections = false;

    if (conditionOrReward !== null && conditionOrReward !== void 0 && conditionOrReward.selectedCategoriesAndSubcategories) {
      matchesSelections = _.includes(conditionOrReward.selectedCategoriesAndSubcategories, item.productCategory) || _.includes(conditionOrReward.selectedCategoriesAndSubcategories, item.productSubcategory);
    }

    return brandMatch && (categoryMatch || matchesSelections);
  }

  if (conditionOrReward.productGroup === 'categories' || conditionOrReward.productGroup === 'category') {
    return brandMatch && categoryMatch;
  }

  var matchesProductIds = matchesProductId(conditionOrReward, item, true);
  var matchOnEnterpriseId = conditionOrReward.enterpriseProductId ? conditionOrReward.enterpriseProductId === "EPID_".concat(item.enterpriseProductId) || conditionOrReward.enterpriseProductId === item.enterpriseProductId : false;
  var matchOnProductId = conditionOrReward.productId ? conditionOrReward.productId === item.id : false;
  return matchOnEnterpriseId || matchOnProductId || matchesProductIds;
};
export var mergeSatisfiers = function mergeSatisfiers() {
  var formerSatisfiers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var additionalSatisfiers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var mergingRewardTypes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var merged = _objectSpread({}, formerSatisfiers);

  _.forEach(additionalSatisfiers, function (value, key) {
    if (!merged[key]) {
      merged[key] = _objectSpread({}, value);
    } else {
      var _value$reward;

      merged[key] = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, merged[key]), value.conditions ? {
        conditions: mergeSatisfiers(merged[key].conditions, value.conditions)
      } : {}), value.rewards ? {
        rewards: mergeSatisfiers(merged[key].rewards, value.rewards)
      } : {}), value.satisfiedBy ? {
        satisfiedBy: mergeSatisfiers(merged[key].satisfiedBy, value.satisfiedBy)
      } : {}), !mergingRewardTypes ? {
        quantity: merged[key].quantity + value.quantity
      } : {}), (_value$reward = value.reward) !== null && _value$reward !== void 0 && _value$reward.redemptionMultiplier ? {
        reward: _objectSpread({}, value.reward)
      } : {});
    }
  });

  return merged;
};
export var checkCombination = function checkCombination(combination, conditions, logicOperator) {
  var winningCombination = null;

  if (conditions.length < 1 || logicOperator === 'or') {
    winningCombination = combination;
    return {
      breakEarly: true,
      winningCombination: winningCombination
    };
  } // If the product logicOperator was set to AND


  var passesConditions = true; // eslint-disable-next-line consistent-return

  _.forEach(conditions, function (condition) {
    var conditionSatisfied = _.reduce(condition.eligibleItems, function (isSatisfied, item) {
      return !(!isSatisfied && !combination.items[item.key]);
    }, false);

    if (!conditionSatisfied) {
      passesConditions = false;
      return false; // break early
    }
  });

  if (passesConditions) {
    winningCombination = combination;
    return {
      breakEarly: true,
      winningCombination: winningCombination
    };
  }

  return {
    breakEarly: false,
    winningCombination: winningCombination
  };
};
export function determineWinningCombo(combinations, itemToConsiderLast, conditions, logicOperator) {
  var considerCombinationsLast = [];
  var breakEarly;
  var winningCombination; // eslint-disable-next-line consistent-return

  _.forEach(combinations, function (combination) {
    if (itemToConsiderLast && combination.items[itemToConsiderLast.key]) {
      considerCombinationsLast.push(combination);
    } else {
      var _checkCombination = checkCombination(combination, conditions, logicOperator);

      breakEarly = _checkCombination.breakEarly;
      winningCombination = _checkCombination.winningCombination;
      return !breakEarly; // break early if a winningCombination was found
    }
  });

  if (!winningCombination) {
    _.forEach(considerCombinationsLast, function (combination) {
      var _checkCombination2 = checkCombination(combination, conditions, logicOperator);

      breakEarly = _checkCombination2.breakEarly;
      winningCombination = _checkCombination2.winningCombination;
      return !breakEarly; // break early if a winningCombination was found
    });
  }

  return winningCombination;
}
export function setSatisfiersToWinningCombo(winningCombination) {
  return _.reduce(winningCombination.items, function (result, item) {
    result[item.key] = {
      item: item.item,
      quantity: item.quantity
    };
    return result;
  }, {});
}

var isAnyCategory = function isAnyCategory(type) {
  return _.toLower(type) === 'any category';
};

var isAnyBrand = function isAnyBrand(type) {
  return _.toLower(type) === 'any brand';
};

function matchesPOSMetaDataBrand(brandCondition, product) {
  var _product$POSMetaData;

  var posMetaDataBrandId = product === null || product === void 0 ? void 0 : (_product$POSMetaData = product.POSMetaData) === null || _product$POSMetaData === void 0 ? void 0 : _product$POSMetaData.canonicalBrandId;
  return posMetaDataBrandId && _.includes(brandCondition, posMetaDataBrandId);
}

function matchesPOSMetaDataCategory(categoryCondition, product) {
  var _product$POSMetaData2;

  var posMetaDataCategoryId = product === null || product === void 0 ? void 0 : (_product$POSMetaData2 = product.POSMetaData) === null || _product$POSMetaData2 === void 0 ? void 0 : _product$POSMetaData2.canonicalCategoryId;
  return posMetaDataCategoryId && _.includes(categoryCondition, posMetaDataCategoryId);
}

function matchesPOSMetaDataStrain(strainCondition, product) {
  var _product$POSMetaData3;

  var posMetaDataStrainId = product === null || product === void 0 ? void 0 : (_product$POSMetaData3 = product.POSMetaData) === null || _product$POSMetaData3 === void 0 ? void 0 : _product$POSMetaData3.canonicalStrainId;
  return posMetaDataStrainId && _.includes(strainCondition, posMetaDataStrainId);
}

function matchesPOSMetaDataVendor(vendorCondition, product) {
  var _product$POSMetaData4;

  var posMetaDataVendorId = product === null || product === void 0 ? void 0 : (_product$POSMetaData4 = product.POSMetaData) === null || _product$POSMetaData4 === void 0 ? void 0 : _product$POSMetaData4.canonicalVendorId;
  return posMetaDataVendorId && _.includes(vendorCondition, posMetaDataVendorId);
}

export function matchesBrand() {
  var _rule$brandIds, _rule$brandNames, _rule$brandIds2, _rule$brandNames2, _product$brand;

  var rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var productGroup = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'individual';
  var failIfNoCriteria = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var isBrandRule = _.includes(['brand', 'brands'], productGroup);

  var hasLegacyBrandCriteria = (rule === null || rule === void 0 ? void 0 : rule.brandName) || (rule === null || rule === void 0 ? void 0 : rule.brandId);
  var noCriteria = !(rule !== null && rule !== void 0 && (_rule$brandIds = rule.brandIds) !== null && _rule$brandIds !== void 0 && _rule$brandIds.length || rule !== null && rule !== void 0 && (_rule$brandNames = rule.brandNames) !== null && _rule$brandNames !== void 0 && _rule$brandNames.length || hasLegacyBrandCriteria);

  if (noCriteria) {
    return !failIfNoCriteria;
  }

  var allowAnyBrands = !isBrandRule && rule.brandName && isAnyBrand(rule.brandName);

  if (allowAnyBrands) {
    return true;
  }

  var brandCondition = [].concat(_toConsumableArray((_rule$brandIds2 = rule === null || rule === void 0 ? void 0 : rule.brandIds) !== null && _rule$brandIds2 !== void 0 ? _rule$brandIds2 : []), _toConsumableArray((_rule$brandNames2 = rule === null || rule === void 0 ? void 0 : rule.brandNames) !== null && _rule$brandNames2 !== void 0 ? _rule$brandNames2 : [])); // check against new brand arrays

  var matchesBrands = (product === null || product === void 0 ? void 0 : product.brandId) && _.includes(brandCondition, product.brandId) || (product === null || product === void 0 ? void 0 : product.brandName) && _.includes(brandCondition, product.brandName) || // 'product' coming from eligibleItems has a dumb name
  (product === null || product === void 0 ? void 0 : product.productBrandId) && _.includes(brandCondition, product.productBrandId) || (product === null || product === void 0 ? void 0 : product.productBrand) && _.includes(brandCondition, product.productBrand) || matchesPOSMetaDataBrand(brandCondition, product); // check against legacy brand fields

  var matchesLegacyBrand = (rule === null || rule === void 0 ? void 0 : rule.brandName) && (product === null || product === void 0 ? void 0 : (_product$brand = product.brand) === null || _product$brand === void 0 ? void 0 : _product$brand.name) && rule.brandName === product.brand.name || (rule === null || rule === void 0 ? void 0 : rule.brandName) && (product === null || product === void 0 ? void 0 : product.brandName) && rule.brandName === product.brandName || (rule === null || rule === void 0 ? void 0 : rule.brandId) && (product === null || product === void 0 ? void 0 : product.brandId) && rule.brandId === product.brandId || // 'product' coming from eligibleItems has a dumb name
  (rule === null || rule === void 0 ? void 0 : rule.brandName) && (product === null || product === void 0 ? void 0 : product.productBrand) && rule.brandName === product.productBrand || (rule === null || rule === void 0 ? void 0 : rule.brandId) && (product === null || product === void 0 ? void 0 : product.productBrandId) && rule.brandId === product.productBrandId;
  return !!(matchesBrands || matchesLegacyBrand);
}
export function matchesCategory() {
  var _rule$categoryIds, _rule$categoryNames, _rule$subcategoryIds, _rule$subcategoryName, _rule$categoryIds2, _rule$categoryNames2, _rule$subcategoryIds2, _rule$subcategoryName2;

  var rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var productGroup = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'individual';
  var failIfNoCriteria = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var isCategoryRule = _.includes(['category', 'categories'], productGroup);

  var hasLegacyCategoryCriteria = rule === null || rule === void 0 ? void 0 : rule.categoryName;
  var noCriteria = !(rule !== null && rule !== void 0 && (_rule$categoryIds = rule.categoryIds) !== null && _rule$categoryIds !== void 0 && _rule$categoryIds.length || rule !== null && rule !== void 0 && (_rule$categoryNames = rule.categoryNames) !== null && _rule$categoryNames !== void 0 && _rule$categoryNames.length || rule !== null && rule !== void 0 && (_rule$subcategoryIds = rule.subcategoryIds) !== null && _rule$subcategoryIds !== void 0 && _rule$subcategoryIds.length || rule !== null && rule !== void 0 && (_rule$subcategoryName = rule.subcategoryNames) !== null && _rule$subcategoryName !== void 0 && _rule$subcategoryName.length || hasLegacyCategoryCriteria);

  if (noCriteria) {
    return !failIfNoCriteria;
  }

  var allowAnyCategories = !isCategoryRule && (isAnyCategory(rule.categoryName) || noCriteria);

  if (allowAnyCategories) {
    return true;
  }

  var categoryCondition = [].concat(_toConsumableArray((_rule$categoryIds2 = rule === null || rule === void 0 ? void 0 : rule.categoryIds) !== null && _rule$categoryIds2 !== void 0 ? _rule$categoryIds2 : []), _toConsumableArray((_rule$categoryNames2 = rule === null || rule === void 0 ? void 0 : rule.categoryNames) !== null && _rule$categoryNames2 !== void 0 ? _rule$categoryNames2 : []));
  var subcategoryCondition = [].concat(_toConsumableArray((_rule$subcategoryIds2 = rule === null || rule === void 0 ? void 0 : rule.subcategoryIds) !== null && _rule$subcategoryIds2 !== void 0 ? _rule$subcategoryIds2 : []), _toConsumableArray((_rule$subcategoryName2 = rule === null || rule === void 0 ? void 0 : rule.subcategoryNames) !== null && _rule$subcategoryName2 !== void 0 ? _rule$subcategoryName2 : []));

  var combinedConditions = _.flatMap([].concat(_toConsumableArray(categoryCondition), [subcategoryCondition]));

  var isUncategorizedProduct = _.includes(rule === null || rule === void 0 ? void 0 : rule.categoryName, 'Uncategorized') || _.find(rule === null || rule === void 0 ? void 0 : rule.categoryNames, function (cat) {
    return _.includes(cat, 'Uncategorized');
  }) || _.find(rule === null || rule === void 0 ? void 0 : rule.categoryIds, function (cat) {
    return _.includes(cat, 'Uncategorized');
  });

  if (isUncategorizedProduct) {
    var result = !!(product !== null && product !== void 0 && product.type && _.find(combinedConditions, function (s) {
      return _.includes(s, product.type);
    }) && (!(product !== null && product !== void 0 && product.subcategory) || product.subcategory === '') || product !== null && product !== void 0 && product.productCategory && _.find(combinedConditions, function (s) {
      return _.includes(s, product.productCategory);
    }) && (!(product !== null && product !== void 0 && product.productSubcategory) || product.productSubcategory === '')); // legacy match

    var legacyResult = (product === null || product === void 0 ? void 0 : product.type) && _.includes(rule === null || rule === void 0 ? void 0 : rule.categoryName, product.type) && (!(product !== null && product !== void 0 && product.subcategory) || product.subcategory === '') || (product === null || product === void 0 ? void 0 : product.productCategory) && _.includes(rule === null || rule === void 0 ? void 0 : rule.categoryName, product.productCategory) && (!(product !== null && product !== void 0 && product.productSubcategory) || product.productSubcategory === '');
    return result || legacyResult;
  }

  var matchesCategories = (product === null || product === void 0 ? void 0 : product.type) && _.includes(categoryCondition, product.type) || // 'product' coming from eligibleItems has a dumb name
  (product === null || product === void 0 ? void 0 : product.productCategory) && _.includes(categoryCondition, product.productCategory) || matchesPOSMetaDataCategory(categoryCondition, product);

  var matchesSubcategories = (product === null || product === void 0 ? void 0 : product.subcategory) && matchesSubcategory(subcategoryCondition, product) || !!_.find(rule.subcategoryIds, function (s) {
    return _.includes(s, product.type);
  }) && !product.subcategory || !!_.find(rule.subcategoryNames, function (s) {
    return _.includes(s, product.type);
  }) && !product.subcategory || // 'product' coming from eligibleItems has a dumb name
  (product === null || product === void 0 ? void 0 : product.productSubcategory) && _.includes(subcategoryCondition, product.productSubcategory);

  var matchesLegacyCategories = (rule === null || rule === void 0 ? void 0 : rule.categoryName) && (product === null || product === void 0 ? void 0 : product.type) && rule.categoryName === product.type || (rule === null || rule === void 0 ? void 0 : rule.categoryName) && (product === null || product === void 0 ? void 0 : product.productCategory) && rule.categoryName === product.productCategory;
  var matchesLegacySubcategories = (rule === null || rule === void 0 ? void 0 : rule.categoryName) && (product === null || product === void 0 ? void 0 : product.subcategory) && matchesSubcategory([rule.categoryName], product) || // 'product' coming from eligibleItems has a dumb name
  (rule === null || rule === void 0 ? void 0 : rule.categoryName) && (product === null || product === void 0 ? void 0 : product.productSubcategory) && rule.categoryName === product.productSubcategory;
  return !!(matchesCategories || matchesSubcategories || matchesLegacyCategories || matchesLegacySubcategories);
}
export function matchesInventoryTag() {
  var _rule$inventoryTags;

  var rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var failIfNoCriteria = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var useActiveBatchTags = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var useActiveBatchTagOfWeightOption = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

  if (!useActiveBatchTags) {
    return !failIfNoCriteria;
  }

  if (!(rule !== null && rule !== void 0 && (_rule$inventoryTags = rule.inventoryTags) !== null && _rule$inventoryTags !== void 0 && _rule$inventoryTags.length)) {
    return !failIfNoCriteria;
  }

  var activeBatchTags;

  if (useActiveBatchTagOfWeightOption) {
    var _product$POSMetaData5, _product$POSMetaData6;

    activeBatchTags = _.flatten(product === null || product === void 0 ? void 0 : (_product$POSMetaData5 = product.POSMetaData) === null || _product$POSMetaData5 === void 0 ? void 0 : (_product$POSMetaData6 = _product$POSMetaData5.children) === null || _product$POSMetaData6 === void 0 ? void 0 : _product$POSMetaData6.map(function (child) {
      var _child$activeBatchTag;

      return child === null || child === void 0 ? void 0 : (_child$activeBatchTag = child.activeBatchTags) === null || _child$activeBatchTag === void 0 ? void 0 : _child$activeBatchTag.map(function (tag) {
        return tag.tagId;
      });
    }));
  } else {
    var _product$POSMetaData7;

    activeBatchTags = _.map((product === null || product === void 0 ? void 0 : (_product$POSMetaData7 = product.POSMetaData) === null || _product$POSMetaData7 === void 0 ? void 0 : _product$POSMetaData7.activeBatchTags) || [], 'tagId');
  }

  return !_.isEmpty(_.intersection(rule.inventoryTags || [], activeBatchTags));
}
export function matchesProductTag() {
  var _rule$productTags, _product$POSMetaData8;

  var rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var failIfNoCriteria = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var useActiveBatchTags = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (!useActiveBatchTags) {
    return !failIfNoCriteria;
  }

  if (!(rule !== null && rule !== void 0 && (_rule$productTags = rule.productTags) !== null && _rule$productTags !== void 0 && _rule$productTags.length)) {
    return !failIfNoCriteria;
  }

  var productTags = _.map((product === null || product === void 0 ? void 0 : (_product$POSMetaData8 = product.POSMetaData) === null || _product$POSMetaData8 === void 0 ? void 0 : _product$POSMetaData8.canonicalProductTags) || [], 'tagId');

  return !_.isEmpty(_.intersection(rule.productTags || [], productTags));
}
export function matchesSubcategory(subcategories, product) {
  return !!_.filter(subcategories, function (subcategory) {
    var isParentChildSubcategoryKey = subcategory.match(/^.*__.*$/i);

    if (isParentChildSubcategoryKey) {
      var subcategoryKey = "".concat(product === null || product === void 0 ? void 0 : product.type, "__").concat(product === null || product === void 0 ? void 0 : product.subcategory);
      return _.isEqual(subcategory, subcategoryKey);
    }

    return _.isEqual(subcategory, product === null || product === void 0 ? void 0 : product.subcategory);
  }).length;
}
export function matchesStrain() {
  var _rule$strainIds, _rule$strainIds2;

  var rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var failIfNoCriteria = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (!(rule !== null && rule !== void 0 && (_rule$strainIds = rule.strainIds) !== null && _rule$strainIds !== void 0 && _rule$strainIds.length)) {
    return !failIfNoCriteria;
  }

  var eligibleStrainIds = _toConsumableArray((_rule$strainIds2 = rule === null || rule === void 0 ? void 0 : rule.strainIds) !== null && _rule$strainIds2 !== void 0 ? _rule$strainIds2 : []);

  var strainMatches = matchesPOSMetaDataStrain(eligibleStrainIds, product);
  return !!strainMatches;
}
export function matchesVendor() {
  var _rule$vendorIds, _rule$vendorIds2;

  var rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var failIfNoCriteria = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (!(rule !== null && rule !== void 0 && (_rule$vendorIds = rule.vendorIds) !== null && _rule$vendorIds !== void 0 && _rule$vendorIds.length)) {
    return !failIfNoCriteria;
  }

  var eligibleVendorIds = _toConsumableArray((_rule$vendorIds2 = rule === null || rule === void 0 ? void 0 : rule.vendorIds) !== null && _rule$vendorIds2 !== void 0 ? _rule$vendorIds2 : []);

  var vendorMatches = matchesPOSMetaDataVendor(eligibleVendorIds, product);
  return !!vendorMatches;
}

function matchesPOSMetaDataProduct(productCriteria, product) {
  var _product$POSMetaData9, _product$POSMetaData10;

  var posMetaDataCanonicalIds = [];

  if (product !== null && product !== void 0 && (_product$POSMetaData9 = product.POSMetaData) !== null && _product$POSMetaData9 !== void 0 && _product$POSMetaData9.canonicalID) {
    posMetaDataCanonicalIds.push(product.POSMetaData.canonicalID);
  }

  _.forEach(product === null || product === void 0 ? void 0 : (_product$POSMetaData10 = product.POSMetaData) === null || _product$POSMetaData10 === void 0 ? void 0 : _product$POSMetaData10.children, function (child) {
    if (child !== null && child !== void 0 && child.canonicalID) {
      posMetaDataCanonicalIds.push(child.canonicalID);
    }
  });

  return _.intersection(productCriteria, _.uniq(posMetaDataCanonicalIds)).length > 0;
}

export function matchesProductId() {
  var _rule$productIds, _rule$enterpriseProdu;

  var rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var product = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var failIfNoCriteria = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var productCriteria = [].concat(_toConsumableArray((_rule$productIds = rule.productIds) !== null && _rule$productIds !== void 0 ? _rule$productIds : []), _toConsumableArray((_rule$enterpriseProdu = rule.enterpriseProductIds) !== null && _rule$enterpriseProdu !== void 0 ? _rule$enterpriseProdu : []));

  if (_.isEmpty(productCriteria)) {
    return !failIfNoCriteria;
  }

  return _.includes(productCriteria, product._id || product.id) || _.includes(productCriteria, "EPID_".concat(product.enterpriseProductId)) || matchesPOSMetaDataProduct(productCriteria, product);
}