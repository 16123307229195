import React, { RefObject } from 'react';
import { SpaceProps } from 'styled-system';

import EmptyLoadingState from 'src/components/empty-loading-state';
import { Dimensions } from 'src/components/product-card/product-card.constants';
import { CarouselStates, useHybridCarouselRendered } from './use-hybrid-carousel-rendered';

import { CarouselContainer, Content } from './hybrid-carousel.styles';

import { HybridHeader } from './hybrid-carousel-header';
import { HybridSlider } from './hybrid-carousel-slider';

export type CarouselTheme = {
  primaryColor: string;
  primaryColorHover?: string;
  backgroundColor: string;
  textColor?: string;
};

export type HybridCarouselProps = SpaceProps & {
  border?: boolean;
  carouselPadding?: number;
  carouselTheme: CarouselTheme;
  children: React.ReactNode[];
  customLoadingSkeleton?: string;
  dimensions: Dimensions;
  fullWidth?: boolean;
  heading?: string;
  href?: string;
  icon?: React.ReactNode;
  linkLabel?: string;
  loading: boolean;
  margin?: number;
  subheading?: string;
  viewportVisibility: {
    ref: RefObject<HTMLDivElement>;
    hasBeenVisible: boolean;
    isVisible: boolean | null;
  };
  onClickHeaderLink?: () => void;
  onRender?: (state: CarouselStates) => void;
};

export function HybridCarousel({
  border = false,
  carouselPadding = 20,
  carouselTheme,
  children,
  customLoadingSkeleton,
  dimensions,
  fullWidth = false,
  heading,
  href,
  icon,
  linkLabel,
  loading = true,
  margin = 0,
  onClickHeaderLink,
  onRender,
  subheading,
  viewportVisibility: { ref, hasBeenVisible },
}: HybridCarouselProps): JSX.Element | null {
  useHybridCarouselRendered({ loading, hasBeenVisible, onRender });
  const { backgroundColor } = carouselTheme;

  return (
    <CarouselContainer
      ref={ref}
      data-testid='hybrid-carousel'
      backgroundColor={backgroundColor}
      border={border}
      fullWidth={fullWidth}
      margin={margin}
    >
      <EmptyLoadingState
        carouselPadding={carouselPadding}
        carouselTheme={carouselTheme}
        dimensions={dimensions}
        isEmpty={false}
        isLoading={loading}
        page={customLoadingSkeleton ?? 'hybridCarousel'}
      >
        <Content>
          <HybridHeader
            carouselTheme={carouselTheme}
            heading={heading}
            href={href}
            icon={icon}
            linkLabel={linkLabel}
            onClickHeaderLink={onClickHeaderLink}
            subheading={subheading}
          />
          <HybridSlider carouselTheme={carouselTheme} carouselPadding={carouselPadding} fullWidth={fullWidth}>
            {children}
          </HybridSlider>
        </Content>
      </EmptyLoadingState>
    </CarouselContainer>
  );
}
