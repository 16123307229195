import React from 'react';

import { GqlSpecials } from 'types/graphql';
import OffersCarouselCard from 'shared/components/specials/offers-carousel-card';
import useUI from 'src/hooks/use-ui';
import { CarouselV2 } from '../carousel-v2';

type OfferCarouselProps = {
  offers: GqlSpecials[];
  href?: string;
  title: string;
};

export const OfferCarousel = ({ offers, href, title }: OfferCarouselProps): JSX.Element => {
  const { isEmbeddedCarousel } = useUI();

  return (
    <CarouselV2 href={href} title={title} mt={isEmbeddedCarousel ? '0' : undefined}>
      {offers.map((offer, index) => (
        <OffersCarouselCard
          key={offer._id}
          aria-label={offer.menuDisplayName}
          aria-roledescription='slide'
          className={`offers ${index !== 0 && index + 1 === offers.length ? `last-card` : ``}`}
          isSingularCard={offers.length === 1}
          displayName={offer.menuDisplayName ?? offer.name}
          displayImage={offer.menuDisplayImage}
          role='group'
          specialId={offer._id}
          width='100%'
          isUsingGapSpacing
        />
      ))}
    </CarouselV2>
  );
};
