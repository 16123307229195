import { action, observable, makeObservable } from 'mobx';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';

const STORAGE_KEY = 'instoreEnrollmentStatus';

export enum InstoreEnrollmentStatus {
  ecommLogin = 'ECOMM_LOGIN',
  payByBankEnrollment = 'PAY_BY_BANK_ENROLLMENT',
  connectBankAccount = 'CONNECT_BANK_ACCOUNT',
  loyaltyEnrollment = 'LOYALTY_ENROLLMENT',
  finishedEnrollment = 'FINISHED_ENROLLMENT',
}

export class InstoreEnrollmentStore {
  @observable currentStatus: InstoreEnrollmentStatus = InstoreEnrollmentStatus.ecommLogin;

  constructor() {
    makeObservable(this);
    // Load persisted status on initialization
    const savedStatus = getPersistedValue(STORAGE_KEY);
    if (savedStatus && Object.values(InstoreEnrollmentStatus).includes(savedStatus)) {
      this.currentStatus = savedStatus;
    }
  }

  @action
  setCurrentStatus(status: InstoreEnrollmentStatus): void {
    this.currentStatus = status;
    setPersistedValue(STORAGE_KEY, status, true);
  }

  @action
  reset(): void {
    this.currentStatus = InstoreEnrollmentStatus.ecommLogin;
    setPersistedValue(STORAGE_KEY, InstoreEnrollmentStatus.ecommLogin, true);
  }
}
