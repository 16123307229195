import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["color"];
var __jsx = React.createElement;
import React from 'react';
export default function RadioCheckedIcon(props) {
  var _props$color = props.color,
      color = _props$color === void 0 ? '#4597e0' : _props$color,
      otherProps = _objectWithoutProperties(props, _excluded);

  return __jsx("svg", _extends({
    height: "18",
    viewBox: "0 0 18 18",
    width: "18",
    xmlns: "http://www.w3.org/2000/svg"
  }, otherProps), __jsx("circle", {
    cx: "9",
    cy: "9",
    fill: "#fcfdfe",
    r: "8.5",
    stroke: color
  }), __jsx("circle", {
    cx: "9",
    cy: "9",
    fill: color,
    r: "4.5"
  }));
}