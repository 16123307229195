import React from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

import { Link } from 'components/core';
import { BREAD_CRUMB_ROUTES, Crumb } from './bread-crumbs.types';
import { useBreadCrumbs } from './use-bread-crumbs';

export const LINKS_CONTAINER_TEST_ID = 'links-container';

type BreadCrumbsProps = {
  includeLeftPadding?: boolean;
  lastCrumbLabel?: string;
};

export function BreadCrumbs({ includeLeftPadding = true, lastCrumbLabel }: BreadCrumbsProps): JSX.Element | null {
  const { crumbs } = useBreadCrumbs({ lastCrumbLabel });
  const { route } = useRouter();

  return (
    <LinksContainer data-testid={LINKS_CONTAINER_TEST_ID} includeLeftPadding={includeLeftPadding}>
      {crumbs.map((crumb: Crumb, index: number) => {
        const urlProps = crumb.urlProps ?? {};
        const isLastCrumb = index === crumbs.length - 1;
        const isProductDetailPage =
          route === BREAD_CRUMB_ROUTES.DEFAULT_PRODUCT_DETAIL_PAGE ||
          route === BREAD_CRUMB_ROUTES.EMBEDDED_PRODUCT_DETAIL_PAGE ||
          route === BREAD_CRUMB_ROUTES.KIOSKS_PRODUCT_DETAIL_PAGE ||
          route === BREAD_CRUMB_ROUTES.STORES_FRONT_PRODUCT_DETAIL_PAGE;

        return (
          <LinkContainer key={crumb.label}>
            {!isProductDetailPage && isLastCrumb ? (
              <SelectedBreadCrumb>{crumb.label}</SelectedBreadCrumb>
            ) : (
              <>
                <Link href={crumb.href} route={crumb.route} {...urlProps}>
                  <Anchor href={crumb.href}>{crumb.label}</Anchor>
                </Link>
                {!(isProductDetailPage && isLastCrumb) && <ArrowContainer>/</ArrowContainer>}
              </>
            )}
          </LinkContainer>
        );
      })}
    </LinksContainer>
  );
}

const LinksContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  padding-left: ${({ includeLeftPadding }) => (includeLeftPadding ? `25px` : 0)};

  > :last-child {
    padding-right: 25px;
  }
`;

const LinkContainer = styled.div`
  margin-right: 8.92px;
  height: 20px;
  align-items: center;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
`;

const crumbStyles = css`
  color: ${({ theme }) => theme.colors.grey[25]};
  text-transform: capitalize;
`;

const Anchor = styled.a`
  ${crumbStyles}
`;

const SelectedBreadCrumb = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-transform: capitalize;
`;

const ArrowContainer = styled.div`
  display: flex;
  margin-left: 8.92px;
  color: ${({ theme }) => theme.colors.grey[70]};
`;
