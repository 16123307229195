/* eslint-disable @typescript-eslint/unbound-method */
import { action, computed, observable, toJS, makeObservable } from 'mobx';
import { sanitizeUrl } from '@braintree/sanitize-url';

import {
  ACCOUNT_SETTINGS_PATH,
  INSTORE_CART_PATH,
  ConnectedBankAccountDetails,
  EnrollmentSteps,
} from 'src/payments/constants';
import { ConnectionStatus, StatusTypes } from 'types/graphql';
import { Nullish } from 'shared/utils/type-utils';
import { InterfaceVariants } from 'src/constants/interface-variants';
import FeatureFlagsStore from 'src/state/feature-flags';

export type AddressProps = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
};

export type Aggregator = 'plaid';

export type EnrollmentBankAccount = {
  accountAlias: string;
  aggregator: Aggregator;
  bankName: string;
  billingAddressId: string;
  connectionStatus: ConnectionStatus;
  id: string;
  lastDigits: string;
  status: string;
};

const initializedDispensary = {
  brandedDutchiePayEnabled: false,
  brandedDutchiePayLogoDark: '',
  brandedDutchiePayLogoLight: '',
  brandedDutchiePayName: '',
  logo: '',
  name: '',
  useWhiteLabel: false,
};

export default class DutchiePayEnrollment {
  @observable featureFlags: FeatureFlagsStore;
  @observable activateBankAccount = false;
  @observable address: AddressProps | Nullish | Record<string, never> = {};
  @observable aggregatorLinkLoading = false;
  @observable aggregatorLinkCompleted = false;
  @observable bankAccounts: EnrollmentBankAccount[] = [];
  @observable connectedBankAccountDetails: ConnectedBankAccountDetails | null = null;
  @observable currentStep: EnrollmentSteps | '' = '';
  @observable enrollmentStatus: StatusTypes = StatusTypes.statusUnenrolled;
  @observable isExistingUser = false;
  @observable isMobileEcommSignup = false;
  @observable mxBillingAddressId = '';
  @observable refUrl = '';
  @observable userAcceptance = false;
  @observable userEmail = '';
  @observable autoCheckoutAfterBankLinkReconnect = false;
  @observable entryPoint = '';
  @observable dispensary = initializedDispensary;

  constructor(featureFlagsStore: FeatureFlagsStore) {
    makeObservable(this);
    this.featureFlags = featureFlagsStore;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  setCurrentStep(step: EnrollmentSteps): void {
    this.currentStep = step;
  }

  @action
  setDispensary(dispensary, uiVariant?): void {
    const { embedded, plus } = InterfaceVariants;
    if (!dispensary) {
      this.dispensary = initializedDispensary;
    } else {
      const hasWhiteLabelAssets =
        !!dispensary?.brandedDutchiePayName &&
        !!dispensary?.brandedDutchiePayLogoLight &&
        !!dispensary?.brandedDutchiePayLogoDark;

      const isWhiteLabeled = Boolean(
        [embedded, plus].includes(uiVariant) && dispensary.brandedDutchiePayEnabled && hasWhiteLabelAssets
      );

      this.dispensary = {
        brandedDutchiePayEnabled: dispensary.brandedDutchiePayEnabled,
        brandedDutchiePayLogoDark: isWhiteLabeled ? dispensary?.brandedDutchiePayLogoDark : '',
        brandedDutchiePayLogoLight: isWhiteLabeled ? dispensary?.brandedDutchiePayLogoLight : '',
        brandedDutchiePayName: isWhiteLabeled ? dispensary?.brandedDutchiePayName : '',
        logo: dispensary?.SpecialLogoImage,
        name: dispensary?.name,
        useWhiteLabel: isWhiteLabeled,
      };
    }
  }

  @action
  resetEnrollment(): void {
    this.activateBankAccount = false;
    this.address = {};
    this.bankAccounts = [];
    this.currentStep = '';
    this.enrollmentStatus = StatusTypes.statusUnenrolled;
    this.isExistingUser = false;
    this.userAcceptance = false;
    this.userEmail = '';
    this.autoCheckoutAfterBankLinkReconnect = false;
    this.entryPoint = '';
    this.setDispensary(null);
    this.setRefUrl(this.refUrl);
  }

  @action
  cancelEnrollment(): void {
    this.resetEnrollment();
  }

  @action.bound
  enrollmentStatusReceived({ enrollmentStatus, bankAccounts, relinkOrEditBankAccountEnabled }): void {
    this.enrollmentStatus = enrollmentStatus;
    this.bankAccounts = bankAccounts;
    if (enrollmentStatus === StatusTypes.statusEnrolled && !relinkOrEditBankAccountEnabled) {
      this.currentStep = !this.refUrl ? EnrollmentSteps.alreadySignedUp : EnrollmentSteps.completeEnrollment;
    }
  }
  @action.bound
  userAlreadySignedUp(): void {
    this.currentStep = EnrollmentSteps.alreadySignedUp;
  }

  @action.bound
  plaidOauthReceived(): void {
    this.currentStep = EnrollmentSteps.connectBank;
  }

  @computed
  get activeBankAccount(): EnrollmentBankAccount {
    return toJS(this.bankAccounts.filter((acct) => acct.status === 'active')[0] || {});
  }

  @computed
  get aggregator(): Aggregator {
    const currentAggregator = this.activeBankAccount.aggregator;
    return toJS(currentAggregator);
  }

  @computed
  get isCobrandedSignup(): boolean {
    const isAccountSettings = this.refUrl.includes(ACCOUNT_SETTINGS_PATH);
    return Boolean(this.dispensary.logo || this.dispensary.useWhiteLabel) && !isAccountSettings;
  }

  @computed
  get isInstoreSignup(): boolean {
    return this.refUrl.includes(INSTORE_CART_PATH);
  }

  @computed
  get needsRelinkBankAccount(): boolean {
    const relinkStatusList: ConnectionStatus[] = Object.values(ConnectionStatus).filter(
      (status) => status !== ConnectionStatus.connectionStatusActive
    );

    const needsRelink =
      Boolean(this.activeBankAccount.connectionStatus) &&
      relinkStatusList.includes(this.activeBankAccount.connectionStatus);

    return toJS(needsRelink);
  }

  @action
  setRefUrl(refUrl: string): void {
    this.refUrl = refUrl === '' ? refUrl : sanitizeUrl(refUrl);
  }

  @action
  beginEnrollment(userExists, refUrl = ''): void {
    const savedEmailAddress = this.userEmail || '';
    this.resetEnrollment();
    this.userEmail = savedEmailAddress;
    this.setRefUrl(refUrl);
    if (savedEmailAddress) {
      this.emailStepCompleted(savedEmailAddress);
    }
    this.setIsMobileEcommSignup(refUrl.includes('mobile-ecomm'));

    if (this.currentStep === EnrollmentSteps.connectBank) {
      return;
    }

    if (userExists) {
      this.currentStep = EnrollmentSteps.connectBank;
      return;
    }

    if (this.currentStep !== EnrollmentSteps.signUpForm) {
      this.resetEnrollment();
      this.currentStep = EnrollmentSteps.emailForm;
    }
  }

  @action setExistingUser(status: boolean): void {
    this.isExistingUser = status;
  }

  @action
  emailStepCompleted(email: string): void {
    this.userEmail = email;
    this.currentStep = this.isExistingUser ? EnrollmentSteps.passwordForm : EnrollmentSteps.signUpForm;
  }

  @action
  passwordStepCompleted(): void {
    if (this.enrollmentStatus !== StatusTypes.statusEnrolled) {
      this.currentStep = EnrollmentSteps.connectBank;
    }
  }

  @action
  signUpStepCompleted(): void {
    this.currentStep = EnrollmentSteps.connectBank;
  }

  @action
  reconnectBankAccount(): void {
    this.currentStep = EnrollmentSteps.connectBank;
  }

  @action
  setIsMobileEcommSignup(value: boolean): void {
    this.isMobileEcommSignup = value;
  }

  @action
  setAddress(address: AddressProps): void {
    this.address = address;
  }

  @action
  setAutoCheckoutAfterBankLinkReconnect(status: boolean): void {
    this.autoCheckoutAfterBankLinkReconnect = status;
  }

  @computed
  get isDutchiePayUser(): boolean {
    return (
      this.enrollmentStatus === StatusTypes.statusEnrolled &&
      Boolean(this.bankAccounts.length > 0 && toJS(this.bankAccounts)[0].id)
    );
  }

  @action
  createBankAccountRequested(): void {
    this.currentStep = EnrollmentSteps.tosLoading;
  }

  @action
  createBankAccountCompleted(): void {
    this.currentStep = EnrollmentSteps.terms;
  }

  @action.bound
  setConnectedBankAccountDetails(connectedBankAccount: ConnectedBankAccountDetails): void {
    this.connectedBankAccountDetails = connectedBankAccount;
  }

  @action.bound
  completeAggregatorLinkStarted(): void {
    this.setCurrentStep(EnrollmentSteps.bankAccountsLoading);
  }

  @action.bound
  completeAggregatorLinkSucceeded(connectedBankAccount: ConnectedBankAccountDetails): void {
    this.setConnectedBankAccountDetails(connectedBankAccount);
    this.setCurrentStep(EnrollmentSteps.terms);
  }

  @action.bound
  plaidLinkExited(): void {
    this.setCurrentStep(EnrollmentSteps.completeEnrollment);
  }

  @action
  termsOfServiceError(): void {
    this.currentStep = EnrollmentSteps.terms;
  }

  @action
  editBankAccount(): void {
    this.currentStep = EnrollmentSteps.connectBank;
  }

  @action
  userAcceptanceRequested(): void {
    this.currentStep = EnrollmentSteps.tosLoading;
  }

  @action
  activateBankAccountRequested(): void {
    this.currentStep = EnrollmentSteps.tosLoading;
  }

  @action
  userAcceptanceSucceeded(): void {
    this.userAcceptance = true;
    this.currentStep = EnrollmentSteps.completeEnrollment;
  }

  activateBankAccountSucceeded(): void {
    this.activateBankAccount = true;
    this.currentStep = EnrollmentSteps.completeEnrollment;
  }

  @action resetPasswordClicked(): void {
    this.currentStep = EnrollmentSteps.forgotPasswordForm;
  }

  @action resetPasswordSucceeded(): void {
    this.currentStep = EnrollmentSteps.passwordForm;
  }
}
