var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
export default function CloseButton(_ref) {
  var onClose = _ref.onClose;
  return __jsx(Button, {
    "aria-label": "Close",
    onClick: onClose
  }, __jsx("img", {
    src: "/icons/x-icon-product.svg",
    alt: "Close Icon",
    height: 21,
    width: 21
  }));
}
var Button = styled.div.withConfig({
  displayName: "floating-close-button__Button",
  componentId: "sc-imwfb7-0"
})(["width:50px;position:absolute;right:-24px;top:-24px;cursor:pointer;display:flex;justify-content:flex-end;img{height:21px;}"]);