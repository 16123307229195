import React from 'react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useModals, ModalNames } from 'src/components/modals';
import { GoogleLogin, AppleLogin } from 'src/components/social-login';
import BlueSparkle from 'src/assets/blue-sparkle-icon';

import { tracker } from 'src/utils/analytics';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import {
  Content,
  Subheading,
  SignUpButton,
  Wrapper,
  Heading,
  AlreadyHaveAccount,
  LoginButton,
  ButtonWrapper,
  SignUpContainer,
  ButtonsContainer,
  HeadingsContainer,
  TextContent,
  SocialLoginWrapper,
  IconContainer,
} from './logged-out-v2.styles';

type TrackUserEventProps = {
  signIn: boolean;
  type: 'email' | 'sso';
};

export function LoggedOutV2(): JSX.Element {
  const flags = useFlags();
  const router = useRouter();
  const { openModal } = useModals();

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.guestRecommendations,
  });

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const isAppleLoginEnabled = flags[`growth.ecomm.sign-in-apple.rollout`] ?? false;

  const trackUserEvent = ({ signIn, type }: TrackUserEventProps): void => {
    tracker.gaGTMClickEvent({
      eventCategory: 'account',
      eventAction: signIn ? 'signed in' : 'created account',
      eventLabel: `${type}: {pageType}`,
    });
  };

  const handleOpenSignUpModal = (): void => {
    void openModal(ModalNames.signUp, trackerSource);
    trackUserEvent({ signIn: false, type: 'email' });
  };

  const handleOpenLoginModal = (): void => {
    void openModal(ModalNames.login, trackerSource);
    trackUserEvent({ signIn: true, type: 'email' });
  };

  const handleSocialLoginSubmit = (): void => {
    trackUserEvent({ signIn: true, type: 'sso' });
  };

  return (
    <Wrapper
      backgroundColor='#f5f6f7'
      fullWidth
      border
      placement={personalizationCarouselPlacement}
      data-testid='personalized-products-carousel-logged-out-v2-cta'
    >
      <Content>
        <HeadingsContainer>
          <IconContainer>
            <BlueSparkle />
          </IconContainer>
          <TextContent>
            <Heading size='medium' weight='bold' tag='h2'>
              {isAppleLoginEnabled ? 'Log in for the best experience' : 'Sign up for the best experience'}
            </Heading>
            <Subheading size='medium' weight='regular' tag='p'>
              {isAppleLoginEnabled
                ? 'Enjoy personalized recommendations, faster checkout, and quick reordering of your favorites.'
                : 'Get personalized recommendations, checkout faster and reorder your favorites more easily!'}
            </Subheading>
          </TextContent>
        </HeadingsContainer>
        <SignUpContainer>
          <ButtonsContainer>
            <ButtonWrapper>
              <SocialLoginWrapper data-testid='google-login-wrapper'>
                <GoogleLogin redirectUrl={window.location.href} onSubmit={handleSocialLoginSubmit} />
              </SocialLoginWrapper>

              {isAppleLoginEnabled ? (
                <SocialLoginWrapper>
                  <AppleLogin redirectUrl={window.location.href} onSubmit={handleSocialLoginSubmit} />
                </SocialLoginWrapper>
              ) : (
                <SignUpButton size='large' width='100%' onClick={handleOpenSignUpModal}>
                  Continue with email
                </SignUpButton>
              )}
            </ButtonWrapper>
          </ButtonsContainer>

          {isAppleLoginEnabled ? (
            <AlreadyHaveAccount size='medium'>
              <LoginButton onClick={handleOpenLoginModal}>Log in or sign up with email</LoginButton>
            </AlreadyHaveAccount>
          ) : (
            <AlreadyHaveAccount size='medium'>
              Already have an account? <LoginButton onClick={handleOpenLoginModal}>Log in</LoginButton>
            </AlreadyHaveAccount>
          )}
        </SignUpContainer>
      </Content>
    </Wrapper>
  );
}
