import React from 'react';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBrand } from 'src/utils/get-brand';
import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import usePaths from 'src/hooks/use-paths';
import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';
import { defaultFilters } from '../carousels.utils';
import { GenericCarousel } from './generic-carousel';

export function BrandCarousel(props) {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const { brands } = useMenuFilterOptions();

  const { section } = props;
  const { label, brandId } = section;

  const { products, loading } = useDispensaryProducts({
    filters: { ...defaultFilters, brandIds: [brandId] },
  });

  const { href, route } = usePaths({ brands: true });
  const { brand } = getBrand({ brands, brandId });
  const brandSlug = _.kebabCase(brand?.name);

  const hasBrand = products?.length > 0;
  if (!hasBrand && !loading) {
    return null;
  }

  const brandHref = `${href}/${brandSlug}`;

  if (isProductCardsV2Enabled) {
    return (
      <ProductCarousel
        products={products}
        isLoading={loading}
        href={brandHref}
        hideTitle={props?.showTitle === false}
        hideLink={props?.showLink === false}
        title={label}
      />
    );
  }

  const sectionProps = {
    ...props,
    isLoading: loading,
    linkLabel: section.linkLabel,
    label,
    products,
    href: brandHref,
    route: `${route}/[brandId]`,
  };

  return <GenericCarousel {...sectionProps} />;
}
