import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import { checkAllLimits } from 'shared/helpers/purchase-limits';
import { productIncludedInType } from 'shared/helpers/check-limits/utils';
export var maxQuantity = function maxQuantity(key, cart, isMedical, order) {
  var dispensary = order.dispensary,
      customerState = order.customerState;
  var _cart$key = cart[key],
      quantity = _cart$key.quantity,
      product = _cart$key.product;
  var currentMaxQuantity = quantity;
  var withinLimits = false;

  while (!withinLimits) {
    var limitsCheck = checkAllLimits({
      newItemId: key,
      itemBeingAdded: _objectSpread(_objectSpread({}, _.clone(cart[key])), {}, {
        quantity: currentMaxQuantity
      }),
      existingItems: cart,
      medical: isMedical,
      dispensary: dispensary,
      changingQuantity: true,
      customerState: customerState,
      complianceOnly: true
    }); // Make sure we're not inadvertently removing products whose types
    // are still within the limits when switching from med -> rec

    var typesOverLimit = limitsCheck.typesOverLimit;
    var productExceedsLimits = void 0;

    if ((typesOverLimit === null || typesOverLimit === void 0 ? void 0 : typesOverLimit.length) > 0) {
      // eslint-disable-next-line max-len
      productExceedsLimits = _.some(typesOverLimit, function (exceededType) {
        return productIncludedInType(product, exceededType, order);
      });
    }

    if (limitsCheck.withinLimits || !productExceedsLimits || currentMaxQuantity === 0) {
      withinLimits = true;
    } else {
      currentMaxQuantity -= 1;
    }
  }

  return currentMaxQuantity;
};