import React, { ReactNode, FunctionComponent } from 'react';
import { template } from 'lodash';

type TransProps = {
  children: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  i18nKey?: string;
};

const Trans: FunctionComponent<TransProps> = React.Fragment;

type TranslationConfig = Record<string, unknown>;

export const MEMOIZED_TEMPLATES = new Map<string, (data: Record<string, unknown>) => string>();

const getRenderedTemplate = (templateString: string): ((data: Record<string, unknown>) => string) => {
  const storedTemplate = MEMOIZED_TEMPLATES.get(templateString);
  if (storedTemplate) {
    return storedTemplate;
  }
  const newTemplate = template(templateString, {
    interpolate: /{{([\s\S]+?)}}/g,
  });
  MEMOIZED_TEMPLATES.set(templateString, newTemplate);
  return newTemplate;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function t(key: string, defaultStringOrConfig: TranslationConfig | string, config?: TranslationConfig): string {
  if (typeof defaultStringOrConfig === 'string' && !config) {
    return defaultStringOrConfig;
  }
  if (typeof defaultStringOrConfig === 'string' && config) {
    return getRenderedTemplate(defaultStringOrConfig)(config);
  }
  if (typeof defaultStringOrConfig === 'object') {
    if (typeof defaultStringOrConfig.defaultValue === 'string') {
      return getRenderedTemplate(defaultStringOrConfig.defaultValue)(defaultStringOrConfig);
    }
  }
  return '';
}

export type TFunction = typeof t;
export type TFunctionResult = ReturnType<TFunction>;

type TranslationResponse = {
  t: TFunction;
  Trans: typeof Trans;
};

export type ExtendedUseTranslationResponse = TranslationResponse & {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Trans: typeof Trans;
};

export default function useTranslationWrapper(): TranslationResponse {
  return {
    t,
    Trans,
  };
}
