import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';
import { useRecommendedProducts } from './use-recommended-products';
import { GenericCarousel } from '../generic-carousel';

type MostPurchasedSectionProps = {
  dispensaryId: string;
};

export function MostPurchasedCarousel({ dispensaryId }: MostPurchasedSectionProps): JSX.Element | null {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const { products, loading, error } = useRecommendedProducts({ dispensaryId });
  const label = `Buy It Again`;

  if (isProductCardsV2Enabled) {
    return <ProductCarousel products={products} isLoading={loading} title={label} />;
  }

  if (!loading && (error || !products.length)) {
    return null;
  }

  const sectionProps = {
    section: {
      hideTitle: false,
    },
    isLoading: loading,
    label,
    products,
  };

  return <GenericCarousel {...sectionProps} />;
}
