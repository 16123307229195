import _ from 'lodash';
import { isBlank } from 'underscore.string';
export var getCityToDisplay = function getCityToDisplay(location) {
  return (location === null || location === void 0 ? void 0 : location.rootType) === 'locality' && location !== null && location !== void 0 && location.locality ? location === null || location === void 0 ? void 0 : location.locality : location === null || location === void 0 ? void 0 : location.city;
};
export var hasStreetNumber = function hasStreetNumber(address) {
  var re = /^\d+$/;
  return address ? re.test(_.split(address, ' ')[0]) : false;
};
export var convertMetersToMiles = function convertMetersToMiles() {
  var meters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0.0;
  return meters * 0.000621371192;
};
export var buildLocationObjectFromGoogle = function buildLocationObjectFromGoogle(data) {
  var findByType = function findByType(components, type) {
    return _.find(components, function (comp) {
      return _.includes(comp.types || [], type);
    });
  };

  return {
    streetNumber: _.get(findByType(data.address_components, 'street_number'), 'short_name'),
    streetName: _.get(findByType(data.address_components, 'route'), 'short_name'),
    ln1: [_.get(findByType(data.address_components, 'street_number'), 'short_name'), _.get(findByType(data.address_components, 'route'), 'short_name')].join(' '),
    ln2: _.get(findByType(data.address_components, 'subpremise'), 'short_name', ''),
    city: _.get(findByType(data.address_components, 'locality'), 'short_name') || _.get(findByType(data.address_components, 'neighborhood'), 'short_name') || _.get(findByType(data.address_components, 'administrative_area_level_3'), 'short_name') || _.get(findByType(data.address_components, 'sublocality_level_1'), 'short_name'),
    county: _.get(findByType(data.address_components, 'administrative_area_level_2'), 'short_name'),
    state: _.get(findByType(data.address_components, 'administrative_area_level_1'), 'short_name'),
    zipcode: _.get(findByType(data.address_components, 'postal_code'), 'short_name'),
    country: _.get(findByType(data.address_components, 'country'), 'long_name')
  };
};
export var formatAddress = function formatAddress(location) {
  var ln1 = location.ln1,
      ln2 = location.ln2,
      city = location.city,
      locality = location.locality,
      state = location.state,
      zipcode = location.zipcode,
      country = location.country;
  var preferredAddressAttributesExist = !_.isEmpty(_.reject([ln1, ln2, city, locality, state, zipcode], isBlank));

  if (!preferredAddressAttributesExist && !isBlank(country)) {
    return country;
  }

  var cityPretty = getCityToDisplay(location);
  return _.reject([_.reject([ln1, ln2], isBlank).join(', '), cityPretty, [state, zipcode].join(' ')], isBlank).join(', ');
};
export var formatAddressForCheckout = function formatAddressForCheckout(_ref) {
  var ln1 = _ref.ln1,
      ln2 = _ref.ln2;
  return _.reject([ln1, ln2], isBlank).join(', ');
};
export var formatAddressShort = function formatAddressShort(location) {
  var ln1 = location.ln1,
      ln2 = location.ln2,
      state = location.state;
  var cityPretty = getCityToDisplay(location);
  var displayFields = !ln1 ? [cityPretty, state] : [ln1, ln2];
  return _.reject(displayFields, isBlank).join(', ');
};
export var formatAddressWithoutApt = function formatAddressWithoutApt(_ref2) {
  var ln1 = _ref2.ln1,
      city = _ref2.city,
      state = _ref2.state,
      zipcode = _ref2.zipcode,
      country = _ref2.country;
  var preferredAddressAttributesExist = !_.isEmpty(_.reject([ln1, city, state, zipcode], isBlank));

  if (!preferredAddressAttributesExist && !isBlank(country)) {
    return country;
  }

  return _.reject([_.reject([ln1], isBlank).join(', '), city, [state, zipcode].join(' ')], isBlank).join(', ');
};