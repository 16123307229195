import { init, registerPlugins } from '@module-federation/runtime';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as styled from 'styled-components';
import * as mui from '@material-ui/core';

import reactPkg from 'react/package.json';
import reactDomPkg from 'react-dom/package.json';
import styledPkg from 'styled-components/package.json';
import muiPkg from '@material-ui/core/package.json';

import PluginOfflineRemotes from './plugin-offline-remotes';

import PublicEnv from 'shared/utils/public-env';

export const initializeModuleFederation = () => {
  const remotes = [];

  if (PublicEnv.consumerThemeMFRemoteUrl) {
    remotes.push({
      name: 'customer_themes',
      alias: '@dutchie/customer-themes',
      entry: PublicEnv.consumerThemeMFRemoteUrl,
    });
  }

  if (remotes.length < 1) {
    return;
  }

  // This is not an async function, it's just a one-time initialization
  // That sets the RUNTIME remotes for the host app
  // The shared packages are loaded at build time in next.config.js
  init({
    name: '@dutchie/host',
    remotes,
    shareStrategy: 'loaded-first',
    shared: {
      react: {
        version: reactPkg.version,
        lib: () => React,
        shareConfig: {
          singleton: true,
          requiredVersion: `^${reactPkg.version.split('.')[0]}`,
        },
      },
      'react-dom': {
        version: reactDomPkg.version,
        lib: () => ReactDOM,
        shareConfig: {
          singleton: true,
          requiredVersion: `^${reactDomPkg.version.split('.')[0]}`,
        },
      },
      'styled-components': {
        version: styledPkg.version,
        lib: () => styled,
        shareConfig: {
          singleton: true,
          requiredVersion: `^${styledPkg.version.split('.')[0]}`,
        },
      },
      '@material-ui/core': {
        version: muiPkg.version,
        lib: () => mui,
        shareConfig: {
          singleton: true,
          requiredVersion: `^${muiPkg.version.split('.')[0]}`,
        },
      },
    },
  });

  registerPlugins([PluginOfflineRemotes()]);
  try {
    window.__MF_INITIALIZED = true;
  } catch (e) {
    console.error('Error setting __MF_INITIALIZED', e);
  }
};
