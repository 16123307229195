import { useRouter } from 'next/router';
import { useGetCitiesWithDispensariesQuery } from 'types/graphql';

import { useIsMobile } from 'src/hooks/use-is-mobile';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useProduct from 'hooks/use-product';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';

import { Crumb } from './bread-crumbs.types';
import {
  getCrumbs,
  removeUnsupportedCities,
  getFormattedCityName,
  getCityWithDispensary,
  getState,
  getLocationUrl,
} from './bread-crumbs.helpers';

type UseGetLocationHrefParams = {
  dispensaryCity: string;
  dispensaryState: string;
};

type UseBreadCrumbsProps = {
  lastCrumbLabel?: string;
};

type UseBreadCrumbsReturn = {
  crumbs: Crumb[];
};

function useGetLocationHref({ dispensaryCity, dispensaryState }: UseGetLocationHrefParams): string | null {
  const { data, loading } = useGetCitiesWithDispensariesQuery();

  if (!data || loading) {
    return null;
  }
  const cleanedCities = removeUnsupportedCities(data);
  const formattedCityName = getFormattedCityName(dispensaryCity);
  const didFindCity = !!getCityWithDispensary(cleanedCities, dispensaryState, formattedCityName);
  const currentState = getState(cleanedCities, dispensaryState);

  const locationHref =
    didFindCity && currentState
      ? getLocationUrl(currentState.country ?? 'US', currentState.name ?? dispensaryState, formattedCityName)
      : null;

  return locationHref;
}

export function useBreadCrumbs({ lastCrumbLabel }: UseBreadCrumbsProps): UseBreadCrumbsReturn {
  const { route, query } = useRouter();
  const isMobile = useIsMobile();
  const { dispensary } = useDispensary();
  const { product } = useProduct();
  const { categories } = useDispensaryCategories();

  const dispensaryCity = dispensary?.location?.city ?? '';
  const dispensaryState = dispensary?.location?.state ?? '';
  const dispensaryName = dispensary?.name ?? '';
  const dispensaryCName = dispensary?.cName ?? '';

  const locationHref = useGetLocationHref({ dispensaryCity, dispensaryState });

  const dispensaryInfo = {
    cName: dispensaryCName,
    name: dispensaryName,
    city: dispensaryCity,
    locationHref,
  };

  const crumbs = getCrumbs({ route, query, dispensaryInfo, isMobile, product, categories });

  if (lastCrumbLabel) {
    crumbs.push({
      label: lastCrumbLabel,
    });
  }

  return {
    crumbs,
  };
}
