import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash'; // eslint-disable-next-line import/no-extraneous-dependencies

import Big from 'big.js';
import { calculateCouponDiscounts } from '../coupons';
import { calculateRewardDiscounts } from '../rewards';
import { redistributePennies } from '../utilities';
import { getAdjustedBasePrice } from './helpers'; // mutates couponData, itemTotals, receiptDetail, and totals

export var applyCouponToDetail = function applyCouponToDetail(_ref) {
  var couponData = _ref.couponData,
      detailItem = _ref.detailItem,
      discountTaxOrder = _ref.discountTaxOrder,
      itemTotals = _ref.itemTotals,
      receiptDetail = _ref.receiptDetail,
      totals = _ref.totals;
  var compositePrice = itemTotals.compositePrice;
  var credit = totals.creditTotal,
      productTotal = totals.productTotal;
  var couponLeftOver = couponData.couponLeftOver;
  var coupon = couponData.coupon,
      couponApplicableSubtotal = couponData.couponApplicableSubtotal,
      couponApplicableSubtotalPlusTaxes = couponData.couponApplicableSubtotalPlusTaxes;

  var _calculateCouponDisco = calculateCouponDiscounts({
    applyStowedDiscounts: false,
    compositePrice: compositePrice,
    coupon: coupon,
    couponApplicableSubtotal: couponApplicableSubtotal,
    couponApplicableSubtotalPlusTaxes: couponApplicableSubtotalPlusTaxes,
    couponLeftOver: couponLeftOver,
    credit: credit,
    detail: detailItem,
    discountTaxOrder: discountTaxOrder,
    originalCompositePrice: itemTotals.originalCompositePrice,
    productTotal: productTotal,
    receiptDetail: receiptDetail,
    // We always stack coupons with compounding calculations in Dutchie POS
    globalSpecialsSettings: {
      discountStacking: true,
      stackingBehavior: 'compounding'
    },
    stowDiscounts: false,
    stowedDiscounts: []
  });

  compositePrice = _calculateCouponDisco.compositePrice;
  couponLeftOver = _calculateCouponDisco.couponLeftOver;
  credit = _calculateCouponDisco.credit;
  productTotal = _calculateCouponDisco.productTotal;
  itemTotals.compositePrice = compositePrice;
  totals.creditTotal = credit;
  totals.productTotal = productTotal;
  couponData.couponLeftOver = couponLeftOver;
}; // mutates itemTotals, receiptDetail, rewardsData, and totals

export var applyRewardsToDetail = function applyRewardsToDetail(_ref2) {
  var detailItem = _ref2.detailItem,
      discountTaxOrder = _ref2.discountTaxOrder,
      itemTotals = _ref2.itemTotals,
      receiptDetail = _ref2.receiptDetail,
      rewardsData = _ref2.rewardsData,
      totals = _ref2.totals;
  var compositePrice = itemTotals.compositePrice;
  var credit = totals.creditTotal,
      productTotal = totals.productTotal;
  var rewardAppliedToCredit = rewardsData.rewardAppliedToCredit,
      rewardCredit = rewardsData.rewardCredit,
      rewardLeftOver = rewardsData.rewardLeftOver;
  var rewardApplicableSubtotal = rewardsData.rewardApplicableSubtotal,
      rewardApplicableSubtotalPlusTaxes = rewardsData.rewardApplicableSubtotalPlusTaxes,
      rewards = rewardsData.rewards;

  var _calculateRewardDisco = calculateRewardDiscounts({
    compositePrice: compositePrice,
    credit: credit,
    detail: detailItem,
    discountTaxOrder: discountTaxOrder,
    originalCompositePrice: itemTotals.originalCompositePrice,
    productTotal: productTotal,
    receiptDetail: receiptDetail,
    rewardApplicableSubtotal: rewardApplicableSubtotal,
    rewardApplicableSubtotalPlusTaxes: rewardApplicableSubtotalPlusTaxes,
    rewardCredit: rewardCredit,
    rewardLeftOver: rewardLeftOver,
    rewards: rewards,
    rewardAppliedToCredit: rewardAppliedToCredit
  });

  compositePrice = _calculateRewardDisco.compositePrice;
  credit = _calculateRewardDisco.credit;
  productTotal = _calculateRewardDisco.productTotal;
  rewardAppliedToCredit = _calculateRewardDisco.rewardAppliedToCredit;
  rewardCredit = _calculateRewardDisco.rewardCredit;
  rewardLeftOver = _calculateRewardDisco.rewardLeftOver;
  itemTotals.compositePrice = compositePrice;
  totals.creditTotal = credit;
  totals.productTotal = productTotal;
  rewardsData.rewardAppliedToCredit = rewardAppliedToCredit;
  rewardsData.rewardCredit = rewardCredit;
  rewardsData.rewardLeftOver = rewardLeftOver;
}; // mutates the receiptDetail and totals

var calculatePreDiscountTaxes = function calculatePreDiscountTaxes(receiptDetail, itemTotals, totals, _ref3) {
  var detail = _ref3.detail,
      discountTaxOrder = _ref3.discountTaxOrder,
      productNonArmsLength = _ref3.productNonArmsLength,
      whPrice = _ref3.whPrice,
      product = _ref3.product;
  var bottleDepositTaxCents = Big(0);
  var cannabisTax = Big(0);
  var salesTax = Big(0);
  var residual = Big(0);
  var price = itemTotals.compositePrice;

  if (discountTaxOrder === 'both' || discountTaxOrder === 'taxesFirst') {
    var _getUpdatedTaxes = getUpdatedTaxes({
      detail: detail,
      price: price,
      productNonArmsLength: productNonArmsLength,
      whPrice: whPrice,
      product: product
    });

    bottleDepositTaxCents = _getUpdatedTaxes.bottleDepositTaxCents;
    cannabisTax = _getUpdatedTaxes.cannabisTax;
    salesTax = _getUpdatedTaxes.salesTax;

    var _redistributePennies = redistributePennies({
      cannabisTax: cannabisTax,
      price: price,
      salesTax: salesTax
    });

    cannabisTax = _redistributePennies.cannabisTax;
    salesTax = _redistributePennies.salesTax;
    residual = _redistributePennies.residual;
    receiptDetail.addTax({
      type: 'bottleDeposit',
      value: bottleDepositTaxCents
    });
    receiptDetail.addTax({
      type: 'cannabis',
      value: cannabisTax.times(100)
    });
    receiptDetail.addTax({
      type: detail.isVapeTaxed ? 'vape' : 'sales',
      value: salesTax.times(100)
    }); // If the tax is taken before the discount then add it into the subtotal

    if (discountTaxOrder === 'both' || Big(detail.basePriceMult, 'Base Price Mult').eq(1)) {
      itemTotals.bottleDepositTaxCentsTotal = itemTotals.bottleDepositTaxCentsTotal.add(bottleDepositTaxCents);
      itemTotals.cannabisTaxTotal = itemTotals.cannabisTaxTotal.add(cannabisTax);
      itemTotals.salesTaxTotal = itemTotals.salesTaxTotal.add(salesTax);
    } else {
      totals.productTotal = totals.productTotal.add(cannabisTax).add(salesTax).add(bottleDepositTaxCents.div(100));
    }

    totals.residualPrice = totals.residualPrice.add(residual);
  }

  var postTaxPrice = price.plus(cannabisTax).plus(salesTax).plus(bottleDepositTaxCents.div(100));
  itemTotals.compositePrice = discountTaxOrder === 'taxesFirst' ? Big(postTaxPrice) : Big(price);
}; // mutates the receiptDetail and totals


var calculatePostDiscountTaxes = function calculatePostDiscountTaxes(receiptDetail, itemTotals, totals, _ref4) {
  var detail = _ref4.detail,
      discountTaxOrder = _ref4.discountTaxOrder,
      productNonArmsLength = _ref4.productNonArmsLength,
      whPrice = _ref4.whPrice,
      product = _ref4.product;
  var bottleDepositTaxCents = Big(0);
  var cannabisTax = Big(0);
  var salesTax = Big(0);
  var residual = Big(0);

  if (discountTaxOrder === 'discountsFirst') {
    var _getUpdatedTaxes2 = getUpdatedTaxes({
      detail: detail,
      price: itemTotals.compositePrice,
      productNonArmsLength: productNonArmsLength,
      whPrice: whPrice,
      product: product
    });

    bottleDepositTaxCents = _getUpdatedTaxes2.bottleDepositTaxCents;
    cannabisTax = _getUpdatedTaxes2.cannabisTax;
    salesTax = _getUpdatedTaxes2.salesTax;

    var _redistributePennies2 = redistributePennies({
      cannabisTax: cannabisTax,
      price: itemTotals.compositePrice,
      salesTax: salesTax
    });

    cannabisTax = _redistributePennies2.cannabisTax;
    salesTax = _redistributePennies2.salesTax;
    residual = _redistributePennies2.residual;
    receiptDetail.addTax({
      type: 'bottleDeposit',
      value: bottleDepositTaxCents
    });
    receiptDetail.addTax({
      type: 'cannabis',
      value: cannabisTax.times(100)
    });
    receiptDetail.addTax({
      type: detail.isVapeTaxed ? 'vape' : 'sales',
      value: salesTax.times(100)
    });
    itemTotals.bottleDepositTaxCentsTotal = itemTotals.bottleDepositTaxCentsTotal.add(bottleDepositTaxCents);
    itemTotals.cannabisTaxTotal = itemTotals.cannabisTaxTotal.add(cannabisTax);
    itemTotals.salesTaxTotal = itemTotals.salesTaxTotal.add(salesTax);
    totals.residualPrice = totals.residualPrice.add(residual);
  }
}; // mutates the receiptDetail


export var checkAndUpdateMixAndMatch = function checkAndUpdateMixAndMatch(receiptDetail, _ref5) {
  var adjustedBasePrice = _ref5.adjustedBasePrice,
      _ref5$mixAndMatchDisc = _ref5.mixAndMatchDiscountAmount,
      mixAndMatchDiscountAmount = _ref5$mixAndMatchDisc === void 0 ? Big(0) : _ref5$mixAndMatchDisc,
      originalBasePrice = _ref5.originalBasePrice;

  if (mixAndMatchDiscountAmount.gt(0)) {
    receiptDetail.addMixAndMatch({
      type: "originalBasePrice",
      value: originalBasePrice.times(100)
    });
    receiptDetail.addMixAndMatch({
      type: "adjustedBasePrice",
      value: adjustedBasePrice.times(100)
    });
    receiptDetail.addMixAndMatch({
      type: "total",
      value: mixAndMatchDiscountAmount.times(100)
    });
  }
};
export var getProductPrices = function getProductPrices(_ref6) {
  var basePrice = _ref6.basePrice,
      quantity = _ref6.quantity,
      wholesalePrice = _ref6.wholesalePrice;
  var price = basePrice.times(quantity);
  var qtyWholesalePrice = wholesalePrice.times(quantity);
  var whPrice = qtyWholesalePrice.round(2, 1);
  return {
    price: price,
    whPrice: whPrice
  };
};
export var getUpdatedTaxes = function getUpdatedTaxes(_ref7) {
  var detail = _ref7.detail,
      price = _ref7.price,
      whPrice = _ref7.whPrice,
      product = _ref7.product;
  return {
    bottleDepositTaxCents: detail.bottleDepositTaxCents.times(detail.quantity),
    cannabisTax: detail.cannabisTax(price, whPrice, product),
    salesTax: detail.salesTax(price, whPrice, product)
  };
};
export var shouldAvoidTaxes = function shouldAvoidTaxes(_ref8) {
  var basePriceMult = _ref8.basePriceMult;
  return !Big(basePriceMult, 'Base Price Mult').eq(1);
}; // mutates receiptDetail and totals

var updateDiscountDetails = function updateDiscountDetails(detailItem, receiptDetail, itemTotals, totals, _ref9) {
  var discounts = _ref9.discounts,
      discountTaxOrder = _ref9.discountTaxOrder,
      mappedSpecials = _ref9.mappedSpecials,
      paymentIncentives = _ref9.paymentIncentives;
  // Set the potential saleAdjustmentAmount to the total original price of all items subtracted by the total discounted
  // price of all items per the serializer. We will remove the discount amount of every sale applied by cart calculator
  // from this value
  var saleAdjustmentAmount = Big(detailItem.menuBasePrice).times(detailItem.quantity).minus(Big(detailItem.menuIndividualPrice).times(detailItem.quantity));

  _.forEach(_.keys(discounts.discountAmountsBySpecial), function (specialId) {
    var _discounts$discountAm = discounts.discountAmountsBySpecial[specialId],
        discountAmountCents = _discounts$discountAm.amount,
        quantityApplied = _discounts$discountAm.quantityApplied;
    var discountAmount = Big(discountAmountCents).div(100);
    var _mappedSpecials$speci = mappedSpecials[specialId],
        name = _mappedSpecials$speci.name,
        rewards = _mappedSpecials$speci.rewards,
        specialType = _mappedSpecials$speci.specialType,
        type = _mappedSpecials$speci.type,
        discountStacking = _mappedSpecials$speci.discountStacking;
    var actualSpecialType = specialType !== null && specialType !== void 0 ? specialType : type;
    itemTotals.compositePrice = itemTotals.compositePrice.minus(discountAmount);
    receiptDetail.addDiscount({
      type: actualSpecialType,
      value: discountAmountCents,
      id: specialId,
      name: name,
      additionalAttributes: {
        satisfiedBy: []
      }
    }); // If an applied discount is a sale, remove the applied value from the potential saleAdjustmentAmount

    if (actualSpecialType === 'SALE') {
      saleAdjustmentAmount = saleAdjustmentAmount.minus(discountAmount);
    }

    if (actualSpecialType === 'OFFER') {
      var _detailItem$bogoSavin;

      detailItem.bogoSavings = (_detailItem$bogoSavin = detailItem.bogoSavings) !== null && _detailItem$bogoSavin !== void 0 ? _detailItem$bogoSavin : {
        individual: [],
        total: Big(0)
      }; // add the discount to the item

      detailItem.bogoSavings.individual.push({
        conditionQuantity: 0,
        discountAmount: discountAmountCents.div(100).div(quantityApplied),
        discountStacking: discountStacking,
        dollarDiscount: +discountAmount.div(quantityApplied),
        // For badge display purposes
        displayAsPercentDiscount: _.every(rewards, function (reward) {
          return reward.discountType === 'PERCENT_OFF' || reward.discountType === 'percentDiscount';
        }),
        maxApplicable: quantityApplied,
        specialId: specialId,
        stackingBehavior: _.toLower(mappedSpecials.stackingBehavior)
      });
    }

    if (discountTaxOrder === 'taxesFirst' || Big(detailItem.basePriceMult, "Base Price Mult").eq(1)) {
      totals.creditTotal = totals.creditTotal.add(discountAmount);
    } else {
      totals.productTotal = totals.productTotal.minus(discountAmount);
    }
  }); // If saleAdjustmentAmount is still larger than 0 afterwards, add a single saleAdjustment with the remaining
  // sale discount value that was not applied. This is used by the engine later to determine which discounts should be
  // "undone" for menu display, as sale discounts had already been added to our product data by the serializer. This
  // only occurs in non-stacking scenarios where an offer defeats a sale that should otherwise apply to the item


  if (saleAdjustmentAmount.gt(0)) {
    detailItem.saleAdjustments = {
      'cart-calc-sale-adjustments': {
        discount: {
          discountData: {
            discount: saleAdjustmentAmount.div(detailItem.quantity),
            percentDiscount: false,
            targetPrice: false
          }
        },
        count: detailItem.quantity
      }
    };
  }
}; //  mutates the receipt and details


export var updateReceiptAndDetails = function updateReceiptAndDetails(receipt, detailsSorted, totals, _ref10) {
  var couponData = _ref10.couponData,
      discountTaxOrder = _ref10.discountTaxOrder,
      products = _ref10.products,
      productsSpecialDiscountAmounts = _ref10.productsSpecialDiscountAmounts,
      rewardsData = _ref10.rewardsData,
      specials = _ref10.specials,
      paymentIncentives = _ref10.paymentIncentives;

  var filteredSpecials = _.filter(specials, function (special) {
    return !paymentIncentives.includes(special);
  });

  var mappedSpecials = _.reduce(specials, function (specialsHash, special) {
    return _objectSpread(_objectSpread({}, specialsHash), {}, _defineProperty({}, special.id, special));
  }, {});

  var avoidTaxes = false;

  _.forEach(detailsSorted, function (detail) {
    var _detail$mixAndMatch;

    var productKey = detail.key,
        quantity = detail.quantity,
        wholesalePrice = detail.wholesalePrice;
    var basePrice = getAdjustedBasePrice(detail);
    var product = products[productKey];

    if (!product) {
      console.warn("can't find product ".concat(productKey));
      return;
    }

    var productNonArmsLength = product.product.nonArmsLength;
    var receiptDetail = receipt.addProduct(detail.id, quantity, basePrice.times(100), detail.option);
    checkAndUpdateMixAndMatch(receiptDetail, {
      originalBasePrice: detail.basePrice,
      adjustedBasePrice: basePrice,
      mixAndMatchDiscountAmount: Big(((_detail$mixAndMatch = detail.mixAndMatch) === null || _detail$mixAndMatch === void 0 ? void 0 : _detail$mixAndMatch.discountAmount) || 0)
    });

    var _getProductPrices = getProductPrices({
      basePrice: basePrice,
      quantity: quantity,
      wholesalePrice: wholesalePrice
    }),
        price = _getProductPrices.price,
        whPrice = _getProductPrices.whPrice;

    var itemTotals = {
      bottleDepositTaxCentsTotal: Big(0),
      cannabisTaxTotal: Big(0),
      compositePrice: Big(price),
      originalCompositePrice: Big(price),
      salesTaxTotal: Big(0)
    }; // update receipt and totals (see above) and get current price to be discounted.

    calculatePreDiscountTaxes(receiptDetail, itemTotals, totals, {
      detail: detail,
      discountTaxOrder: discountTaxOrder,
      productNonArmsLength: productNonArmsLength,
      whPrice: whPrice,
      product: product.product
    }); // Unclear how to deal with residual taxes in this case.

    avoidTaxes = avoidTaxes || shouldAvoidTaxes(detail); // add discounts from dart module to bogoSavings, receipt, and totals ============================================

    updateDiscountDetails(detail, receiptDetail, itemTotals, totals, {
      discounts: productsSpecialDiscountAmounts[productKey],
      discountTaxOrder: discountTaxOrder,
      mappedSpecials: mappedSpecials,
      paymentIncentives: paymentIncentives
    }); // calculate coupon discount for details after discounts are applied and update itemTotals, receipt, couponData, and totals

    applyCouponToDetail({
      couponData: couponData,
      detailItem: detail,
      discountTaxOrder: discountTaxOrder,
      itemTotals: itemTotals,
      receiptDetail: receiptDetail,
      totals: totals
    }); // calculate rewards for details after discounts are applied and update itemTotals, receipt, rewardsData, and totals

    applyRewardsToDetail({
      detailItem: detail,
      discountTaxOrder: discountTaxOrder,
      itemTotals: itemTotals,
      receiptDetail: receiptDetail,
      rewardsData: rewardsData,
      totals: totals
    }); // update receiptDetail and (current element of) detailsSorted ============================================

    calculatePostDiscountTaxes(receiptDetail, itemTotals, totals, {
      detail: detail,
      discountTaxOrder: discountTaxOrder,
      productNonArmsLength: productNonArmsLength,
      whPrice: whPrice,
      product: product.product
    });
    totals.bottleDepositTaxCentsTotal = totals.bottleDepositTaxCentsTotal.add(itemTotals.bottleDepositTaxCentsTotal);
    totals.cannabisTaxTotal = totals.cannabisTaxTotal.add(itemTotals.cannabisTaxTotal);
    totals.salesTaxTotal = totals.salesTaxTotal.add(itemTotals.salesTaxTotal);
  });

  return {
    avoidTaxes: avoidTaxes
  };
};
/**
 *
 * receipt is a DiscountOutputCollection object
 * @returns {*[]}
 * @param {DiscountOutputCollection[]} receipt
 */

export var getPaymentIncentiveSpecials = function getPaymentIncentiveSpecials(receipt) {
  var paymentIncentiveSpecials = [];

  _.forEach(receipt, function (entry) {
    _.forEach(entry.outputs, function (special) {
      var _special$paymentRestr, _special$paymentRestr2;

      if ((_special$paymentRestr = (_special$paymentRestr2 = special.paymentRestrictions) === null || _special$paymentRestr2 === void 0 ? void 0 : _special$paymentRestr2.payByBankSignupIncentive) !== null && _special$paymentRestr !== void 0 ? _special$paymentRestr : false) {
        paymentIncentiveSpecials.push(special);
      }
    });
  });

  return paymentIncentiveSpecials;
};
export var incentivesPerLineItem = function incentivesPerLineItem(paymentIncentives) {
  return _.map(paymentIncentives, function (incentive) {
    return {
      type: incentive.discountName,
      value: Number(incentive.amount) / 100
    };
  });
};
/**
 * IncentivesPerLineItem is an array of objects that represent the incentives rewarded for each line item.
 * The object has the following structure:
 * {
 * type: "Pay By Bank Incentive",
 * value: 1.00
 * },
 * {
 * type: "Pay By Bank Incentive",
 * value: 2.60
 * }
 *
 * Consolidate the incentives by type and sum the values.
 * {
 * type: "Pay By Bank Incentive",
 * value: 3.60
 * }
 */

export var consolidateIncentives = function consolidateIncentives(incentives) {
  return _.map(_.groupBy(incentives, 'type'), function (incentives, type) {
    return {
      type: type,
      value: _.sumBy(incentives, 'value')
    };
  });
}; // export const addLogEntry = (stage = '', args) => {
//   logTotals('DISCOUNT SYNC', stage.toUpperCase(), args);
// };