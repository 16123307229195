import React, { ReactNode, useRef } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { v4 } from 'uuid';

import useUI from 'src/hooks/use-ui';
import { useIntersectionObserver } from 'src/hooks/use-intersection-observer';
import { VisuallyHidden } from 'shared/components/visually-hidden';
import { EmbeddedExternalLink } from 'src/components/core';
import ChevronIcon from 'src/assets/chevron-icon';

type CarouselV2Props = {
  children: ReactNode;
  href?: string;
  hideTitle?: boolean;
  title?: string;
  subTitle?: string;
  icon?: ReactNode;
  hideLink?: boolean;
  header?: ReactNode;
  mt?: string;
  gradientColor?: string;
  isSmallTitle?: boolean;
};

export const CarouselV2 = ({
  children,
  href,
  hideTitle = false,
  title,
  subTitle,
  icon,
  hideLink = false,
  header,
  mt = '32px',
  gradientColor = '#ffffff',
  isSmallTitle = false,
}: CarouselV2Props): JSX.Element => {
  const { isEmbeddedCarousel } = useUI();

  const viewportRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);
  const startWatcherRef = useRef(null);
  const startWatcher = useIntersectionObserver(startWatcherRef);
  const endWatcherRef = useRef<HTMLDivElement>(null);
  const endWatcher = useIntersectionObserver(endWatcherRef);
  const id = v4();

  // 20px to account for shift of container to account for shadow padding
  const buffer = 20;
  const viewportLeft = viewportRef.current?.getBoundingClientRect().left ?? 0;

  const handlePrevNextClick = (direction: -1 | 1): void => {
    if (!viewportRef.current || !itemsRef.current?.children.length) {
      return;
    }

    const { clientWidth: containerWidth } = viewportRef.current;
    const elements = Array.from(itemsRef.current.children);
    let target: Element | undefined;
    let scrollBy: number;

    // Next
    if (direction === 1) {
      target = elements.find((el) => {
        const { left, width } = el.getBoundingClientRect();
        return left + width - viewportLeft > containerWidth;
      });

      if (!target) {
        return;
      }

      scrollBy = target.getBoundingClientRect().left - viewportLeft - buffer;
      viewportRef.current.scrollBy({ left: scrollBy, behavior: 'smooth' });

      // Previous
    } else {
      target = elements.reverse().find((el) => {
        const { left } = el.getBoundingClientRect();
        return left - viewportLeft < 0;
      });

      if (!target) {
        viewportRef.current.scrollBy({ left: 0, behavior: 'smooth' });
        return;
      }

      scrollBy = (containerWidth - (target.getBoundingClientRect().right - viewportLeft) - buffer) * -1;
    }

    viewportRef.current.scrollBy({ left: scrollBy, behavior: 'smooth' });
  };

  return (
    <Wrapper $mt={mt}>
      <WrapperShift>
        {header ||
          ((!hideTitle || (!hideLink && href)) && (
            <Header>
              {!hideTitle && title && (
                <TextWrapper>
                  {icon}

                  <div>
                    <Heading id={id} $isSmallTitle={isSmallTitle}>
                      {title}
                    </Heading>
                    {subTitle && <SubTitle>{subTitle}</SubTitle>}
                  </div>
                </TextWrapper>
              )}

              {!hideLink &&
                href &&
                (isEmbeddedCarousel ? (
                  <ViewAllEmbedded href={href}>View All</ViewAllEmbedded>
                ) : (
                  <Link href={href}>
                    <ViewAll href={href}>View All</ViewAll>
                  </Link>
                ))}
            </Header>
          ))}

        <SliderContainer role='group' aria-roledescription='carousel' aria-labelledby={id}>
          <Viewport ref={viewportRef}>
            <ItemsContainer>
              <GradientLeft isVisible={!startWatcher?.isIntersecting} $gradientColor={gradientColor} />

              <div ref={startWatcherRef} />

              <Items ref={itemsRef}>{children}</Items>

              <EndWatcher ref={endWatcherRef} />

              <GradientRight isVisible={!endWatcher?.isIntersecting} $gradientColor={gradientColor} />
            </ItemsContainer>
          </Viewport>

          <PrevButton isVisible={!startWatcher?.isIntersecting} onClick={() => handlePrevNextClick(-1)}>
            <VisuallyHidden>Previous</VisuallyHidden>
            <PrevArrow />
          </PrevButton>

          <NextButton isVisible={!endWatcher?.isIntersecting} onClick={() => handlePrevNextClick(1)}>
            <VisuallyHidden>Next</VisuallyHidden>
            <NextArrow />
          </NextButton>
        </SliderContainer>
      </WrapperShift>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;

  ${({ $mt }) => css`
    margin-top: ${$mt};
  `};
`;

const WrapperShift = styled.div`
  margin: 0 -20px;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 20px;
  width: 100%;
`;

const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;

const Heading = styled.h2`
  font-weight: bold;
  line-height: ${24 / 20};

  ${({ $isSmallTitle }) =>
    $isSmallTitle
      ? css`
          font-size: 16px;
        `
      : css`
          font-size: 20px;

          @media screen and (min-width: 600px) {
            font-size: 26px;
          }
        `};
`;

const SubTitle = styled.p`
  color: #828a8f;
  font-size: 12px;
  line-height: ${16 / 12};
  margin-top: 4px;
`;

const viewAllLinkStyles = css`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: ${20 / 14};
  margin-left: auto;
  white-space: nowrap;
`;

const ViewAll = styled.a`
  ${viewAllLinkStyles};
`;

const ViewAllEmbedded = styled(EmbeddedExternalLink)`
  ${viewAllLinkStyles};
`;

const SliderContainer = styled.div`
  position: relative;
`;

const Viewport = styled.div`
  overflow-x: auto;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scrollbar-width: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Gradient = styled.div`
  display: block;
  height: 100%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.1s;
  width: 40px;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 100px;
  }
`;

const GradientLeft = styled(Gradient)`
  background: linear-gradient(
    to left,
    ${({ $gradientColor }) => `${String($gradientColor)}00`},
    ${({ $gradientColor }) => $gradientColor} 100%
  );
  left: 0;
`;

const GradientRight = styled(Gradient)`
  background: linear-gradient(
    to right,
    ${({ $gradientColor }) => `${String($gradientColor)}00`},
    ${({ $gradientColor }) => $gradientColor} 100%
  );
  right: 0;
`;

const EndWatcher = styled.div`
  flex-shrink: 0;
  width: 1px;
`;

// Need the watcher divs to have a height
const ItemsContainer = styled.div`
  display: flex;
`;

export const Items = styled.div`
  display: flex;
  flex-basis: 100%;
  gap: 12px;
  padding: 20px;
`;

const prevNextButtonStyles = css`
  appearance: none;
  align-items: center;
  background: ${({ theme }) => theme.colors.grey[95]};
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 5px 0 #0000002b;
  cursor: pointer;
  display: none;
  height: 48px;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s;
  width: 48px;
  z-index: 11;

  &:hover {
    background: ${({ theme }) => theme.colors.blueGrey[90]};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      opacity: 0;
      visibility: hidden;
    `};
`;

const PrevButton = styled.button`
  ${prevNextButtonStyles}
  left: 8px;
`;

const NextButton = styled.button`
  ${prevNextButtonStyles}
  right: 8px;
`;

const prevNextArrowStyles = css`
  height: 10px;
  width: 17px;
`;

const PrevArrow = styled(ChevronIcon)`
  ${prevNextArrowStyles}
  transform: rotate(90deg);
`;
const NextArrow = styled(ChevronIcon)`
  ${prevNextArrowStyles}
  transform: rotate(-90deg);
`;
