import React from 'react';

import SvgGoogleLogo from 'src/assets/google-logo';
import { SocialLogin } from './social-login';
import { ProviderProps } from './social-login.types';

export const GoogleLogin = ({ redirectUrl, autoLogin = false, onSubmit, className }: ProviderProps): JSX.Element => (
  <SocialLogin
    redirectUrl={redirectUrl}
    autoLogin={autoLogin}
    onSubmit={onSubmit}
    className={className}
    icon={<SvgGoogleLogo height={20} width={20} />}
    provider='google'
    buttonText='Continue with Google'
  />
);
