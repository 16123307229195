import React from 'react';

import { Product } from 'src/utils/helpers/product';

import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';

type TabSliderProps = {
  products: Product[];
  viewAllCard?: React.ReactNode;
  handleQuickAddToCart: (product: Product, index: number) => void;
  handleProductClick: (product: Product, index: number) => void;
  handleProductImpression?: (product: Product) => void;
  isLoading: boolean;
};

export const TabSlider = ({
  products = [],
  handleProductClick,
  handleProductImpression,
  handleQuickAddToCart,
  viewAllCard,
  isLoading,
}: TabSliderProps): JSX.Element => (
  <ProductCarousel
    products={products}
    isLoading={isLoading}
    hideTitle
    hideLink
    customProductClick={handleProductClick}
    customQuickAddToCart={handleQuickAddToCart}
    handleProductImpression={handleProductImpression}
    afterProducts={viewAllCard}
    mt='0'
  />
);
