/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { safeToBig } from 'shared/helpers/utils_math';
import { getProductNetWeightInGrams } from 'shared/helpers/products';
import { getFlowerEquivalent } from 'shared/helpers/check-limits';
import { getTypeLimits, getLimitMessage } from '../utils';
export function isSolidEdible(_ref) {
  var type = _ref.type,
      subcategory = _ref.subcategory;
  var isLiquid = subcategory === 'drinks';
  return type === 'Edible' && !isLiquid;
}
export function checkSolidEdibleNetWeight(cart) {
  var _getTypeLimits = getTypeLimits(cart),
      solidEdibleNetWeightLimit = _getTypeLimits.solidEdibleNetWeightLimit;

  var weightInGrams = getTotalSolidGramsForCart(cart);
  var message = getLimitMessage('solid edibles', solidEdibleNetWeightLimit);
  return weightInGrams > solidEdibleNetWeightLimit ? {
    total: weightInGrams,
    message: message,
    withinLimits: false
  } : {
    withinLimits: true
  };
}
export function getTotalSolidGramsForCart(_ref2) {
  var orders = _ref2.orders,
      state = _ref2.state,
      limits = _ref2.limits,
      medical = _ref2.medical;
  return Number(_.reduce(orders, function (previous, order) {
    var product = order.product,
        quantity = order.quantity;

    if (!isSolidEdible(product)) {
      return safeToBig(previous);
    }

    var netWeightInGrams = getProductNetWeightInGrams(product);
    var totalNetWeight = netWeightInGrams * quantity;
    var isBritishColumbiaDispensary = state === 'BC';
    var flowerEquivalent = getFlowerEquivalent(order, medical, limits);
    var useEquivalent = isBritishColumbiaDispensary && !!flowerEquivalent;
    return safeToBig(previous).plus(useEquivalent ? flowerEquivalent : totalNetWeight);
  }, 0.0));
}