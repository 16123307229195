import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash'; // eslint-disable-next-line import/no-cycle

import { getProductWeight } from 'shared/helpers/products';
import { matchesBrand, matchesCategory, matchesInventoryTag, matchesProductId, matchesProductTag, matchesStrain, matchesVendor } from 'shared/order/bogo/common';
/* **************************
 * A set of helpers specifically for 3.5 specials
 *************************** */
// Accepts a product, a set of restrictions (bogoConditions, bogoRewards, saleDiscounts), and a special object
// Returns an object with a boolean for whether any option was found, along with the eligible set per restriction

export function checkProductForEligibleOptions() {
  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var restrictions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var special = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var eligibleProductOptionsPerRestriction = _.map(restrictions, function (restriction) {
    return eligibleProductOptionsForRestriction(product, restriction, 'inclusion', special, special.useActiveBatchTags);
  });

  var productHasEligibleOption = false;

  _.forEach(eligibleProductOptionsPerRestriction, function (options) {
    if (options.length > 0) {
      productHasEligibleOption = true;
      return false;
    }

    return true;
  });

  return {
    productHasEligibleOption: productHasEligibleOption,
    eligibleProductOptionsPerRestriction: eligibleProductOptionsPerRestriction
  };
} // Accepts a special. Returns true or false depending on if the special is v3.5

export function isSpecialVersion3dot5() {
  var special = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (special === null || special === void 0 ? void 0 : special.version) === 3 && (special === null || special === void 0 ? void 0 : special.minorVersion) === 5 || // TODO: remove this once minorVersion is being added by Arma discountSync as 5
  isArmageddonDiscountSyncSpecial(special);
} // Accepts a special. Returns true or false depending on if the special is created or updated by discount sync v2

export function isArmageddonDiscountSyncSpecial() {
  var special = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (special === null || special === void 0 ? void 0 : special.createdBy) === 'ArmageddonDiscountSync' || (special === null || special === void 0 ? void 0 : special.updatedBy) === 'ArmageddonDiscountSync';
} // Accepts a product, a restriction object (bogoCondition, bogoRewards, saleDiscount, or an exclusion),
//    a restrictionType ('inclusion' or 'exclusion'), and a special object
// Returns an array of all eligible product options (weights) for that restriction

export function eligibleProductOptionsForRestriction() {
  var _restriction$productI;

  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var restriction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var restrictionType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'inclusion';
  var special = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var useActiveBatchTags = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

  // Totally skip the restriction if 'IGNORE' is present
  if (((_restriction$productI = restriction.productIds) === null || _restriction$productI === void 0 ? void 0 : _restriction$productI[0]) === 'IGNORE') {
    return [];
  }

  var matchOperator = getMatchOperator(restriction, restrictionType);
  var eligibleOptions = []; // Only set eligibleOptions in 'or' matchOperator cases at this point.
  // Parsing of weights for comparison isn't needed in 'and' cases if the non-weight restrictions aren't matched to begin with.
  // Also if none of the non-weight restrictions are matched this option set will be used as the eligible option set

  if (matchOperator === 'or') {
    eligibleOptions = getMatchingProductWeightOptions(product, restriction, true, matchOperator, special);
  } // Check for non-weight restrictions


  var _matchesRestriction = matchesRestriction(product, // In matchOperator 'or' cases combined with directPOSProductIdMapping, we want to bypass checking productIds on the
  // restriction because they have already been considered in the previous getMatchingProductWeightOptions check
  // as a result of the "one POS ID to one weight option" relation.
  matchOperator === 'or' && special !== null && special !== void 0 && special.directPOSProductIdMapping ? _objectSpread(_objectSpread({}, restriction), {}, {
    productIds: []
  }) : restriction, restrictionType, useActiveBatchTags, special === null || special === void 0 ? void 0 : special.useActiveBatchTagOfWeightOption),
      isRestrictionMatch = _matchesRestriction.isMatch,
      isNonProductIdMatch = _matchesRestriction.isNonProductIdMatch; // Since only matchOperator 'or' cases could have populated the eligibleOptions array at this point, we can safely check it's length here via or


  if (isRestrictionMatch || eligibleOptions.length > 0) {
    // Set eligibleOptions for matchOperator 'and' cases since we did not do so earlier, with all options eligible if there are no weight criteria
    if (matchOperator === 'and') {
      eligibleOptions = getMatchingProductWeightOptions(product, restriction, false, matchOperator, special);
    } else if (isRestrictionMatch) {
      var _restriction$productI2, _restriction$inventor;

      // In matchOperator 'or' cases, if there was a matching non-weight restriction all possible weight options are
      // eligible so we bypass checking specific weight criteria here.
      var adjustedRestriction = {
        productIds: (_restriction$productI2 = restriction === null || restriction === void 0 ? void 0 : restriction.productIds) !== null && _restriction$productI2 !== void 0 ? _restriction$productI2 : [],
        weights: [],
        inventoryTags: (_restriction$inventor = restriction === null || restriction === void 0 ? void 0 : restriction.inventoryTags) !== null && _restriction$inventor !== void 0 ? _restriction$inventor : []
      }; // For directPOSProductIdMapping, options tied to productIds will still be considered unless we've matched on
      // restriction criteria outside productIds, in which case we bypass checking productIds as weight options here

      if (isNonProductIdMatch && special !== null && special !== void 0 && special.directPOSProductIdMapping) {
        adjustedRestriction.productIds = [];
      }

      eligibleOptions = getMatchingProductWeightOptions(product, adjustedRestriction, false, matchOperator, special);
    }

    var excludedOptions = [];

    if (!_.isEmpty(restriction.exclusions)) {
      excludedOptions = _.union.apply(_, _toConsumableArray(_.map(restriction.exclusions, function (exclusion) {
        return eligibleProductOptionsForRestriction(product, exclusion, 'exclusion', special, useActiveBatchTags);
      })));
    }

    if (!_.isArray(eligibleOptions)) {
      eligibleOptions = _.uniq([].concat(_toConsumableArray(eligibleOptions.filteredOptions), _toConsumableArray(eligibleOptions.filteredRawOptions)));
    } // Remove excluded options from the resulting eligible set


    return _.difference(eligibleOptions, excludedOptions);
  }

  return [];
} // Accepts a product, restriction (bogoCondition, bogoReward, saleDiscount, or an exclusion), and a restrictionType (inclusion or exclusion)
// Returns an object with booleans indicating whether the product matches restriction criteria on productIds or non-productIds fields

function matchesRestriction() {
  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var restriction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var restrictionType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'inclusion';
  var useActiveBatchTags = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var useActiveBatchTagOfWeightOption = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

  if (restriction.productGroup === 'all') {
    return {
      isMatch: true,
      isNonProductIdMatch: true
    };
  }

  var matchOperator = getMatchOperator(restriction, restrictionType); // For 'or' cases, we should only be returning true if criteria is present and a match
  // For 'and' cases, if criteria is not present it is still considered a match

  var failIfNoCriteria = matchOperator === 'or';

  if (matchOperator === 'or') {
    if (matchesBrand(restriction, product, 'brand', failIfNoCriteria) || matchesCategory(restriction, product, 'category', failIfNoCriteria) || matchesStrain(restriction, product, failIfNoCriteria) || matchesVendor(restriction, product, failIfNoCriteria) || matchesInventoryTag(restriction, product, failIfNoCriteria, useActiveBatchTags, useActiveBatchTagOfWeightOption) || matchesProductTag(restriction, product, failIfNoCriteria, useActiveBatchTags)) {
      return {
        isMatch: true,
        isNonProductIdMatch: true
      };
    }

    if (matchesProductId(restriction, product, failIfNoCriteria)) {
      return {
        isMatch: true,
        isNonProductIdMatch: false
      };
    }

    return {
      isMatch: false,
      isNonProductIdMatch: false
    };
  }

  var brandMatch = matchesBrand(restriction, product, 'brand', failIfNoCriteria);
  var categoryMatch = matchesCategory(restriction, product, 'category', failIfNoCriteria);
  var productMatch = matchesProductId(restriction, product, failIfNoCriteria);
  var strainMatch = matchesStrain(restriction, product, failIfNoCriteria);
  var vendorMatch = matchesVendor(restriction, product, failIfNoCriteria);
  var productTagMatch = matchesProductTag(restriction, product, failIfNoCriteria, useActiveBatchTags);
  var inventoryTagMatch = matchesInventoryTag(restriction, product, failIfNoCriteria, useActiveBatchTags, useActiveBatchTagOfWeightOption);
  var nonProductMatch = brandMatch && categoryMatch && strainMatch && vendorMatch && productTagMatch && inventoryTagMatch;
  return {
    isMatch: productMatch && nonProductMatch,
    isNonProductIdMatch: nonProductMatch
  };
} // Accepts a product restriction object (bogoCondition, bogoReward, saleDiscount, or an exclusion) and a restrictionType (inclusion or exclusion)
// Returns the matchOperator designating logic between product group restrictions ("and" or "or")


function getMatchOperator() {
  var restriction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var restrictionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'inclusion';
  var defaultMatchOperator = restrictionType === 'inclusion' ? 'and' : 'or';
  return restriction !== null && restriction !== void 0 && restriction.matchOperator ? restriction.matchOperator : defaultMatchOperator;
} // Accepts a product, restriction object (bogoCondition, bogoReward, saleDiscount, or an exclusion), boolean to define
//    return behavior if no weight criteria is present, matchOperator ('and' or 'or'), and special object
// Returns an array of weight options that the product matches based on the restriction's weight criteria


function getMatchingProductWeightOptions() {
  var _restriction$inventor2;

  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var restriction = arguments.length > 1 ? arguments[1] : undefined;
  var returnEmptyIfNoCriteria = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var matchOperator = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'and';
  var special = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var _restriction$weights = restriction.weights,
      weights = _restriction$weights === void 0 ? [] : _restriction$weights,
      _restriction$weightOp = restriction.weightOperator,
      weightOperator = _restriction$weightOp === void 0 ? 'equalTo' : _restriction$weightOp;

  var weightConstraints = _.compact(weights); // remove nil values


  var activeBatchTagsConstraints = special !== null && special !== void 0 && special.useActiveBatchTagOfWeightOption ? (_restriction$inventor2 = restriction.inventoryTags) !== null && _restriction$inventor2 !== void 0 ? _restriction$inventor2 : [] : [];
  var productOptions = [];
  var rawProductOptions = [];

  if (!_.isEmpty(product.Options)) {
    productOptions = _toConsumableArray(product.Options);
  } else if (product.option) {
    productOptions.push(product.option);
  }

  if (special !== null && special !== void 0 && special.rawPOSWeightMapping) {
    if (!_.isEmpty(product.rawOptions)) {
      rawProductOptions = _toConsumableArray(product.rawOptions);
    } else if (product.rawOption) {
      rawProductOptions.push(product.rawOption);
    }
  } // No weight or activeBatchTags constraints or 'Any Weight'


  if ((_.isEmpty(weightConstraints) || _.includes(weightConstraints, 'Any Weight')) && _.isEmpty(activeBatchTagsConstraints)) {
    // returnEmptyIfNoCriteria: true is used for initial 'or' match comparison, where we want the resulting option set
    // to be empty unless a product is explicitly a part of the inclusion / exclusion set due to weight criteria
    var optionsWhenNoCriteria = returnEmptyIfNoCriteria ? [] : productOptions; // For discount sync v2 created specials with directPOSProductIdMapping enabled, any productIds on the restriction
    // are also technically weight constraints due to the "one POS ID to one weight option" relation. As such we need to
    // determine the inferred options when product child canonicalIDs match any productIds on the restriction.
    // Otherwise, we simply return optionsWhenNoCriteria

    return special !== null && special !== void 0 && special.directPOSProductIdMapping ? getOptionsFromPOSProductIds(product, optionsWhenNoCriteria, restriction, special) : optionsWhenNoCriteria;
  } // If activeBatchTagsConstraints are present, we need to filter the productOptions by activeBatchTags


  if (!_.isEmpty(activeBatchTagsConstraints) && special !== null && special !== void 0 && special.useActiveBatchTagOfWeightOption) {
    var _getOptionsFromActive = getOptionsFromActiveBatchTags(product, productOptions, rawProductOptions, restriction),
        filteredOptions = _getOptionsFromActive.filteredOptions,
        filteredRawOptions = _getOptionsFromActive.filteredRawOptions; // If no weight constraints are present, return the options filtered by activeBatchTags


    if (_.isEmpty(weightConstraints)) {
      return {
        filteredOptions: filteredOptions,
        filteredRawOptions: filteredRawOptions
      };
    } // Otherwise, continue with the weight constraint filtering


    productOptions = filteredOptions;
    rawProductOptions = filteredRawOptions;
  }

  var checkEqualTo = _.isNil(weightOperator) || weightOperator === 'equalTo' || weightOperator === 'greaterThanEqualTo';
  var checkGreaterThan = weightOperator === 'greaterThan' || weightOperator === 'greaterThanEqualTo';

  var weightConstraintsInGrams = _.map(weightConstraints, function (constraint) {
    return getProductWeight(constraint);
  }); // Filter options by equalTo, greaterThan, or greaterThanEqualTo weight criteria


  var weightFilteredOptions = _.reduce(productOptions, function (matchingProductOptions, productOption, optionIndex) {
    var _rawProductOptions$op;

    var productOptionInGrams = getProductWeight(productOption); // Grab the rawProductOption at the same index as the productOption

    var rawProductOption = (_rawProductOptions$op = rawProductOptions[optionIndex]) !== null && _rawProductOptions$op !== void 0 ? _rawProductOptions$op : null;
    var rawProductOptionInGrams = rawProductOption ? getProductWeight(rawProductOptions[optionIndex]) : null;

    _.forEach(weightConstraintsInGrams, function (weightConstraintInGrams, index) {
      if (checkEqualTo && ( // Try to match the string option directly first before checking weight conversion
      productOption === weightConstraints[index] || productOptionInGrams === weightConstraintInGrams || rawProductOption === weightConstraints[index] || rawProductOptionInGrams === weightConstraintInGrams || weightConstraints[index] === 'Any Weight' && special !== null && special !== void 0 && special.useActiveBatchTagOfWeightOption)) {
        matchingProductOptions.push(productOption);
        return false; // exit early
      }

      if (checkGreaterThan && (productOptionInGrams > weightConstraintInGrams || rawProductOptionInGrams > weightConstraintInGrams)) {
        matchingProductOptions.push(productOption);
        return false; // exit early
      }

      return true; // consistent return
    });

    return matchingProductOptions;
  }, []); // For discount sync v2 created specials with directPOSProductIdMapping enabled...


  if (special !== null && special !== void 0 && special.directPOSProductIdMapping) {
    // Retrieve any inferred options due to productIds on the restriction
    var posProductIdFilteredOptions = getOptionsFromPOSProductIds(product, productOptions, restriction, special); // Merge the POS product ID options with those derived from specific weight criteria

    var combinedFilteredOptions = matchOperator === 'or' ? // In matchOperator 'or' cases, we want the unique list of all weights found in either set
    _.union(posProductIdFilteredOptions, weightFilteredOptions) : // In matchOperator 'and' cases, we want the unique list of all weights found in both sets
    _.intersection(posProductIdFilteredOptions, weightFilteredOptions); // Sort the results based on original productOptions order

    return _.intersection(productOptions, combinedFilteredOptions);
  } // Otherwise return the options filtered by weight criteria alone, which are already in the correct order


  return weightFilteredOptions;
} // Accepts a product, set of productOptions, and restriction object
// Returns either the set of options applicable for this product based on child canonicalIDs and restriction criteria
//    or the productOptions unaltered if no productIds are present on the restriction


function getOptionsFromPOSProductIds() {
  var _restriction$productI3, _product$POSMetaData;

  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var productOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var restriction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var productCriteria = _toConsumableArray((_restriction$productI3 = restriction.productIds) !== null && _restriction$productI3 !== void 0 ? _restriction$productI3 : []);

  if (_.isEmpty(productCriteria)) {
    return productOptions;
  }

  return _.reduce((_product$POSMetaData = product.POSMetaData) === null || _product$POSMetaData === void 0 ? void 0 : _product$POSMetaData.children, function (options, child) {
    if (_.includes(productCriteria, child === null || child === void 0 ? void 0 : child.canonicalID)) {
      options.push(child.option);
    }

    return options;
  }, []);
}

function getOptionsFromActiveBatchTags() {
  var _restriction$inventor3;

  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var productOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var rawOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var restriction = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var qualifiedTags = _toConsumableArray((_restriction$inventor3 = restriction.inventoryTags) !== null && _restriction$inventor3 !== void 0 ? _restriction$inventor3 : []);

  if (_.isEmpty(qualifiedTags)) {
    return {
      productOptions: productOptions,
      rawOptions: rawOptions
    };
  }

  var filterOptions = function filterOptions(options, getOptionValue) {
    return _.reduce(options, function (filteredOptions, option) {
      var _product$POSMetaData2, _product$POSMetaData3, _childProduct$activeB;

      var childProduct = (_product$POSMetaData2 = product.POSMetaData) === null || _product$POSMetaData2 === void 0 ? void 0 : (_product$POSMetaData3 = _product$POSMetaData2.children) === null || _product$POSMetaData3 === void 0 ? void 0 : _product$POSMetaData3.find(function (child) {
        return child.option === getOptionValue(option);
      });
      var childTags = childProduct === null || childProduct === void 0 ? void 0 : (_childProduct$activeB = childProduct.activeBatchTags) === null || _childProduct$activeB === void 0 ? void 0 : _childProduct$activeB.map(function (tag) {
        return tag.tagId;
      });

      if (_.intersection(qualifiedTags, childTags).length > 0) {
        filteredOptions.push(option);
      }

      return filteredOptions;
    }, []);
  };

  var filteredOptions = filterOptions(productOptions, function (option) {
    return option;
  });
  var filteredRawOptions = filterOptions(rawOptions, getProductWeight);
  return {
    filteredOptions: filteredOptions,
    filteredRawOptions: filteredRawOptions
  };
}