import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Backdrop from '@material-ui/core/Backdrop';
import styled from 'styled-components';
export var DialogWrapper = function DialogWrapper(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return (// eslint-disable-next-line no-use-before-define
    __jsx(Dialog, _extends({}, props, {
      BackdropComponent: BackdropComponent,
      fullScreen: true,
      scroll: "body"
    }), children)
  );
};
var BackdropComponent = styled(Backdrop).withConfig({
  displayName: "v2-dialog-wrapper__BackdropComponent",
  componentId: "sc-18ytwkj-0"
})(["&.MuiBackdrop-root{background-color:", ";}"], function (_ref2) {
  var _theme$colors;

  var theme = _ref2.theme;
  return theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.greyOverlay;
});