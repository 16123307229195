import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// TODO: Move this into /marketplace/src/checkout/actions, build-order.test.js is located there
import _ from 'lodash';
import { toJS } from 'mobx';
import { getPersistedValue } from 'shared/utils/persisted-values';
import { hasSaleSpecialForMenuType } from 'shared/helpers/specials';
import { removeTypename, serializeUtmData } from 'shared/helpers/utils';
import { isDutchiePlusKioskOrder } from 'shared/helpers/checkout';
import { ChannelSources } from './constants';
/*
 * called via shared/checkout/actions/onClickSubmit.js
 */

export default function buildOrder(_ref) {
  var _flags$growthEcommU, _dispensary$storeSett;

  var Cart = _ref.Cart,
      UI = _ref.UI,
      User = _ref.User,
      Checkout = _ref.Checkout,
      paymentId = _ref.paymentId,
      _ref$flags = _ref.flags,
      flags = _ref$flags === void 0 ? {} : _ref$flags;
  var isVariant = Checkout.isVariant,
      checkoutId = Checkout.checkoutId;
  var dispensary = Cart.order.dispensary;
  var removeMedicalCardFieldsAtCheckout = dispensary.removeMedicalCardFieldsAtCheckout;
  var sendPaysafeInput = !!(dispensary.payOnlineMerrco && Cart.order.paymentMethod === 'payOnlineMerrco');
  var guestCustomerFields = null;

  if (Cart.isGuestOrder || isVariant('kiosk')) {
    guestCustomerFields = Cart.order.guestCustomer;
  } // if we have an external order put those values on the guestCustomer field
  // if the MSO gives us that info, lets store it.


  if (User.hasExternalUser) {
    guestCustomerFields = toJS(User.userDetailsFromExternalSource);
  } // If a user clicks the box to save info, then enters a password, then unchecks the box,
  // don't send the password to the server.


  if (!Cart.saveGuestInfo && guestCustomerFields) {
    delete guestCustomerFields.password;
  } // this serializes the utmData to ensure that it is not an array of duplicated values, but a string of that value that
  // was duplicated


  var serializedUtmData = serializeUtmData(Cart.order.utmData);
  var sessionStoredUtmData = getPersistedValue('utmSessionData', {
    parameters: {}
  });

  var sessionUtmParameters = _.get(sessionStoredUtmData, 'parameters', {});

  var sessionStorageUtmDataEnabled = (_flags$growthEcommU = flags === null || flags === void 0 ? void 0 : flags['growth.ecomm.use-session-storage-utm-data.rollout']) !== null && _flags$growthEcommU !== void 0 ? _flags$growthEcommU : false;
  var priceCartEnabled = (flags === null || flags === void 0 ? void 0 : flags['core.cats-ecomm.price-cart-calcs-state.rollout']) === 'ENABLED';
  var discountSyncEnabled = dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$storeSett = dispensary.storeSettings) === null || _dispensary$storeSett === void 0 ? void 0 : _dispensary$storeSett.enableLLxSaleDiscountSync;
  var sendCouponCode = priceCartEnabled && discountSyncEnabled;
  var couponObject = sendCouponCode ? {
    couponCode: _.get(Cart.order, 'coupon.code')
  } : {
    couponId: _.get(Cart.order, 'coupon._id')
  }; // if possible, dont destructure values here. Logic above the fold could be altering them.

  return {
    proceed: true,
    order: _.omitBy(removeTypename(_objectSpread(_objectSpread({
      dispensaryId: dispensary.id,
      products: _.map(_.values(Cart.order.cart), function (item) {
        return _.omitBy({
          id: item.product._id,
          quantity: parseInt(item.quantity, 10),
          option: item.option,
          additionalOption: item.additionalOption,
          special: hasSaleSpecialForMenuType({
            product: item.product,
            medicalOrder: Cart.order.medicalOrder
          }),
          rawOption: item.rawOption
        }, _.isNil);
      }),
      tip: Cart.order.tipValue
    }, couponObject), {}, {
      paysafeInput: sendPaysafeInput ? _.get(Cart.order, 'paysafeInput') : undefined,
      paymentMethod: Cart.order.paymentMethod,
      paymentMethodId: Cart.paymentMethodId,
      paymentId: Cart.paymentMethod === 'rethinkPay' ? paymentId : undefined,
      utmData: sessionStorageUtmDataEnabled ? sessionUtmParameters : serializedUtmData,
      embedded: isVariant(['embedded', 'store-front']),
      variantSource: Checkout.variant,
      medicalOrder: Cart.order.medicalOrder,
      medicalCard: removeMedicalCardFieldsAtCheckout ? null : _.get(Cart.order, 'medicalCard'),
      driversLicense: _.get(Cart.order, 'driversLicense'),
      photoId: _.get(Cart.order, 'photoId', User.profile.photoId),
      deliveryOption: Cart.isDelivery,
      address: UI.formattedAddress,
      location: _.pick(UI.currentAddress, ['ln1', 'ln2', 'city', 'state', 'zipcode', 'lat', 'lng', 'streetNumber', 'streetName', 'country']),
      specialInstructions: _.get(Cart.order, 'specialInstructions', ''),
      isPreviewOrder: UI.previewMode,
      isKioskOrder: isVariant('kiosk') || isDutchiePlusKioskOrder(Cart.orderType, UI.isPlus),
      isGuestOrder: isVariant('kiosk') ? true : Cart.isGuestOrder,
      isExternalOrder: User.hasExternalUser,
      isAfterHoursOrder: Cart.isAfterHoursOrder,
      isCurbsidePickupOrder: Cart.isCurbsidePickup,
      isDriveThruPickupOrder: Cart.isDriveThruPickup,
      guestCustomer: guestCustomerFields,
      checkoutToken: Cart.order.checkoutToken,
      expectedTotal: Cart.costBreakdown.total,
      destinationTerminal: Cart.order.destinationTerminal,
      customerState: Cart.customerState,
      appliedRewards: _.map(Cart.order.appliedRewards, function (reward) {
        return _.pick(reward, ['id', 'available', 'operator', 'value', 'brand', 'copy', 'rewardType', 'discountId']);
      }),
      reservation: _.pick(Cart.order.reservation, ['startTimeISO', 'endTimeISO', 'slotId']),
      reservationType: Cart.reservationType,
      hypurAccessToken: Cart.hypurAccessToken,
      monerisInput: _.isEmpty(Cart.monerisInput) ? null : Cart.monerisInput,
      saveGuestInfo: Cart.saveGuestInfo,
      checkoutId: checkoutId,
      isPTPOT: true,
      channelSource: isVariant('mobile-ecomm') ? ChannelSources.retailerMobile : null,
      schedulingData: toJS(Cart.schedulingData)
    })), _.isNil)
  };
}