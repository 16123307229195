import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var Big = require('big.js');

var _ = require('lodash');
/*
 * Tax configuration is stored in a list of objects which look like
 * - name         string
 * - taxType      sales|cannabis
 * - rate         float percentage
 * - potency      string
 * - potencyRate  float percentage
 * - medical      boolean
 * - recreational boolean
 * - applyTo: {
 * -  types: [Flower|Edibles|....]
 * -  hemp: boolean (currently not supported in admin/integrations)
 * - }
 * - stages [
 * -  {type: pos|menu|checkout, op: add/subtract/noop}
 * -  ...
 * - ]
 * - deliveryPolicy originBased|destinationBased (sales tax only)
 */


var operandMap = {
  lessThan: function lessThan(a, b) {
    return a < b;
  },
  lessThanEqualTo: function lessThanEqualTo(a, b) {
    return a <= b;
  },
  equalTo: function equalTo(a, b) {
    return a === b;
  },
  greaterThan: function greaterThan(a, b) {
    return a > b;
  },
  greaterThanEqualTo: function greaterThanEqualTo(a, b) {
    return a >= b;
  },
  notApplicable: function notApplicable() {
    return true;
  }
};

var roundTo = function roundTo(value, digits) {
  return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
}; // Takes rules which have sub-structure and flattes them out.


var flattenTaxes = function flattenTaxes(taxData) {
  var rv = [];

  _.forEach(taxData.taxes, function (tax) {
    _.forEach(tax.stages, function (stage) {
      var f = _objectSpread(_objectSpread({}, tax), {}, {
        taxType: tax.type,
        taxBasis: tax.taxBasis,
        stage: stage
      });

      delete f.stages;
      rv.push(f);
    });
  });

  return rv;
};

var CTRates = function CTRates(item) {
  var variableTaxRate = 0;

  switch (item.product.type) {
    case 'Flower':
    case 'Pre-Rolls':
      variableTaxRate = 0.625;
      break;

    case 'Edible':
      variableTaxRate = 2.75;
      break;

    default:
      variableTaxRate = 0.9;
      break;
  }

  return variableTaxRate;
};

var milligramsTHC = function milligramsTHC(product, itemOption) {
  var _product$POSMetaData, _product$POSMetaData$;

  if (!product) {
    console.warn("can't find product in milligramsTHC");
    return 0;
  }

  var optionIndex = itemOption !== null && itemOption !== void 0 ? itemOption : 0;

  if (itemOption) {
    optionIndex = _.findIndex(product.Options, function (s) {
      return s === itemOption;
    });
    optionIndex = optionIndex === -1 ? 0 : optionIndex;
  }

  var child = (_product$POSMetaData = product.POSMetaData) === null || _product$POSMetaData === void 0 ? void 0 : (_product$POSMetaData$ = _product$POSMetaData.children) === null || _product$POSMetaData$ === void 0 ? void 0 : _product$POSMetaData$[optionIndex];
  return parseFloat((child === null || child === void 0 ? void 0 : child.canonicalEffectivePotencyMg) || 0);
}; // Filter for a set of taxes.


var relevantTaxRules = function relevantTaxRules(category, hemp, type, taxRules, stage) {
  return _.filter(taxRules, function (rule) {
    var catValue = hemp ? rule.hemp : !rule.applyTo || rule.applyTo.types && rule.applyTo.types.includes(category);
    return (type === 'med' ? rule.medical : rule.recreational) && catValue && (!stage || rule.stage.type === stage);
  });
};

var sumTaxesAtLayer = function sumTaxesAtLayer(flattenedRules, item) {
  return _.reduce(flattenedRules, function (accum, rule) {
    var _rule$stage;

    var value = rule.rate;

    if (rule.type === 'bottleDeposit') {
      value = item.product.bottleDepositTaxCents || 0;
    } else if (rule.rate === 'Variable') {
      value = CTRates(item) * milligramsTHC(item.product, item.option);
    }

    switch ((_rule$stage = rule.stage) === null || _rule$stage === void 0 ? void 0 : _rule$stage.op) {
      case 'add':
        accum = accum.plus(value);
        break;

      case 'subtract':
        accum = accum.minus(value);
        break;

      default:
        break;
    }

    return accum;
  }, Big(0));
};

var calcTaxesAtLayer = function calcTaxesAtLayer(_ref) {
  var category = _ref.category,
      hemp = _ref.hemp,
      type = _ref.type,
      taxRules = _ref.taxRules,
      stage = _ref.stage,
      thcContent = _ref.thcContent,
      isDelivery = _ref.isDelivery,
      product = _ref.product,
      option = _ref.option;
  var taxes = {
    sales: Big(0),
    cannabis: Big(0),
    bottleDeposit: Big(0)
  };
  var rules = relevantTaxRules(category, hemp, type, taxRules, stage);

  _.forEach(Object.keys(taxes), function (taxType) {
    var value = Big(0);

    _.forEach(_.filter(rules, function (rule) {
      return rule.taxType === taxType;
    }), function (rule) {
      var potency = rule.potency,
          potencyRate = rule.potencyRate,
          rate = rule.rate,
          destinationRate = rule.destinationRate; // apply destination-based sales tax, if applicable

      var baseValue = isDelivery && _.isFinite(destinationRate) ? destinationRate : rate;

      if (taxType === 'bottleDeposit') {
        baseValue = Big(product.bottleDepositTaxCents || 0);
      }

      if (rate === 'Variable') {
        baseValue = Big(0);
      } // if we have potency & thcContent, but the operand formed is false, do not include
      // that rule's rate in the total tax rate.


      var thcValueDoesntMatchRule = potency && thcContent && !operandMap[potency](thcContent, potencyRate * 100); // if we have potency but no thcContent, then exclude all but rules with 'greater', 'less', or 'notApplicable'

      var noThc = potency && !thcContent;

      var largestPotencyRule = _.includes(potency, 'greater');

      var lesserPotencyRule = _.includes(potency, 'less');

      var ignorePotency = _.includes(potency, 'notApplicable');

      switch (rule.stage.op) {
        case 'noop':
          break;

        case 'add':
          // even if the THC content is nothing we should still apply the tax if applicable
          if (thcValueDoesntMatchRule || noThc && largestPotencyRule && !lesserPotencyRule && !ignorePotency) {
            baseValue = Big(0);
          } // if we do not have potency or thcContent, or we do and the operand perfomed is true,
          // include that rule's rate in the total tax rate.


          value = value.plus(baseValue);
          break;

        case 'subtract':
          if (thcValueDoesntMatchRule || noThc && largestPotencyRule && !lesserPotencyRule && !ignorePotency) {
            baseValue = Big(0);
          } // if we do not have potency or thcContent, or we do and the operand perfomed is true,
          // subtract that rule's rate from the total tax rate.


          value = value.minus(baseValue);
          break;

        default:
          throw new Error("improper tax configuration op ".concat(rule.stage.op));
      }
    });

    taxes[taxType] = taxes[taxType].add(value);
  });

  return taxes;
};

var layerOrder = ['base', 'pos', 'menu', 'checkout'];
/*
 * When calculating to layer we assume you start with the taxes at layer n - 1.
 *
 * We call the n-1 layer the Previous (pre) layer.
 * Nominally in order to do taxes at layer N we need to undo what was done at
 * the previous layer and redo them at this layer.  With a positive tax you undo
 * by dividing and do by multiplying.  Reverse that for a negative tax.
 *
 * initialStage lets you go back more then one stage.  If not null it defines the
 * stage to start at by name.
 *
 * We allow for taxes to be based off of a number that comes from the POS (that might include
 * taxes) or for cannabis taxes to be based off the wholesale price.  We wrap this logic and
 * configuration up in the closures we return to the caller.
 */

var calcTaxes = function calcTaxes(_ref2) {
  var _dispensary$location2;

  var category = _ref2.category,
      hemp = _ref2.hemp,
      thcContent = _ref2.thcContent,
      type = _ref2.type,
      taxRules = _ref2.taxRules,
      taxMethod = _ref2.taxMethod,
      stage = _ref2.stage,
      _ref2$initialStage = _ref2.initialStage,
      initialStage = _ref2$initialStage === void 0 ? null : _ref2$initialStage,
      _ref2$isDelivery = _ref2.isDelivery,
      isDelivery = _ref2$isDelivery === void 0 ? false : _ref2$isDelivery,
      _ref2$product = _ref2.product,
      product = _ref2$product === void 0 ? {} : _ref2$product,
      option = _ref2.option,
      dispensary = _ref2.dispensary,
      quantity = _ref2.quantity;

  if (stage === 'base') {
    throw new Error('stage should be pos or higher');
  }

  var returnZero = function returnZero() {
    return 0;
  };

  if (stage === initialStage) {
    return {
      cannabisTax: returnZero,
      salesTax: returnZero,
      taxMult: 1.0
    };
  }

  var retailTaxRules = _.filter(taxRules, function (rule) {
    return rule.taxBasis !== 'wholesale';
  });

  var wholesaleTaxRules = _.filter(taxRules, function (rule) {
    return rule.taxBasis === 'wholesale';
  });

  var stageIndex = _.findIndex(layerOrder, function (s) {
    return s === stage;
  });

  var startStageIndex;

  if (!initialStage) {
    startStageIndex = stageIndex - 1;
  } else {
    startStageIndex = _.findIndex(layerOrder, function (s) {
      return s === initialStage;
    });
  }

  var stageTaxes = [];

  _.forEach(layerOrder.slice(1, stageIndex + 1), function (stageName) {
    stageTaxes.push(calcTaxesAtLayer({
      category: category,
      hemp: hemp,
      type: type,
      taxRules: retailTaxRules,
      stage: stageName,
      thcContent: thcContent,
      isDelivery: isDelivery,
      product: product,
      option: option
    }));
  });

  var wholesaleStageTaxes = [];

  _.forEach(layerOrder.slice(1, stageIndex + 1), function (stageName) {
    wholesaleStageTaxes.push(calcTaxesAtLayer({
      category: category,
      hemp: hemp,
      type: type,
      taxRules: wholesaleTaxRules,
      stage: stageName,
      thcContent: thcContent,
      isDelivery: isDelivery,
      product: product,
      option: option
    }));
  });

  var upTo = stageTaxes.slice(0, startStageIndex);
  var salesPreRate = Big(0),
      cannabisPreRate = Big(0),
      bottleDepositPreSum = Big(0);
  cannabisPreRate = _.reduce(upTo, function (accum, tax) {
    return accum.add(tax.cannabis);
  }, cannabisPreRate);
  salesPreRate = _.reduce(upTo, function (accum, tax) {
    return accum.add(tax.sales);
  }, salesPreRate);
  bottleDepositPreSum = _.reduce(upTo, function (accum, tax) {
    return accum.add(tax.bottleDeposit);
  }, bottleDepositPreSum);

  var cannabisRate = _.reduce(stageTaxes, function (accum, tax) {
    return accum.add(tax.cannabis);
  }, Big(0));

  var salesRate = _.reduce(stageTaxes, function (accum, tax) {
    return accum.add(tax.sales);
  }, Big(0));

  var bottleDepositRate = _.reduce(stageTaxes, function (accum, tax) {
    return accum.add(tax.bottleDeposit);
  }, Big(0));

  var wholesaleCannabisTaxRate = _.reduce(wholesaleStageTaxes, function (accum, tax) {
    return accum.add(tax.cannabis);
  }, Big(0));

  var undo, apply;

  if (taxMethod === 'compound') {
    undo = Big(1);

    if (salesPreRate.lt(0)) {
      undo = undo.div(salesPreRate.times(-1).plus(1));
    } else if (salesPreRate.gt(0)) {
      undo = undo.times(salesPreRate.plus(1));
    }

    if (cannabisPreRate.lt(0)) {
      undo = undo.div(cannabisPreRate.times(-1).plus(1));
    } else if (cannabisPreRate.gt(0)) {
      undo = undo.times(cannabisPreRate.plus(1));
    }

    apply = Big(1);

    if (salesRate.lt(0)) {
      apply = apply.div(salesRate.times(-1).plus(1));
    } else if (salesRate.gt(0)) {
      apply = apply.times(salesRate.plus(1));
    }

    if (cannabisRate.lt(0)) {
      apply = apply.div(cannabisRate.times(-1).plus(1));
    } else if (cannabisRate.gt(0)) {
      apply = apply.times(cannabisRate.plus(1));
    }
  } else {
    undo = Big(1);
    undo = undo.plus(salesPreRate);
    undo = undo.plus(cannabisPreRate);
    apply = Big(1);
    apply = apply.plus(salesRate);
    apply = apply.plus(cannabisRate);
  } // bottle deposit tax is non-componding, therefore tax method is irrelevant


  var bottleDepositTaxCents = bottleDepositRate.minus(bottleDepositPreSum);
  var taxMult = apply.div(undo);
  var isOrdered = taxMethod !== 'cumulative' && retailTaxRules.filter(function (rule) {
    return rule.stage.op !== 'noop' && !_.isNil(rule.order);
  }).length > 0;
  var orderedRules;

  if (isOrdered) {
    orderedRules = relevantTaxRules(category, hemp, type, retailTaxRules, null).filter(function (rule) {
      return rule.stage.op !== 'noop';
    }).sort(function (ruleA, ruleB) {
      return ruleA.order - ruleB.order;
    });
  }

  var orderedTaxesMult = function orderedTaxesMult(rules, taxType, lastTotalInit) {
    var taxValue = Big(lastTotalInit || 1);
    var layers = layerOrder.slice(startStageIndex + 1, stageIndex + 1);

    _.forEach(rules, function (rule) {
      if ((!taxType || rule.type === taxType) && layers.includes(rule.stage.type)) {
        taxValue = rule.stage.op === 'add' ? taxValue.times(Big(1).plus(rule.rate)) : taxValue.div(Big(1).plus(rule.rate));
      }
    });

    return taxValue;
  };

  var makeTaxOp = function makeTaxOp(taxMeth) {
    return taxMeth === 'compound' ? function (a, b) {
      return (1 + a) * (1 + b);
    } : function (a, b) {
      return 1 + a + b;
    };
  };

  var taxOp = makeTaxOp(taxMethod);

  var basePriceCalc = function basePriceCalc(salesPreRate, cannabisPreRate, bottleDepositPreSum) {
    return function (price) {
      return (price - bottleDepositPreSum / 100) / taxOp(Number(salesPreRate), Number(cannabisPreRate));
    };
  };

  var makeCalcTax = function makeCalcTax(otherTax, tax, taxType) {
    return function (price, wPrice, productData, option) {
      var base = price / taxOp(Number(salesPreRate), Number(cannabisPreRate));
      wPrice = _.isNil(wPrice) ? Big(0) : Big(wPrice);

      if (taxMethod === 'cumulative') {
        if (!isOrdered) {
          if (taxType === 'cannabis') {
            var _dispensary$location;

            var productMilligramsTHC = milligramsTHC(productData, option);
            var variableTax = 0;

            if ((dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$location = dispensary.location) === null || _dispensary$location === void 0 ? void 0 : _dispensary$location.state) === 'CT' && type === 'rec') {
              var variableTaxRate = CTRates({
                option: option,
                product: product
              });
              variableTax = Big(variableTaxRate).times(productMilligramsTHC || 0).times(quantity).div(100);
            }

            return tax.times(base).add(wPrice.times(wholesaleCannabisTaxRate)).add(variableTax);
          } // eslint-disable-line


          return base * taxOp(Number(otherTax), Number(tax)) - base * (1 + Number(otherTax));
        }
      } else {
        var _$maxBy;

        var currentTaxBasis = Big(base);
        var totalTax = {
          sales: Big(0),
          cannabis: Big(0),
          bottleDeposit: Big(0)
        };
        var filteredRules = relevantTaxRules(category, hemp, type, taxRules, null);
        filteredRules = _.filter(filteredRules, function (rule) {
          return rule.stage.op !== 'noop';
        });

        var groupedRules = _.groupBy(filteredRules, 'id');

        var filteredGroupedRules = _.reduce(groupedRules, function (accum, value, key) {
          var minuses = _.filter(value, function (val) {
            return val.stage.op === 'subtract';
          }).length;

          if (minuses !== 0) {
            var adds = _.filter(value, function (val) {
              return val.stage.op === 'add';
            }).length; // We can fix up this situation.  If there are more subtracts we're bailing.


            if (adds > minuses) {
              value = _.filter(value, function (val) {
                return val.stage.op !== 'subtract';
              });
              value = value.slice(0, adds - minuses);
            }
          }

          accum[key] = value;
          return accum;
        }, {});

        var flattenedRules = _.flatMap(_.values(filteredGroupedRules), function (val) {
          return val;
        }); // If there isn't an order enforce one where the cannabis taxes are before the sales taxes.
        // This should be a fairly uncommon configuration these days.


        var orderedTaxRules = _.map(flattenedRules, function (rule) {
          return _objectSpread(_objectSpread({}, rule), {}, {
            order: rule.order || (rule.taxType === 'cannabis' ? 0 : 1)
          });
        });

        var maxOrder = ((_$maxBy = _.maxBy(orderedTaxRules, 'order')) === null || _$maxBy === void 0 ? void 0 : _$maxBy.order) || 0;

        var _loop = function _loop(order) {
          var rules = _.filter(orderedTaxRules, function (rule) {
            return rule.order === order && rule.stage.op === 'add';
          });

          var orderTax = Big(0); // eslint-disable-next-line no-restricted-syntax

          var _iterator = _createForOfIteratorHelper(rules),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var rule = _step.value;
              var thisTax = void 0;

              if (rule.taxBasis === 'wholesale') {
                var nonArmsLength = productData === null || productData === void 0 ? void 0 : productData.nonArmsLength;

                if (!nonArmsLength && wPrice !== undefined) {
                  thisTax = wPrice.times(rule.rate);
                } else {
                  thisTax = currentTaxBasis.times(rule.rate);
                }
              } else {
                /* eslint-disable */
                var potency = rule.potency,
                    potencyRate = rule.potencyRate,
                    destinationRate = rule.destinationRate;
                var effectiveRate = isDelivery && _.isFinite(destinationRate) ? destinationRate : rule.rate; // if we have potency & thcContent, but the operand formed is false, do not include
                // that rule's rate in the total tax rate.

                var thcValueDoesntMatchRule = potency && thcContent && !operandMap[potency](thcContent, potencyRate * 100); // if we have potency but no thcContent, then exclude all but rules with 'greater'

                var noThc = potency && !thcContent;

                var largestPotencyRule = _.includes(potency, 'greater');

                if (thcValueDoesntMatchRule || noThc && !largestPotencyRule) {
                  effectiveRate = Big(0);
                }

                thisTax = currentTaxBasis.times(effectiveRate);
              }

              orderTax = orderTax.plus(thisTax);
              totalTax[rule.taxType] = totalTax[rule.taxType].plus(thisTax);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          currentTaxBasis = currentTaxBasis.plus(orderTax);
        };

        for (var order = 0; order <= maxOrder; order++) {
          _loop(order);
        }
        /* eslint-enable */


        return totalTax[taxType];
      }
    };
  };

  var isCannabisTax = ((_dispensary$location2 = dispensary.location) === null || _dispensary$location2 === void 0 ? void 0 : _dispensary$location2.state) === 'CT' || cannabisRate.gt(cannabisPreRate) || wholesaleCannabisTaxRate.gt(0);
  var isSalesTax = salesRate.gt(salesPreRate);

  if (isOrdered) {
    taxMult = orderedTaxesMult(orderedRules);
  }

  return {
    bottleDepositTaxCents: bottleDepositTaxCents,
    cannabisTax: isCannabisTax ? makeCalcTax(salesRate, cannabisRate, 'cannabis') : returnZero,
    salesTax: isSalesTax ? makeCalcTax(cannabisRate, salesRate, 'sales') : returnZero,
    basePrice: basePriceCalc(salesPreRate, cannabisPreRate, bottleDepositPreSum),
    taxMult: taxMult
  };
};
/*
 * This returns true if the dispensary has discounts before taxes but any tax
 * data in their data by the menu.
 */


var oddDiscountTreatment = function oddDiscountTreatment(dispensary) {
  if (dispensary.taxConfig.discountTaxOrder !== 'discountsFirst' && dispensary.taxConfig.discountTaxOrder !== 'both') {
    return false;
  }

  var rules = flattenTaxes(dispensary.taxConfig).filter(function (rule) {
    return rule.stage.type !== 'checkout' && rule.stage.op !== 'noop';
  });
  var numAdds = rules.filter(function (rule) {
    return rule.stage.op === 'add';
  }).length;
  var numSubs = rules.filter(function (rule) {
    return rule.stage.op === 'subtract';
  }).length;
  return numAdds - numSubs > 0;
};

var originBasedTaxStates = ['AZ', 'CA', 'IL', 'MS', 'MO', 'NM', 'OH', 'PA', 'TN', 'TX', 'UT', 'VA'];

var getDefaultSalesTaxDeliveryPolicy = function getDefaultSalesTaxDeliveryPolicy(state) {
  return originBasedTaxStates.includes(state) ? 'originBased' : 'destinationBased';
};

export { calcTaxes, getDefaultSalesTaxDeliveryPolicy, flattenTaxes, layerOrder, oddDiscountTreatment, relevantTaxRules, roundTo, sumTaxesAtLayer, milligramsTHC };