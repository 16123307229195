import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import moment from 'moment-timezone';
import { formatCurrency, removeTypename } from 'shared/helpers/utils';
import { getSpecialHoursDays, getEnabledV2OrderTypes, openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { mutuallyInclusiveFeatures, defaultCategoryPhotos } from 'shared/constants';
export var getOpenNowStatus = function getOpenNowStatus(openInfo) {
  var _openInfo$pickup, _openInfo$delivery, _openInfo$inStorePick, _openInfo$driveThruPi, _openInfo$curbsidePic;

  return ((_openInfo$pickup = openInfo.pickup) === null || _openInfo$pickup === void 0 ? void 0 : _openInfo$pickup.isOpen) || ((_openInfo$delivery = openInfo.delivery) === null || _openInfo$delivery === void 0 ? void 0 : _openInfo$delivery.isOpen) || ((_openInfo$inStorePick = openInfo.inStorePickup) === null || _openInfo$inStorePick === void 0 ? void 0 : _openInfo$inStorePick.isOpen) || ((_openInfo$driveThruPi = openInfo.driveThruPickup) === null || _openInfo$driveThruPi === void 0 ? void 0 : _openInfo$driveThruPi.isOpen) || ((_openInfo$curbsidePic = openInfo.curbsidePickup) === null || _openInfo$curbsidePic === void 0 ? void 0 : _openInfo$curbsidePic.isOpen);
};
export var getNextServiceOpenString = function getNextServiceOpenString(openInfo) {
  var pickup = openInfo.pickup,
      delivery = openInfo.delivery;

  if (pickup.nextService.openMoment && delivery.nextService.openMoment) {
    return pickup.nextService.openMoment.isBefore(delivery.nextService.openMoment) ? pickup.nextService.openString : delivery.nextService.openString;
  }

  if (!pickup.nextService.openMoment && delivery.nextService.openMoment) {
    return delivery.nextService.openString;
  }

  return pickup.nextService.openString;
};
export var getNextOpenService = function getNextOpenService(dispensary) {
  var openInfo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var includeDelivery = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var enabledServices = getEnabledV2OrderTypes(dispensary);
  var openInfoToUse = openInfo || openInfoForDispensary(dispensary);
  var filteredEnabledServices = includeDelivery ? enabledServices : _.without(enabledServices, 'delivery');
  var nextOpenService = 'inStorePickup';

  if (filteredEnabledServices.length === 0) {
    return nextOpenService;
  }

  var _filteredEnabledServi = _slicedToArray(filteredEnabledServices, 1);

  nextOpenService = _filteredEnabledServi[0];

  // assign the nextOpenService as the first enabled type
  var enabledOrderTypeOpenMoments = _.compact(_.map(filteredEnabledServices, function (service) {
    var _openInfoToUse$servic;

    return (_openInfoToUse$servic = openInfoToUse[service]) === null || _openInfoToUse$servic === void 0 ? void 0 : _openInfoToUse$servic.nextService.openMoment;
  }));

  if (enabledOrderTypeOpenMoments.length === 0) {
    return nextOpenService;
  }

  var nextOpenServiceMoment = moment.min(enabledOrderTypeOpenMoments);
  nextOpenService = _.find(filteredEnabledServices, function (service) {
    var _openInfoToUse$servic2;

    return nextOpenServiceMoment.isSame((_openInfoToUse$servic2 = openInfoToUse[service]) === null || _openInfoToUse$servic2 === void 0 ? void 0 : _openInfoToUse$servic2.nextService.openMoment);
  });
  return nextOpenService;
};
export var getDispensaryHomePageUrl = function getDispensaryHomePageUrl(dispensary, isEmbedded) {
  var isMobileEcommApp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (isEmbedded) {
    return dispensary === null || dispensary === void 0 ? void 0 : dispensary.embeddedMenuUrl;
  }

  if (isMobileEcommApp) {
    return "/mobile-ecomm/".concat(dispensary === null || dispensary === void 0 ? void 0 : dispensary.cName);
  }

  return "/dispensary/".concat(dispensary === null || dispensary === void 0 ? void 0 : dispensary.cName);
};
/**
 * @param {Dispensary} dispensary
 * @returns {boolean}
 */

export var isMed = function isMed(_ref) {
  var medicalDispensary = _ref.medicalDispensary;
  return medicalDispensary;
};
/**
 * @param {Dispensary} dispensary
 * @returns {boolean}
 */

export var isRec = function isRec(_ref2) {
  var recDispensary = _ref2.recDispensary;
  return !!recDispensary;
};
/**
 * @param {Dispensary} dispensary
 * @returns {boolean}
 */

export var isAfterHoursCurrentlyAvailable = function isAfterHoursCurrentlyAvailable(dispensary) {
  return acceptingAfterHoursForDelivery(dispensary) || acceptingAfterHoursForPickup(dispensary);
};
/**
 * @param {Dispensary} dispensary
 * @param {boolean} checkHideDeliveryEstimate
 * @returns {boolean}
 */

export var shouldHideOrderEstimates = function shouldHideOrderEstimates(dispensary, Cart) {
  var _dispensary$featureFl, _dispensary$featureFl2;

  var checkHideDeliveryEstimate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var orderTypesConfigV2 = dispensary.orderTypesConfigV2;
  var isInStorePickup = Cart.isInStorePickup,
      isCurbsidePickup = Cart.isCurbsidePickup,
      isDriveThruPickup = Cart.isDriveThruPickup,
      isDelivery = Cart.isDelivery;
  var scheduledOrderingForPickup = isInStorePickup && (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : orderTypesConfigV2.inStorePickup.enableScheduledOrdering) || isCurbsidePickup && (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : orderTypesConfigV2.curbsidePickup.enableScheduledOrdering) || isDriveThruPickup && (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : orderTypesConfigV2.driveThruPickup.enableScheduledOrdering);
  var scheduledOrderingForDelivery = isDelivery && (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : orderTypesConfigV2.delivery.enableScheduledOrdering);
  return !!(dispensary !== null && dispensary !== void 0 && (_dispensary$featureFl = dispensary.featureFlags) !== null && _dispensary$featureFl !== void 0 && _dispensary$featureFl.hideStoreHours || (scheduledOrderingForPickup || scheduledOrderingForDelivery) && Cart.reservation || checkHideDeliveryEstimate && dispensary !== null && dispensary !== void 0 && (_dispensary$featureFl2 = dispensary.featureFlags) !== null && _dispensary$featureFl2 !== void 0 && _dispensary$featureFl2.hideDeliveryEstimate);
}; // If we are not given a max delivery distance then any is good.
// [maxDeliveryDistance] Float in Miles
// [distanceFromDispensary] Float in Meters

export var closeEnoughForDelivery = function closeEnoughForDelivery(maxDeliveryDistance, distanceFromDispensary) {
  return !maxDeliveryDistance || parseFloat(maxDeliveryDistance) / 0.00062137 >= distanceFromDispensary;
};
/**
 * @param {Object}
 * @param {boolean} hasDeliveryAddress
 * @returns {string | false}
 */

export var getDeliveryFee = function getDeliveryFee(_ref3) {
  var _deliveryInfo$feeType;

  var deliveryInfo = _ref3.deliveryInfo,
      feeTiers = _ref3.feeTiers,
      enabledOrderTypes = _ref3.enabledOrderTypes,
      orderTypesConfig = _ref3.orderTypesConfig,
      orderTypesConfigV2 = _ref3.orderTypesConfigV2;
  var hasDeliveryAddress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var deliveryEnabled;

  if (enabledOrderTypes) {
    deliveryEnabled = enabledOrderTypes.delivery;
  } else if (orderTypesConfigV2) {
    var _orderTypesConfigV2$d, _orderTypesConfigV2$d2;

    deliveryEnabled = (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : (_orderTypesConfigV2$d = orderTypesConfigV2.delivery) === null || _orderTypesConfigV2$d === void 0 ? void 0 : _orderTypesConfigV2$d.enableASAPOrdering) || (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : (_orderTypesConfigV2$d2 = orderTypesConfigV2.delivery) === null || _orderTypesConfigV2$d2 === void 0 ? void 0 : _orderTypesConfigV2$d2.enableScheduledOrdering);
  } else {
    var _orderTypesConfig$del;

    deliveryEnabled = orderTypesConfig === null || orderTypesConfig === void 0 ? void 0 : (_orderTypesConfig$del = orderTypesConfig.delivery) === null || _orderTypesConfig$del === void 0 ? void 0 : _orderTypesConfig$del.enabled;
  }

  if (!deliveryEnabled) {
    return false;
  }

  if (!hasDeliveryAddress && deliveryInfo !== null && deliveryInfo !== void 0 && deliveryInfo.feeVaries) {
    return 'varies';
  }

  var feeType = (_deliveryInfo$feeType = deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.feeType) !== null && _deliveryInfo$feeType !== void 0 ? _deliveryInfo$feeType : 'flat';

  if (feeType === 'flat' && _.isFinite(deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.fee) && !((deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.fee) <= 0)) {
    return "".concat(formatCurrency(deliveryInfo.fee / 100, {
      trimZeroCents: true
    }));
  }

  if (feeType === 'percent' && _.isFinite(deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.percentFee) && !((deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.percentFee) <= 0)) {
    return "".concat(String(deliveryInfo.percentFee), "%");
  }

  if (!(feeTiers !== null && feeTiers !== void 0 && feeTiers.length)) {
    return false;
  }

  var firstFeeType = _.get(feeTiers, '0.feeType', 'flat');

  var firstFee = _.get(feeTiers, "0.".concat(firstFeeType === 'percent' ? 'percentFee' : 'fee'), '') || '';
  var parsedFee = firstFeeType !== 'percent' ? Number(firstFee) : parseFloat(firstFee.toString().replace(/[^\d.]/, ''));
  var hasDeliveryFee = _.isFinite(parsedFee) && parsedFee !== 0;
  var formattedFee = parsedFee % 1 === 0 ? parsedFee : parsedFee.toFixed(2);

  if (!hasDeliveryFee) {
    return false;
  }

  return firstFeeType === 'percent' ? "".concat(parsedFee, "%") : "".concat(formatCurrency(formattedFee, {
    trimZeroCents: true
  }));
};
/**
 * @typedef {import('types/graphql').GqlWeeklyHours} GqlWeeklyHours
 * @typedef {import('types/graphql').GqlSpecialHours} GqlSpecialHours
 *
 * @param {GqlWeeklyHours} hours
 * @param {DaysOfWeek} day
 * @param {boolean} lowercaseSuffix
 * @returns {string}
 */

export var getFormattedHoursForDay = function getFormattedHoursForDay(hours, day) {
  var _hours$day;

  var lowercaseSuffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var _ref4 = (_hours$day = hours[day]) !== null && _hours$day !== void 0 ? _hours$day : {},
      active = _ref4.active,
      start = _ref4.start,
      end = _ref4.end;

  var amSuffix = lowercaseSuffix ? 'am' : 'AM';
  var pmSuffix = lowercaseSuffix ? 'pm' : 'PM';

  if (!active) {
    return "".concat(day, " (Closed)");
  }

  if (start === 'open') {
    return "".concat(day, " (24 Hours)");
  }

  return "".concat(day, " (").concat(start === null || start === void 0 ? void 0 : start.replace(/^00/, '12').replace(/:00 /i, '').replace(/ AM/i, amSuffix).replace(/ PM/i, pmSuffix), " -\n    ").concat(end === null || end === void 0 ? void 0 : end.replace(/:00 /i, '').replace(/ AM/i, amSuffix).replace(/ PM/i, pmSuffix), ")");
};
export var getDeliveryMinimum = function getDeliveryMinimum(_ref5) {
  var deliveryInfo = _ref5.deliveryInfo,
      enabledOrderTypes = _ref5.enabledOrderTypes,
      orderTypesConfig = _ref5.orderTypesConfig,
      orderTypesConfigV2 = _ref5.orderTypesConfigV2;
  var hasDeliveryAddress = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var deliveryEnabled;

  if (enabledOrderTypes) {
    deliveryEnabled = enabledOrderTypes.delivery;
  } else if (orderTypesConfigV2) {
    var _orderTypesConfigV2$d3, _orderTypesConfigV2$d4;

    deliveryEnabled = ((_orderTypesConfigV2$d3 = orderTypesConfigV2.delivery) === null || _orderTypesConfigV2$d3 === void 0 ? void 0 : _orderTypesConfigV2$d3.enableASAPOrdering) || ((_orderTypesConfigV2$d4 = orderTypesConfigV2.delivery) === null || _orderTypesConfigV2$d4 === void 0 ? void 0 : _orderTypesConfigV2$d4.enableScheduledOrdering);
  } else {
    var _orderTypesConfig$del2;

    deliveryEnabled = (_orderTypesConfig$del2 = orderTypesConfig.delivery) === null || _orderTypesConfig$del2 === void 0 ? void 0 : _orderTypesConfig$del2.enabled;
  }

  if (!deliveryEnabled) {
    return false;
  }

  if (!hasDeliveryAddress && deliveryInfo !== null && deliveryInfo !== void 0 && deliveryInfo.minimumVaries) {
    return 'varies';
  }

  return _.isFinite(deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.minimum) && (deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.minimum) > 0 && (deliveryInfo === null || deliveryInfo === void 0 ? void 0 : deliveryInfo.minimum) / 100;
};
/*
 * This return the whole orderMinimum object that includes the minimumInCents and enabled fields
 */

export var getPickupMinimum = function getPickupMinimum(dispensary, pickupOrderType) {
  var _orderTypesConfigV2$o;

  var orderTypesConfigV2 = dispensary.orderTypesConfigV2;
  var orderTypeKey = pickupOrderType === 'pickup' ? 'inStorePickup' : pickupOrderType;

  if (!orderTypesConfigV2) {
    return null;
  }

  var minimum = (_orderTypesConfigV2$o = orderTypesConfigV2[orderTypeKey]) === null || _orderTypesConfigV2$o === void 0 ? void 0 : _orderTypesConfigV2$o.orderMinimum;
  return minimum;
};
export var getFirstAvailablePickupOption = function getFirstAvailablePickupOption(dispensary) {
  var enabledOrderTypes = getEnabledV2OrderTypes(dispensary);

  if (_.includes(enabledOrderTypes, 'inStorePickup')) {
    return 'pickup';
  }

  if (_.includes(enabledOrderTypes, 'curbsidePickup')) {
    return 'curbsidePickup';
  }

  if (_.includes(enabledOrderTypes, 'driveThruPickup')) {
    return 'driveThruPickup';
  }

  return 'pickup';
};
export var formatDeliveryZoneInfo = function formatDeliveryZoneInfo(dispensary, hasDeliveryAddress) {
  var fieldsHaveLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var fee = getDeliveryFee(dispensary, hasDeliveryAddress);
  var minimum = getDeliveryMinimum(dispensary, hasDeliveryAddress);
  var feeDisplay = fee ? "".concat(fee, " delivery fee") : "Free delivery";
  var minimumDisplay = minimum ? "".concat(formatCurrency(minimum, {
    trimZeroCents: true
  }), " delivery min.") : "No minimum";

  if (fee === 'varies') {
    feeDisplay = fieldsHaveLabel ? "Varies by location" : "Fee varies by location";
  }

  if (minimum === 'varies') {
    minimumDisplay = fieldsHaveLabel ? "Varies by location" : "Minimum varies by location";
  }

  var info = {
    feeDisplay: feeDisplay,
    minimumDisplay: minimumDisplay,
    fee: fee,
    minimum: minimum
  };

  if (fee === 'varies' && minimum === 'varies') {
    _.set(info, 'bothVary', 'Fees and minimums vary by location');
  }

  return info;
}; // Condensed version of the above delivery info formatting function

export var formatAmountMessage = function formatAmountMessage(amount, label) {
  var amtMessage;
  var formattedAmount = label === 'Minimum' ? "$".concat(amount) : amount;

  if (amount && amount !== '0') {
    amtMessage = "".concat(formattedAmount, " ").concat(label);
  }

  if (amount && amount === 'varies') {
    amtMessage = "".concat(label, " Varies");
  }

  if (!amount) {
    amtMessage = "No ".concat(label);
  }

  return amtMessage;
};
export var formatPickupInfo = function formatPickupInfo(dispensary, pickupOrderType) {
  var minimum = getPickupMinimum(dispensary, pickupOrderType);
  var pickupMinimum = (minimum === null || minimum === void 0 ? void 0 : minimum.minimumInCents) > 0 && minimum !== null && minimum !== void 0 && minimum.enabled ? "".concat(formatCurrency(minimum.minimumInCents / 100, {
    trimZeroCents: true
  }), " pickup min.") : 'No minimum';
  var info = {
    pickupMinimum: pickupMinimum
  };
  return info;
};
export var hasNonCashPaymentOptions = function hasNonCashPaymentOptions(dispensaryProfile) {
  var ignoreDebit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var debitCard = ignoreDebit ? false : _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'debit', false);

  var linx = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'linx', false);

  var creditCardNoInfo = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'creditCardAtDoor', false);

  var creditCardByPhone = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'creditCardByPhone', false);

  var alt36 = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'alt36', false);

  var chaseOnline = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'payOnlineChase', false);

  var hypurOnline = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'payOnlineHypur', false);

  var merrcoOnline = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'payOnlineMerrco', false);

  var monerisOnline = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'payOnlineMoneris', false);

  var canPay = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'canPay', false);

  var paytender = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'paytender', false);

  var aeropay = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'aeropay', false);

  var check = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'check', false);

  var dutchiePay = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'dutchiePay', true);

  var rethinkPay = _.get(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.paymentTypesAccepted, 'rethinkPay', false);

  return linx || creditCardNoInfo || creditCardByPhone || debitCard || alt36 || chaseOnline || hypurOnline || canPay || merrcoOnline || monerisOnline || paytender || aeropay || check || dutchiePay || rethinkPay;
};
export var getAcceptedPaymentOptions = function getAcceptedPaymentOptions(dispensary) {
  if (!dispensary) {
    return {
      cash: true,
      debit: false,
      credit: false
    };
  } // get a list of all payment types enabled for any enabled order type, excluding kiosk


  var orderTypesConfigV2 = dispensary.orderTypesConfigV2;
  var cleanConfigs = removeTypename(orderTypesConfigV2);

  var nonKioskConfigs = _.filter(_.omit(cleanConfigs, 'kiosk'), function (config) {
    return config.enableASAPOrdering || config.enableScheduledOrdering;
  });

  var nonKioskPaymentTypes = _.map(nonKioskConfigs, "paymentTypes");

  var acceptedPaymentTypes = _.pickBy(_.assignInWith.apply(_, [{}].concat(_toConsumableArray(nonKioskPaymentTypes), [function (a, b) {
    return a || b;
  }])));

  return acceptedPaymentTypes;
};
export var getPaymentFeeForMethod = function getPaymentFeeForMethod(paymentMethod) {
  var paymentFees = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return _.find(paymentFees, function (feeEntry) {
    return feeEntry.paymentType === paymentMethod || feeEntry.paymentType === 'creditCardAtDoor' && paymentMethod === 'creditCard' || feeEntry.paymentType === 'debit' && paymentMethod === 'debitCard' || feeEntry.paymentType === 'debitOnly' && paymentMethod === 'debit';
  });
};
export var dispensaryOpenStatus = function dispensaryOpenStatus(dispensary, openInfo) {
  var pickup = openInfo.pickup,
      delivery = openInfo.delivery;

  if (pickup.isOpen || delivery.isOpen) {
    return 'open';
  }

  if (pickup.isClosed && dispensary.offerAnyPickupService && dispensary.storeSettings.enableAfterHoursOrderingForPickup) {
    return 'closedButAfterHours';
  }

  return 'closed';
};
export var dispensaryStatusForUser = function dispensaryStatusForUser(dispensary, openInfo) {
  var _openInfo$nextOpenSer;

  var inDeliveryRange = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var orderTypesConfigV2 = dispensary.orderTypesConfigV2;

  var _ref6 = orderTypesConfigV2 || {},
      offerAnyPickupService = _ref6.offerAnyPickupService,
      offerDeliveryService = _ref6.offerDeliveryService;

  var inStorePickup = openInfo.inStorePickup,
      curbsidePickup = openInfo.curbsidePickup,
      driveThruPickup = openInfo.driveThruPickup,
      delivery = openInfo.delivery;
  var pickupIsOpen = inStorePickup.isOpen || curbsidePickup.isOpen || driveThruPickup.isOpen;
  var afterHoursForPickup = acceptingAfterHoursForPickup(dispensary);
  var afterHoursForDelivery = acceptingAfterHoursForDelivery(dispensary);
  var nextOpenService = getNextOpenService(dispensary, openInfo);
  var nextOpenServiceString = (_openInfo$nextOpenSer = openInfo[nextOpenService].nextService.openString) !== null && _openInfo$nextOpenSer !== void 0 ? _openInfo$nextOpenSer : 'Next service opportunity unknown';
  var useOpensAt = nextOpenServiceString.search('unknown') === -1;

  if (!offerAnyPickupService && !offerDeliveryService) {
    return 'This dispensary is currently closed.';
  }

  if (offerDeliveryService && delivery.isOpen && !pickupIsOpen && afterHoursForPickup) {
    return 'Delivery available, Pickup available for pre-order';
  }

  if (offerAnyPickupService && pickupIsOpen && delivery.isClosed && afterHoursForDelivery) {
    return 'Pickup available, Delivery available for pre-order';
  }

  if (!pickupIsOpen && delivery.isClosed && (afterHoursForPickup || afterHoursForDelivery)) {
    var afterHourOrderTypesAvailable = function () {
      if (afterHoursForPickup && afterHoursForDelivery) {
        return '';
      }

      if (afterHoursForDelivery) {
        return ' Delivery';
      }

      return ' Pickup';
    }();

    return "Closed,".concat(afterHourOrderTypesAvailable, " available for pre-order");
  }

  if (!pickupIsOpen && (!offerDeliveryService || delivery.isClosed)) {
    return "Closed.".concat(useOpensAt ? ' Opens at' : '', " ").concat(nextOpenServiceString);
  }

  if (offerDeliveryService && inDeliveryRange) {
    if (delivery.isOpen && pickupIsOpen) {
      return 'Delivery + Pickup available';
    }

    if (delivery.isOpen && !offerAnyPickupService) {
      return 'Delivery available';
    }

    if (delivery.isClosed && pickupIsOpen) {
      return 'Delivery closed, Pickup available';
    }

    if (offerAnyPickupService && !pickupIsOpen && delivery.isOpen) {
      return 'Delivery available, Pickup closed';
    }
  }

  if (pickupIsOpen) {
    return 'Pickup available';
  }

  if (offerDeliveryService && !inDeliveryRange) {
    return 'Delivery unavailable';
  }

  return "".concat(useOpensAt ? 'Opens at' : '', " ").concat(nextOpenServiceString);
}; // this is just used to return the first available open pickup type

export var openInfoForPickup = function openInfoForPickup(openInfo) {
  var _pickupInfo;

  var pickupInfo = openInfo.inStorePickup;

  var otherPickupTypesInfo = _.pick(openInfo, ['curbsidePickup', 'driveThruPickup']);

  if ((_pickupInfo = pickupInfo) !== null && _pickupInfo !== void 0 && _pickupInfo.isClosed) {
    pickupInfo = _.head(_.toPairs(_.pickBy(otherPickupTypesInfo, ['isOpen', true])));
  }

  return _.isEmpty(pickupInfo) || !pickupInfo[0] ? {
    type: 'inStorePickup',
    info: openInfo.inStorePickup
  } : {
    type: pickupInfo[0],
    info: pickupInfo[1]
  };
}; // The next set of helpers is used to determine if after-hours
// or scheduled ordering is enabled for a dispensary.

export var scheduledOrderingEnabledForDelivery = function scheduledOrderingEnabledForDelivery(dispensary) {
  var _dispensary$orderType, _dispensary$orderType2;

  if (!dispensary) {
    return false;
  }

  return (_dispensary$orderType = dispensary.orderTypesConfigV2) === null || _dispensary$orderType === void 0 ? void 0 : (_dispensary$orderType2 = _dispensary$orderType.delivery) === null || _dispensary$orderType2 === void 0 ? void 0 : _dispensary$orderType2.enableScheduledOrdering;
};
export var scheduledOrderingEnabledForPickup = function scheduledOrderingEnabledForPickup(dispensary) {
  var _dispensary$orderType3, _dispensary$orderType4, _dispensary$orderType5, _dispensary$orderType6, _dispensary$orderType7, _dispensary$orderType8;

  if (!dispensary) {
    return false;
  }

  return ((_dispensary$orderType3 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType3 === void 0 ? void 0 : (_dispensary$orderType4 = _dispensary$orderType3.inStorePickup) === null || _dispensary$orderType4 === void 0 ? void 0 : _dispensary$orderType4.enableScheduledOrdering) || ((_dispensary$orderType5 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType5 === void 0 ? void 0 : (_dispensary$orderType6 = _dispensary$orderType5.curbsidePickup) === null || _dispensary$orderType6 === void 0 ? void 0 : _dispensary$orderType6.enableScheduledOrdering) || ((_dispensary$orderType7 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType7 === void 0 ? void 0 : (_dispensary$orderType8 = _dispensary$orderType7.driveThruPickup) === null || _dispensary$orderType8 === void 0 ? void 0 : _dispensary$orderType8.enableScheduledOrdering);
};
export var afterHoursEnabledForDelivery = function afterHoursEnabledForDelivery(dispensary) {
  var _dispensary$orderType9, _dispensary$orderType10;

  if (!dispensary) {
    return false;
  }

  return (_dispensary$orderType9 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType9 === void 0 ? void 0 : (_dispensary$orderType10 = _dispensary$orderType9.delivery) === null || _dispensary$orderType10 === void 0 ? void 0 : _dispensary$orderType10.enableAfterHoursOrdering;
};
export var afterHoursEnabledForPickup = function afterHoursEnabledForPickup(dispensary) {
  var _dispensary$orderType11, _dispensary$orderType12, _dispensary$orderType13, _dispensary$orderType14, _dispensary$orderType15, _dispensary$orderType16;

  if (!dispensary) {
    return false;
  }

  return ((_dispensary$orderType11 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType11 === void 0 ? void 0 : (_dispensary$orderType12 = _dispensary$orderType11.inStorePickup) === null || _dispensary$orderType12 === void 0 ? void 0 : _dispensary$orderType12.enableAfterHoursOrdering) || ((_dispensary$orderType13 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType13 === void 0 ? void 0 : (_dispensary$orderType14 = _dispensary$orderType13.curbsidePickup) === null || _dispensary$orderType14 === void 0 ? void 0 : _dispensary$orderType14.enableAfterHoursOrdering) || ((_dispensary$orderType15 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType15 === void 0 ? void 0 : (_dispensary$orderType16 = _dispensary$orderType15.driveThruPickup) === null || _dispensary$orderType16 === void 0 ? void 0 : _dispensary$orderType16.enableAfterHoursOrdering);
}; // The next set of helpers is used to determine if a dispensary should currently be taking after hours
// orders, based on the current hours settings and whether after-hours or scheduled ordering are enabled.

export var acceptingAfterHoursForDelivery = function acceptingAfterHoursForDelivery(dispensary) {
  if (!dispensary) {
    return false;
  }

  var orderTypesConfigV2 = dispensary.orderTypesConfigV2,
      status = dispensary.status;
  var afterHoursDeliveryEnabled = afterHoursEnabledForDelivery(dispensary);
  var scheduledDeliveryEnabled = scheduledOrderingEnabledForDelivery(dispensary);

  var _openInfoForDispensar = openInfoForDispensary(dispensary, {}),
      deliveryOpenInfoV2 = _openInfoForDispensar.delivery;

  return status === 'open' && orderTypesConfigV2.offerDeliveryService && deliveryOpenInfoV2.isClosed && deliveryOpenInfoV2.nextService.openMoment && afterHoursDeliveryEnabled && scheduledDeliveryEnabled;
};
export var acceptingAfterHoursForPickup = function acceptingAfterHoursForPickup(dispensary) {
  var _orderTypesConfigV2$i, _orderTypesConfigV2$c, _orderTypesConfigV2$d5;

  if (!dispensary) {
    return false;
  }

  var orderTypesConfigV2 = dispensary.orderTypesConfigV2,
      status = dispensary.status;
  var offerAnyPickupService = orderTypesConfigV2.offerAnyPickupService;

  var _openInfoForDispensar2 = openInfoForDispensary(dispensary, {}),
      inStorePickup = _openInfoForDispensar2.inStorePickup,
      curbsidePickup = _openInfoForDispensar2.curbsidePickup,
      driveThruPickup = _openInfoForDispensar2.driveThruPickup;

  var inStoreOpenForPreOrder = inStorePickup.isClosed && (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : (_orderTypesConfigV2$i = orderTypesConfigV2.inStorePickup) === null || _orderTypesConfigV2$i === void 0 ? void 0 : _orderTypesConfigV2$i.enableAfterHoursOrdering) && inStorePickup.nextService.openMoment;
  var curbsideOpenForPreOrder = curbsidePickup.isClosed && (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : (_orderTypesConfigV2$c = orderTypesConfigV2.curbsidePickup) === null || _orderTypesConfigV2$c === void 0 ? void 0 : _orderTypesConfigV2$c.enableAfterHoursOrdering) && curbsidePickup.nextService.openMoment;
  var driveThruOpenForPreOrder = driveThruPickup.isClosed && (orderTypesConfigV2 === null || orderTypesConfigV2 === void 0 ? void 0 : (_orderTypesConfigV2$d5 = orderTypesConfigV2.driveThruPickup) === null || _orderTypesConfigV2$d5 === void 0 ? void 0 : _orderTypesConfigV2$d5.enableAfterHoursOrdering) && driveThruPickup.nextService.openMoment;
  var afterHoursPickupEnabled = inStoreOpenForPreOrder || curbsideOpenForPreOrder || driveThruOpenForPreOrder;
  return status === 'open' && offerAnyPickupService && afterHoursPickupEnabled;
};

var formatTime = function formatTime(timeStr) {
  return (timeStr || '').replace(/^00/, '12').replace(' ', '').replace(':00', '').replace('AM', 'am').replace('PM', 'pm');
};

export var dispensaryHoursForDay = function dispensaryHoursForDay(day) {
  if (_.isUndefined(day)) {
    return 'Closed';
  }

  var active = day.active,
      start = day.start,
      end = day.end;

  if (!active) {
    return 'Closed';
  }

  if (start === 'open' && end === 'open') {
    return 'Open 24 Hours';
  }

  if (start === end) {
    return 'Closed';
  }

  return "".concat(formatTime(day.start), " - ").concat(formatTime(day.end));
};
export var getEffectiveHoursForOrderType = function getEffectiveHoursForOrderType(operatingHoursForType, specialHoursForType, timezone) {
  var effectiveHours = {};
  var specialHoursDays = getSpecialHoursDays(specialHoursForType, timezone);

  var specialHoursThisWeek = _.filter(specialHoursDays, function (_ref7) {
    var date = _ref7.date;
    return moment.tz(date, timezone).isSame(moment().tz(timezone), 'week');
  });

  if (specialHoursThisWeek) {
    _.forEach(specialHoursThisWeek, function (day) {
      _.set(effectiveHours, moment.tz(day.date, timezone).format('dddd'), day.hours);
    });
  }

  return _objectSpread(_objectSpread({}, operatingHoursForType), effectiveHours);
};
export function clearDependentSettingsIfFeatureDisabled(_ref8) {
  var profile = _ref8.profile;

  _.forEach(_.keys(mutuallyInclusiveFeatures), function (key) {
    var feature = mutuallyInclusiveFeatures[key];

    var featureEnabled = _.get(profile, feature.path, false);

    if (!featureEnabled) {
      _.forEach(feature.dependentSettingPaths, function (path) {
        _.set(profile, path, false);
      });
    }
  });
}
export function generateAcceptedPaymentsString(dispensary) {
  var _dispensary$paymentTy = dispensary.paymentTypesAccepted,
      credit = _dispensary$paymentTy.credit,
      debit = _dispensary$paymentTy.debit,
      cash = _dispensary$paymentTy.cash,
      check = _dispensary$paymentTy.check,
      linx = _dispensary$paymentTy.linx,
      canPay = _dispensary$paymentTy.canPay,
      paytender = _dispensary$paymentTy.paytender,
      aeropay = _dispensary$paymentTy.aeropay,
      dutchiePay = _dispensary$paymentTy.dutchiePay,
      rethinkPay = _dispensary$paymentTy.rethinkPay;
  var paymentOpts = [];

  if (cash) {
    paymentOpts.push('Cash');
  }

  if (credit) {
    paymentOpts.push('Credit');
  }

  if (debit) {
    paymentOpts.push('Debit');
  }

  if (check) {
    paymentOpts.push('Check');
  }

  if (linx) {
    paymentOpts.push('Linx');
  }

  if (canPay) {
    paymentOpts.push('CanPay');
  }

  if (paytender) {
    paymentOpts.push('Paytender');
  }

  if (aeropay) {
    paymentOpts.push('Aeropay');
  }

  if (dutchiePay) {
    paymentOpts.push('dutchiePay');
  }

  if (rethinkPay) {
    paymentOpts.push('rethinkPay');
  }

  return paymentOpts.length >= 1 ? paymentOpts.join(', ') : 'Cash';
}
export var getCategoryPhoto = function getCategoryPhoto(dispensaryProfile) {
  var _$find, _$find2, _dispensaryProfile$we, _dispensaryProfile$we2, _dispensaryProfile$lo, _ref9;

  var category = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var photoFromDispensaryProfile = (_$find = _.find(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : dispensaryProfile.categoryPhotos, {
    category: category.value
  })) === null || _$find === void 0 ? void 0 : _$find.src;
  var v2CategoryPhoto = (_$find2 = _.find(dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : (_dispensaryProfile$we = dispensaryProfile.webCustomizationSettingsV2) === null || _dispensaryProfile$we === void 0 ? void 0 : (_dispensaryProfile$we2 = _dispensaryProfile$we.categories) === null || _dispensaryProfile$we2 === void 0 ? void 0 : _dispensaryProfile$we2.items, {
    category: category.value
  })) === null || _$find2 === void 0 ? void 0 : _$find2.image;
  var defaultPhoto = (dispensaryProfile === null || dispensaryProfile === void 0 ? void 0 : (_dispensaryProfile$lo = dispensaryProfile.location) === null || _dispensaryProfile$lo === void 0 ? void 0 : _dispensaryProfile$lo.state) === 'UT' && category.value === 'Edible' ? 'https://images.dutchie.com/category-stock-photos/edibles/edibles-gummies.png' : defaultCategoryPhotos[category.value];
  return (_ref9 = v2CategoryPhoto !== null && v2CategoryPhoto !== void 0 ? v2CategoryPhoto : photoFromDispensaryProfile) !== null && _ref9 !== void 0 ? _ref9 : defaultPhoto;
};