import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash'; // eslint-disable-next-line import/no-extraneous-dependencies

import Big from 'big.js';

function sumConditionSatisfiersTotal(conditionsManager, discountTaxOrder) {
  var originalTotalPrice = Big(0); // Iterate over condition-satisfying products to determine originalTotalPrice and totalQuantity

  _.forEach(conditionsManager.satisfiers, function (_ref) {
    var _item$mixAndMatch;

    var item = _ref.item,
        quantity = _ref.quantity;
    var adjustedBasePrice = Big(((_item$mixAndMatch = item.mixAndMatch) === null || _item$mixAndMatch === void 0 ? void 0 : _item$mixAndMatch.adjustedBasePrice) || item.basePrice || 0);
    originalTotalPrice = originalTotalPrice.add( // Multiply basePrice by the tax mult to account for taxes in the menu or from POS for taxesFirst setting
    adjustedBasePrice.times(discountTaxOrder === 'taxesFirst' ? item.basePriceMult || 1 : 1).times(quantity));
  });

  return originalTotalPrice;
}

function removeItemForOverride(_ref2) {
  var conditionSatisfiers = _ref2.conditionSatisfiers,
      items = _ref2.items;

  var itemKeyToRemove = _.keys(conditionSatisfiers)[0];

  var _$filter = _.filter(items, ['key', itemKeyToRemove]),
      _$filter2 = _slicedToArray(_$filter, 1),
      itemToBeAltered = _$filter2[0];

  var restOfItems = _.reject(items, ['key', itemKeyToRemove]);

  if (itemToBeAltered.quantity - 1 < 1) {
    return restOfItems;
  }

  restOfItems.push(_objectSpread(_objectSpread({}, itemToBeAltered), {}, {
    quantity: itemToBeAltered.quantity - 1
  }));
  return restOfItems;
}

var issueItemsForAPriceRewards = function issueItemsForAPriceRewards(bogoCalcsData, bogoSpecial, conditionsManager, discountTaxOrder, items, redemptionIndex) {
  var itemsForAPrice = bogoSpecial.itemsForAPrice,
      specialId = bogoSpecial.specialId; // since we're calling this function again on ln56 we need another check here to make sure we're not dividing by 0

  if (!conditionsManager.satisfied) {
    return {};
  }

  var discountedTotalPrice = Big((itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.value) || 0);
  var originalTotalPrice = sumConditionSatisfiersTotal(conditionsManager, discountTaxOrder);
  var targetIndividualDiscountPercentage = originalTotalPrice.minus(discountedTotalPrice).div(originalTotalPrice); // If the discount would raise the price of the items rather than lower it, rerun the conditionsManager without the cheapest item

  if (targetIndividualDiscountPercentage.lte(0)) {
    // add util func here to remove only 1 qty of an item to send back in, if zero remove item
    var itemsOverride = removeItemForOverride({
      conditionSatisfiers: conditionsManager.satisfiers,
      items: items
    });

    var _conditionsManager$ev = conditionsManager.evaluate({
      itemsOverride: itemsOverride
    });

    conditionsManager.satisfiers = _conditionsManager$ev.satisfiers;
    conditionsManager.satisfied = _conditionsManager$ev.satisfied;
    return issueItemsForAPriceRewards(bogoCalcsData, bogoSpecial, conditionsManager, discountTaxOrder, itemsOverride, redemptionIndex);
  }

  var rewardsSatisfiers = {}; // Iterate over items to determine applicableQuantity & maximum individualDiscount while preserving order

  _.forEach(items, function (item) {
    var conditionSatisfier = conditionsManager.satisfiers[item.key];

    if (!_.isNil(conditionSatisfier)) {
      var _item$mixAndMatch2;

      var quantity = conditionSatisfier.quantity;
      var adjustedBasePrice = Big(((_item$mixAndMatch2 = item.mixAndMatch) === null || _item$mixAndMatch2 === void 0 ? void 0 : _item$mixAndMatch2.adjustedBasePrice) || item.basePrice || 0);
      var discount = adjustedBasePrice.times(discountTaxOrder === 'taxesFirst' ? item.basePriceMult || 1 : 1).times(targetIndividualDiscountPercentage);

      if (discount.gt(adjustedBasePrice)) {
        discount = adjustedBasePrice;
      }

      var rewardId = itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice._id;
      var redemptionNumber = redemptionIndex + 1;
      var rewardIdKey = "".concat(rewardId, "__").concat(redemptionNumber);
      rewardsSatisfiers[item.key] = {
        item: item,
        quantity: quantity,
        rewards: _defineProperty({}, rewardIdKey, {
          quantity: quantity,
          reward: {
            _id: rewardId,
            dollarDiscount: Big(discount),
            itemsForAPrice: _objectSpread({}, itemsForAPrice)
          },
          specialId: specialId,
          discountStacking: bogoSpecial.discountStacking,
          discountPrecedence: bogoSpecial.discountPrecedence,
          stackingBehavior: bogoSpecial.stackingBehavior,
          nonStackingBehavior: bogoSpecial.nonStackingBehavior,
          settingsDerivedFrom: bogoSpecial.settingsDerivedFrom
        })
      };
    }
  });

  return rewardsSatisfiers;
};

export default issueItemsForAPriceRewards;