import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import useUI from 'src/hooks/use-ui';
import { SocialLoginProps } from './social-login.types';

export const SocialLogin = ({
  redirectUrl,
  autoLogin = false,
  onSubmit,
  className,
  icon,
  provider,
  buttonText,
}: SocialLoginProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const { isEmbedded } = useUI();
  const buttonTestId = `social-login-button-${provider}`;
  const formTestId = `social-login-form-${provider}`;

  useEffect(() => {
    if (autoLogin && formRef.current) {
      formRef.current.submit();
    }
  }, [autoLogin]);

  const handleEmbeddedMenuLogin = (): void => {
    onSubmit?.();

    window.open(
      `/oauth/auto-login?provider=${provider}&redirectUrl=${redirectUrl}`,
      '_blank',
      'width=400,height=600,toolbar=0,location=0,menubar=0'
    );
  };

  // For the initial embedded menu button, we need to open a new popup window instead of submitting form
  if (isEmbedded && !autoLogin) {
    return (
      <Button type='button' onClick={handleEmbeddedMenuLogin} className={className} data-testid={buttonTestId}>
        {icon}
        <span>{buttonText}</span>
      </Button>
    );
  }

  return (
    <>
      <form
        method='post'
        action={`/api/v2/auth/${provider}`}
        ref={formRef}
        onSubmit={onSubmit}
        className={className}
        data-testid={formTestId}
      >
        <input type='hidden' name='redirect_url' value={redirectUrl} />

        {!autoLogin && (
          <Button type='submit'>
            {icon}
            <span>{buttonText}</span>
          </Button>
        )}
      </form>
    </>
  );
};

const Button = styled.button`
  align-items: center;
  appearance: none;
  background: #fff;
  border-radius: ${({ theme }) => theme.customized.radius.buttons};
  border: 1px solid #747775;
  color: #1f1f1f;
  cursor: pointer;
  display: flex;
  font: 500 14px / ${20 / 14} 'Roboto', 'Matter', arial, sans-serif;
  gap: 8px;
  justify-content: center;
  padding: 14px;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background: #30303014;
    box-shadow: 0 1px 2px 0 #3c40434d, 0px 1px 3px 1px #3c404326;
  }
`;
