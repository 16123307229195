import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useDispensaryChains } from 'src/dispensary/hooks/use-dispensary-chain';

import useLazyRemoteModule from 'src/utils/module-federation/use-lazy-remote-module';
import { prepareDataForRemote } from './data-interface';
import { DataBridgeVersion } from './types';

export function findRemoteThemeMapping(config = {}, { chainId, dispensaryId }) {
  // First check dispensaryId mapping
  if (config.byDispensaryId) {
    const dispensaryMapping = config.byDispensaryId.find((mapping) => Object.keys(mapping)[0] === dispensaryId);
    if (dispensaryMapping) {
      return dispensaryMapping[dispensaryId];
    }
  }

  // Then check chainId mapping
  if (config.byChainId) {
    const chainMapping = config.byChainId.find((mapping) => Object.keys(mapping)[0] === chainId);
    if (chainMapping) {
      return chainMapping[chainId];
    }
  }

  return null;
}

const withProps = (Component, partialProps) => {
  return (props) => <Component {...partialProps} {...props} />;
};

const remoteComponentIsValid = (Component) => {
  if (!Component) {
    return false;
  }

  if (Component.DataBridgeVersion !== DataBridgeVersion) {
    console.warn(
      `Remote component (${Component.name} v${Component.DataBridgeVersion}) is not compatible with Dutchie Commerce Components data bridge host version: ${DataBridgeVersion}`
    );
    return false;
  }
  return true;
};

export function useRemoteCommerceComponents() {
  const flags = useFlags();
  const { dispensary } = useDispensary();

  const remoteThemeConfig = flags['growth.ecomm.remote-theme-config'] ?? { byChainId: [], byDispensaryId: [] };

  const themeNameSpace = findRemoteThemeMapping(remoteThemeConfig, {
    chainId: dispensary?.chain,
    dispensaryId: dispensary?.id,
  });

  const remoteThemeModule = useLazyRemoteModule({
    scope: '@dutchie/customer-themes',
    module: `themes/${themeNameSpace}`,
    fallback: { default: () => {} },
    skip: !themeNameSpace,
  });

  const { dispensaryChainLocations } = useDispensaryChains({
    activeOnly: true,
    includeStealthMode: true,
    skip: !themeNameSpace,
  });

  const remoteData = prepareDataForRemote(dispensary, dispensaryChainLocations);

  const getCommerceComponentsTheme = (styledTheme) => ({
    components: {
      storeFront: {
        header: ({ fallback }) =>
          remoteComponentIsValid(remoteThemeModule.StoreFrontHeader)
            ? withProps(remoteThemeModule.StoreFrontHeader, { theme: styledTheme, data: remoteData })
            : fallback,
        footer: ({ fallback }) =>
          remoteComponentIsValid(remoteThemeModule.StoreFrontFooter)
            ? withProps(remoteThemeModule.StoreFrontFooter, { theme: styledTheme, data: remoteData })
            : fallback,
      },
    },
  });

  return {
    getCommerceComponentsTheme,
    remoteThemeModule,
    themeNameSpace,
  };
}
