import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _excluded = ["applyRewardsToConditions"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import moment from 'moment-timezone';
import { v4 } from 'uuid'; // eslint-disable-next-line import/no-extraneous-dependencies

import Big from 'big.js';
import { ProductGroupPropertyKey } from './schema-mapper.types';
import { getWeightAndUnits } from '../../products';
import { DIRECT_COPY_FIELDS, ENUM_FIELDS } from './constants';
import { doRewardsHaveRestrictions, isAdvancedRewardEnabled, shouldUseWeightRestrictions } from './helpers';
export var mapApplicationType = function mapApplicationType(applicationType) {
  switch (applicationType) {
    case 'quantity':
      return 'QUANTITY';

    case 'totalQuantity':
      return 'TOTAL_QUANTITY';

    default:
      console.error('invalid applicationType provided');
      return applicationType;
  }
};
export var mapApplicationRules = function mapApplicationRules(_ref) {
  var _ref$applicationRules = _ref.applicationRules,
      applicationRules = _ref$applicationRules === void 0 ? [] : _ref$applicationRules;
  return _.map(applicationRules, function (_ref2) {
    var applicationType = _ref2.applicationType,
        comparisonOperator = _ref2.comparisonOperator,
        quantity = _ref2.quantity;
    return _objectSpread(_objectSpread(_objectSpread({}, applicationType ? {
      applicationType: mapApplicationType(applicationType)
    } : {}), comparisonOperator ? {
      comparisonOperator: mapComparisonOperator(comparisonOperator)
    } : {}), quantity ? {
      quantity: mapQuantity(quantity)
    } : {});
  });
};
export var mapApplyRewardsToConditions = function mapApplyRewardsToConditions(_ref3, v4Rewards) {
  var applyRewardsToConditions = _ref3.applyRewardsToConditions,
      special = _objectWithoutProperties(_ref3, _excluded);

  return applyRewardsToConditions || isAdvancedRewardEnabled(special) && !doRewardsHaveRestrictions(v4Rewards);
};
export var mapComparisonOperator = function mapComparisonOperator() {
  var comparisonOperator = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'equalTo';

  switch (comparisonOperator) {
    case 'equalTo':
      return 'EQUAL_TO';

    case 'greaterThan':
      return 'GREATER_THAN';

    case 'greaterThanEqualTo':
      return 'GREATER_THAN_OR_EQUAL_TO';

    case 'lessThan':
      return 'LESS_THAN';

    case 'lessThanEqualTo':
      return 'LESS_THAN_OR_EQUAL_TO';

    default:
      console.error('invalid comparison operator provided');
      return comparisonOperator;
  }
};
export var mapConsumerTypes = function mapConsumerTypes(menuType) {
  switch (menuType) {
    case 'both':
      return [{
        id: 'medical',
        isMedical: true
      }, {
        id: 'recreational',
        isMedical: false
      }];

    case 'medical':
      return [{
        id: 'medical',
        isMedical: true
      }];

    case 'recreational':
      return [{
        id: 'recreational',
        isMedical: false
      }];

    default:
      return null;
  }
};
export var mapDirectCopyFields = function mapDirectCopyFields(special) {
  return _.reduce(DIRECT_COPY_FIELDS, function (acc, field) {
    // get the names of the v3 and v4 fields
    // eslint-disable-next-line camelcase
    var v3dot5field = field.v3dot5field,
        v4Field = field.v4Field;
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, v4Field, special[v3dot5field]));
  }, {
    name: ''
  });
};
export var mapEndedAt = function mapEndedAt(_ref4) {
  var endStamp = _ref4.endStamp;
  var endedAt = moment.utc(endStamp).format();
  return endedAt === 'Invalid date' ? null : endedAt;
};
export var mapEnumFields = function mapEnumFields(special) {
  return _.reduce(ENUM_FIELDS, function (acc, field) {
    // get the names of the v3 and v4 fields and the enumMap
    var v3dot5field = field.v3dot5field,
        v4Field = field.v4Field,
        enumMap = field.enumMap;
    var v3dot5FieldValue = special[v3dot5field];
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, v4Field, enumMap[v3dot5FieldValue]));
  }, {
    precedence: 'LOW_TO_HIGH'
  });
};
export var mapExclusions = function mapExclusions(specialItem) {
  var _specialItem$exclusio;

  return _.flatMap((_specialItem$exclusio = specialItem === null || specialItem === void 0 ? void 0 : specialItem.exclusions) !== null && _specialItem$exclusio !== void 0 ? _specialItem$exclusio : [], function (exclusion, index) {
    return mapInclusionsOrExclusions(exclusion, 'exclusions', index);
  });
}; // TODO: implement this function
// eslint-disable-next-line no-unused-vars

export var mapGlobalApplicationRules = function mapGlobalApplicationRules(special) {
  return [];
};
export var mapInclusions = function mapInclusions(specialItem) {
  return mapInclusionsOrExclusions(specialItem, 'inclusions', 0);
};
export var mapInclusionsOrExclusions = function mapInclusionsOrExclusions() {
  var specialItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var specialItemType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'inclusions';
  var specialItemIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  var newRestrictions = _.flatMap(_.keys(ProductGroupPropertyKey), function (key) {
    var _specialItem$productI;

    var productGroupId;
    var restrictionType;

    switch (key) {
      case 'inventoryTags':
        productGroupId = 'inventoryTag';
        restrictionType = 'INVENTORY_TAG';
        break;

      case 'productIds':
        productGroupId = 'productId';
        restrictionType = 'PRODUCT';
        break;

      case 'brandIds':
      case 'brandNames':
        productGroupId = 'brandId';
        restrictionType = 'BRAND';
        break;

      case 'categoryIds':
      case 'categoryNames':
        productGroupId = 'categoryId';
        restrictionType = 'CATEGORY';
        break;

      case 'subcategoryIds':
      case 'subcategoryNames':
        productGroupId = 'subCategoryId';
        restrictionType = 'SUB_CATEGORY';
        break;

      case 'strainIds':
        productGroupId = 'strainId';
        restrictionType = 'STRAIN';
        break;

      case 'vendorIds':
        productGroupId = 'vendorId';
        restrictionType = 'VENDOR';
        break;

      case 'productTags':
        productGroupId = 'productTag';
        restrictionType = 'PRODUCT_TAG';
        break;

      default:
        console.error('unable to set sale discount');
    } // TODO: temporary workaround until 4.0 model is updated to contain arrays of plural productGroups & weights
    // This is a workaround using exclusions since 4.0 currently does not support targeting specific weights for
    // particular products, categories, brands, etc within the same condition or reward object.
    // (ex: exclude only 2g for product A and only 3.5g for product B. As the model is defined today, within the same
    // condition or reward both 2g and 4g weights for either product A or product B would be excluded)
    // Discount sync is currently utilizing specials v3.5 exclusions to work around a similar limitation, which adds
    // exclusions with mixed product IDs and weights. If not for these additions exclusions would always be length 1 or
    // smaller, so we can rely on the 1+ restriction index (specialItemIndex) as a means to determine whether mixed
    // product ID and weight exclusions should be created in the v4 special.
    // See https://dutchie.atlassian.net/browse/ENG-53309, https://dutchie.atlassian.net/browse/ENG-53044, &
    // https://dutchie.atlassian.net/browse/ENG-52385 for details


    if (restrictionType === 'PRODUCT' && specialItemType === 'exclusions' && specialItemIndex > 0 && ((_specialItem$productI = specialItem.productIds) === null || _specialItem$productI === void 0 ? void 0 : _specialItem$productI.length) === 1 && shouldUseWeightRestrictions(specialItem.weights, specialItem.weightOperator)) {
      return mapProductAndWeightRestrictions(productGroupId, specialItem[key], restrictionType, specialItem.weights);
    }

    return mapRestrictions(productGroupId, specialItem[key], restrictionType);
  });

  if (specialItem.productGroup === 'all' || specialItem.productGroup === 'ALL') {
    newRestrictions.push({
      id: v4(),
      restrictionType: 'ALL'
    });
  } // TODO: temporary workaround until 4.0 model is updated to contain arrays of plural productGroups & weights
  // Another part of the aforementioned workaround. We can rely on the index because discount sync will always
  // create a default 0-index exclusion for most bogoCondition, bogoReward, or saleDiscount restrictions.
  // Any others are additional exclusions to target specific weights for particular products


  if (specialItemIndex < 1) {
    if (shouldUseWeightRestrictions(specialItem.weights, specialItem.weightOperator)) {
      newRestrictions = _.concat(newRestrictions, mapRestrictions('weight', specialItem.weights, 'WEIGHT'));
    }
  }

  return newRestrictions;
};
export var mapPriceThresholds = function mapPriceThresholds(_ref5) {
  var maxPrice = _ref5.maxPrice,
      price = _ref5.price,
      _ref5$priceOperator = _ref5.priceOperator,
      priceOperator = _ref5$priceOperator === void 0 ? 'equalTo' : _ref5$priceOperator,
      thresholdType = _ref5.thresholdType;

  if (thresholdType !== 'price' && thresholdType !== 'totalPrice') {
    console.error('invalid price threshold type');
  }

  if (priceOperator === 'between') {
    return [mapThreshold({
      comparisonOperator: 'greaterThanEqualTo',
      price: price,
      type: thresholdType
    }), mapThreshold({
      comparisonOperator: 'lessThanEqualTo',
      price: maxPrice,
      type: thresholdType
    })];
  }

  return [mapThreshold({
    comparisonOperator: priceOperator,
    price: price,
    type: thresholdType
  })];
};
export var mapQuantity = function mapQuantity(quantity) {
  return {
    amount: +quantity
  };
};
export var mapQuantityThresholds = function mapQuantityThresholds(_ref6) {
  var maxQuantity = _ref6.maxQuantity,
      quantity = _ref6.quantity,
      _ref6$quantityOperato = _ref6.quantityOperator,
      quantityOperator = _ref6$quantityOperato === void 0 ? 'equalTo' : _ref6$quantityOperato,
      thresholdType = _ref6.thresholdType;

  if (thresholdType !== 'quantity' && thresholdType !== 'totalQuantity') {
    console.error('invalid quantity threshold type');
  }

  if (quantityOperator === 'between') {
    return [mapThreshold({
      comparisonOperator: 'greaterThanEqualTo',
      quantity: quantity,
      type: thresholdType
    }), mapThreshold({
      comparisonOperator: 'lessThanEqualTo',
      quantity: maxQuantity,
      type: thresholdType
    })];
  }

  return [mapThreshold({
    comparisonOperator: quantityOperator,
    quantity: quantity,
    type: thresholdType
  })];
};

var mapRestrictions = function mapRestrictions(productGroupId) {
  var productGroupIdArray = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var restrictionType = arguments.length > 2 ? arguments[2] : undefined;
  return _.map(_.reject(productGroupIdArray, function (value) {
    return value === 'IGNORE';
  }), function (key) {
    if (restrictionType === 'SUB_CATEGORY') {
      var _key$match;

      var _ref7 = (_key$match = key.match(/^(.*)__(.*)$/i)) !== null && _key$match !== void 0 ? _key$match : [],
          _ref8 = _slicedToArray(_ref7, 3),
          parentChildSubcategoryKey = _ref8[0],
          category = _ref8[1],
          subcategory = _ref8[2];

      return _objectSpread(_defineProperty({
        id: v4(),
        restrictionType: restrictionType
      }, productGroupId, parentChildSubcategoryKey ? subcategory : key), parentChildSubcategoryKey ? {
        categoryId: category
      } : {});
    }

    return _defineProperty({
      id: v4(),
      restrictionType: restrictionType
    }, productGroupId, restrictionType === 'WEIGHT' ? mapWeight(key) : key);
  });
};

var mapProductAndWeightRestrictions = function mapProductAndWeightRestrictions(productGroupId) {
  var productGroupIdArray = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var restrictionType = arguments.length > 2 ? arguments[2] : undefined;
  var weights = arguments.length > 3 ? arguments[3] : undefined;
  return _.flatMap(_.reject(productGroupIdArray, function (value) {
    return value === 'IGNORE';
  }), function (key) {
    return _.map(weights, function (weight) {
      var _ref10;

      return _ref10 = {
        id: v4(),
        restrictionType: restrictionType
      }, _defineProperty(_ref10, productGroupId, key), _defineProperty(_ref10, "weight", mapWeight(weight)), _ref10;
    });
  });
};

export var mapSchedule = function mapSchedule() {
  var _special$recurring$da, _special$recurring, _special$recurring2, _special$recurring3, _special$recurring4;

  var special = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var dispensaryTimezone = arguments.length > 1 ? arguments[1] : undefined;
  return special.isRecurring ? {
    days: (_special$recurring$da = (_special$recurring = special.recurring) === null || _special$recurring === void 0 ? void 0 : _special$recurring.days) !== null && _special$recurring$da !== void 0 ? _special$recurring$da : [],
    endStamp: (_special$recurring2 = special.recurring) === null || _special$recurring2 === void 0 ? void 0 : _special$recurring2.endDate,
    endTime: (_special$recurring3 = special.recurring) === null || _special$recurring3 === void 0 ? void 0 : _special$recurring3.endTime,
    startTime: (_special$recurring4 = special.recurring) === null || _special$recurring4 === void 0 ? void 0 : _special$recurring4.startTime,
    timezone: dispensaryTimezone
  } : null;
};
export var mapSpecialId = function mapSpecialId(_ref11) {
  var _ref12;

  var _id = _ref11._id,
      id = _ref11.id,
      specialId = _ref11.specialId;
  return (_ref12 = _id !== null && _id !== void 0 ? _id : id) !== null && _ref12 !== void 0 ? _ref12 : specialId;
};
export var mapStackingBehavior = function mapStackingBehavior(_ref13) {
  var stackingBehavior = _ref13.stackingBehavior,
      stackingMode = _ref13.stackingMode;
  return stackingMode === 'stacking' ? _.upperCase(stackingBehavior) : 'PROHIBITED';
};
/**
 * maps any kind of threshold for global conditions, global rewards, conditions, and rewards
 */

export var mapThreshold = function mapThreshold(_ref14) {
  var comparisonOperator = _ref14.comparisonOperator,
      price = _ref14.price,
      quantity = _ref14.quantity,
      type = _ref14.type,
      weight = _ref14.weight;
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, comparisonOperator ? {
    comparisonOperator: mapComparisonOperator(comparisonOperator)
  } : {}), price ? {
    price: +Big(price).times(100)
  } : {}), quantity ? {
    quantity: mapQuantity(quantity)
  } : {}), type ? {
    type: mapThresholdType(type)
  } : {}), weight ? {
    weight: mapWeight(weight)
  } : {});
};
export var mapThresholdType = function mapThresholdType(thresholdType) {
  switch (thresholdType) {
    case 'price':
      return 'PRICE';

    case 'quantity':
      return 'QUANTITY';

    case 'totalPrice':
      return 'TOTAL_PRICE';

    case 'totalQuantity':
      return 'TOTAL_QUANTITY';

    case 'totalWeight':
      return 'TOTAL_WEIGHT';

    case 'weight':
      return 'WEIGHT';

    default:
      console.error('invalid threshold provided');
      return thresholdType;
  }
};
export var mapThresholds = function mapThresholds(_ref15) {
  var quantity = _ref15.quantity,
      _ref15$quantityOperat = _ref15.quantityOperator,
      quantityOperator = _ref15$quantityOperat === void 0 ? 'equalTo' : _ref15$quantityOperat,
      totalQuantity = _ref15.totalQuantity,
      totalSpend = _ref15.totalSpend,
      totalWeight = _ref15.totalWeight;
  var thresholds = [];

  if (quantity) {
    thresholds = _.concat(thresholds, mapQuantityThresholds({
      quantity: quantity,
      quantityOperator: quantityOperator,
      thresholdType: 'quantity'
    }));
  }

  if (totalQuantity !== null && totalQuantity !== void 0 && totalQuantity.enabled) {
    thresholds = _.concat(thresholds, mapQuantityThresholds(_objectSpread(_objectSpread({}, totalQuantity), {}, {
      thresholdType: 'totalQuantity'
    })));
  }

  if (totalSpend !== null && totalSpend !== void 0 && totalSpend.enabled) {
    thresholds = _.concat(thresholds, mapPriceThresholds({
      maxPrice: totalSpend.maximumSpend,
      price: totalSpend.minimumSpend,
      priceOperator: totalSpend.spendOperator,
      thresholdType: 'totalPrice'
    }));
  } // TODO: ungate this maybe? ticket created here: https://dutchie.atlassian.net/browse/ENG-42654
  // if (false && shouldUseWeightThreshold(weights, weightOperator)) {
  //   thresholds = _.concat(
  //     thresholds,
  //     mapWeightThresholds({ weight: weights[0], weightOperator, thresholdType: 'weight' })
  //   );
  // }


  if (totalWeight !== null && totalWeight !== void 0 && totalWeight.enabled) {
    thresholds = _.concat(thresholds, mapWeightThresholds(_objectSpread(_objectSpread({}, totalWeight), {}, {
      thresholdType: 'totalWeight'
    })));
  }

  return thresholds;
};
export var mapWeight = function mapWeight(weight) {
  var weightAndUnits = getWeightAndUnits(weight);

  if (weightAndUnits) {
    return {
      amount: weightAndUnits.amount,
      weightUnit: mapWeightUnit(weightAndUnits.unit)
    };
  }

  return null;
};
export var mapWeightThresholds = function mapWeightThresholds(_ref16) {
  var thresholdType = _ref16.thresholdType,
      weight = _ref16.weight,
      _ref16$weightOperator = _ref16.weightOperator,
      weightOperator = _ref16$weightOperator === void 0 ? 'equalTo' : _ref16$weightOperator;

  if (thresholdType !== 'weight' && thresholdType !== 'totalWeight') {
    console.error('invalid weight threshold type');
  }

  return [mapThreshold({
    comparisonOperator: weightOperator,
    type: thresholdType,
    weight: weight
  })];
};
export var mapWeightUnit = function mapWeightUnit(weightUnit) {
  switch (weightUnit) {
    case 'g':
    case 'gC':
      return 'GRAM';

    case 'mg':
      return 'MILLIGRAM';

    case 'oz':
      return 'OUNCE';

    default:
      console.error('Invalid weight unit provided to mapWeightUnit: ', weightUnit);
      return null;
  }
};