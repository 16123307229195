import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ProductRecommendationSection } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { ProductCarousel } from 'src/components/product-carousel';
import { ProductCarousel as ProductCarouselV2 } from 'src/components/carousel-v2/types/product-carousel';
import { GqlMenuSection } from 'types/graphql';
import { useRecentlyViewedCarousel } from './use-recently-viewed-carousel';

export type RecentlyViewedCarouselProps = {
  section?: GqlMenuSection;
  sectionIndex?: number;
  dispensaryId: string;
  excludeProductId?: string;
};

export function RecentlyViewedCarousel(props: RecentlyViewedCarouselProps): JSX.Element | null {
  const { section, dispensaryId, excludeProductId } = props;
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;
  const label = `Recently Viewed`;

  const carouselProps = useRecentlyViewedCarousel({
    dispensaryId,
    excludeProductId,
  });
  const { products, loading } = carouselProps;

  if (!loading && !products.length) {
    return null;
  }

  if (isProductCardsV2Enabled) {
    return <ProductCarouselV2 products={products} isLoading={loading} title={section?.label ?? label} />;
  }

  return (
    <ProductCarousel
      heading={section?.label ?? label}
      trackerSection={ProductRecommendationSection.recentlyViewed}
      {...carouselProps}
    />
  );
}
