import _ from 'lodash';
import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useCart from 'shared/hooks/use-cart';
import useDispensarySpecials from 'src/dispensary/hooks/use-dispensary-specials';
import usePaths from 'src/hooks/use-paths';
import { OfferCarousel as OfferCarouselV2 } from 'src/components/carousel-v2/types/offer-carousel';
import { OffersCarousel } from './offers-carousel';

export function SpecialOffersCarousel(props) {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const cart = useCart();
  const isMedical = useObserver(() => cart?.isMedical);
  const { section } = props;
  const { loading, specials } = useDispensarySpecials();
  const offers = _.filter(specials, [`specialType`, `bogo`]);
  const { href, route } = usePaths({ specials: true });
  const title = `Offers`;

  if (!offers?.length && !loading) {
    return null;
  }

  if (isProductCardsV2Enabled) {
    return (
      <OfferCarouselV2
        offers={offers}
        href={href}
        title={title}
        hideTitle={props?.showTitle === false}
        hideLink={props?.showLink === false}
      />
    );
  }

  const sectionProps = {
    ...props,
    carouselType: `offers`,
    displayGradient: false,
    isLoading: loading,
    isMedical,
    linkLabel: section.linkLabel,
    label: title,
    specials: offers,
    href,
    route,
  };
  return <OffersCarousel {...sectionProps} />;
}
