import styled from 'styled-components';

import { Typography } from 'src/typography';

export const Container = styled.div`
  border: 1px solid #e3e7e9;
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  width: 380px;

  @media screen and (min-width: 1110px) {
    width: 452px;
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => `${String(padding)}px ${String(padding)}px`};
  width: 100%;
`;

export const ListHeaderText = styled(Typography.Heading)`
  font-size: 18px;
`;

export const ListHeaderLink = styled.a`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 14px;
  font-weight: 600;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 390px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const gradientStyles = (isVisible: boolean): string => `
  height: 56px;
  left: 0;
  opacity: ${isVisible ? 1 : 0};
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 10;
`;

export const GradientStart = styled.div`
  ${({ isVisible }) => gradientStyles(isVisible)}
  background: linear-gradient(0deg, transparent 0%, #ffffff 100%);
  border-radius: 0;
  top: 0;
`;

export const GradientEnd = styled.div`
  ${({ isVisible }) => gradientStyles(isVisible)}
  background: linear-gradient(180deg, transparent 0%, #ffffff 100%);
  border-radius: 0 0 15px 15px;
  bottom: 0;
`;

export const ListPlaceholder = styled.div`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.grey[40]};
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  justify-content: center;
  margin-top: 20px;
  min-height: 175px;
  padding: 20px;

  p {
    line-height: 20px;
    margin: 5px 0 10px;
    max-width: 300px;
    padding: 0 15px;
    text-align: center;
  }
`;

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
  min-height: 100%;
  overflow-y: scroll;
  padding: ${({ padding, showPlaceholder }) => `0 ${String(padding)}px ${showPlaceholder ? String(padding) : 16}px`};
  scrollbar-width: none;
`;
