import styled, { css } from 'styled-components';

import { mediaQueries } from 'shared/styles';

export const Option = styled.button`
  align-items: center;
  appearance: none;
  background: #f5f6f7;
  border: none;
  border-radius: ${({ theme }) => theme.customized.radius.sm};
  color: #121516;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 0 0 96px;
  font-size: 12px;
  justify-content: center;
  line-height: ${13 / 12};
  min-height: 60px;
  padding: 2px;
  position: relative;
  transition: all 0.3s;

  @media ${mediaQueries.medium} {
    min-height: 68px;
  }

  ${({ $hasMultipleOptions }) =>
    !$hasMultipleOptions &&
    css`
      flex-basis: 100%;
    `}

  ${({ $isFixedSize, $hasMultipleOptions }) =>
    $isFixedSize
      ? css`
          flex-basis: 72px;
        `
      : css`
          @media ${mediaQueries.medium} {
            flex-basis: 72px;

            ${!$hasMultipleOptions &&
            css`
              flex-basis: 152px;
            `}
          }
        `}

  ${({ $isOutlinedVariant }) =>
    $isOutlinedVariant
      ? css`
          background: #fff;
          border: 1px solid #d2d7da;

          &:hover {
            border-color: #646d72;
          }
        `
      : css`
          background: #f5f6f7;
          border: none;

          &:hover {
            background: #e3e7e9;
          }
        `}

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      border: 2px solid ${theme.customized.colors.buttonsLinks};

      &:hover {
        border-color: ${theme.customized.colors.buttonsLinks};
      }
    `}

  ${({ $blank }) =>
    $blank &&
    css`
      background: #fff;
      border: 2px dashed #e3e7e9;
      flex-grow: 0;
    `}

  b {
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: ${18 / 16};

    @media ${mediaQueries.medium} {
      font-size: 14px;
    }
  }
`;

export const DiscountedPrice = styled.span`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
  gap: 2px;
  justify-content: center;
  line-height: 1;
  margin-top: 4px;

  ${({ $hasMultipleOptions }) =>
    $hasMultipleOptions &&
    css`
      @media ${mediaQueries.medium} {
        flex-direction: column;
      }
    `}
`;

export const OriginalPrice = styled.span`
  color: #485055;
  display: block;
  text-decoration: line-through;
`;

export const Discount = styled.div`
  color: ${({ theme }) => theme.customized.colors.discountTag};
  font-weight: 600;
  white-space: nowrap;
`;

export const Add = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.customized.colors.buttonsLinks};
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 20px;
`;
