import _ from 'lodash';
import PublicEnv from 'shared/utils/public-env';
var amplitudeClient = {
  init: _.noop,
  logEvent: _.noop,
  setUserId: _.noop,
  setUserProperties: _.noop
}; // we need to prevent import on the serverside

if (typeof window !== 'undefined') {// NOOP - Amplitude support removed on 7/15/24.
  // Keeping this file because the events are helpful and will likely be sent elsewhere
}

var setupAmplitude = _.once(function () {// NOOP - Amplitude support removed on 7/15/24.
  // Keeping this file because the events are helpful and will likely be sent elsewhere
});

export var getCartEventProperties = function getCartEventProperties(Cart, UI) {
  var _Cart$costBreakdown$t, _Cart$costBreakdown, _Cart$order, _Cart$order$appliedRe, _Cart$order2, _Cart$costBreakdown2, _Cart$order3, _Cart$dispensary, _Cart$dispensary2;

  return {
    orderTotal: ((_Cart$costBreakdown$t = Cart === null || Cart === void 0 ? void 0 : (_Cart$costBreakdown = Cart.costBreakdown) === null || _Cart$costBreakdown === void 0 ? void 0 : _Cart$costBreakdown.total) !== null && _Cart$costBreakdown$t !== void 0 ? _Cart$costBreakdown$t : 0).toFixed(2),
    rewardRedemption: !!((Cart === null || Cart === void 0 ? void 0 : (_Cart$order = Cart.order) === null || _Cart$order === void 0 ? void 0 : (_Cart$order$appliedRe = _Cart$order.appliedRewards) === null || _Cart$order$appliedRe === void 0 ? void 0 : _Cart$order$appliedRe.length) > 0),
    couponRedemption: !!(Cart !== null && Cart !== void 0 && (_Cart$order2 = Cart.order) !== null && _Cart$order2 !== void 0 && _Cart$order2.coupon),
    saleRedemption: (Cart === null || Cart === void 0 ? void 0 : (_Cart$costBreakdown2 = Cart.costBreakdown) === null || _Cart$costBreakdown2 === void 0 ? void 0 : _Cart$costBreakdown2.credit) > 0,
    paymentType: Cart === null || Cart === void 0 ? void 0 : (_Cart$order3 = Cart.order) === null || _Cart$order3 === void 0 ? void 0 : _Cart$order3.paymentMethod,
    dispensaryId: Cart === null || Cart === void 0 ? void 0 : (_Cart$dispensary = Cart.dispensary) === null || _Cart$dispensary === void 0 ? void 0 : _Cart$dispensary.id,
    dispensaryName: Cart === null || Cart === void 0 ? void 0 : (_Cart$dispensary2 = Cart.dispensary) === null || _Cart$dispensary2 === void 0 ? void 0 : _Cart$dispensary2.name,
    menuVariant: UI.analyticsMenuVariant
  };
};
export var AmplitudeCategory = Object.freeze({
  menu: 'Menu',
  checkout: 'Checkout',
  paymentsMoneris: 'Payments-Moneris',
  paymentsMerrco: 'Payments-Merrco',
  paymentChase: 'Payments-Chase'
});
export var AmplitudeEvents = {
  checkout: {
    placeOrder: "Place Order",
    clickSave: "Click Save",
    goToCheckout: "Go to Checkout",
    createAccount: "Create Account"
  },
  payments: {
    // nesting this assuming there will be other payment modals
    moneris: {
      onSave: "User clicks save",
      onError: "Invalid input"
    }
  },
  menu: {
    viewHomepage: "View Homepage",
    viewCategory: "View a Category Page",
    viewBrand: "View Brand Page",
    viewProduct: "View Product Detail Page",
    addToCart: "Add to Cart",
    quickAdd: "Add to Cart - Quick Add",
    viewSpecials: "View Specials Page",
    clickedIndividualCannabinoid: 'Clicked Individual Cannabinoid'
  }
};
export var AmplitudePaymentFormEventDescriptions = {
  moneris: {
    onSaveSuccess: 'Save Card Info: Success',
    onError: 'Save Card Info: Fail - '
  }
};
export function useAmplitude() {
  setupAmplitude();

  function log(event, data) {
    amplitudeClient.logEvent(event, data);
  }

  return {
    log: log
  };
}