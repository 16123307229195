import _ from 'lodash'; // eslint-disable-next-line import/no-extraneous-dependencies

import Big from 'big.js';
import { getBogoIndividualDiscount } from 'shared/order/utilities';

var getBogoDiscountForAddedItems = function getBogoDiscountForAddedItems(costBreakdownDetails, individualPrice, product, option, quantity, specialsSettings) {
  var _breakdownItem$bogoSa;

  var breakdownItem = _.find(costBreakdownDetails || [], {
    id: product.id || product._id,
    option: option
  });

  var _$reduce = _.reduce((breakdownItem === null || breakdownItem === void 0 ? void 0 : (_breakdownItem$bogoSa = breakdownItem.bogoSavings) === null || _breakdownItem$bogoSa === void 0 ? void 0 : _breakdownItem$bogoSa.individual) || [], function (_ref, savingsObj) {
    var discountTotal = _ref.discountTotal,
        itemPrice = _ref.itemPrice;

    // we don't want to show the discount badge if it's
    // a discount to cart reward
    if (savingsObj.isDiscountToCartReward) {
      return {
        discountTotal: discountTotal,
        itemPrice: itemPrice
      };
    }

    var previousItemsAddedPastCondition = Math.max(0, breakdownItem.quantity - quantity - savingsObj.conditionQuantity);
    var totalItemsAddedPastCondition = Math.max(0, breakdownItem.quantity - savingsObj.conditionQuantity);
    var availableDiscounts = Math.max(0, savingsObj.maxApplicable - previousItemsAddedPastCondition);
    var discountQuantity = Math.min(availableDiscounts, totalItemsAddedPastCondition);
    var discountAmount = getBogoIndividualDiscount(savingsObj, itemPrice).times(discountQuantity);
    return {
      discountTotal: discountTotal.add(discountAmount),
      itemPrice: (specialsSettings === null || specialsSettings === void 0 ? void 0 : specialsSettings.discountBehavior) === 'compounding' ? itemPrice.minus(discountAmount.div(breakdownItem.quantity)) : itemPrice
    };
  }, {
    discountTotal: Big(0),
    itemPrice: Big(individualPrice || 0)
  }),
      bogoDiscount = _$reduce.discountTotal;

  return !_.isNil(bogoDiscount) && bogoDiscount.gt(0) ? bogoDiscount : null;
};

export default getBogoDiscountForAddedItems;