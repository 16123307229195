import React from 'react';
import ContentWrapper from 'components/core/content-wrapper';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ProductImage from 'components/product-image';
import ProductImages from 'components/product-images';
import { Typography } from 'src/typography';
import useColors from 'hooks/use-colors';

export function Container({ children, ...otherProps }) {
  const colors = useColors();

  return (
    <ContentWrapper bg={colors.white} margin='4px 20px 0;' {...otherProps}>
      <RootContainer>{children}</RootContainer>
    </ContentWrapper>
  );
}

const RootContainer = styled.div`
  margin: 0 auto;
  max-width: 1052px;
  min-height: 100%;
  padding: 44px 0 140px 0;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 22px 0 140px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? `center` : `flex-start`)};
  flex-direction: ${({ isMobile }) => (isMobile ? `column` : `row`)};
`;

export const Details = styled.div`
  flex: 1 0 0%;
  min-width: 0;
  margin: ${({ isMobile }) => (isMobile ? `0 28px` : 0)};
  margin-top: 15px;
  width: 100%;
`;

export function Images({ product, isUnavailable }) {
  // eslint-disable-next-line lodash/prefer-lodash-method
  const activeImages = product.images?.filter((img) => img.active);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));

  const resolvedImageWidth = isMobile ? 344 : 450;

  return (
    <ImagesContainer isMobile={isMobile} isUnavailable={isUnavailable} $width={resolvedImageWidth}>
      {activeImages?.length > 0 ? (
        <ProductImages images={product.images} isZoomable width={resolvedImageWidth} />
      ) : (
        <ProductImage product={product} width={resolvedImageWidth} isZoomable />
      )}
    </ImagesContainer>
  );
}

const ImagesContainer = styled.div`
  flex: ${({ isMobile, $width }) => (isMobile ? `0 0 0` : `0 0 ${$width}px`)};
  margin-right: ${({ isMobile }) => (isMobile ? 0 : `62px`)};
  min-width: 0;
  padding-top: 16px;
  padding-bottom: ${({ isMobile }) => (isMobile ? `18px` : 0)};
  border-radius: 3px;
  img {
    border-radius: 3px;
  }
  opacity: ${({ isUnavailable }) => (isUnavailable ? `50%` : `100%`)};
`;

export const AddToCartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const AddToCartErrorContainer = styled.div`
  margin-bottom: ${({ includeBottomMargin }) => (includeBottomMargin ? `20px` : '0')};
`;

export const AddToCartError = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.red[45]}; ;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 39px;
`;

export const CarouselContainer = styled.div`
  margin-bottom: 34px;
`;

export const TaxText = styled.p`
  color: ${({ theme }) => theme.colors.grey[60]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 12px;
  margin-bottom: 20px;
`;

export const UnavailableText = styled.p`
  color: ${({ theme }) => theme.colors.grey[40]};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 35px;
`;
