import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';

import useTranslation from 'hooks/use-translation';

import { ModalExitIcon, ModalFooter } from 'components/core';
import { useModals, ModalNames } from 'components/modals';
import { ModalContent, HeaderText, ModalFooterText, SignUpText as TermsText } from 'components/modals/components';
import { MobileEcommTermsLink } from 'src/mobile-ecomm/components/terms-link';
import SignUpForm from 'components/forms/sign-up-form';
import useUI from 'src/hooks/use-ui';
import useUser from 'src/hooks/use-user';
import { Divider } from 'src/components/divider';
import { AppleLogin, GoogleLogin } from 'src/components/social-login';
import { redirectUrlForMobileEcomm } from 'src/components/modals/login/redirect-mobile-ecomm';

export type SignupModalContentProps = {
  onClose: () => void;
  analyticsEventLabel?: string;
};

export function SignupModalContent({ onClose, analyticsEventLabel }: SignupModalContentProps): JSX.Element {
  const { t } = useTranslation();
  const { token } = useUser();
  const { query } = useRouter();
  const { isMobileEcommApp } = useUI();
  const flags = useFlags();
  const isAppleLoginEnabled = flags[`growth.ecomm.sign-in-apple.rollout`] ?? false;
  const { openModal } = useModals();

  const handleOpenLoginModal = (): void => {
    onClose();
    void openModal(ModalNames.login, analyticsEventLabel);
  };

  useEffect(() => {
    const url = redirectUrlForMobileEcomm({ isMobileEcommApp, query, token });
    if (url) {
      window.location.href = url;
    }
  }, [isMobileEcommApp, query, token]);

  return (
    <>
      {!isMobileEcommApp && <ModalExitIcon onClick={onClose} />}
      <ModalContent pb={isMobileEcommApp ? 30 : 40}>
        <HeaderText data-testid='sign-up-modal-header' pt={26} mb={25} mt={0}>
          {t('signUpModal.headerText', 'Create an Account')}
        </HeaderText>

        <SocialLogins>
          <GoogleLogin redirectUrl={window.location.href} />

          {isAppleLoginEnabled && <AppleLogin redirectUrl={window.location.href} />}
        </SocialLogins>

        <Divider text={isAppleLoginEnabled ? 'or manually enter your information' : 'or enter your information'} />

        <SignUpForm onClose={onClose} analyticsEventLabel={analyticsEventLabel} />
      </ModalContent>

      {isAppleLoginEnabled ? (
        <ModalFooterV2>
          Already have an account?
          <LoginButton data-testid='sign-up-login-link' onClick={handleOpenLoginModal}>
            Login
          </LoginButton>
        </ModalFooterV2>
      ) : (
        <ModalFooter includeBackgroundColor={!isMobileEcommApp}>
          <ModalFooterText xSmall>
            {isMobileEcommApp
              ? t('signUpModal.footerText', `By creating an account, you agree to our`)
              : t('signUpModal.footerText', `By creating an account, you agree to Dutchie's`)}
          </ModalFooterText>

          <StyledTermsText xSmall isMobileEcommApp>
            {isMobileEcommApp ? (
              <MobileEcommTermsLink text={t('signUpModal.terms', 'Terms')} />
            ) : (
              <a data-testid='terms-link' href='/terms' target='_blank' rel='noopener noreferrer'>
                {t('signUpModal.terms', 'Terms')}
              </a>
            )}
          </StyledTermsText>
        </ModalFooter>
      )}
    </>
  );
}

const StyledTermsText = styled(TermsText)`
  margin-left: 0px;
  padding-left: ${({ isMobileEcommApp }) => (isMobileEcommApp ? '3px' : '5px')};
`;

const SocialLogins = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const ModalFooterV2 = styled.div`
  align-items: center;
  background-color: ${({ theme, includeBackgroundColor = true }) =>
    includeBackgroundColor ? theme?.colors?.primaryGrey : `inherit`};
  border-radius: 0 0 28px 28px;
  color: #485055;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: ${20 / 14};
  justify-content: center;
  padding: 20px 32px;
  width: 100%;
`;

const LoginButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
  font: inherit;
  margin-left: 0.5ch;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;
