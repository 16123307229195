import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensaryProducts from 'src/dispensary/hooks/use-dispensary-products';
import usePaths from 'src/hooks/use-paths';
import { ProductCarousel } from 'src/components/carousel-v2/types/product-carousel';
import { GenericCarousel } from './generic-carousel';

export function SpecialsCarousel(props) {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;
  const { section } = props;
  const { loading, products } = useDispensaryProducts({
    filters: { isOnSpecial: true, useCache: true },
    perPage: 25,
  });
  const { href, route } = usePaths({ specials: true });
  const label = `Items on Sale`;

  if (!products?.length && !loading) {
    return null;
  }

  if (isProductCardsV2Enabled) {
    return (
      <ProductCarousel
        products={products}
        isLoading={loading}
        href={href}
        hideTitle={props?.showTitle === false}
        hideLink={props?.showLink === false}
        title={label}
      />
    );
  }

  const sectionProps = {
    ...props,
    isLoading: loading,
    linkLabel: section.linkLabel,
    label,
    products,
    href,
    route,
  };

  return <GenericCarousel {...sectionProps} />;
}
